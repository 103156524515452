// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PreventivoOutputAssicurazioni,
    PreventivoOutputNoteLegali,
} from './';

/**
 * 
 * @export
 * @interface PreventivoRicalcoloOutput
 */
export interface PreventivoRicalcoloOutput {
    /**
     * @type {number}
     * @memberof PreventivoRicalcoloOutput
     */
    numero_preventivo?: number;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    tan?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    taeg?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    prezzo_vendita?: string;
    /**
     * @type {number}
     * @memberof PreventivoRicalcoloOutput
     */
    durata?: number;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    importo_rata?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    importo_dovuto?: string;
    /**
     * @type {number}
     * @memberof PreventivoRicalcoloOutput
     */
    anticipo?: number;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    lordo?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    maxirata?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    interessi?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    spese?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    incasso_rata?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    bollo?: string;
    /**
     * @type {number}
     * @memberof PreventivoRicalcoloOutput
     */
    km_annuali?: number;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    intermediario?: string;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    codiceInternet?: string;
    /**
     * @type {Array<PreventivoOutputAssicurazioni>}
     * @memberof PreventivoRicalcoloOutput
     */
    assicurazioni?: Array<PreventivoOutputAssicurazioni>;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    tipoProdotto?: string;
    /**
     * @type {Array<PreventivoOutputNoteLegali>}
     * @memberof PreventivoRicalcoloOutput
     */
    note_legali?: Array<PreventivoOutputNoteLegali>;
    /**
     * @type {string}
     * @memberof PreventivoRicalcoloOutput
     */
    errore?: string;
}
