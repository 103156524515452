// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RispostaPreference,
} from './';

/**
 * @export
 * @interface QuestionarioPreference
 */
export interface QuestionarioPreference {
    /**
     * @type {string}
     * @memberof QuestionarioPreference
     */
    codiceQuestionario?: string;
    /**
     * @type {string}
     * @memberof QuestionarioPreference
     */
    tipoControparte?: QuestionarioPreferenceTipoControparteEnum;
    /**
     * @type {Array<RispostaPreference>}
     * @memberof QuestionarioPreference
     */
    risposte?: Array<RispostaPreference>;
}

/**
 * @export
 * @enum {string}
 */
export enum QuestionarioPreferenceTipoControparteEnum {
    CO = 'CO',
    CL = 'CL'
}

