// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ListaZipCodeInputParametro,
} from './';

/**
 * 
 * @export
 * @interface ListaZipCodeInput
 */
export interface ListaZipCodeInput {
    /**
     * @type {ListaZipCodeInputParametro}
     * @memberof ListaZipCodeInput
     */
    parametro?: ListaZipCodeInputParametro;
}
