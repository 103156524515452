// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Cassandra
 */
export interface Cassandra {
    /**
     * chilometri
     * @type {number}
     * @memberof Cassandra
     */
    km?: number;
    /**
     * percentuali delle maxirate
     * @type {Array<number>}
     * @memberof Cassandra
     */
    percentuali?: Array<number>;
}
