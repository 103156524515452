// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Commissione
 */
export interface Commissione {
    /**
     * valore della commissione
     * @type {number}
     * @memberof Commissione
     */
    commissione?: number;
    /**
     * utile a selezionare il valore di default
     * @type {boolean}
     * @memberof Commissione
     */
    selected?: boolean;
}
