// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    PreventivoDatiCheckResponse,
} from '../models';

export interface PreventivoDatiCheckGetRequest {
    provenienza: string;
    numeroPreventivo: number;
    numeroPratica: number;
    X_B3_TraceId?: string;
}

/**
 * no description
 */
export class DefaultApi extends BaseAPI {

    /**
     */
    preventivoDatiCheckGet = (requestParameters: PreventivoDatiCheckGetRequest): Observable<PreventivoDatiCheckResponse> => {
        throwIfRequired(requestParameters, 'provenienza', 'preventivoDatiCheckGet');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'preventivoDatiCheckGet');
        throwIfRequired(requestParameters, 'numeroPratica', 'preventivoDatiCheckGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.provenienza && { 'provenienza': requestParameters.provenienza }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
            ...(requestParameters.numeroPratica && { 'numeroPratica': requestParameters.numeroPratica }),
        };

        return this.request<PreventivoDatiCheckResponse>({
            path: '/preventivo/dati/check',
            method: 'GET',
            headers,
            query,
        });
    };

}
