// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PraticaConsumoOkDataErroriServizi,
} from './';

/**
 * @export
 * @interface PraticaConsumoOkDataErrori
 */
export interface PraticaConsumoOkDataErrori {
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    servizi?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    pianofinanziario?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    TITOLAREEFFETTIVO1?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    CL?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    G1?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    G2?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    CO?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    G3?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    E1?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    TITOLAREEFFETTIVO2?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    E2?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    TITOLAREEFFETTIVO3?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    E3?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    detrazioni?: Array<PraticaConsumoOkDataErroriServizi>;
    /**
     * @type {Array<PraticaConsumoOkDataErroriServizi>}
     * @memberof PraticaConsumoOkDataErrori
     */
    pagamento?: Array<PraticaConsumoOkDataErroriServizi>;
}
