// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (...message: any[]): void => {
  process.env.NODE_ENV !== "production" && console.log(...message);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const warn = (...message: any[]): void => {
  process.env.NODE_ENV !== "production" && console.warn(...message);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const info = (...message: any[]): void => {
  process.env.NODE_ENV !== "production" && console.info(...message);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const error = (...message: any[]): void => {
  process.env.NODE_ENV !== "production" && console.error(...message);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const alertBrowser = (...message: any[]): void => {
  process.env.NODE_ENV === "acceptance" && process.env.SHOW_BROWSER_ALERT && alert(...message);
};
