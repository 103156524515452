// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroINR620FLXPLCOBJ
 */
export interface WpNormInputParametroINR620FLXPLCOBJ {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXCNY?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXSTA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXREG?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXDPT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXCNL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXDS1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXDS2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620FLXPLCOBJ
     */
    FLXDS3?: string;
}
