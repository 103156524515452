// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CheckPagamentoOutputData,
    ErrorOutputKOErroriErroriPianofinanziario,
} from './';

/**
 * 
 * @export
 * @interface CheckPagamentoOutput
 */
export interface CheckPagamentoOutput {
    /**
     * @type {boolean}
     * @memberof CheckPagamentoOutput
     */
    success?: boolean;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof CheckPagamentoOutput
     */
    errori?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {CheckPagamentoOutputData}
     * @memberof CheckPagamentoOutput
     */
    data?: CheckPagamentoOutputData;
}
