import produce from "immer";
import { SESSION_ID } from "../config/application";
import { APP_ORIGIN_KEY } from "../config/constants";
import { Middleware, RequestArgs } from "../generated/globals";
import { SessionStorage } from "./utility/storage-v2";

const randomSpanId = (): string => {
  const digits = "0123456789abcdef";
  let n = "";
  for (let i = 0; i < 16; i += 1) {
    const rand = Math.floor(Math.random() * 16);
    n += digits[rand];
  }
  return n;
};

const randomTraceId = (): string => {
  const digits = "0123456789abcdef";
  let n = "";
  for (let i = 0; i < 32; i += 1) {
    const rand = Math.floor(Math.random() * 16);
    n += digits[rand];
  }
  return n;
};

export const addTracingHeaders: Middleware = {
  pre(request: { headers: Record<string, string> }): RequestArgs {
    return produce(request, draft => {
      // fix error when trying to set "app-origin" of undefined
      if (draft.headers === undefined) {
        draft.headers = {};
      }
      draft.headers["app-origin"] = APP_ORIGIN_KEY;
      draft.headers["session-id"] = SessionStorage.read(SESSION_ID) || "";
      draft.headers["X-B3-TraceId"] = randomTraceId();
      draft.headers["X-B3-SpanId"] = randomSpanId();
    });
  },
};
