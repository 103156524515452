// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TipoIntermediario,
} from './';

/**
 * Esistono due tipi di provvigione: <br/> <ul>   <li><strong>Base:</strong> è la provvigione per l\'utilizzo di una determinata tabella finanziaria, in questo caso il campo servizio non è valorizzato;   <li><strong>Servizio Assicurativo:</strong> è la provvigione relativa all\'utilizzo di un determinato servizio assicurativo, in questo caso il campo servizio sarà valorizzato con il codice del servizio assicurativo; </ul>
 * @export
 * @interface Provvigione
 */
export interface Provvigione {
    /**
     * codice intermediario destinatario della provvigione
     * @type {number}
     * @memberof Provvigione
     */
    intermediario?: number;
    /**
     * @type {string}
     * @memberof Provvigione
     */
    servizio?: string;
    /**
     * @type {TipoIntermediario}
     * @memberof Provvigione
     */
    tipoIntermediario?: TipoIntermediario;
    /**
     * totale importo provvigione
     * @type {number}
     * @memberof Provvigione
     */
    totale?: number;
}
