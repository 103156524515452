// tslint:disable
/**
 * cittadinanza
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    CittadinanzaOutput,
    InlineObject,
} from '../models';

export interface LoaderCittadinanzaRequest {
    requestParameters?: InlineObject;
}

/**
 * no description
 */
export class CittadinanzaApi extends BaseAPI {

    /**
     */
    loaderCittadinanza = (requestParameters: LoaderCittadinanzaRequest): Observable<CittadinanzaOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CittadinanzaOutput>({
            path: '/cittadinanza',
            method: 'POST',
            headers,
            body: requestParameters.requestParameters,
        });
    };

}
