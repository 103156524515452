// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    ListaStradeInput,
    ListaStradeOutput,
    ListaZipCodeInput,
    ListaZipCodeOutput,
    WpNormInput,
    WpNormOutput,
} from '../models';

export interface ListaStradeRequest {
    Input: ListaStradeInput;
}

export interface ListaZipCodeRequest {
    Input: ListaZipCodeInput;
}

export interface WpNormRequest {
    Input: WpNormInput;
}

/**
 * no description
 */
export class NormalizzatoreApi extends BaseAPI {

    /**
     */
    listaStrade = (requestParameters: ListaStradeRequest): Observable<ListaStradeOutput> => {
        throwIfRequired(requestParameters, 'Input', 'listaStrade');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ListaStradeOutput>({
            path: '/lista-strade/',
            method: 'POST',
            headers,
            body: requestParameters.Input,
        });
    };

    /**
     */
    listaZipCode = (requestParameters: ListaZipCodeRequest): Observable<ListaZipCodeOutput> => {
        throwIfRequired(requestParameters, 'Input', 'listaZipCode');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ListaZipCodeOutput>({
            path: '/lista-zipcode/',
            method: 'POST',
            headers,
            body: requestParameters.Input,
        });
    };

    /**
     */
    wpNorm = (requestParameters: WpNormRequest): Observable<WpNormOutput> => {
        throwIfRequired(requestParameters, 'Input', 'wpNorm');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<WpNormOutput>({
            path: '/wpNorm',
            method: 'POST',
            headers,
            body: requestParameters.Input,
        });
    };

}
