// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Contiene il tokwn di autenticazione JWT e l\'id del preventivo
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * @type {string}
     * @memberof AuthResponse
     */
    authToken?: string;
    /**
     * Id del preventivo associato alla sessione
     * @type {string}
     * @memberof AuthResponse
     */
    prev_id?: string;
}
