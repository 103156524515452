// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroKIIELECLI
 */
export interface WpNormInputParametroKIIELECLI {
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIIELECLI
     */
    CDPFON?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIIELECLI
     */
    CDPCLI?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIIELECLI
     */
    CDXCLI?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIIELECLI
     */
    PRPIND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIIELECLI
     */
    PRXIND?: string;
}
