import React, { FunctionComponent } from "react";
import { isFunction } from "../../../shared/lib/utility/types";
import { TForms } from "../../lib/dataHandling/types";
import { Consumer, IWizardContext } from "./wizard";

interface IWizardStepperInjectedProps extends IWizardContext, IWizardStepperProps {
  handleClick: (forms: Partial<TForms>) => (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
}

type WizardStepperRenderProp = (props: IWizardStepperInjectedProps) => React.ReactNode;

interface IWizardStepperProps {
  id?: string;
  children?: React.ReactNode | WizardStepperRenderProp;
  render?: WizardStepperRenderProp;
}

export const WizardStepper: FunctionComponent<IWizardStepperProps> = props => {
  // cleanedProps does NOT contain neither children nor render. We don't want them in the Render Prop.
  const { children, render, ...cleanedProps } = props;
  const { id } = cleanedProps;

  return (
    <Consumer>
      {contextProps => {
        const { activeStepId, changeStep, forceShow } = contextProps;

        const handleClick = (forms: TForms) => () => {
          const currentFormIndex = forms.findIndex(form => form.formName === activeStepId);
          const targetedFormIndex = forms.findIndex(form => form.formName === id);

          const isLessThanCurrent = targetedFormIndex < currentFormIndex;
          if (activeStepId && id && !forceShow && changeStep && isLessThanCurrent) {
            changeStep(id);
          }
        };

        const isActive = id === activeStepId;

        const injectedProps = {
          ...cleanedProps,
          ...contextProps,
          handleClick,
          isActive,
        };

        if (render) {
          return render(injectedProps);
        }
        if (children && isFunction(children)) {
          // render prop
          return children(injectedProps);
        }
        if (children) {
          // render children
          return children;
        }

        return null;
      }}
    </Consumer>
  );
};
