// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NuovaControparteContropartiCoobbligato,
    NuovaControparteContropartiGarante1,
    NuovaControparteContropartiGarante2,
} from './';

/**
 * @export
 * @interface NuovaControparteControparti
 */
export interface NuovaControparteControparti {
    /**
     * @type {NuovaControparteContropartiCoobbligato}
     * @memberof NuovaControparteControparti
     */
    coobbligato?: NuovaControparteContropartiCoobbligato;
    /**
     * @type {NuovaControparteContropartiGarante1}
     * @memberof NuovaControparteControparti
     */
    garante1?: NuovaControparteContropartiGarante1;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    garante2?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    garante3?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    esponente1?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    esponente2?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    esponente3?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    titolareeffettivo1?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    titolareeffettivo2?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante2}
     * @memberof NuovaControparteControparti
     */
    titolareeffettivo3?: NuovaControparteContropartiGarante2;
    /**
     * @type {NuovaControparteContropartiGarante1}
     * @memberof NuovaControparteControparti
     */
    titolareeffettivo4?: NuovaControparteContropartiGarante1;
}
