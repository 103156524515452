import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

type TProps = RouteComponentProps;

class ScrollToTop extends React.Component<TProps> {
  public componentDidUpdate(prevProps: TProps) {
    if (this.props.location.pathname !== prevProps.location.pathname && prevProps.history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
