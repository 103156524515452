/* ---------------------------------
.Incompatibility Modal
--------------------------------- */

import Dialog, { DialogContent, DialogProps, DialogTitle } from "@material/react-dialog";
import React, { useEffect } from "react";
import { TFieldValue } from "../../../caricamento/lib/dataHandling/types";
import {
  INCOMPATIBILITY_DIALOG_DISCLAIMER,
  INCOMPATIBILITY_DIALOG_DOWNLOAD_DISCLAIMER,
  INCOMPATIBILITY_DIALOG_DOWNLOAD_DISCLAIMER_ERROR,
  INCOMPATIBILITY_DIALOG_MODAL_CL_CHANGE_HEAD,
  INCOMPATIBILITY_DIALOG_MODAL_GENERIC_VARIATION_HEAD,
  INCOMPATIBILITY_DIALOG_PROCEED,
  INCOMPATIBILITY_DIALOG_PROCEED_WITHOUT_INSURANCE,
  LABEL_PROSEGUI,
  LOADING_LABEL,
} from "../../constants/labels";
import {
  MODAL_CL_CHANGE,
  MODAL_GENERIC_VARIATION,
  MODAL_PROVINCE_VARIATION,
  STATUS_ERROR,
  STATUS_GET_ELIGIBILITA,
  STATUS_GET_ELIGIBILITA_CHECK,
  STATUS_GET_INFO_DOCS,
  STATUS_GET_PREVENTIVO_DATI_CHECK,
  STATUS_WAITING,
} from "../../logic/modificaPratica/consts";
import { IState as IModificaPratica, TModalType } from "../../logic/modificaPratica/types";
import Button from "../button/button";
import "./incompatibility_modal.scss";
import { insuranceDescriptionLabel } from "./utils";

interface IOwnProps extends Partial<DialogProps<HTMLElement>> {
  modificaPratica: IModificaPratica;
  onDownload: () => void;
  onProceed: (modalType: TModalType) => void;
  onDismiss: (modalType: TModalType) => void;
  onOpen?: () => void;
  onSwitch?: () => void;
  titleLabel?: string;
  modalType: TModalType;
  provincia?: TFieldValue;
  loadings?: {
    preventivoRicalcoloLoading: boolean;
    downsellingLoading: boolean;
    infoDocsLoading: boolean;
  };
}

type Props = IOwnProps;

const IncompatibilityModal: React.FunctionComponent<Props> = ({
  titleLabel,
  modificaPratica,
  modalType,
  provincia,
  loadings,
  onDownload,
  onDismiss,
  onProceed,
  onOpen,
  onSwitch,
}) => {
  /**Api calls on open modal */
  useEffect(() => {
    if (onOpen) {
      onOpen();
    }
  }, []);

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss(modalType);
    }
  };

  const handleProceed = (toSwitch: boolean) => {
    if (modalType === MODAL_CL_CHANGE && toSwitch && onSwitch) {
      onSwitch();
    }
    if (onProceed) {
      onProceed(modalType);
    }
  };

  const proceedWithoutInsuranceButton = (
    title: string,
    toSwitch: boolean,
    theme?: "primary" | "secondary" | "success" | "error" | "complete" | "error-outline" | "secondary-outline"
  ): React.ReactNode => {
    return (
      <Button
        className="incompatibility-modal__without-insurance-button"
        theme={theme}
        fullWidth
        size="big"
        // disabled={status !== "STATUS_POST_PREVENTIVO_RICALCOLO"}
        onClick={() => handleProceed(toSwitch)}
        isLoading={loadings && loadings.preventivoRicalcoloLoading}
      >
        {title}
      </Button>
    );
  };

  const downloadInfoDocsAndProceed = (modificaPratica: IModificaPratica): React.ReactNode => {
    switch (modificaPratica.status) {
      case STATUS_GET_ELIGIBILITA:
      case STATUS_GET_ELIGIBILITA_CHECK:
      case STATUS_GET_PREVENTIVO_DATI_CHECK:
        return (
          <Button
            className="download-button"
            theme="success"
            fullWidth
            size="big"
            onClick={onDownload}
            isLoading={loadings && loadings.infoDocsLoading}
          >
            <i className="material-icons download-button__icon">get_app</i>
            <span className="download-button__label">{INCOMPATIBILITY_DIALOG_DOWNLOAD_DISCLAIMER}</span>
          </Button>
        );
      case STATUS_ERROR:
        return (
          <Button className="download-button" theme="error" fullWidth size="big" onClick={onDownload}>
            <i className="material-icons download-button__icon">report_problem</i>
            <span className="download-button__label">{INCOMPATIBILITY_DIALOG_DOWNLOAD_DISCLAIMER_ERROR}</span>
          </Button>
        );
      case STATUS_GET_INFO_DOCS:
        return (
          <Button className="download-button" theme="success" fullWidth size="big" onClick={() => handleProceed(false)}>
            <span className="download-button__label">{INCOMPATIBILITY_DIALOG_PROCEED}</span>
          </Button>
        );
      case STATUS_WAITING:
      default:
        return (
          <Button theme="success" fullWidth size="normal" isLoading={true}>
            {LOADING_LABEL}
          </Button>
        );
    }
  };

  switch (modalType) {
    case MODAL_PROVINCE_VARIATION:
      return (
        <Dialog open={true} escapeKeyAction={""} scrimClickAction={""} className="incompatibility-modal">
          <DialogTitle className="incompatibility-modal__dialog-title">{titleLabel}</DialogTitle>
          <DialogContent className="incompatibility-modal__dialog-content">
            <div className="incompatibility-modal__dialog-text">
              <p>
                Hai inserito come provincia di residenza {provincia} che sembra essere diversa da quella inserita nel
                preventivo.
              </p>

              <p>Confermi la tua provincia di residenza?</p>
              <div className="row center-sm">
                <div className="col-xs-12 col-sm-8">
                  {proceedWithoutInsuranceButton("Si, prosegui", false, "success")}
                </div>
              </div>
              <div className="row center-sm text-center">
                <div className="col-xs-12 col-sm-8">
                  <Button
                    className="incompatibility-modal__without-insurance-button"
                    theme="secondary"
                    fullWidth
                    size="big"
                    onClick={handleDismiss}
                  >
                    No, correggi
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );

    case MODAL_CL_CHANGE:
      return (
        <Dialog open={true} escapeKeyAction={""} scrimClickAction={""} className="incompatibility-modal">
          <DialogTitle className="incompatibility-modal__dialog-title">{titleLabel}</DialogTitle>
          <DialogContent className="incompatibility-modal__dialog-content">
            <div className="incompatibility-modal__dialog-text">
              <p dangerouslySetInnerHTML={{ __html: INCOMPATIBILITY_DIALOG_MODAL_CL_CHANGE_HEAD }}></p>

              <h2 className="incompatibility-modal__insurance-name">{"CREDIT LIFE"}</h2>
              <h3 className="incompatibility-modal__insurance-label">{insuranceDescriptionLabel(modificaPratica)}</h3>
            </div>
            <p dangerouslySetInnerHTML={{ __html: INCOMPATIBILITY_DIALOG_DISCLAIMER }}></p>
            <div className="row center-sm">
              <div className="col-xs-12 col-sm-8">{downloadInfoDocsAndProceed(modificaPratica)}</div>
            </div>
            <div className="row center-sm text-center">
              <div className="col-xs-12 col-sm-8">
                <h4>Oppure</h4>
                {proceedWithoutInsuranceButton(INCOMPATIBILITY_DIALOG_PROCEED_WITHOUT_INSURANCE, true)}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );

    case MODAL_GENERIC_VARIATION:
      return (
        <Dialog open={true} escapeKeyAction={""} scrimClickAction={""} className="incompatibility-modal">
          <DialogTitle className="incompatibility-modal__dialog-title">{titleLabel}</DialogTitle>
          <DialogContent className="incompatibility-modal__dialog-content">
            <div className="incompatibility-modal__dialog-text">
              <p dangerouslySetInnerHTML={{ __html: INCOMPATIBILITY_DIALOG_MODAL_GENERIC_VARIATION_HEAD }}></p>
            </div>
            <div className="row center-sm text-center">
              <div className="col-xs-12 col-sm-8">
                {proceedWithoutInsuranceButton(LABEL_PROSEGUI, false, "success")}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
  }
};

export default IncompatibilityModal;
