/**
 * @param key
 * @param value
 * @return {Boolean} success
 */
export const setToLocalStorage = (key: string, value: string): boolean => {
  if (!window.localStorage) {
    return false;
  }

  window.localStorage.setItem(key, value);
  return true;
};

export const getFromLocalStorage = (key: string): string | undefined => {
  if (!window.localStorage) {
    return undefined;
  }

  return window.localStorage.getItem(key) ?? undefined;
};

export const setToSessionStorage = (key: string, value: string): boolean => {
  if (!window.sessionStorage) {
    return false;
  }

  window.sessionStorage.setItem(key, value);
  return true;
};

export const getFromSessionStorage = (key: string): string | undefined => {
  if (!window.sessionStorage) {
    return undefined;
  }

  return window.sessionStorage.getItem(key) ?? undefined;
};
