// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PraticaSalvaTesseraSanitariaInputTesseraSanitaria
 */
export interface PraticaSalvaTesseraSanitariaInputTesseraSanitaria {
    /**
     * @type {string}
     * @memberof PraticaSalvaTesseraSanitariaInputTesseraSanitaria
     */
    nome?: string;
    /**
     * @type {string}
     * @memberof PraticaSalvaTesseraSanitariaInputTesseraSanitaria
     */
    cognome?: string;
    /**
     * @type {string}
     * @memberof PraticaSalvaTesseraSanitariaInputTesseraSanitaria
     */
    codiceFiscale?: string;
    /**
     * @type {string}
     * @memberof PraticaSalvaTesseraSanitariaInputTesseraSanitaria
     */
    dataDiNascita?: string;
    /**
     * @type {string}
     * @memberof PraticaSalvaTesseraSanitariaInputTesseraSanitaria
     */
    numeroTessera?: string;
    /**
     * @type {string}
     * @memberof PraticaSalvaTesseraSanitariaInputTesseraSanitaria
     */
    scadenzaTesseraSanitaria?: string;
}
