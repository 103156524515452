import classNames from "classnames";
import { Field, FieldProps, Form, withFormik } from "formik";
import set from "lodash.set";
import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../../shared/components/button/button";
import { FormInfo } from "../../../../shared/components/form_info/form_info";
import TextField from "../../../../shared/components/text_field/text_field";
import { LABEL_PROSEGUI } from "../../../../shared/constants/labels";
import { DatiVeicoloOutput } from "../../../../shared/generated/whiteLabel";
import { isFormSectionHidden } from "../../../../shared/lib/form/form";
import { TFieldName, TSomeFormValues } from "../../../lib/dataHandling/types";
import { getVehicleData } from "../../../logic/caricamento/actions";
import { IStoreState } from "../../../types/store";
import { useGoogleTagManager } from "../hooks/useGoogleTagManager";

interface IOwnProps {
  preventivoId: string;
  isReviewing: boolean;
  isReviewingShowAll: boolean;
  isReviewingShowForm?: boolean;
  onSubmit: () => void;
  isLoading: boolean;
  onPartialSubmit?: (values: TSomeFormValues) => void;
}

const FormVehicleData: FunctionComponent<IOwnProps> = ({
  preventivoId,
  isReviewing,
  isReviewingShowAll,
  isReviewingShowForm = false,
  isLoading,
  // isSubmitting,
  onPartialSubmit,
}) => {
  const getRegularFormItemClass = (fieldName: "targa" | "telaio") => {
    return classNames("col-xs-12", "col-sm-6", {
      "field-hidden":
        isFormSectionHidden(isReviewing, isReviewingShowAll, fieldName as TFieldName, {}) && !isReviewingShowForm,
    });
  };

  const [state, setState] = useState<{ isLoading: boolean; values: DatiVeicoloOutput }>({
    isLoading: false,
    values: { targa: "", telaio: "" },
  });

  useGoogleTagManager("/dati-veicolo", "Dati Veicolo");

  useEffect(() => {
    const preventivoIdInt = parseInt(preventivoId);
    setState(prevState => ({ ...prevState, isLoading: true }));
    getVehicleData(preventivoIdInt).subscribe(values => {
      values && setState({ isLoading: false, values });
    });
  }, []);

  return (
    <Form>
      <div className="row">
        <div className={getRegularFormItemClass("targa")}>
          <Field name="tipoDocumento">
            {(field: FieldProps<"targa">) => (
              <TextField
                formikField={set(field, "field.value", state.values.targa)}
                isLoading={state.isLoading}
                label="Targa"
                isUpperCase={true}
                disabled={true}
              />
            )}
          </Field>
        </div>
        <div className={getRegularFormItemClass("telaio")}>
          <Field name="tipoDocumento">
            {(field: FieldProps<"telaio">) => (
              <TextField
                formikField={set(field, "field.value", state.values.telaio)}
                isLoading={state.isLoading}
                label="Telaio"
                isUpperCase={true}
                disabled={true}
              />
            )}
          </Field>
        </div>
      </div>
      <p>Questi campi non sono modificabili dal cliente e sono valorizzati automaticamente dal sistema.</p>

      {!isReviewing ? (
        <React.Fragment>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                isLoading={isLoading}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </React.Fragment>
      ) : onPartialSubmit ? (
        <div>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                isLoading={isLoading}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </div>
      ) : null}
    </Form>
  );
};

const FormWithFormik = withFormik<IOwnProps, never>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit();
  },
})(FormVehicleData);

const mapStateToProps = (state: IStoreState) => ({
  preventivoId: state.authentication.preventivoId,
  isLoading: state.caricamento.isLoading,
});

export default connect(mapStateToProps)(FormWithFormik);
