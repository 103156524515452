import { useEffect, useState } from "react";
import { ComuniObservableGenerator, IComune, IProvincia, ProvinceObservableGenerator } from "./normalizzatore.types";

// TODO:
//  - Change useState to useReducer (?)
//  - Add loading status

interface IUseComuniProvince {
  comuni: Record<string, IComune[]> | null;
  province: IProvincia[];
  getComuniForProvincia: (provincia: string, soppresse?: boolean) => void;
}

export const useComuniProvince = (
  province$: ProvinceObservableGenerator,
  comuni$: ComuniObservableGenerator
): IUseComuniProvince => {
  const [province, setProvince] = useState<IProvincia[]>([]);
  const [comuni, setComuni] = useState<Record<string, IComune[]> | null>({});

  /**
   * Automatically fetch province on startup
   */
  useEffect(() => {
    province$().subscribe(province => {
      const provinceNotSuppress = province?.filter(provincia => !provincia.soppressa);
      if (provinceNotSuppress) {
        setProvince(provinceNotSuppress);
      }
    });
  }, []);

  /**
   * Set a list of Comuni to the given Provincia
   * @param provincia {string} The 'codice' value for provincia (e.g "VR")
   * @param newComuni {IComune[]} A list of Comuni for the given provincia
   */
  const setComuniForProvincia = (provincia: string, newComuni: IComune[] | null) => {
    if (newComuni !== null) {
      setComuni(prevState => ({
        ...prevState,
        [provincia]: newComuni,
      }));
    }
  };

  /**
   * Get a list of Comuni for the given Provincia
   * If the list of comuni is not available, use comuni$ to fetch it from the APIs
   * @param provincia {string} The 'codice' value for provincia (e.g "VR")
   * @param soppresse {boolean} Also get suppressed province
   */
  const getComuniForProvincia = (provincia: string, soppresse?: boolean) => {
    if (provincia && comuni && !comuni[provincia]) {
      comuni$(provincia, soppresse).subscribe(comuni => {
        // console.log("[useComuniProvincia] Fetch comuni %o for provincia %o", comuni, provincia);
        setComuniForProvincia(provincia, comuni);
      });
    }
  };

  return {
    comuni,
    province,
    getComuniForProvincia,
  };
};
