// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ListaStradeOutputWpLstZipResultLSTERR,
    WpNormInputParametroSOG,
} from './';

/**
 * @export
 * @interface WpNormInputParametro
 */
export interface WpNormInputParametro {
    /**
     * @type {Array<WpNormInputParametroSOG>}
     * @memberof WpNormInputParametro
     */
    SOG?: Array<WpNormInputParametroSOG>;
    /**
     * @type {ListaStradeOutputWpLstZipResultLSTERR}
     * @memberof WpNormInputParametro
     */
    ERR?: ListaStradeOutputWpLstZipResultLSTERR;
}
