// tslint:disable
/**
 * servizi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    LoaderServiziOutputDataServiziCO,
    LoaderServiziOutputDataServiziLE,
} from './';

/**
 * @export
 * @interface LoaderServiziOutputDataServizi
 */
export interface LoaderServiziOutputDataServizi {
    /**
     * @type {Array<LoaderServiziOutputDataServiziCO>}
     * @memberof LoaderServiziOutputDataServizi
     */
    CO?: Array<LoaderServiziOutputDataServiziCO>;
    /**
     * @type {Array<LoaderServiziOutputDataServiziLE>}
     * @memberof LoaderServiziOutputDataServizi
     */
    LE?: Array<LoaderServiziOutputDataServiziLE>;
}
