import get from "lodash.get";
import has from "lodash.has";
import { parse } from "qs";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import LoadingScreen from "../../../shared/components/loading_screen/loading_screen";
import { SESSION_ID } from "../../../shared/config/application";
import {
  EXIT_URL_QUERY_PARAMETER,
  EXIT_URL_STORAGE_KEY,
  ID_CAMPAIGN_SESSION_KEY,
  ID_MEDIA_SESSION_KEY,
} from "../../../shared/constants/application";
import { ERROR_PAGE_TYPES } from "../../../shared/constants/errorPageLabels";
import { resolveRoute } from "../../../shared/lib/utility/resolveRoutes";
import { setToSessionStorage } from "../../../shared/lib/utility/storage";
import IAction from "../../../shared/types/actions";
import { TRoute } from "../../../shared/types/routes";
import { authCaricamento, authFatalError, setToken } from "../../logic/authentication/actions";
import { IState as IAuthenticationState } from "../../logic/authentication/types";
import { IStoreState } from "../../types/store";

interface IOwnProps {
  successRoute: TRoute;
  errorRoute: TRoute;
}
interface ISelectorProps {
  authentication: IAuthenticationState;
}

interface IDispatchProps {
  authCaricamento: (authToken: string) => void;
  setToken: () => IAction;
  authFatalError: () => void;
}

type TProps = IOwnProps & ISelectorProps & IDispatchProps;

const Authentication: React.FunctionComponent<TProps> = ({
  authCaricamento,
  authFatalError,
  authentication,
  setToken,
  successRoute,
  errorRoute,
}) => {
  const actualSuccessRoute = resolveRoute(successRoute);
  const actualErrorRoute = resolveRoute(errorRoute);

  // The hooks of react-router
  const location = useLocation();
  const params = useParams();

  // The variable token takes either the value passed as parameter
  // from the caller component or the value contained in the URL
  const token = get(params, "token");

  if (!token) {
    authFatalError();
    return <Redirect to={actualErrorRoute} />;
  }

  if (token && authentication.authError) {
    const errorPage = `/error/${ERROR_PAGE_TYPES.AUTHENTICATION_FAILED}`;
    return <Redirect to={errorPage} />;
  }

  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  const hasExitUrl = has(queryParams, EXIT_URL_QUERY_PARAMETER);
  const hasIdMedia = has(queryParams, ID_MEDIA_SESSION_KEY);
  const hasIdCampaign = has(queryParams, ID_CAMPAIGN_SESSION_KEY);

  if (hasExitUrl) {
    // save exitUrl on SessionStorage
    setToSessionStorage(EXIT_URL_STORAGE_KEY, get(queryParams, EXIT_URL_QUERY_PARAMETER));
  }

  if (hasIdMedia) {
    // save idMedia on SessionStorage
    setToSessionStorage(ID_MEDIA_SESSION_KEY, get(queryParams, ID_MEDIA_SESSION_KEY));
  }

  if (hasIdCampaign) {
    //save idCampaign on SessionStorage
    setToSessionStorage(ID_CAMPAIGN_SESSION_KEY, get(queryParams, ID_CAMPAIGN_SESSION_KEY));
  }

  if (authentication.jwt) {
    return <Redirect to={actualSuccessRoute} />;
  }

  useEffect(() => {
    setToSessionStorage(SESSION_ID, token);
    authCaricamento(token);
  }, [token]);

  useEffect(() => {
    if (authentication.preventivoId && !authentication.jwt) {
      setToken();
    }
  }, [authentication]);

  return <LoadingScreen />;
};

const mapStateToProps = (state: IStoreState): ISelectorProps => ({
  authentication: state.authentication,
});

const mapDispatchToProps: IDispatchProps = {
  authCaricamento,
  setToken,
  authFatalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
