import Select, { Option } from "@material/react-select";
import { HelperText } from "@material/react-text-field";
import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash.get";
import * as React from "react";
import { IDataAttributes } from "../../../caricamento/types/form";
import { LOADING_LABEL } from "../../constants/labels";
import "./select_field.scss";

interface IOwnProps {
  // Shrink the field to a smallest variant. Defaults to true
  dense?: boolean;
  // Disable the field. Defaults to false
  disabled?: boolean;
  // Formik render prop
  formikField: FieldProps;
  fullWidth?: boolean;
  helperText?: string;
  isLoading?: boolean;
  isResettable?: boolean;
  // The label for the field
  label?: string;
  options?: Array<string | React.HTMLProps<HTMLOptionElement>>;
  serverSideError?: string;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
}

type Props = IOwnProps;

const SelectField: React.FunctionComponent<Props> = ({
  dense = false,
  disabled = false,
  formikField,
  fullWidth = true,
  helperText = "",
  isLoading = false,
  isResettable = false,
  label = "",
  options = [],
  serverSideError,
  dataAttr,
}) => {
  const { field, form } = formikField;
  const isPristine = !get(form.touched, field.name);
  const isValid = !((!isPristine && get(form.errors, field.name)) || serverSideError);

  const classes = classNames({
    "mdc-select--invalid": !isValid,
    "select-field--dense": dense,
    "select-field--fullwidth": fullWidth,
  });

  const emptyOption = {
    disabled: !isResettable,
    label: "", // label is an empty string because the Select label will be shown as placeholder when the field is empty.
    value: "",
  };

  const opts = [emptyOption, ...options];

  const optionClickHandler = (e: React.MouseEvent<HTMLOptionElement>) => {
    form.setFieldValue(field.name, e.target.value);
  };

  const renderOptions = (options: (string | React.HTMLProps<HTMLOptionElement>)[]): JSX.Element[] =>
    options.map((option, index) => {
      if (typeof option === "string") {
        return (
          <Option key={index} value={option} onClick={optionClickHandler}>
            {option}
          </Option>
        );
      }
      return (
        <Option key={index} value={option.value} onClick={optionClickHandler}>
          {option.label || option.value}
        </Option>
      );
    });

  return formikField ? (
    <div className="form__field select-field">
      <Select
        data-cy={dataAttr && dataAttr.cypressSelector}
        className={classes}
        disabled={disabled || isLoading}
        helperText={
          isLoading ? (
            <HelperText persistent={true} isValidationMessage={false}>
              {LOADING_LABEL}
            </HelperText>
          ) : serverSideError ? (
            <HelperText isValidationMessage={true} persistent={true} validation={true}>
              {serverSideError}
            </HelperText>
          ) : isPristine || isValid ? (
            helperText ? (
              <HelperText persistent={true} isValidationMessage={false}>
                {helperText}
              </HelperText>
            ) : (
              <HelperText>{/* Empty Component */}</HelperText>
            )
          ) : (
            <HelperText
              isValidationMessage={true}
              persistent={true}
              validation={true}
              className={classNames({ "mdc-text-field-helper-text--invalid": !isValid })}
            >
              {get(form.errors, field.name)}
            </HelperText>
          )
        }
        label={label}
        outlined={false}
        {...field}
      >
        {renderOptions(opts)}
      </Select>
    </div>
  ) : null;
};

export default SelectField;
