import { useEffect } from "react";
import {
  ATTIVITA_LAVORATORE_AUTONOMO,
  INCOMPATIBLE_AMOUNT_LIMIT,
  INCOMPATIBLE_DURATION_LIMIT,
  PROFESSIONE_PENSIONE_INVALIDITA,
} from "../../../../shared/constants/application";
import { INCOMPATIBLE_AMOUNT_OR_DURATION_TEXT, INCOMPATIBLE_PENSION_TEXT } from "../../../../shared/constants/labels";
import { PreventivoOutput } from "../../../../shared/generated/whiteLabel";
import { getFeatureFlag } from "../../../../shared/lib/featureFlagsFunctions";
import { TFieldValue } from "../../../lib/dataHandling/types";
import { isCreditLifeInsurance } from "../caricamento.utils";

export const useIncompatibilityCustomer = (
  preventivo: PreventivoOutput | undefined,
  setIsIncompatible: (isIncompatible: boolean, message: string) => void,
  attivita: TFieldValue,
  professione: TFieldValue
): void => {
  const incompatibilePensionCheck = getFeatureFlag("incompatibilePensionCheck");
  const selfEmployedCheck = getFeatureFlag("selfEmployedCheck");

  useEffect(() => {
    if (preventivo) {
      const prezzoVendita = Number(preventivo.prezzo_vendita);
      const durata = Number(preventivo.durata);

      if (
        attivita === ATTIVITA_LAVORATORE_AUTONOMO &&
        (durata > INCOMPATIBLE_DURATION_LIMIT || prezzoVendita > INCOMPATIBLE_AMOUNT_LIMIT) &&
        selfEmployedCheck
      ) {
        setIsIncompatible(true, `${INCOMPATIBLE_AMOUNT_OR_DURATION_TEXT}`);
      }

      if (
        professione === PROFESSIONE_PENSIONE_INVALIDITA &&
        isCreditLifeInsurance(preventivo.assicurazioni) &&
        incompatibilePensionCheck
      ) {
        setIsIncompatible(true, INCOMPATIBLE_PENSION_TEXT);
      }

      // setIsIncompatible(false, "");
    }
  }, [attivita, professione]);
};
