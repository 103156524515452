// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AbstractPreventivoInput,
    Assicurazione,
    ModalitaPagamento,
    PreventivoConsumoInputAllOf,
    PreventivoConsumoInputAllOfPersonalizzazioni,
    TipologiaAnagrafica,
    User,
} from './';

/**
 * modello complessivo degli attributi di input
 * @export
 * @interface PreventivoConsumoInput
 */
export interface PreventivoConsumoInput {
    /**
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    codiceInternet?: string;
    /**
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    intermediario: string;
    /**
     * codice del bene
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    codiceBene: string;
    /**
     * codice del prodotto
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    codiceProdotto: string;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    commissioni: number;
    /**
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    descrizioneFinanziamento: string;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    durata: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    finanziato: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    prezzoVendita: number;
    /**
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    tabellaFinanziaria: string;
    /**
     * codice del tipo di bene
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    tipoBene?: number;
    /**
     * codice della marca del veicolo
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    marca?: string;
    /**
     * codice del modello di veicolo
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    modello?: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    eurotax?: string;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    acconto?: number;
    /**
     * @type {Array<Assicurazione>}
     * @memberof PreventivoConsumoInput
     */
    assicurazioni?: Array<Assicurazione>;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    chilometraggio?: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    cilindrata?: number;
    /**
     * @type {Date}
     * @memberof PreventivoConsumoInput
     */
    dataImmatricolazione?: Date;
    /**
     * @type {Date}
     * @memberof PreventivoConsumoInput
     */
    dataNascita?: Date;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    importoMaxirata?: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    listino?: number;
    /**
     * @type {ModalitaPagamento}
     * @memberof PreventivoConsumoInput
     */
    pagamento?: ModalitaPagamento;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    percentualeCapitaleResiduo?: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    percentualeMaxirata?: number;
    /**
     * @type {PreventivoConsumoInputAllOfPersonalizzazioni}
     * @memberof PreventivoConsumoInput
     */
    personalizzazioni?: PreventivoConsumoInputAllOfPersonalizzazioni;
    /**
     * @type {string}
     * @memberof PreventivoConsumoInput
     */
    provincia?: string;
    /**
     * @type {TipologiaAnagrafica}
     * @memberof PreventivoConsumoInput
     */
    tipoAnagrafica?: TipologiaAnagrafica;
    /**
     * importo eurotax
     * @type {number}
     * @memberof PreventivoConsumoInput
     */
    valoreEurotax?: number;
}
