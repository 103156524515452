import { convertStringToNumber, formatCurrency } from "../../lib/utility/numbers";

export const summaryFormat = (value?: string): string | undefined =>
  value && formatCurrency(convertStringToNumber(value));

export const beneRichiesto = (prezzoVendita: string | undefined, anticipo: number | undefined): string | undefined => {
  if (prezzoVendita && anticipo !== undefined) {
    return summaryFormat((parseFloat(prezzoVendita) - anticipo).toString());
  }
};

export const taegMassimo = (taegMassimo: number | undefined): number | undefined =>
  taegMassimo && taegMassimo !== 0 ? taegMassimo : undefined;

export const durata = (durata: number | undefined): string | undefined => (durata ? durata.toString() : undefined);
