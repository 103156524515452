/* ---------------------------------
customization
--------------------------------- */

import React from "react";

// partner logos
import * as partnerLogos from "../../custom/assets/images/partner-logos";
import { ICustomizationOptions } from "../lib/customization/customization.types";

// TODO what will be the actual API format for the `name` param?
export enum PartnerNames {
  ILLIMITY = "gabriele-negro",
  HYUNDAI = "hyundai",
  HYUNDAI2 = "hmci-online-sales",
  INTESYS = "intesys",
  ALKEMY = "alkemy",
  LYNKCO = "LYN",
  KOELLIKER = "koelliker",
  TESLA = "tesla",
  AIWAYS = "Aiways",
  LENZI = "lenzi",
}

export const initialCustomization: ICustomizationOptions = {
  hideScbLogo: false,
  logo: "",
  title: "",
  url: "",
  contactUsElement: "",
  distanceSellingPolicyUrl: "",
  privacyPolicyUrl: "",
  theme: "",
};

/**
 * getPartnerCustomizations
 * @param name the partner's name,
 * as returned by whiteLabelPreventivoPartnerPrevIdGet
 */
export function getPartnerCustomizations(name: string | undefined): ICustomizationOptions {
  switch (name) {
    case PartnerNames.ILLIMITY:
      return {
        hideScbLogo: false,
        logo: partnerLogos.illimityLogo,
        title: "Illimity - Banca oltre la forma",
        url: "https://www.illimity.com/it",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>0116318862</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:filialeonline@santanderconsumer.it">
              filialeonline@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl: "",
        privacyPolicyUrl: "",
        theme: "",
      };

    case PartnerNames.HYUNDAI:
    case PartnerNames.HYUNDAI2:
      return {
        hideScbLogo: true,
        logo: partnerLogos.hyundaiLogo,
        title: "",
        url: "",
        contactUsElement:
          "Se hai bisogno di assistenza, contatta il dealer scelto in fase di configurazione sul sito Click To Buy.",
        distanceSellingPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2021-01/Informativa%20vendita%20a%20distanza%20%20servizi%20finanziari.pdf",
        privacyPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2020-12/Informativa%20privacy%20contratti%20SCB%2011_2020.pdf",
        theme: "",
      };

    case PartnerNames.INTESYS:
      return {
        hideScbLogo: false,
        logo: "",
        title: "",
        url: "",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>0116318862</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:filialeonline@santanderconsumer.it">
              filialeonline@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl: "",
        privacyPolicyUrl: "",
        theme: "",
      };

    case PartnerNames.ALKEMY:
      return {
        hideScbLogo: false,
        logo: "",
        title: "",
        url: "",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>0116318862</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:filialeonline@santanderconsumer.it">
              filialeonline@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl: "",
        privacyPolicyUrl: "",
        theme: "",
      };
    case PartnerNames.LYNKCO:
      return {
        hideScbLogo: true,
        logo: partnerLogos.lynkcoLogo,
        title: "Finanziamento Lynk&Co Finance",
        url: "https://www.lynkco.com/",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>011 6318625</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:pat.cli@santanderconsumer.it">
              pat.cli@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2021-01/Informativa%20vendita%20a%20distanza%20%20servizi%20finanziari.pdf",
        privacyPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2020-12/Informativa%20privacy%20contratti%20SCB%2011_2020.pdf",
        theme: "lynkco",
      };
    case PartnerNames.KOELLIKER:
      return {
        hideScbLogo: false,
        logo: partnerLogos.koellikerLogo,
        title: "Mitsubishi Motors Italia",
        url: "https://www.mitsubishi-motors.it/shop-online",
        contactUsElement: "Se hai bisogno di assistenza contatta il numero 0116318690",
        distanceSellingPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2021-01/Informativa%20vendita%20a%20distanza%20%20servizi%20finanziari.pdf",
        privacyPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2020-12/Informativa%20privacy%20contratti%20SCB%2011_2020.pdf",
        theme: "",
      };
    case PartnerNames.TESLA:
      return {
        hideScbLogo: false,
        logo: "",
        title: "",
        url: "",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>011 6318645</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:tesla@santanderconsumer.it">
              tesla@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2021-01/Informativa%20vendita%20a%20distanza%20%20servizi%20finanziari.pdf",
        privacyPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2020-12/Informativa%20privacy%20contratti%20SCB%2011_2020.pdf",
        theme: "",
      };
    case PartnerNames.AIWAYS:
      return {
        hideScbLogo: false,
        logo: partnerLogos.aiwaysLogo,
        title: "AW Italia",
        url: "https://shop.aiwaysitalia.it ",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>0116318862</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:filialeonline@santanderconsumer.it">
              filialeonline@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2021-01/Informativa%20vendita%20a%20distanza%20%20servizi%20finanziari.pdf",
        privacyPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2020-12/Informativa%20privacy%20contratti%20SCB%2011_2020.pdf",
        theme: "",
      };
    case PartnerNames.LENZI:
      return {
        hideScbLogo: false,
        logo: partnerLogos.lenziLogo,
        title: "LenziBike",
        url: "http://www.lenzibike.it/",
        contactUsElement: (
          <div>
            Se hai bisogno di assistenza, contatta il numero <b>0116318862</b> o scrivi all'indirizzo{" "}
            <a className="link link--light-blue" href="mailto:filialeonline@santanderconsumer.it">
              filialeonline@santanderconsumer.it
            </a>
            .
          </div>
        ),
        distanceSellingPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2021-01/Informativa%20vendita%20a%20distanza%20%20servizi%20finanziari.pdf",
        privacyPolicyUrl:
          "https://www.santanderconsumer.it/sites/default/files/2020-12/Informativa%20privacy%20contratti%20SCB%2011_2020.pdf",
        theme: "",
      };
    default:
      return initialCustomization;
  }
}
