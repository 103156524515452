import { isEmpty } from "lodash";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { getActiveInsuranceDescriptions } from "../../../caricamento/components/caricamento/caricamento.utils";
import Button from "../../../shared/components/button/button";
import Container from "../../../shared/components/container/container";
import Hero from "../../../shared/components/hero/hero";
import Intro from "../../../shared/components/intro/intro";
import NestedSection from "../../../shared/components/nested_section/nested_section";
import Spinner from "../../../shared/components/spinner/spinner";
import SkelSummary from "../../../shared/components/summary/SkelSummary";
import Summary from "../../../shared/components/summary/summary";
import { beneRichiesto, durata, summaryFormat, taegMassimo } from "../../../shared/components/summary/utils";
import { UPLOAD_TOKEN_SESSION_KEY } from "../../../shared/config/application";
import { UPLOAD_PATH } from "../../../shared/config/constants";
import {
  EXIT_URL_QUERY_PARAMETER,
  ID_CAMPAIGN_SESSION_KEY,
  ID_MEDIA_SESSION_KEY,
} from "../../../shared/constants/application";
import { LoaderServiziOutputData } from "../../../shared/generated/loaderServizi";
import { PreventivoOutput } from "../../../shared/generated/whiteLabel";
import { genericComposeURL } from "../../../shared/lib/genericComposeURL";
import { sharedTokenMiddleware } from "../../../shared/lib/sharedTokenMiddleware";
import { getFromSessionStorage } from "../../../shared/lib/utility/storage";
import { SessionStorage } from "../../../shared/lib/utility/storage-v2";
import {
  getPreventivo,
  getPreventivoSecci,
  getServizi,
  postPianoFinanziario,
  postPreventivoRicalcolo,
} from "../../../shared/logic/modificaPratica/actions";
import {
  STATUS_ERROR,
  STATUS_GET_SECCI,
  STATUS_POST_PIANO_FINANZIARIO,
  STATUS_POST_PREVENTIVO_RICALCOLO,
  STATUS_WAITING,
} from "../../../shared/logic/modificaPratica/consts";
import { TStatus } from "../../../shared/logic/modificaPratica/types";
import { IApiResponse } from "../../../shared/types/actions";
import {
  ACTIVITY_DESCRIPTION,
  GET_SECCI_LABEL,
  HERO_DESCRIPTION,
  SUBMIT_BUTTON_LABEL,
} from "../../config/editPageStrings";
import { IStoreState } from "../../types/store";
import "./styles.scss";
import { IDispatchProps, IStateProps, TProps } from "./types";

interface IFPSProps {
  preventivo: IApiResponse<PreventivoOutput>;
  servizi: IApiResponse<LoaderServiziOutputData>;
}
const SummaryPlan: FunctionComponent<IFPSProps> = ({ preventivo, servizi }) => {
  const isLoading = preventivo.loading || servizi.loading;
  return (
    <>
      {!isLoading && preventivo.data ? (
        <>
          <p className="activity_description">
            {ACTIVITY_DESCRIPTION[0]}
            <br />
            {ACTIVITY_DESCRIPTION[1]}
          </p>
          <Summary
            beneRichiesto={beneRichiesto(preventivo.data.prezzo_vendita, preventivo.data.anticipo)}
            tan={preventivo.data.tan}
            taeg={preventivo.data.taeg}
            taegMassimo={taegMassimo(preventivo.data.taegMassimo)}
            rataMensile={summaryFormat(preventivo.data.importo_rata)}
            durata={durata(preventivo.data.durata)}
            importoTotale={summaryFormat(preventivo.data.importo_dovuto)}
            assicurazioni={getActiveInsuranceDescriptions(preventivo, servizi)}
          />
        </>
      ) : (
        <SkelSummary />
      )}
    </>
  );
};

const FinancialSummary: FunctionComponent<TProps> = ({
  preventivo,
  servizi,
  status,
  numPratica,
  preventivoId,
  codiceFiscale,
  pianoFinanziario,
  secci,
  tokenSessionKey,
  getServizi,
  getPreventivo,
  postPianoFinanziario,
  getPreventivoSecci,
}) => {
  const intermediario = preventivo.data?.intermediario;

  /**
   * postPianoFinanziario
   */
  const handleProceed = () => {
    postPianoFinanziario(
      {
        praticaPianofinanziarioInput: { numeroPratica: numPratica, numeroPreventivo: Number(preventivoId) },
      },
      sharedTokenMiddleware(tokenSessionKey),
      !isEmpty(preventivo.data?.assicurazioni)
    );
  };

  /**
   * getSecci
   */
  const handleDownloadSecci = () => {
    if (intermediario && preventivoId) {
      getPreventivoSecci(
        { intermediario: intermediario, numeroPreventivo: Number(preventivoId) },
        sharedTokenMiddleware(tokenSessionKey)
      );
    }
    return;
  };

  /**
   * getPreventivo (if I come from upload or page reload)
   */
  useEffect(() => {
    if (preventivoId) {
      getPreventivo(String(preventivoId), sharedTokenMiddleware(tokenSessionKey));
    }
  }, [preventivoId]);

  /**
   * getServizi (if I come from upload or page reload)
   */
  useEffect(() => {
    if (isEmpty(servizi.data)) {
      getServizi(sharedTokenMiddleware(tokenSessionKey));
    }
  }, [getServizi]);

  useEffect(() => {
    document?.documentElement?.style?.setProperty("--brand-color", "#e2e2e2");
  }, []);

  const primaryButton = (status: TStatus): React.ReactNode => {
    switch (status) {
      case STATUS_GET_SECCI:
        return (
          <Button
            className="download-button"
            theme="primary"
            fullWidth
            size="big"
            onClick={handleProceed}
            isLoading={pianoFinanziario.loading}
          >
            <span className="download-button__label">{SUBMIT_BUTTON_LABEL}</span>
          </Button>
        );
      default:
      case STATUS_WAITING:
      case STATUS_POST_PREVENTIVO_RICALCOLO:
        return (
          <Button
            className="download-button"
            theme="success"
            fullWidth
            size="big"
            onClick={handleDownloadSecci}
            isLoading={secci.loading}
          >
            <i className="material-icons download-button__icon">get_app</i>
            <span className="download-button__label">{GET_SECCI_LABEL}</span>
          </Button>
        );
    }
  };

  switch (status) {
    case STATUS_POST_PIANO_FINANZIARIO: {
      const exitToken = pianoFinanziario.data?.token2;
      const exitUrl = getFromSessionStorage(EXIT_URL_QUERY_PARAMETER);
      const idMedia = getFromSessionStorage(ID_MEDIA_SESSION_KEY);
      const idCampaign = getFromSessionStorage(ID_CAMPAIGN_SESSION_KEY);
      const [toAppURL] = genericComposeURL(exitToken, exitUrl, codiceFiscale, UPLOAD_PATH, idMedia, idCampaign);

      if (toAppURL) {
        SessionStorage.remove(UPLOAD_TOKEN_SESSION_KEY);
        window.location.href = toAppURL;
      }

      return <Spinner centered />;
    }

    case STATUS_ERROR: {
      return <Redirect to="/error" />;
    }

    case STATUS_WAITING:
    case STATUS_POST_PREVENTIVO_RICALCOLO:
    default: {
      return (
        <NestedSection
          nestAmount={64}
          headerContent={<Hero title={<h1 className="edit-page-heading">{HERO_DESCRIPTION}</h1>} />}
          bodyContent={
            <Container>
              <Intro padded={true} className="edit-page-intro">
                <SummaryPlan preventivo={preventivo} servizi={servizi} />
              </Intro>
              <div className="row center-sm">
                <div className="col-xs-12 col-sm-6">{primaryButton(status)}</div>
              </div>
            </Container>
          }
        />
      );
    }
  }
};

const mapStateToProps = (state: IStoreState): IStateProps => ({
  status: state.modificaPratica.status,
  preventivo: state.modificaPratica.preventivo,
  servizi: state.modificaPratica.servizi,
  pianoFinanziario: state.modificaPratica.pianoFinanziario,
  numPratica: state.modificaPratica.numPratica,
  preventivoId: state.modificaPratica.preventivoId,
  codiceFiscale: state.modificaPratica.codiceFiscale,
  secci: state.modificaPratica.secci,
});

const mapDispatchToProps: IDispatchProps = {
  getServizi,
  postPreventivoRicalcolo,
  postPianoFinanziario,
  getPreventivo,
  getPreventivoSecci,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialSummary);
