// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AssicurazioneLeasing,
} from './';

/**
 * @export
 * @interface PreventivoLeasingOutputAllOf
 */
export interface PreventivoLeasingOutputAllOf {
    /**
     * importo acconto (calcolato sulla percentuale di input)
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    acconto?: number;
    /**
     * @type {Array<AssicurazioneLeasing>}
     * @memberof PreventivoLeasingOutputAllOf
     */
    assicurazioni?: Array<AssicurazioneLeasing>;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    bollo?: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    interessi?: number;
    /**
     * @type {Array<object>}
     * @memberof PreventivoLeasingOutputAllOf
     */
    noteLegali?: Array<object>;
    /**
     * valore complessivo del leasing differente dal finanziato di input perché calcolato meno la quota esente
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    finanziato?: number;
    /**
     * importo correlato alla tipologia richiesta in input (vedi TipologiaImportoFirma)
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    importoFirma?: number;
    /**
     * importo che può o deve pagare il cliente per poter tenere il bene a fine finanziamento
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    importoRiscatto?: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutputAllOf
     */
    taeg?: number;
    /**
     * descrizione del errore riscontrato in fase di calcolo
     * @type {string}
     * @memberof PreventivoLeasingOutputAllOf
     */
    errore?: string;
}
