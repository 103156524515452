import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { DEFAULT_ROUTE_KEY, TAddOn, TRoutesConfig } from "../../types/routes";

interface IRoutesFactory {
  (config: TRoutesConfig, addOn?: TAddOn): (JSX.Element | undefined)[];
}

/**
 * This function produces an array of Route Components using
 * a specification object describing the Routes.
 *
 * @param config Object carrying specifications for every path
 * @param addOn Extra specifications for runtime use situations
 */
export const routesFactory: IRoutesFactory = (config, addOn) => {
  /**
   * Inject add-on properties to route configurations. Add-ons
   * are useful for runtime props assignment and similar needs
   */
  if (addOn) {
    Object.keys(addOn).forEach(path => {
      config[path].props = { ...config[path].props, ...addOn[path].props };
    });
  }

  /**
   * Go through the configuration items and compose the Route
   * components with the given data. It avoids the default case
   * for further elaboration.
   */
  const Routes = Object.keys(config)
    .map((path, index) => {
      const pathConfig = config[path];
      const { Component, props } = pathConfig;
      const routeProps: RouteProps = {
        exact: Boolean(pathConfig.exact),
        strict: Boolean(pathConfig.strict),
        sensitive: Boolean(pathConfig.sensitive),
      };
      if (path !== DEFAULT_ROUTE_KEY) {
        return (
          <Route key={index} path={path} {...routeProps}>
            <Component {...props} />
          </Route>
        );
      }
      return;
    })
    .filter(Route => Route !== undefined);

  /**
   * This push makes sure that the default route case is going
   * to occupy the last position in the returning Route array.
   */
  Routes.push(<Route key={Object.keys(config).length} component={config[DEFAULT_ROUTE_KEY].Component} />);

  return Routes;
};
