import * as React from "react";
import { Image } from "../image/image";

interface ILogoProps {
  logo?: string;
  title?: string;
  url?: string;
}

export const Logo: React.FunctionComponent<ILogoProps> = ({ logo, title, url }) => {
  return (
    <React.Fragment>
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          <Image title={title} src={logo} />
        </a>
      ) : (
        <Image title={title} src={logo} />
      )}
    </React.Fragment>
  );
};
