import { renderIncompatibilityDialog } from "../shared/lib/renderIncompatibilityDialog";
import { renderReactApp } from "./renderReactApp";

const isIECaricamento = /*@cc_on!@*/ false || !!document["documentMode"];

if (isIECaricamento) {
  renderIncompatibilityDialog();
} else {
  renderReactApp();
}
