import has from "lodash.has";
import isEmpty from "lodash.isempty";
import { Dispatch } from "redux";
import { Observable } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { TCustomFormName } from "../../../custom/libraries/dataHandling/types";
import { CODICE_FISCALE_KEY, NUM_PRATICA_KEY, SESSION_ID } from "../../../shared/config/application";
import { DOCUMENTARY_DATA, PRATICA_ERRORS_MAPPING, TAX_DEDUCTIONS } from "../../../shared/constants/application";
import { ERROR_SAVE_PRATICA_FAILED_WITH_NO_ERRORS } from "../../../shared/constants/labels";
import {
  BASE_PATH,
  CaricamentoPraticaApi,
  Configuration,
  DefaultApi as E2eApi,
  GetEligibilitaCheckRequest,
  Middleware,
  PreventivoDatiCheckGetRequest,
} from "../../../shared/generated/e2e";
import { PreventivoDatiCheckResponse, SuccessInformativaCheck } from "../../../shared/generated/e2e/models";
import { DefaultApi, LoaderServiziOutput } from "../../../shared/generated/loaderServizi";
import {
  AdeguamentoSPACaricamentoApi,
  Controparte,
  DatiVeicoloOutput,
  DatiVeicoloPrevIdGetRequest,
  ErrorOutputKO,
  ErrorOutputOK,
  GETErroriRequest,
  GETPreventivoRequest,
  POSTPraticaRequest,
  PraticaConsumoKo,
  PraticaConsumoOk,
  PreventivoOutput,
  SCBPaginaCaricamentoApi,
} from "../../../shared/generated/whiteLabel";
import { DetrazioneFiscale } from "../../../shared/generated/whiteLabel/models/DetrazioneFiscale";
import { addTracingHeaders } from "../../../shared/lib/addTracingHeaders";
import { getNeoAssunto } from "../../../shared/lib/form/form";
import { setToSessionStorage } from "../../../shared/lib/utility/storage";
import { SessionStorage } from "../../../shared/lib/utility/storage-v2";
import {
  GET_ACTIVE_INSURANCE,
  GET_ALL_SERVICES_CODE,
  GET_DATI_CHECK_FAILED,
  GET_DATI_CHECK_PENDING,
  GET_DATI_CHECK_SUCCEED,
  GET_ELIGIBILITA_CHECK_FAILED,
  GET_ELIGIBILITA_CHECK_PENDING,
  GET_ELIGIBILITA_CHECK_SUCCEED,
  SET_CODICE_FISCALE,
  SET_NUM_PRATICA,
} from "../../../shared/logic/modificaPratica/consts";
import IAction from "../../../shared/types/actions";
import { flatToPraticaConsumo, parsePostPraticaErrors } from "../../lib/dataHandling/dataHandling";
import initialCustomer from "../../lib/dataHandling/initialCustomer";
import {
  TAllEnablers,
  TAllFOrmsKey,
  TAllValues,
  TFieldName,
  TFormName,
  TMappingPaths,
  TServerSideErrors,
} from "../../lib/dataHandling/types";
import { environmentApiBasePath } from "../../lib/environmentApiBasePath";
import { errorAction } from "../../lib/handlers";
import { loaderServiziApiConfig } from "../../lib/loaderServiziApiConfig";
import { preTokenMiddleware } from "../../lib/preTokenMiddleware";
import { whitelabelApiConfig } from "../../lib/whitelabelApiConfig";
import { CLEAR_AUTH_STATE } from "../authentication/consts";
import {
  CLEAR_CARICAMENTO_STATE,
  GET_CUSTOMER,
  GET_CUSTOMER_FAILED,
  GET_CUSTOMER_PENDING,
  GET_ERRORI_FAILED,
  GET_ERRORI_PENDING,
  GET_ERRORI_SUCCEED,
  GET_PREVENTIVO,
  GET_PREVENTIVO_FAILED,
  GET_PREVENTIVO_PENDING,
  GET_SERVIZI,
  GET_SERVIZI_FAILED,
  GET_SERVIZI_PENDING,
  GET_TAX_DEDUCTIONS_FORM_ACTIVATION,
  GET_TAX_DEDUCTIONS_FORM_ACTIVATION_FAILED,
  GET_TAX_DEDUCTIONS_FORM_ACTIVATION_PENDING,
  HIDE_NOTIFICATION,
  RESET_CUSTOMER,
  SAVE_PRATICA_FAILED,
  SAVE_PRATICA_FAILED_WITH_NO_ERRORS,
  SAVE_PRATICA_PENDING,
  SAVE_PRATICA_SUCCEED,
  SET_ACTIVE_STEP,
  SET_COMPLETE_STEP,
  SET_CUSTOMER_CACHE,
  SET_CUSTOMER_ENABLERS,
  SET_CUSTOMER_VALUES,
  SET_ERROR_STEP,
  SET_FORM_ENABLER,
  SET_IS_INCOMPATIBLE,
  SET_IS_REVIEWING,
  SET_IS_REVIEWING_SHOW_ALL,
  SET_IS_REVIEWING_SHOW_FORM,
  SET_ON_REVIEW_ERRORS,
  SET_UPPER_CASE_FIELDS,
} from "./consts";
import { ISavePratica } from "./types";

const caricamentoApi = new SCBPaginaCaricamentoApi(whitelabelApiConfig());
const adeguamentoApi = new AdeguamentoSPACaricamentoApi(whitelabelApiConfig());
const serviziApi = new DefaultApi(loaderServiziApiConfig());

const caricamentoPraticaApi = (preTokenMiddleware: Middleware): CaricamentoPraticaApi =>
  new CaricamentoPraticaApi(
    new Configuration({
      basePath: environmentApiBasePath(BASE_PATH),
      middleware: [addTracingHeaders, preTokenMiddleware],
    })
  );
export const clearState = () => async (dispatch: Dispatch<IAction<PreventivoOutput>>): Promise<void> => {
  SessionStorage.clear();
  dispatch({
    type: CLEAR_CARICAMENTO_STATE,
  });
  dispatch({
    type: CLEAR_AUTH_STATE,
  });
};
const e2eApi = (preTokenMiddleware: Middleware): E2eApi =>
  new E2eApi(
    new Configuration({
      basePath: environmentApiBasePath(BASE_PATH),
      middleware: [addTracingHeaders, preTokenMiddleware],
    })
  );

export const setFormEnabler = (
  formName: TFormName,
  isEnabled: boolean
): IAction<{ formName: TFormName; isEnabled: boolean }> => ({
  type: SET_FORM_ENABLER,
  payload: {
    formName,
    isEnabled,
  },
});

export const getPreventivo = (prevId: string) => async (
  dispatch: Dispatch<IAction<PreventivoOutput>>
): Promise<void> => {
  const requestParameters: GETPreventivoRequest = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prev_id: prevId,
  };
  dispatch({
    type: GET_PREVENTIVO_PENDING,
  });
  caricamentoApi.gETPreventivo(requestParameters).subscribe(
    payload => {
      dispatch({
        payload,
        type: GET_PREVENTIVO,
      });

      /**For copy preventivo.assicurazioni on modificaPratica reducer */
      dispatch({
        payload: payload,
        type: GET_ACTIVE_INSURANCE,
      });

      // enable the vehicleData form based on the given condition
      // if (payload.tipoProdotto === PreventivoOutputTipoProdottoEnum.PA) {
      //   dispatch(setFormEnabler(VEHICLE_DATA, true));
      // }
    },
    errorPayload => dispatch(errorAction(GET_PREVENTIVO_FAILED, errorPayload))
  );
};

export const getServizi = () => async (dispatch: Dispatch<IAction<LoaderServiziOutput>>): Promise<void> => {
  dispatch({
    type: GET_SERVIZI_PENDING,
  });
  serviziApi.loaderServiziGet().subscribe(
    payload => {
      dispatch({
        payload,
        type: GET_SERVIZI,
      });

      dispatch({
        payload,
        type: GET_ALL_SERVICES_CODE,
      });
    },
    errorPayload => dispatch(errorAction(GET_SERVIZI_FAILED, errorPayload))
  );
};

export const getErrori = (prevId: string, inputMapping: TMappingPaths) => async (
  dispatch: Dispatch<
    IAction<
      | ErrorOutputOK
      | { serverSideErrors: Partial<TServerSideErrors>; enablers: Partial<TAllEnablers> }
      | "serverSideError"
    >
  >
): Promise<void> => {
  const requestParameters: GETErroriRequest = {
    prev_id: (prevId as unknown) as number, // TODO: Rimuovere quando fixano l'api
  };

  dispatch({
    type: GET_ERRORI_PENDING,
  });

  caricamentoApi.gETErrori(requestParameters).subscribe(
    payload => {
      // No errors
      dispatch({
        payload,
        type: GET_ERRORI_SUCCEED,
      });
    },
    (errorPayload: AjaxError) => {
      const response: ErrorOutputKO = errorPayload.response;
      if (response.Errori?.errori) {
        const { serverSideErrors } = parsePostPraticaErrors(response.Errori.errori, inputMapping, "CL", "data");
        if (!isEmpty(serverSideErrors)) {
          const enablers = Object.keys(serverSideErrors).reduce((acc: Partial<TAllEnablers>, key: TFieldName) => {
            acc[key] = true;
            return acc;
          }, {} as Partial<TAllEnablers>);
          if (has(serverSideErrors, "codiceFiscale")) {
            enablers["nome"] = true;
            enablers["cognome"] = true;
          }
          dispatch({
            type: RESET_CUSTOMER,
            payload: "serverSideError",
            meta: {
              initial: initialCustomer,
            },
          });
          dispatch({
            type: GET_ERRORI_FAILED,
            payload: { serverSideErrors, enablers },
          });
        } else {
          dispatch({
            type: GET_ERRORI_SUCCEED,
            payload: {},
          });
        }
      } else {
        dispatch({
          type: GET_ERRORI_SUCCEED,
          payload: {},
        });
      }
    }
  );
};

export const getCustomer = (allFormsKey: TAllFOrmsKey) => async (
  dispatch: Dispatch<IAction<Controparte>>
): Promise<void> => {
  dispatch({
    type: GET_CUSTOMER_PENDING,
  });

  caricamentoApi.gETCliente({}).subscribe(
    payload => {
      dispatch({
        payload,
        meta: { allFormsKey },
        type: GET_CUSTOMER,
      });
      dispatch({
        type: SET_UPPER_CASE_FIELDS,
      });
    },
    (errorPayload: AjaxError) => dispatch(errorAction(GET_CUSTOMER_FAILED, errorPayload))
  );
};

export const setCustomerEnablers = (enablers: Partial<TAllEnablers>): IAction<Partial<TAllEnablers>> => ({
  type: SET_CUSTOMER_ENABLERS,
  payload: enablers,
});

export const savePratica: ISavePratica = (values, preventivoId, inputMapping, intermediario) => async (
  dispatch: Dispatch<
    IAction<
      | PraticaConsumoOk
      | { serverSideErrors: Partial<TServerSideErrors>; enablers: Partial<TAllEnablers> }
      | "cache"
      | "serverSideError"
      | SuccessInformativaCheck
      | PreventivoDatiCheckResponse
      | boolean
      | string
      | TFormName
    >
  >
) => {
  const requestParameters: POSTPraticaRequest = {
    requestBody: flatToPraticaConsumo(values as TAllValues, preventivoId),
  };

  dispatch({
    type: RESET_CUSTOMER,
    payload: "cache",
    meta: {
      initial: initialCustomer,
    },
  });

  dispatch({
    type: SAVE_PRATICA_PENDING,
  });

  /** Check eligibilita */
  dispatch({
    type: GET_ELIGIBILITA_CHECK_PENDING,
  });

  /** Dati Check */
  dispatch({
    type: GET_DATI_CHECK_PENDING,
  });

  caricamentoApi.pOSTPratica(requestParameters).subscribe(
    payload => {
      const numPratica = payload.data?.pratica;
      const token2 = payload.token2;
      if (numPratica && token2) {
        setToSessionStorage(SESSION_ID, token2);
        savePraticaSucceed(numPratica, preventivoId, intermediario, requestParameters, payload, dispatch);
      } else {
        savePraticaFailedWithNoErrors(dispatch);
      }
    },
    (errorPayload: AjaxError) => {
      const status = errorPayload.status.toString();

      //Handle error 500
      if (status === "500") {
        savePraticaFailedWithNoErrors(dispatch);
        return;
      }

      const response: PraticaConsumoKo = errorPayload.response;
      if (response.data?.errori) {
        const { serverSideErrors, unrecoverableErrors } = parsePostPraticaErrors(
          response.data?.errori,
          inputMapping,
          "CL",
          "data",
          PRATICA_ERRORS_MAPPING
        );

        if (!isEmpty(serverSideErrors) || !isEmpty(unrecoverableErrors)) {
          const enablers = Object.keys(serverSideErrors).reduce((acc: Partial<TAllEnablers>, key: TFieldName) => {
            acc[key] = true;
            return acc;
          }, {} as Partial<TAllEnablers>);
          if (has(serverSideErrors, "codiceFiscale")) {
            enablers["nome"] = true;
            enablers["cognome"] = true;
          }
          if (has(serverSideErrors, "numeroDocumento")) {
            dispatch({
              type: SET_IS_REVIEWING_SHOW_FORM,
              payload: DOCUMENTARY_DATA,
            });
          }

          dispatch({
            type: RESET_CUSTOMER,
            payload: "serverSideError",
            meta: {
              initial: initialCustomer,
            },
          });
          dispatch({
            type: SAVE_PRATICA_FAILED,
            payload: { serverSideErrors, enablers, unrecoverableErrors },
          });
        } else {
          savePraticaFailedWithNoErrors(dispatch);
        }
      } else {
        savePraticaFailedWithNoErrors(dispatch);
      }
    }
  );
};

/** Dispatch a generic error (displayed with toast message) if savePratica
 * response it's not manageable:
 * - httpStatus 500
 * - success true without numPratica
 * - Errors object with not managed errors code
 */
export const savePraticaFailedWithNoErrors = (dispatch: Dispatch<IAction>): void => {
  dispatch({
    type: SAVE_PRATICA_FAILED_WITH_NO_ERRORS,
    payload: {
      message: ERROR_SAVE_PRATICA_FAILED_WITH_NO_ERRORS,
      timeout: 5000,
      visible: true,
    },
  });
};

export const savePraticaSucceed = (
  numPratica: string,
  preventivoId: string,
  intermediario: string,
  savePraticaRequest: POSTPraticaRequest,
  savePraticaResponse: PraticaConsumoOk,
  dispatch: Dispatch<IAction<string | PraticaConsumoOk | boolean | PreventivoDatiCheckResponse>>
): void => {
  /** Set numPratica & codiceFiscale on sessionStorage
   * (necessary for modificaPratica reducer)
   */
  SessionStorage.write(NUM_PRATICA_KEY, numPratica);
  dispatch({
    type: SET_NUM_PRATICA,
    payload: numPratica,
  });
  if (savePraticaRequest.requestBody.cliente?.codicefiscale) {
    const codiceFiscale = savePraticaRequest.requestBody.cliente?.codicefiscale;
    SessionStorage.write(CODICE_FISCALE_KEY, codiceFiscale);
    dispatch({
      type: SET_CODICE_FISCALE,
      payload: codiceFiscale,
    });
  }

  dispatch({
    type: SAVE_PRATICA_SUCCEED,
    payload: savePraticaResponse,
  });

  const { cliente } = savePraticaRequest.requestBody;

  const getEligibilitaRequest: GetEligibilitaCheckRequest = {
    intermediario: intermediario ? intermediario.toString() : "",
    attivita: cliente?.occupazione?.attivita ? cliente?.occupazione?.attivita : "",
    professione: cliente?.occupazione?.professione ? cliente?.occupazione?.professione : "",
    neoAssunto: getNeoAssunto(cliente?.occupazione?.dataoccupazione) ? "true" : "false",
    statoAttivita: cliente?.occupazione?.datore?.provincia === "*" ? "E" : "I",
    numeroPreventivo: Number(preventivoId),
  };

  const getDatiRequest: PreventivoDatiCheckGetRequest = {
    numeroPratica: Number(numPratica),
    numeroPreventivo: Number(preventivoId),
    provenienza: "CO",
  };

  caricamentoPraticaApi(preTokenMiddleware)
    .getEligibilitaCheck(getEligibilitaRequest)
    .subscribe(
      (response: SuccessInformativaCheck) => {
        dispatch({
          payload: response.success,
          type: GET_ELIGIBILITA_CHECK_SUCCEED,
        });

        e2eApi(preTokenMiddleware)
          .preventivoDatiCheckGet(getDatiRequest)
          .subscribe(
            (response: PreventivoDatiCheckResponse) => {
              dispatch({
                payload: response,
                type: GET_DATI_CHECK_SUCCEED,
              });
            },
            (errorResponse: AjaxError) => {
              dispatch(errorAction(GET_DATI_CHECK_FAILED, errorResponse));
            }
          );
      },
      errorResponse => {
        dispatch(errorAction(GET_ELIGIBILITA_CHECK_FAILED, errorResponse));
      }
    );
};

export const setCustomerValues = (values: Partial<TAllValues>) => (dispatch: Dispatch<IAction>): void => {
  dispatch({
    type: SET_CUSTOMER_VALUES,
    payload: values,
  });
  dispatch({
    type: SET_UPPER_CASE_FIELDS,
  });
};

export const setActiveStep = (stepSlug: string): IAction<{ stepSlug: string }> => ({
  type: SET_ACTIVE_STEP,
  payload: { stepSlug },
});

export const setCompleteStep = (
  stepSlug: string,
  isComplete: boolean
): IAction<{ stepSlug: string; isComplete: boolean }> => ({
  type: SET_COMPLETE_STEP,
  payload: { stepSlug, isComplete },
});

export const setFormHasErrorStatus = (
  stepSlug: string,
  hasError: boolean
): IAction<{ stepSlug: string; hasError: boolean }> => ({
  type: SET_ERROR_STEP,
  payload: { stepSlug, hasError },
});

export const setIsReviewing = (isReviewing: boolean): IAction<{ isReviewing: boolean }> => ({
  type: SET_IS_REVIEWING,
  payload: { isReviewing },
});

export const setIsReviewingShowAll = (isReviewingShowAll: boolean): IAction<{ isReviewingShowAll: boolean }> => ({
  type: SET_IS_REVIEWING_SHOW_ALL,
  payload: { isReviewingShowAll },
});

export const setCustomerCache = (values: Partial<TAllValues>) => (dispatch: Dispatch<IAction>): void => {
  dispatch({
    type: SET_CUSTOMER_CACHE,
    payload: values,
  });
  dispatch({
    type: SET_UPPER_CASE_FIELDS,
  });
};

// TODO: remove IActionWithCaller type and put "caller" into regular "meta" action property
export interface IActionWithCaller extends IAction {
  caller: TFormName | TCustomFormName;
}

export const setOnReviewErrors = (
  errors: Record<TFieldName, string>,
  caller: TFormName | TCustomFormName
): IActionWithCaller => ({
  type: SET_ON_REVIEW_ERRORS,
  payload: errors,
  caller,
});

export const hideNotification = (): IAction => ({
  type: HIDE_NOTIFICATION,
});

export const setIsIncompatible = (
  isIncompatible: boolean,
  message: string
): IAction<{ isIncompatible: boolean; message: string }> => ({
  type: SET_IS_INCOMPATIBLE,
  payload: { isIncompatible, message },
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const getVehicleData = (prev_id: number): Observable<DatiVeicoloOutput> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const requestParameters: DatiVeicoloPrevIdGetRequest = { prev_id };
  return adeguamentoApi.datiVeicoloPrevIdGet(requestParameters);
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const getFormTaxDeductionsActivation = (prev_id: number) => async (
  dispatch: Dispatch<IAction<DetrazioneFiscale>>
): Promise<void> => {
  dispatch({
    type: GET_TAX_DEDUCTIONS_FORM_ACTIVATION_PENDING,
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  adeguamentoApi.detrazioniFiscaliPrevIdGet({ prev_id }).subscribe(
    response => {
      dispatch({
        type: GET_TAX_DEDUCTIONS_FORM_ACTIVATION,
        payload: response,
      });

      // enable the taxDeductions form based on the given condition
      dispatch(setFormEnabler(TAX_DEDUCTIONS, Boolean(response.detrazioniFiscali)));
    },
    error => dispatch(errorAction(GET_TAX_DEDUCTIONS_FORM_ACTIVATION_FAILED, error))
  );
};
