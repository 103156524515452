// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * A: AUTOVETTURE<br/> C: CICLOMOTORE<br/> F: FUORISTRADA<br/> I: MIETITREBBIA<br/> M: CAMPER<br/> N: NAUTICA<br/> O: MOTORI NAUTICI<br/> R: CARAVAN<br/> T: TRATTORI<br/> V: VEICOLI COMMERCIALI
 * @export
 * @enum {string}
 */
export enum TipologiaVeicolo {
    A = 'A',
    C = 'C',
    F = 'F',
    I = 'I',
    M = 'M',
    N = 'N',
    O = 'O',
    R = 'R',
    T = 'T',
    V = 'V'
}

