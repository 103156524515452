import {
  CODICE_FISCALE_QUERY_PARAMETER,
  EXIT_URL_QUERY_PARAMETER,
  ID_CAMPAIGN_SESSION_KEY,
  ID_MEDIA_SESSION_KEY,
  TOKEN_QUERY_PARAMETER,
  TO_DOCUMENTS_UPLOAD_URL_KEY,
  URL_UPLOAD,
} from "../../../shared/constants/application";
import { getFromSessionStorage } from "../../../shared/lib/utility/storage";
import { UPLOAD_PATH } from "../../config/constants";

export function makeUrlParams(params: Record<string, string | number | boolean | undefined>): string {
  // no undefined
  // no empty strings
  // we need to let `false` pass as a value so we can't `!val`
  const queryString = Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, val]) => val !== undefined && val !== "")
    .map(([key, val]) => `${key}=${val}`)
    .join("&");

  if (queryString.length) {
    return "?" + queryString;
  }

  return "";
}

export const composeURL = (
  token: string | undefined,
  exitUrl: string | undefined,
  codiceFiscale: string | number | undefined
): readonly string[] => {
  const fromHostAppURL = getFromSessionStorage(TO_DOCUMENTS_UPLOAD_URL_KEY);
  const idMedia = getFromSessionStorage(ID_MEDIA_SESSION_KEY);
  const idCampaign = getFromSessionStorage(ID_CAMPAIGN_SESSION_KEY);

  let toAppURL, toEmailURL;

  if (fromHostAppURL) {
    toAppURL = `${fromHostAppURL}?${TOKEN_QUERY_PARAMETER}=${token}&${CODICE_FISCALE_QUERY_PARAMETER}=${codiceFiscale}`;
    toEmailURL = `${fromHostAppURL}?${TOKEN_QUERY_PARAMETER}=${token}&${ID_MEDIA_SESSION_KEY}=${idMedia}&${ID_CAMPAIGN_SESSION_KEY}=${idCampaign}`;
  } else {
    toAppURL =
      `${URL_UPLOAD}/authenticate/${token}` +
      makeUrlParams({
        [EXIT_URL_QUERY_PARAMETER]: exitUrl,
        [CODICE_FISCALE_QUERY_PARAMETER]: codiceFiscale,
        [ID_MEDIA_SESSION_KEY]: idMedia,
        [ID_CAMPAIGN_SESSION_KEY]: idCampaign,
      });

    toEmailURL =
      `${UPLOAD_PATH}/authenticate/${token}` +
      makeUrlParams({
        [EXIT_URL_QUERY_PARAMETER]: exitUrl,
        [ID_MEDIA_SESSION_KEY]: idMedia,
        [ID_CAMPAIGN_SESSION_KEY]: idCampaign,
      });
  }
  return [toAppURL, toEmailURL] as const;
};
