// tslint:disable
/**
 * Authentication
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    CaricamentoInput,
    CaricamentoOutput,
    DocumentiInput,
    DocumentiOutput,
    LoginOutput,
    Ok,
} from '../models';

export interface AuthCaricamentoRequest {
    Input?: CaricamentoInput;
}

export interface AuthDocumentiRequest {
    Input: DocumentiInput;
}

/**
 * no description
 */
export class PartnerApi extends BaseAPI {

    /**
     */
    authCaricamento = (requestParameters: AuthCaricamentoRequest): Observable<CaricamentoOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-IBM-Client-Id': this.configuration.apiKey('X-IBM-Client-Id') }), // clientIdHeader authentication
            ...(this.configuration.apiKey && { 'X-IBM-Client-Secret': this.configuration.apiKey('X-IBM-Client-Secret') }), // clientSecretHeader authentication
        };

        return this.request<CaricamentoOutput>({
            path: '/caricamento',
            method: 'POST',
            headers,
            body: requestParameters.Input,
        });
    };

    /**
     */
    authCaricamentoRecall = (): Observable<Ok> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-IBM-Client-Id': this.configuration.apiKey('X-IBM-Client-Id') }), // clientIdHeader authentication
            ...(this.configuration.apiKey && { 'X-IBM-Client-Secret': this.configuration.apiKey('X-IBM-Client-Secret') }), // clientSecretHeader authentication
        };

        return this.request<Ok>({
            path: '/caricamento/recall',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    authDocumenti = (requestParameters: AuthDocumentiRequest): Observable<DocumentiOutput> => {
        throwIfRequired(requestParameters, 'Input', 'authDocumenti');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-IBM-Client-Id': this.configuration.apiKey('X-IBM-Client-Id') }), // clientIdHeader authentication
            ...(this.configuration.apiKey && { 'X-IBM-Client-Secret': this.configuration.apiKey('X-IBM-Client-Secret') }), // clientSecretHeader authentication
        };

        return this.request<DocumentiOutput>({
            path: '/documenti',
            method: 'POST',
            headers,
            body: requestParameters.Input,
        });
    };

    /**
     */
    authLogin = (): Observable<LoginOutput> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'X-IBM-Client-Id': this.configuration.apiKey('X-IBM-Client-Id') }), // clientIdHeader authentication
            ...(this.configuration.apiKey && { 'X-IBM-Client-Secret': this.configuration.apiKey('X-IBM-Client-Secret') }), // clientSecretHeader authentication
        };

        return this.request<LoginOutput>({
            path: '/login',
            method: 'GET',
            headers,
        });
    };

}
