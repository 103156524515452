import get from "lodash.get";
import isEmpty from "lodash.isempty";
import uniq from "lodash.uniq";
import { IAddress } from "./normalizzatore.types";
import { ListaStradeRequest } from "../../../shared/generated/normalizzatore";

export interface IListaStradeInputOptions {
  numResults?: number;
  fullName?: boolean;
}

const APPELLATIVES = [
  "ARCO",
  "ALZAIA",
  "ARGINE",
  "AUTOSTRADA",
  "BAGLIO",
  "BALUARDO",
  "BANCHINA",
  "BIVIO",
  "BORGATA",
  "BORGHETTO",
  "BORGO",
  "CA'",
  "CALATA",
  "CALLE",
  "CAMPIELLO",
  "CAMPO",
  "CANTONE",
  "CASALE",
  "CASCINA",
  "CHIASSO",
  "CIRCONVALLAZIONE",
  "CLAUSTRO",
  "COLLE",
  "CONDOMINIO",
  "CONTRA'",
  "CONTRADA",
  "CORSO",
  "CORTE",
  "CORTICELLA",
  "CORTILE",
  "CUPA",
  "DIRAMAZIONE",
  "DIRAMAZIONI",
  "DISCESA",
  "FONDACO",
  "FONDAMENTA",
  "FONDO",
  "FRAZIONE",
  "GALLERIA",
  "GIARDINI",
  "GIARDINO",
  "GRADINATA",
  "GRADINI",
  "GRADONI",
  "ISOLA",
  "ISOLATO",
  "LARGHETTO",
  "LARGO",
  "LOCALITA'",
  "LUNGADDA",
  "LUNGADIGE",
  "LUNGARGINE",
  "LUNGARNO",
  "LUNGO",
  "LUNGOBISAGNO",
  "LUNGOBRENTA",
  "LUNGOCRATI",
  "LUNGODORA",
  "LUNGOISARCO",
  "LUNGOLAGO",
  "LUNGOLARIO",
  "LUNGOMALLERO",
  "LUNGOMARE",
  "LUNGOPO'",
  "LUNGOSABATO",
  "LUNGOSTURA",
  "LUNGOTALVERA",
  "LUNGOTANARO",
  "LUNGOTEVERE",
  "MOLO",
  "MURA",
  "NUCLEO",
  "PARCO",
  "PASSAGGIO",
  "PASSEGGIATA",
  "PIAZZA",
  "PIAZZALE",
  "PIAZZETTA",
  "PODERE",
  "PORTICO",
  "PROLUNGAMENTO",
  "QUARTIERE",
  "RAMI",
  "RAMO",
  "RAMPA",
  "RAMPE",
  "RECINTO",
  "REGIONE",
  "RIONE",
  "RIVIERA",
  "RONCO",
  "ROTONDA",
  "RUA",
  "RUE",
  "AVENUE",
  "SALITA",
  "SALIZADA",
  "SCALINATA",
  "SENTIERO",
  "SESTIERE",
  "SOBBORGO",
  "SOTTOPASSAGGIO",
  "SOTTOPORTICO",
  "SOTTOVIA",
  "STRADA",
  "STRADELLA",
  "STRADELLO",
  "STRADONE",
  "SUPERSTRADA",
  "SUPPORTICO",
  "TANGENZIALE",
  "TRAVERSA",
  "TRONCO",
  "VIA",
  "VIADOTTO",
  "VIALE",
  "VIALETTO",
  "VICO",
  "VICOLETTI",
  "VICOLETTO",
  "VICOLO",
  "VIELLA",
  "VILLAGGIO",
  "VILLETTA",
  "VIOTTOLO",
  "VIUZZA",
  "VIUZZO",
  "VOCABOLO",
  "ZONA",
  "STRADA STATALE",
  "STRADA COMUNALE",
  "STRADA PROVINCIALE",
  "VICOLO CHIUSO",
  "VICOLO STRETTO",
  "VICOLO CIECO",
  "VICOLO LUNGO",
  "FATTORIA",
  "RIVA",
  "MASO",
  "MASSERIA",
  "CASE SPARSE",
  "CASE",
  "CASA",
  "CASCINE",
  "CASALI",
  "CARRARA",
  "CENTRO ABITATO",
  "CENTRO",
  "FRAKTION",
  "HAMEAU",
  "LARGO PO",
  "STREDA",
  "PIAZ",
  "STRADA VICINALE",
  "STRADA PRIVATA",
  "CAMPAZZO",
  "STRADA REGIONALE",
  "BASTIONI",
];

export const getListaStradeInputParams = (
  address: Partial<IAddress>,
  options?: IListaStradeInputOptions
): ListaStradeRequest => ({
  Input: {
    parametro: {
      LSTPAR: {
        ...(options && options.numResults ? { NR9CND: options.numResults.toString() } : { NR9CND: "99" }),
        CDXISO: "ITA", // codice ISO del paese per cui si vuole effettuare la ricerca
        ...(options && options.fullName ? { FLXDSXDPT: options.fullName ? "0" : "1" } : { FLXDSXDPT: "1" }),
        WPUSER: "wp",
        WPPASW: "wp1299",
      },
      STR_AREA_INP: {
        ...(address.cap ? { CDXZIP: address.cap } : {}), // CAP
        ...(address.comune ? { DSXCNL: address.comune } : {}), // Comune
        DSXST1: address.indirizzo, // stringa di ricerca
      },
    },
  },
});

// TODO: dovrebbe essere ListaStradeOutput ma lo yaml è errato
// [FPIPS-7] According to the original author, the API spec is incorrect;
// it still appears to be this way, so we're leaving the `any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const parseListaStradeResponse = (response: any): string[] => {
  const indirizziOutput = get(response, "wpLstStrResult.STR_AREA_OUT.STR", []);

  if (indirizziOutput.length === 1 && isEmpty(indirizziOutput[0])) {
    return [];
  }
  // same as line @196
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const indirizziSuggeriti = indirizziOutput.map((item: any) => item.DSXOBJSTR);

  // DE-4579: Remove multiple results for an address over multiple fractions
  return uniq(indirizziSuggeriti);
};

export const filterAppellatives = (value: string): string =>
  value.replace(new RegExp(APPELLATIVES.join("|"), "i"), "").trim();
