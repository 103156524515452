import * as React from "react";
import { FC } from "react";
import { NormalizzatoreProvider } from "./normalizzatore.context";
import { ComuniObservableGenerator, ProvinceObservableGenerator } from "./normalizzatore.types";
import { useComuniProvince } from "./useComuniProvince";

export interface INormalizzatoreProps {
  children: React.ReactNode;
  comuni$: ComuniObservableGenerator;
  province$: ProvinceObservableGenerator;
}
// TODO:
//  - Improve typing (inject Context props to Wrapped Component)

export const Normalizzatore: FC<INormalizzatoreProps> = ({ children, comuni$, province$ }) => {
  const comuniProvince = useComuniProvince(province$, comuni$);
  return <NormalizzatoreProvider value={comuniProvince}>{children}</NormalizzatoreProvider>;
};

export const withNormalizzatore = (
  province$: ProvinceObservableGenerator,
  comuni$: ComuniObservableGenerator
): ((WrappedComponent: React.ComponentType) => React.ComponentClass) => (WrappedComponent: React.ComponentType) =>
  class NormalizedComponent extends React.Component {
    public render(): React.ReactNode {
      return (
        <Normalizzatore comuni$={comuni$} province$={province$}>
          <WrappedComponent {...this.props} />
        </Normalizzatore>
      );
    }
  };
