// tslint:disable
/**
 * provincegenerico
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ProvinceGenericoOutputDataProvince,
} from './';

/**
 * @export
 * @interface ProvinceGenericoOutputData
 */
export interface ProvinceGenericoOutputData {
    /**
     * @type {Array<ProvinceGenericoOutputDataProvince>}
     * @memberof ProvinceGenericoOutputData
     */
    province?: Array<ProvinceGenericoOutputDataProvince>;
}
