import { setFeatureFlags, evaluateEnvVar } from "../lib/featureFlagsFunctions";

/**
 * These type registers all the
 * build-time & runtime flags and
 * provides auto-completion down the line.
 *
 * Add new flags following this format:
 * "flag-1" | "flag-2" | "flag-3" | "flag-4"
 */

export type TFeatureFlagsRegister =
  | "useCaseWeb"
  | "onlineServiceGuardsActive"
  | "incompatibilePensionCheck"
  | "selfEmployedCheck"
  | "hideSomeActivityOptions"
  | "handleIdMedia"
  | "handleIdCampaign"
  | "taegMassimoOnSummary";

/**
 * This type builds an object which accepts
 * only the props defined in TFeatureFlagsRegister;
 * when used in the app, the props are optional.
 */

export type TFeatureFlagsObject = {
  [flag in TFeatureFlagsRegister]?: boolean;
};

/**
 * This object contains global build-time flags
 * evaluated from the .env.development file.
 *
 * The entirety of this file is loaded in `index.tsx`,
 * so the contents of the object are stored in
 * session storage as soon as the app starts.
 */

const features: TFeatureFlagsObject = {
  // Register your flags here, using the `evaluateEnvVar` utility like so:
  // {flag name}: evaluateEnvVar(process.env.{flag key found in the .env file}),
  incompatibilePensionCheck: evaluateEnvVar(process.env.INCOMPATIBLE_PENSION_CHECK),
  selfEmployedCheck: evaluateEnvVar(process.env.SELF_EMPLOYED_CHECK),
  hideSomeActivityOptions: evaluateEnvVar(process.env.HIDE_SOME_ACTIVITY_OPTIONS),
  handleIdMedia: evaluateEnvVar(process.env.HANDLE_ID_MEDIA),
  handleIdCampaign: evaluateEnvVar(process.env.HANDLE_ID_CAMPAIGN),
  taegMassimoOnSummary: evaluateEnvVar(process.env.TAEG_MASSIMO_ENABLED),
};

setFeatureFlags(features);
