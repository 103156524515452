// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    PreventivoConsumo,
    PreventivoListaResponse,
    PreventivoSalvaRequest,
    Success,
} from '../models';

export interface GetListaRequest {
    intermediario: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    pagina?: number;
    dimensionePagina?: number;
    ordinamentoCrescente?: boolean;
    filtroRicerca?: string;
}

export interface GetPreventivoCORequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface SalvaPreventivoRequest {
    X_B3_TraceId?: string;
    body?: PreventivoSalvaRequest;
}

/**
 * no description
 */
export class P2Api extends BaseAPI {

    /**
     * Ritorna la lista dei preventivi
     */
    getLista = (requestParameters: GetListaRequest): Observable<PreventivoListaResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getLista');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.pagina && { 'pagina': requestParameters.pagina }),
            ...(requestParameters.dimensionePagina && { 'dimensionePagina': requestParameters.dimensionePagina }),
            ...(requestParameters.ordinamentoCrescente && { 'ordinamentoCrescente': requestParameters.ordinamentoCrescente }),
            ...(requestParameters.filtroRicerca && { 'filtroRicerca': requestParameters.filtroRicerca }),
        };

        return this.request<PreventivoListaResponse>({
            path: '/preventivo/lista',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * carica un preventivo consumo dato il suo id
     */
    getPreventivoCO = (requestParameters: GetPreventivoCORequest): Observable<PreventivoConsumo> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoCO');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoCO');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<PreventivoConsumo>({
            path: '/preventivo/CO',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Salva un nuovo preventivo associandolo ad un nomePreventivo, se vecchioNumeroPreventivo è valorizzato si sta facendo un\'operazione di modifica in cui è necessario sostituire il vecchio preventivo con quello nuovo. <br/> Il risultato in lista è che si vedrà solo il nuovo preventivo e non più il vecchio 
     * salva un preventivo correlandolo con una descrizione
     */
    salvaPreventivo = (requestParameters: SalvaPreventivoRequest): Observable<Success> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<Success>({
            path: '/preventivo/salva',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

}
