import * as React from "react";
import { useContext } from "react";
import { CustomizationContext } from "../../lib/customization/CustomizationContext";
import "./form_info.scss";

export const FormInfo: React.FunctionComponent = () => {
  const {
    data: { contactUsElement },
  } = useContext(CustomizationContext);

  return <div className="form-info">{contactUsElement}</div>;
};
