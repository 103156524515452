// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroKIIELECLI,
    WpNormInputParametroKIIKEY,
} from './';

/**
 * @export
 * @interface WpNormInputParametroKII
 */
export interface WpNormInputParametroKII {
    /**
     * @type {string}
     * @memberof WpNormInputParametroKII
     */
    CDPFAS?: string;
    /**
     * @type {WpNormInputParametroKIIELECLI}
     * @memberof WpNormInputParametroKII
     */
    ELECLI?: WpNormInputParametroKIIELECLI;
    /**
     * @type {WpNormInputParametroKIIELECLI}
     * @memberof WpNormInputParametroKII
     */
    ELECLIDOP?: WpNormInputParametroKIIELECLI;
    /**
     * @type {string}
     * @memberof WpNormInputParametroKII
     */
    NR9KEY?: string;
    /**
     * @type {Array<WpNormInputParametroKIIKEY>}
     * @memberof WpNormInputParametroKII
     */
    KEY?: Array<WpNormInputParametroKIIKEY>;
}
