import get from "lodash.get";
import React, { Component, createContext } from "react";
import { WizardStepper } from "./wizard_stepper";
import { WizardPage } from "./wizard_page";

export interface IWizardContext {
  activeStepId?: string;
  forceShow?: boolean;
  changeStep?: (stepId: string) => void;
}

const wizardContext = createContext<IWizardContext>({});

const { Provider, Consumer } = wizardContext;

interface IWizardProps {
  activeStep?: string;
  initialStep?: string;
  forceShow?: boolean;
  onStepChange?: (stepId: string, prevStepId: string) => void;
}

interface IWizardState {
  activeStepId: string;
}

class Wizard extends Component<IWizardProps, IWizardState> {
  constructor(props: IWizardProps) {
    super(props);
    const activeStepId = get(props, "activeStep", get(props, "initialStep", ""));
    this.state = { activeStepId };
  }

  public componentDidUpdate(prevProps: Readonly<IWizardProps>): void {
    if (this.props.activeStep && prevProps.activeStep !== this.props.activeStep) {
      this.changeStep(this.props.activeStep);
    }
  }

  public render(): React.ReactNode {
    return (
      <Provider
        value={{
          activeStepId: this.state.activeStepId,
          forceShow: this.props.forceShow,
          changeStep: this.changeStep,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }

  private changeStep = (stepId: string) => {
    // console.log("Opening Step: ", stepId);

    const { onStepChange } = this.props;
    const { activeStepId: prevStepId } = this.state;

    if (onStepChange) {
      onStepChange(stepId, prevStepId);
    }

    this.setState({
      activeStepId: stepId,
    });
  };
}

export default Wizard;
export { WizardStepper, WizardPage, Consumer };
