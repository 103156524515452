import { useEffect } from "react";
import { PreventivoOutputTipoProdottoEnum } from "../../../../shared/generated/whiteLabel";

export const useFormTaxDeduction = (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  getFormTaxDeductionsActivation: (prev_id: number) => void,
  preventivoId?: string,
  tipoProdotto?: PreventivoOutputTipoProdottoEnum
): void => {
  useEffect(() => {
    if (!preventivoId) {
      return;
    }
    if (!tipoProdotto) {
      return;
    }
    // https://intesys.atlassian.net/browse/SCBE2ESPA-84 - Tax deduction is hidden when tipoProdotto is PP
    if (tipoProdotto === PreventivoOutputTipoProdottoEnum.PP) {
      return;
    }
    getFormTaxDeductionsActivation(Number(preventivoId));
  }, [preventivoId, tipoProdotto]);
};
