/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";
import { ADFORM_FUNNEL_PREFIX, ADFORM_ID } from "../../../../shared/config/constants";
import { warn } from "../../../../shared/lib/log";

declare global {
  // no need to prefix Window
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    _adftrack?: unknown[];
    // couldn't find any docs about this object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    adf?: any;
  }
}

export const useAdForm = (path: string): void => {
  useEffect(() => {
    if (!ADFORM_ID) {
      warn("ADFORM_ID not found");
      return;
    }
    if (!window._adftrack) {
      warn("In order to use AdForm, window._adftrack should be initialized");
      window._adftrack = [];
    }
    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : window._adftrack ? [window._adftrack] : [];
    window._adftrack.push({
      HttpHost: "track.adform.net",
      pm: ADFORM_ID,
      divider: encodeURIComponent("|"),
      pagename: encodeURIComponent(`${ADFORM_FUNNEL_PREFIX}${path}`),
    });

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
    const x = document.getElementsByTagName("script")[0];
    x.parentNode && x.parentNode.insertBefore(s, x);
  }, []);
};

export const sendAdFormEvent = (path: string): void => {
  if (!window.adf) {
    return;
  }
  window.adf.ClickTrack(this, ADFORM_ID, `${ADFORM_FUNNEL_PREFIX}${path}`, {});
};
