// tslint:disable
/**
 * localita-generico
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    LocalitaGenericoOutputData,
} from './';

/**
 * 
 * @export
 * @interface LocalitaGenericoOutput
 */
export interface LocalitaGenericoOutput {
    /**
     * @type {boolean}
     * @memberof LocalitaGenericoOutput
     */
    success?: boolean;
    /**
     * @type {LocalitaGenericoOutputData}
     * @memberof LocalitaGenericoOutput
     */
    data?: LocalitaGenericoOutputData;
}
