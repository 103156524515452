// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Assicurazione,
    Provenienza,
} from './';

/**
 * @export
 * @interface UpsellingServiziInputAllOf
 */
export interface UpsellingServiziInputAllOf {
    /**
     * @type {number}
     * @memberof UpsellingServiziInputAllOf
     */
    numeroPreventivo: number;
    /**
     * @type {Provenienza}
     * @memberof UpsellingServiziInputAllOf
     */
    provenienza: Provenienza;
    /**
     * @type {Array<Assicurazione>}
     * @memberof UpsellingServiziInputAllOf
     */
    servizi: Array<Assicurazione>;
}
