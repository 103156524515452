import React from "react";
import { CREDIT_LIFE_KEYS } from "../../../shared/constants/application";
import { LoaderServiziOutputData, LoaderServiziOutputDataServiziCO } from "../../../shared/generated/loaderServizi";
import { PreventivoOutput, PreventivoOutputAssicurazioni } from "../../../shared/generated/whiteLabel";
import { IState as IModificaPratica, TServicesChanges } from "../../../shared/logic/modificaPratica/types";
import { IApiResponse } from "../../../shared/types/actions";
import { IFormElementsSet, TForms } from "../../lib/dataHandling/types";

export const setFormCounterMessage = (activeForm: IFormElementsSet, forms: TForms): JSX.Element => {
  const currentFormOrder = forms.indexOf(activeForm) + 1;
  return (
    <div>
      <b>{currentFormOrder}</b> di {forms.length}
    </div>
  );
};

export const getActiveInsuranceDescriptions = (
  preventivo: IApiResponse<PreventivoOutput>,
  servizi: IApiResponse<LoaderServiziOutputData>
): Array<LoaderServiziOutputDataServiziCO> | undefined => {
  const preventivoActiveInsuranceCodes =
    preventivo.data?.assicurazioni && preventivo.data?.assicurazioni.map(item => item.codice); //Active insurance codes returned by getPreventivo

  const insurancesList = servizi.data?.servizi?.CO; //Insurance object list returned by getServizi

  if (preventivoActiveInsuranceCodes) {
    return insurancesList?.filter(insurance => preventivoActiveInsuranceCodes.includes(insurance.codice));
  }
};

export const isCreditLifeInsurance = (assicurazioni?: PreventivoOutputAssicurazioni[]): boolean =>
  assicurazioni
    ? assicurazioni.some((assicurazione: PreventivoOutputAssicurazioni) =>
        assicurazione.pacchetto ? CREDIT_LIFE_KEYS.includes(assicurazione.pacchetto) : false
      )
    : false;

interface IModificaPraticaCaricamento {
  modalClChange: boolean;
  modalClRemove: boolean;
  modalProvinceVariation: boolean;
  servicesChangesProceeding: boolean;
  servicesChangesData: TServicesChanges | undefined;
  servicesChangesLoading: boolean;
  preventivoRicalcoloLoading: boolean;
  downsellingLoading: boolean;
  infoDocsLoading: boolean;
}

export const modificaPraticaCaricamento = (modificaPratica: IModificaPratica): IModificaPraticaCaricamento => {
  //Incompatibility modals
  const { modalClChange, modalClRemove, modalProvinceVariation } = modificaPratica.incompatibilityModal;

  //Services Changes
  const {
    proceeding: servicesChangesProceeding,
    data: servicesChangesData,
    loading: servicesChangesLoading,
  } = modificaPratica.servicesChanges;

  //Loadings
  const preventivoRicalcoloLoading = modificaPratica.preventivo.loading;
  const downsellingLoading = modificaPratica.downselling.loading;
  const infoDocsLoading = modificaPratica.infoDocs.loading;

  return {
    modalClChange,
    modalClRemove,
    modalProvinceVariation,
    servicesChangesProceeding,
    servicesChangesData,
    servicesChangesLoading,
    preventivoRicalcoloLoading,
    downsellingLoading,
    infoDocsLoading,
  };
};
