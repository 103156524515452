// tslint:disable
/**
 * caricamento
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    ErrorObj,
    JWT2Input,
    JWT2Output,
    StoricoOutput,
} from '../models';

export interface CartaRecallRequest {
    cartaInput?: JWT2Input;
}

/**
 * no description
 */
export class DefaultApi extends BaseAPI {

    /**
     */
    cartaRecall = (requestParameters: CartaRecallRequest): Observable<JWT2Output> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<JWT2Output>({
            path: '/JWT2',
            method: 'POST',
            headers,
            body: requestParameters.cartaInput,
        });
    };

    /**
     */
    storico = (): Observable<StoricoOutput> => {
        return this.request<StoricoOutput>({
            path: '/storico',
            method: 'GET',
        });
    };

}
