import { CodiceDomandaEligibilita, Domanda, RispostaInput } from "../../generated/e2e";

export const getAnswers = (
  questionario: Domanda[],
  attivita: string,
  professione: string,
  neoAssunto: string,
  statoDatore: string
): RispostaInput[] => {
  const answers = questionario?.map(domande => {
    switch (domande.codiceDomanda) {
      case CodiceDomandaEligibilita.Attivita: {
        return {
          codiceDomanda: domande.codiceDomanda,
          codiceRisposta: attivita,
        };
      }
      case CodiceDomandaEligibilita.Professione: {
        return {
          codiceDomanda: domande.codiceDomanda,
          codiceRisposta: professione,
        };
      }
      case CodiceDomandaEligibilita.NeoAssunto: {
        return {
          codiceDomanda: domande.codiceDomanda,
          codiceRisposta: neoAssunto,
        };
      }
      case CodiceDomandaEligibilita.StatoDatore: {
        return {
          codiceDomanda: domande.codiceDomanda,
          codiceRisposta: statoDatore,
        };
      }
      default: {
        const otherAnswers = domande.risposte?.find(risposta => risposta.selected === true && risposta.codiceRisposta)
          ?.codiceRisposta;
        if (otherAnswers) {
          return {
            codiceDomanda: domande.codiceDomanda,
            codiceRisposta: otherAnswers,
          };
        }
        return;
      }
    }
  });

  return answers.filter(Boolean) as Domanda[];
};
