// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * codice della tipologia di finanziamento
 * @export
 * @enum {string}
 */
export enum TipologiaFinanziamento {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}

