import { ConfigurationParameters, Configuration, BASE_PATH } from "../generated/Authentication";
import { addTracingHeaders } from "./addTracingHeaders";
import { authWithTokenMiddleware } from "./authWithTokenMiddleware";
import { environmentApiBasePath } from "./environmentApiBasePath";

export const authenticationApiConfig: (configParams?: Partial<ConfigurationParameters>) => Configuration = (
  configParams = {}
) => {
  const { basePath, middleware } = configParams;
  return new Configuration({
    ...configParams,
    basePath: environmentApiBasePath(BASE_PATH, basePath),
    middleware: [authWithTokenMiddleware, addTracingHeaders, ...(middleware || [])],
  });
};
