import * as React from "react";
import "./nested_section.scss";

interface IOwnProps {
  headerContent?: React.ReactNode;
  bodyContent?: React.ReactNode;
  nestAmount?: number;
}

type Props = IOwnProps;

const NestedSection: React.FunctionComponent<Props> = ({ headerContent, bodyContent, nestAmount = 10 }) => {
  return (
    <div className="nested-section">
      <div className="nested-section__header" style={nestAmount ? { paddingBottom: nestAmount } : {}}>
        {headerContent}
      </div>
      <div className="nested-section__body" style={nestAmount ? { marginTop: -nestAmount } : {}}>
        {bodyContent}
      </div>
    </div>
  );
};

export default NestedSection;
