import moment, { Moment, unitOfTime } from "moment";
import { DEFAULT_DATE_FORMAT } from "../../constants/application";
import { deltaTime } from "../../moment";
import { TDateFormat } from "../../../caricamento/lib/dataHandling/types";

/**
 * Return true if a date is passed by "delta" time.
 * @param date (Date) - The target date.
 * @param delta (deltaTime)
 *  - Quantity (number) - How many time you need to check - e.g. 5 for 5 years.
 *  - Unit (unitOfTime) - A Moment "unitOfTime" string, e.g: 'years', 'days', 'minutes' (and short) etc.
 */
export const isPassedDate = (date: Date, delta: deltaTime): boolean => {
  const now = moment();
  const diff = now.diff(date, delta.unit, true);
  const isDateInFuture = diff < 0;
  const isDatePassedByDelta = diff > delta.quantity;
  return !(isDateInFuture || isDatePassedByDelta);
};

/**
 * Converts a date (in string format) to another format using Moment.
 * Example: convertDate('1990-07-20', 'YYYY-MM-DD', 'DD/MM/YYYY') -- Converts 1990-07-20 to 20/07/1990
 * @param {string} date - The date to convert.
 * @param {string} fromFormat - The starting format, using Moment 'format' string tokens
 * @param {string} toFormat - The wanted format, using Moment 'format' string tokens
 */
export const convertDate = (date: string, fromFormat: string, toFormat: string): string =>
  moment(date, fromFormat).format(toFormat);

/**
 * Check if the date is in future
 * @param date - Moment date
 * @param diffUnit - A Moment "unitOfTime" string, e.g: 'years', 'days', 'minutes' (and short) etc.
 */
export const isDateInFuture = (date: Moment, diffUnit: unitOfTime.Diff = "d"): boolean =>
  date.diff(moment(), diffUnit) >= 0;

/**
 * Check if the date is in past
 * @param date - Moment date
 * @param diffUnit - A Moment "unitOfTime" string, e.g: 'years', 'days', 'minutes' (and short) etc.
 */
export const isDateInPast = (date: Moment, diffUnit: unitOfTime.Diff = "d"): boolean =>
  date.diff(moment(), diffUnit) < 0;

/**
 * HOF to generate a validator to avoid future dates
 * @param format: a Moment.js 'format' string (e.g "YYYYMMDD")
 */
export const avoidFutureDates = (format: string = DEFAULT_DATE_FORMAT): ((value: string) => boolean) => value => {
  if (!value) {
    // returning the date as valid because this validator should not care whether is present or not
    return true;
  }

  const valueMoment = moment(value, format);

  if (!valueMoment.isValid()) {
    // returning the date as valid because this validator should not care whether is valid or not
    return true;
  }

  return !isDateInFuture(valueMoment);
};

/**
 * HOF to generate a validator to avoid dates that represent years out of the accepted range
 * @param {string} date - The date to be validated.
 * @param {number} min - The min value.
 * @param {number} max - The max value.
 */
export const isWithinYearsRange = (date: string, min: number, max: number): boolean => {
  if (!date) {
    // returning the date as valid because this validator should not care whether is present or not
    return true;
  }

  const age = Math.abs(moment().diff(moment(date), "years"));

  return min <= age && age < max;
};

/**
 * HOF to generate a validator to avoid past dates
 * @param format: a Moment.js 'format' string (e.g "YYYYMMDD")
 */
export const avoidPastDates = (format: string = DEFAULT_DATE_FORMAT): ((value: string) => boolean) => value => {
  if (!value) {
    // returning the date as valid because this validator should not care whether is present or not
    return true;
  }

  const valueMoment = moment(value, format);

  if (!valueMoment.isValid()) {
    // returning the date as valid because this validator should not care whether is valid or not
    return true;
  }

  return !isDateInPast(valueMoment);
};

export const validateIncomingDate = (strDate: string, format: TDateFormat): string => {
  /**
   * This function receives the incoming date string and the format to be verified. If the
   * date string corresponds to the format, the function returns the date string itself.
   * Otherwise, it returns an empty string.
   */

  let regEx = /^&/;

  if (!strDate) {
    return "";
  }

  switch (format) {
    case "YYYYMMDD":
      regEx = /^(203[0-9]|202[0-9]|20[01][0-9]|[0-1][0-9]{3})(1[0-2]|0[1-9])(3[01]|[0-2][1-9]|[12]0)$/;
      break;
    case "YYYYMM":
      regEx = /^(203[0-9]|202[0-9]|20[01][0-9]|[0-1][0-9]{3})(1[0-2]|0[1-9])$/;
      break;
    default:
      return "";
  }

  return strDate.match(regEx) ? strDate : "";
};
