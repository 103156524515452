// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroINR620LNGPLCCNY,
} from './';

/**
 * @export
 * @interface WpNormInputParametroINR620INRCND
 */
export interface WpNormInputParametroINR620INRCND {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    TPPCND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    CDPCND?: string;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620INRCND
     */
    LNGCND?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    DPTCND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    CNLCND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    DS1CND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    ST1CND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    ZIPCND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    CDPSOTFAS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    VL9PESASS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    VL9PESREL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    NR9PARPIU?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    NR9PARMEN?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    FLXVERSEQ?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620INRCND
     */
    FILLERCND?: string;
}
