import { BASE_PATH, Configuration, ConfigurationParameters } from "../../../shared/generated/loader/beni";
import { addTracingHeaders } from "../../../shared/lib/addTracingHeaders";
import { authWithTokenMiddleware } from "../authWithTokenMiddleware";
import { environmentApiBasePath } from "../environmentApiBasePath";

export const loaderBeniApiConfig: (configParams?: Partial<ConfigurationParameters>) => Configuration = (
  configParams = {}
) => {
  const { basePath, middleware } = configParams;
  return new Configuration({
    ...configParams,
    basePath: environmentApiBasePath(BASE_PATH, basePath),
    middleware: [authWithTokenMiddleware, addTracingHeaders, ...(middleware || [])],
  });
};
