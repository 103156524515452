import classNames from "classnames";
import * as React from "react";
import "./toggler.scss";

interface IOwnProps {
  isOpen: boolean;
  openText: string;
  closedText: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

type Props = IOwnProps;

const Toggler: React.FunctionComponent<Props> = ({ isOpen, openText, closedText, onClick }) => {
  const classes = classNames("toggler", {
    "toggler--is-open": isOpen,
  });

  return (
    <div className={classes} onClick={onClick}>
      <div className="toggler__icon material-icons">{isOpen ? "expand_less" : "expand_more"}</div>
      <div className="toggler__text">{isOpen ? openText : closedText}</div>
    </div>
  );
};

export default Toggler;
