// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AssicurazioneLeasing,
    MisuraGovernativa,
    PreventivoLeasingInputAllOfPersonalizzazioni,
    TipologiaAnagrafica,
    TipologiaImportoFirma,
    TipologiaVeicolo,
} from './';

/**
 * @export
 * @interface PreventivoLeasingInputAllOf
 */
export interface PreventivoLeasingInputAllOf {
    /**
     * percentule dell\'importo di acconto (fino a 3 cifre decimali)
     * @type {number}
     * @memberof PreventivoLeasingInputAllOf
     */
    acconto: number;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof PreventivoLeasingInputAllOf
     */
    eurotax: string;
    /**
     * @type {boolean}
     * @memberof PreventivoLeasingInputAllOf
     */
    buyback?: boolean;
    /**
     * @type {TipologiaVeicolo}
     * @memberof PreventivoLeasingInputAllOf
     */
    tipologiaVeicolo: TipologiaVeicolo;
    /**
     * @type {boolean}
     * @memberof PreventivoLeasingInputAllOf
     */
    veicoloCommerciale?: boolean;
    /**
     * @type {Date}
     * @memberof PreventivoLeasingInputAllOf
     */
    dataImmatricolazione: Date;
    /**
     * @type {TipologiaAnagrafica}
     * @memberof PreventivoLeasingInputAllOf
     */
    tipoAnagrafica: TipologiaAnagrafica;
    /**
     * calcolata in base al flag over64
     * @type {Date}
     * @memberof PreventivoLeasingInputAllOf
     */
    dataNascita: Date;
    /**
     * @type {string}
     * @memberof PreventivoLeasingInputAllOf
     */
    provincia: string;
    /**
     * @type {Array<AssicurazioneLeasing>}
     * @memberof PreventivoLeasingInputAllOf
     */
    assicurazioni: Array<AssicurazioneLeasing>;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInputAllOf
     */
    chilometriIniziali?: number;
    /**
     * valore dei km di riscatto espresso in migliaia
     * @type {number}
     * @memberof PreventivoLeasingInputAllOf
     */
    chilometraggio?: number;
    /**
     * recuperato dal servizio /preventivo/cassandra
     * @type {number}
     * @memberof PreventivoLeasingInputAllOf
     */
    percentualeRiscatto?: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInputAllOf
     */
    quotaEsente?: number;
    /**
     * @type {MisuraGovernativa}
     * @memberof PreventivoLeasingInputAllOf
     */
    misuraGovernativa?: MisuraGovernativa;
    /**
     * @type {TipologiaImportoFirma}
     * @memberof PreventivoLeasingInputAllOf
     */
    tipologiaImportoFirma?: TipologiaImportoFirma;
    /**
     * @type {PreventivoLeasingInputAllOfPersonalizzazioni}
     * @memberof PreventivoLeasingInputAllOf
     */
    personalizzazioni?: PreventivoLeasingInputAllOfPersonalizzazioni;
}
