// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Promozione,
    Provenienza,
} from './';

/**
 * @export
 * @interface PreventivoAllOf
 */
export interface PreventivoAllOf {
    /**
     * Valore della maxirata per le casistiche CO, valore dell\'importo di riscatto per le casistiche LE
     * @type {number}
     * @memberof PreventivoAllOf
     */
    importoRataFinale?: number;
    /**
     * importo che può o deve pagare il cliente per poter tenere il bene a fine finanziamento
     * @type {number}
     * @memberof PreventivoAllOf
     */
    importoRiscatto?: number;
    /**
     * Preventivo: un preventivo è scaduto se sono passati più di tre giorni dalla data di creazione;<br/> Promozione: una promozione è scaduta se la data corrente è maggiore alla data di fine validità della stessa
     * @type {boolean}
     * @memberof PreventivoAllOf
     */
    scaduto?: boolean;
    /**
     * @type {string}
     * @memberof PreventivoAllOf
     */
    modello: string;
    /**
     * concatenzione nomi assicurazioni presenti nel preventivo
     * @type {string}
     * @memberof PreventivoAllOf
     */
    nomiServizi: string;
    /**
     * @type {Promozione}
     * @memberof PreventivoAllOf
     */
    promozione?: Promozione;
    /**
     * @type {Provenienza}
     * @memberof PreventivoAllOf
     */
    provenienza: Provenienza;
}
