import { NativeRadioControl, Radio } from "@material/react-radio";
import { FieldProps } from "formik";
import * as React from "react";
import { IDataAttributes } from "caricamento/types/form";

interface IOwnProps {
  disabled?: boolean;
  label: string;
  // Formik render prop
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikField: FieldProps<any>;
  value: string | number | readonly string[] | undefined;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

type Props = IOwnProps;

const RadioField: React.FunctionComponent<Props> = ({
  disabled = false,
  label,
  formikField,
  value,
  onBlur,
  onChange,
  dataAttr,
}) => {
  /* Remove value from field, assigning it to a _ variable (not used)*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange: formikOnChange, onBlur: formikOnBlur, value: _, ...rest } = formikField.field;

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }

    formikOnChange(e);
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }

    formikOnBlur(e);
  };

  return (
    <React.Fragment>
      <Radio label={label}>
        <NativeRadioControl
          data-cy={dataAttr && dataAttr.cypressSelector}
          checked={value === formikField.field.value}
          disabled={disabled}
          value={value}
          onChange={changeHandler}
          onBlur={blurHandler}
          {...rest}
          name={formikField.field.name}
        />
      </Radio>
    </React.Fragment>
  );
};

export default React.memo(RadioField);
