import { FormikErrors } from "formik";
import { GenericCodiceDescrizioneItem } from "../../../shared/generated/globals";
import { SelectOptions } from "../../../shared/lib/utility/data_handling";
import { getElementOffset } from "../../../shared/lib/utility/dom";
import { scrollTo } from "../../../shared/lib/utility/generic";
import { TAllValues, TFieldName, TFieldValue } from "../../lib/dataHandling/types";

// the lib expects any: node_modules\formik\dist\types.d.ts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormikValidator = (values?: any) => Promise<FormikErrors<TAllValues>>;

export const scrollToError = (
  validateForm: FormikValidator,
  isReviewing: boolean
): (() => PromiseLike<void>) => async () => {
  const validationErrors = await validateForm();
  const fieldsWithValidationErrors = Object.keys(validationErrors);

  if (fieldsWithValidationErrors.length) {
    const firstElementWithError: HTMLInputElement | HTMLSelectElement | null = document.querySelector(
      `[name=${fieldsWithValidationErrors[0]}`
    );

    if (!isReviewing && firstElementWithError) {
      // Remove 100px to the offset value to avoid the input to be "under" the fixed header.
      scrollTo(getElementOffset(firstElementWithError).top - 100, 300).then(() => firstElementWithError.focus());
    }
  }
};

export const scrollToErrorWithoutValidate = (elementWithError: TFieldName): void => {
  const firstElementWithError: HTMLInputElement | HTMLSelectElement | null = document.querySelector(
    `[name=${elementWithError}`
  );

  if (firstElementWithError) {
    // Remove 100px to the offset value to avoid the input to be "under" the fixed header.
    scrollTo(getElementOffset(firstElementWithError).top - 100, 300).then(() => firstElementWithError.focus());
  }
};

export const contains = (options: string[], value: string): boolean => {
  const isNotListed = options.every(option => option != value);
  return !isNotListed;
};

export const evaluateSelect = (options: Array<SelectOptions>, value: TFieldValue): boolean =>
  options.some(obj => obj.value === value);

/**
 *
 * @param options full picklist entry
 * @param toHide values to hide
 * @returns
 */

export const hideOptions = (
  options: GenericCodiceDescrizioneItem[],
  toHide: string[],
  enable: boolean
): GenericCodiceDescrizioneItem[] => {
  if (enable && options.length) {
    return options.filter(option => option.codice && !toHide.includes(option.codice));
  }
  return options;
};
