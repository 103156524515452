export * from './CodiceIntermediario';
export * from './CodiceIntermediarioData';
export * from './DatiCFInput';
export * from './DatiCodiceFiscale';
export * from './DatiCodiceFiscaleData';
export * from './DatiCodiceFiscaleDataNascita';
export * from './DatiStorici';
export * from './DatiStoriciObjDatiStorici';
export * from './ErrorObj';
export * from './Esito';
export * from './EsitoPagamentoCarta';
export * from './EsitoPraticaInput';
export * from './Files';
export * from './FilesFiles';
export * from './InvioScbOK';
export * from './JWT2Input';
export * from './JWT2Output';
export * from './ObjDatiStorici';
export * from './PagamentiWL';
export * from './PagamentoCarta';
export * from './PagamentoCartaOK';
export * from './PagamentoCartaOKPayload';
export * from './PagamentoCartaOKPayloadUserRedirect';
export * from './PagamentoWLItem';
export * from './Pratica';
export * from './Preventivo';
export * from './StoricoOutput';
export * from './StoricoOutputObjDatiStorici';
export * from './SuggerimentiDati';
export * from './SuggerimentiDatiSuggerimenti';
export * from './SuggerimentiDatiSuggerimentiCL';
export * from './UploadDocVerifiedKO';
