// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface NuovaControparteContropartiCoobbligatoResidenza
 */
export interface NuovaControparteContropartiCoobbligatoResidenza {
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    indirizzo?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    cap?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    provincia?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    localita?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    abitazione?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    dataabitazione?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    telefono?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    cellulare?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    email?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiCoobbligatoResidenza
     */
    presenzaemail?: string;
}
