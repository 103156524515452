import { Middleware, ResponseArgs } from "../../shared/generated/globals";
import { SessionStorage } from "../../shared/lib/utility/storage-v2";
import { AUTH_TOKEN_SESSION_KEY } from "../config/application";

export const tokenMiddleware: Middleware = {
  post(response: ResponseArgs): ResponseArgs {
    if (response.xhr.getResponseHeader("Authorization")) {
      const authHeader = response.xhr.getResponseHeader("Authorization");
      if (authHeader) {
        const token = authHeader.replace("Bearer ", "");
        SessionStorage.write(AUTH_TOKEN_SESSION_KEY, token);
      }
    }
    return response;
  },
};
