import { createContext } from "react";
import { INormalizzatoreContext } from "./normalizzatore.types";

const initialValue: INormalizzatoreContext = {
  province: [],
  comuni: {},
  getComuniForProvincia: () => {
    return;
  },
};

const context = createContext<INormalizzatoreContext>(initialValue);
context.displayName = "Normalizzatore";

export const NormalizzatoreProvider = context.Provider;
export const NormalizzatoreConsumer = context.Consumer;
export default context;
