// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TipologiaCreditLife,
} from './';

/**
 * @export
 * @interface ServizioAssicurativo
 */
export interface ServizioAssicurativo {
    /**
     * stabilisce se il servizio richiede un allestimento/eurotax
     * @type {boolean}
     * @memberof ServizioAssicurativo
     */
    carlineObbligatoria?: boolean;
    /**
     * @type {string}
     * @memberof ServizioAssicurativo
     */
    pacchetto?: string;
    /**
     * @type {string}
     * @memberof ServizioAssicurativo
     */
    descrizionePacchetto?: string;
    /**
     * @type {string}
     * @memberof ServizioAssicurativo
     */
    codice?: string;
    /**
     * @type {TipologiaCreditLife}
     * @memberof ServizioAssicurativo
     */
    creditlife?: TipologiaCreditLife;
    /**
     * @type {string}
     * @memberof ServizioAssicurativo
     */
    descrizione?: string;
    /**
     * durate
     * @type {Array<number>}
     * @memberof ServizioAssicurativo
     */
    durate?: Array<number>;
    /**
     * @type {string}
     * @memberof ServizioAssicurativo
     */
    fascicoloInformativo?: string;
    /**
     * @type {string}
     * @memberof ServizioAssicurativo
     */
    help?: string;
    /**
     * stabilisce se il servizio assicurativo non è opzionale e quindi va usato in modo obbligatorio nel calcolo del preventivo
     * @type {boolean}
     * @memberof ServizioAssicurativo
     */
    obbligatorio?: boolean;
    /**
     * @type {number}
     * @memberof ServizioAssicurativo
     */
    ordineServizio?: number;
    /**
     * stabilisce se il servizio richiede una provincia di residenza per calcolarne il premio
     * @type {boolean}
     * @memberof ServizioAssicurativo
     */
    provinciaRichiesta?: boolean;
    /**
     * stabilisce se è possibile recuperare l\'iva di uno specifico servizio (solo leasing)
     * @type {boolean}
     * @memberof ServizioAssicurativo
     */
    recuperoIva?: boolean;
}
