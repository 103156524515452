import classNames from "classnames";
import * as React from "react";
import Sticky from "react-sticky-el";
import "./stepper_button.scss";

interface IOwnProps {
  children?: React.ReactNode;
  hasErrors?: boolean;
  isActive?: boolean;
  isComplete?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  stepNumber?: string;
}

type Props = IOwnProps;

const StepperButton: React.FunctionComponent<Props> = ({
  children,
  hasErrors = false,
  isActive = false,
  isComplete = false,
  onClick,
  stepNumber,
}) => {
  const classes = classNames({
    "stepper-button": true,
    "stepper-button--active": isActive,
    // "stepper-button--complete": isComplete,
    "stepper-button--errors": hasErrors,
  });

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div className="stepper-button">
      <div className="stepper-button__row">
        <div className="stepper-button__number-col">
          <Sticky
            className="stepper-button__number-wrapper"
            boundaryElement=".caricamento-step"
            hideOnBoundaryHit={false}
            topOffset={0}
            bottomOffset={-104}
            disabled={!isActive}
          >
            {hasErrors ? (
              <div className="material-icons icon--warning">warning</div>
            ) : isComplete ? (
              <div className="material-icons icon--check_circle">check_circle</div>
            ) : (
              <div className="stepper-button__number">{stepNumber}</div>
            )}
          </Sticky>
        </div>
        <div className="stepper-button__button-col">
          <button type="button" className={classes} onClick={clickHandler}>
            {children ? children : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StepperButton);
