// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Tipologia di controparte CL=Cliente, CO=Coobbligato, etc...
 * @export
 * @enum {string}
 */
export enum TipologiaControparte {
    CO = 'CO',
    CL = 'CL',
    E1 = 'E1',
    E2 = 'E2',
    E3 = 'E3',
    G1 = 'G1',
    G2 = 'G2',
    G3 = 'G3',
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3'
}

