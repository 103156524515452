import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Switch } from "react-router-dom";
import { customRoutesConfig } from "../custom/components/personalDataForm/Routes";
import scbLogo from "../shared/assets/images/logo.svg";
import ErrorPage from "../shared/components/error_page/error_page";
import Header from "../shared/components/header/header";
import LoadingScreen from "../shared/components/loading_screen/loading_screen";
import NotFoundPage from "../shared/components/not_found_page/not_found_page";
import { CARICAMENTO_TOKEN_SESSION_KEY } from "../shared/config/application";
import { AdeguamentoSPACaricamentoApi } from "../shared/generated/whiteLabel";
import { ICustomizationState } from "../shared/lib/customization/customization.types";
import { CustomizationContext } from "../shared/lib/customization/CustomizationContext";
import { customizationHookState, useCustomization } from "../shared/lib/customization/useCustomization";
import { routesFactory } from "../shared/lib/utility/routesFactory";
import ScrollToTop from "../shared/scroll_to_top";
import { DEFAULT_ROUTE_KEY, TAddOn, TRoutesConfig } from "../shared/types/routes";
import FinancialSummary from "../upload/components/financialSummary/FinancialSummary";
import Authentication from "./components/authentication/authentication";
import CaricamentoWrapper from "./components/caricamento/wrapper/CaricamentoWrapper";
import { whitelabelApiConfig } from "./lib/whitelabelApiConfig";
import { setToken } from "./logic/authentication/actions";
import { IState } from "./logic/authentication/types";
import { IStoreState } from "./types/store";

interface IProps extends RouteComponentProps {
  addOn?: TAddOn;
}

const defaultRoutesConfig: TRoutesConfig = {
  "/authenticate/:token": {
    Component: Authentication,
    props: {
      successRoute: "/caricamento",
      errorRoute: "/error",
    },
  },
  "/caricamento": {
    Component: CaricamentoWrapper,
  },
  "/edit": {
    Component: FinancialSummary,
    props: {
      tokenSessionKey: CARICAMENTO_TOKEN_SESSION_KEY,
    },
  },
  "/error/:type?": {
    Component: ErrorPage,
  },
  [DEFAULT_ROUTE_KEY]: {
    Component: NotFoundPage,
  },
};

const routesConfig = { ...defaultRoutesConfig, ...customRoutesConfig };

const adeguamentoApi = new AdeguamentoSPACaricamentoApi(whitelabelApiConfig());

const Routes: React.FunctionComponent<IProps> = ({ addOn }) => {
  const { preventivoId, jwt } = useSelector<IStoreState, IState>(state => state.authentication);

  const nPrevId = preventivoId ? Number(preventivoId) : 0;
  const customization: ICustomizationState = useCustomization(
    nPrevId,
    adeguamentoApi.preventivoPartnerPrevIdGet,
    true,
    jwt
  );

  const loaded =
    customization.state === customizationHookState.SUCCESS || customization.state === customizationHookState.ERROR;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setToken());
  }, []);

  return (
    <ScrollToTop>
      <CustomizationContext.Provider value={customization}>
        {loaded && (
          <Header sideLogo={scbLogo} sideTitle="Santander Consumer Bank" sideUrl="https://www.santanderconsumer.it/" />
        )}

        <LoadingScreen show={!loaded} />

        <Switch>{routesFactory(routesConfig, addOn)}</Switch>
      </CustomizationContext.Provider>
    </ScrollToTop>
  );
};

export default withRouter(Routes);
