import MaterialTextField, { HelperText, Input } from "@material/react-text-field";
import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash.get";
import moment from "moment";
import * as React from "react";
import { useEffect, useRef } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import { Rifm } from "rifm";
import {
  DEFAULT_DATE_DISPLAY_FORMAT,
  DEFAULT_DATE_FORMAT,
  MONTHYEAR_DATE_DISPLAY_FORMAT,
  MONTHYEAR_DATE_FORMAT,
} from "../../constants/application";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { isMobile } from "../../lib/utility/responsive";
import "./date_field.scss";
import ReactPickerJs from "./react_picker_js";
import { IDataAttributes } from "../../../caricamento/types/form";

type dateType = "monthYear" | "date" | "datetime" | "time";

interface IOwnProps {
  // Shrink the field to a smallest variant. Defaults to true
  dense?: boolean;
  // Disable the field. Defaults to false
  disabled?: boolean;
  // The momentjs format for the date which is displayed to the user
  displayFormat?: string;
  // The momentjs input/output format for the date
  format?: string;
  // Formik render prop
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikField: FieldProps<any>;
  // Defaults to true
  fullWidth?: boolean;
  // Some helper text showed under the input
  helperText?: string;
  // The label for the field
  label?: string;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
  onChange?: (selectedDate: Date) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onPick?: () => void;
  serverSideError?: string;
  // type of the picker
  type?: dateType;
  tooltip?: string | React.ReactNode;
}

type Props = IOwnProps;

const getDateFormat = (type: dateType): string[] => {
  switch (type) {
    case "monthYear":
      return [MONTHYEAR_DATE_DISPLAY_FORMAT, MONTHYEAR_DATE_FORMAT];
    case "datetime":
      return ["DD/MM/YYYY HH:mm:ss", "YYYYMMDD[T]HHmmssZ"]; // TODO: definire costante per datetime
    case "time":
      return ["HH:mm:ss", "HHmmssZ"]; // TODO: definire costante per time
    case "date":
    default:
      return [DEFAULT_DATE_DISPLAY_FORMAT, DEFAULT_DATE_FORMAT];
  }
};

const DateField: React.FunctionComponent<Props> = ({
  dense = false,
  disabled = false,
  displayFormat,
  format,
  formikField,
  fullWidth = true,
  helperText,
  label = "",
  onBlur,
  onChange,
  onOpen,
  onClose,
  onPick,
  serverSideError,
  type = "date",
  dataAttr,
  tooltip,
}) => {
  const id = useRef(`id-${Math.random()}`);
  const inputRef = useRef(null);
  const pickerJsInstanceRef = useRef<Picker | null>(null);

  // recupero format e displayFormat dal tipo
  // TODO: Rewrite this as `const [displayFormat, dateFormat] = getDateFormat(type)`  -- and use it accordingly
  //       CAUTION: `displayFormat` is not a valid name, as it is already a prop (for overwriting the type behaviour)
  const dateFormat = getDateFormat(type);

  const getDateFromFormikValue = (): Date | undefined => {
    const fieldValue = formikField.field.value;
    const formatOfDate = format ? format : dateFormat[1];

    if (!fieldValue || fieldValue === "") {
      return undefined;
    }

    const momentDate = moment(fieldValue, formatOfDate);

    return momentDate.isValid() ? moment(fieldValue, formatOfDate).toDate() : undefined;
  };

  const [datePickerValue, setDatePickerValue] = React.useState<Date | undefined>(getDateFromFormikValue());

  useEffect(() => {
    setDatePickerValue(getDateFromFormikValue());
  }, [formikField.field.value]);

  const isPristine = !get(formikField.form.touched, formikField.field.name);
  const isValid = !((!isPristine && get(formikField.form.errors, formikField.field.name)) || serverSideError);

  const classes = classNames({
    "date-field--invalid": !isValid,
    "date-field--fullwidth": fullWidth,
  });

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }

    if (pickerJsInstanceRef && pickerJsInstanceRef.current) {
      pickerJsInstanceRef.current.update();
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleChange = (date: Date) => {
    formikField.form.setFieldValue(formikField.field.name, moment(date).format(format ? format : dateFormat[1]));

    if (onPick) {
      onPick();
    }

    if (onChange) {
      onChange(date);
    }
  };

  const handlePick = () => {
    const date =
      ((pickerJsInstanceRef && pickerJsInstanceRef.current && pickerJsInstanceRef.current.getDate()) as Date) ||
      undefined;

    handleChange(date);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }

    formikField.form.setFieldTouched(formikField.field.name, true, false);
  };

  const width = useWindowWidth();

  const composedLabel = (
    <>
      {label} {tooltip ? tooltip : null}
    </>
  );

  const renderInput = ({ value, onChange }: { value: string; onChange: () => void }) => (
    <MaterialTextField
      className={classes}
      dense={dense}
      helperText={
        serverSideError ? (
          <HelperText isValidationMessage={true} persistent={true} validation={true}>
            {serverSideError}
          </HelperText>
        ) : isPristine || isValid ? (
          helperText ? (
            <HelperText persistent={true} isValidationMessage={false}>
              {helperText}
            </HelperText>
          ) : (
            <HelperText>{/* Empty Component */}</HelperText>
          )
        ) : (
          <HelperText
            isValidationMessage={true}
            persistent={true}
            validation={true}
            className={classNames({ "mdc-text-field-helper-text--invalid": !isValid })}
          >
            {get(formikField.form.errors, formikField.field.name)}
          </HelperText>
        )
      }
      label={composedLabel}
      outlined={false}
    >
      <Input
        type="tel"
        placeholder="gg/mm/aaaa"
        name={formikField.field.name}
        disabled={disabled}
        isValid={isValid}
        onBlur={handleBlur}
        value={value}
        onChange={onChange}
        id={id.current}
        data-cy={dataAttr && dataAttr.cypressSelector}
      />
    </MaterialTextField>
  );

  const parseDigits = (string: string) => (string.match(/\d+/g) || []).join("");

  const addMask = (value: string) => {
    const digits = parseDigits(value);
    let maskedValue = "";
    if (dateFormat[1] === MONTHYEAR_DATE_FORMAT) {
      const months = digits.slice(0, 2).padEnd(2, "");
      const years = digits.slice(2, 6).padEnd(4, "");

      maskedValue = `${months}/${years}`;
    } else if (dateFormat[1] === DEFAULT_DATE_FORMAT) {
      const days = digits.slice(0, 2).padEnd(2, "");
      const months = digits.slice(2, 4).padEnd(2, "");
      const years = digits.slice(4, 8).padEnd(4, "");

      maskedValue = `${days}/${months}/${years}`;
    }

    if (digits.length <= 2) {
      maskedValue = maskedValue.replace(/\//g, "");
    }
    return maskedValue;
  };

  /**
   * Returns date to display
   */
  const formatDate = (value: string): string => {
    const _valueFormat = format ? format : dateFormat[1];
    const _displayFormat = displayFormat ? displayFormat : dateFormat[0];
    const momentInstance = moment(value, _valueFormat, true);
    if (momentInstance.isValid()) {
      return momentInstance.format(_displayFormat);
    }
    return value;
  };

  const formatString = (value: string): string => {
    const _displayFormat = displayFormat ? displayFormat : dateFormat[0];
    let _index = 0;
    return value
      .replace(/\//g, "")
      .split("")
      .reduce((result, letter) => {
        if (_displayFormat[_index] === "/") {
          result += "/" + letter;
          _index++;
        } else {
          result += letter;
        }
        _index++;
        return result;
      }, "");
  };

  const handleRifmChange = (value: string): void => {
    const _valueFormat = format ? format : dateFormat[1];

    const _displayFormat = displayFormat ? displayFormat : dateFormat[0];
    const momentInstance = moment(value, _displayFormat, true);
    if (momentInstance.isValid()) {
      const _value = momentInstance.format(_valueFormat);
      formikField.form.setFieldValue(formikField.field.name, _value);
    } else {
      formikField.form.setFieldValue(formikField.field.name, value);
    }
  };

  return formikField ? (
    <div className="form__field date-field">
      {isMobile(width) ? (
        <div>
          <MaterialTextField
            className={classes}
            dense={dense}
            helperText={
              serverSideError ? (
                <HelperText isValidationMessage={true} persistent={true} validation={true}>
                  {serverSideError}
                </HelperText>
              ) : isPristine || isValid ? (
                helperText ? (
                  <HelperText persistent={true} isValidationMessage={false}>
                    {helperText}
                  </HelperText>
                ) : (
                  <HelperText>{/* Empty Component */}</HelperText>
                )
              ) : (
                <HelperText isValidationMessage={true} persistent={true} validation={true}>
                  {get(formikField.form.errors, formikField.field.name)}
                </HelperText>
              )
            }
            label={label}
            outlined={false}
          >
            <Input
              autoComplete="off"
              disabled={disabled}
              isValid={isValid}
              name={formikField.field.name}
              data-cy={dataAttr && dataAttr.cypressSelector}
              onBlur={handleBlur}
              // onClick={handleClick}
              // onKeyDown={handleKeyDown}
              readOnly={true}
              value={
                datePickerValue === undefined
                  ? ""
                  : moment(datePickerValue).format(displayFormat ? displayFormat : dateFormat[0])
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref={(input: any) => {
                if (input && input.inputElement) {
                  inputRef.current = input.inputElement;
                }
              }}
              id={id.current}
            />
          </MaterialTextField>
          <div>
            <ReactPickerJs
              inputRef={inputRef}
              handlePick={handlePick}
              handleOpen={handleOpen}
              handleClose={handleClose}
              innerRef={pickerJsInstanceRef}
              // {...overrideDisplayFormat ? {overrideDisplayFormat: overrideDisplayFormat} : null}
              displayFormat={displayFormat ? displayFormat : dateFormat[0]}
              value={
                datePickerValue === undefined
                  ? ""
                  : moment(datePickerValue).format(displayFormat ? displayFormat : dateFormat[0])
              }
            />
          </div>
        </div>
      ) : (
        <div>
          <Rifm
            accept={/[\d]/g}
            mask={true}
            replace={addMask}
            format={formatDate}
            append={formatString}
            value={formikField.field.value}
            onChange={handleRifmChange}
          >
            {renderInput}
          </Rifm>
        </div>
      )}
    </div>
  ) : null;
};

export default React.memo(DateField);
