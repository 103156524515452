// tslint:disable
/**
 * Create Codice Fiscale
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    Output,
    OutputError,
} from '../models';

export interface CodiceFiscaleGetRequest {
    nome: string;
    cognome: string;
    sesso: string;
    luogoDiNascita: string;
    provincia: string;
    dataDiNascita: Date;
    X_B3_TraceId?: string;
}

/**
 * no description
 */
export class CreazioneCodiceFiscaleApi extends BaseAPI {

    /**
     * Il presente documento descrive l\'interfaccia esposta da Santander consumer bank per creare il codice fiscale.
     */
    codiceFiscaleGet = (requestParameters: CodiceFiscaleGetRequest): Observable<Output> => {
        throwIfRequired(requestParameters, 'nome', 'codiceFiscaleGet');
        throwIfRequired(requestParameters, 'cognome', 'codiceFiscaleGet');
        throwIfRequired(requestParameters, 'sesso', 'codiceFiscaleGet');
        throwIfRequired(requestParameters, 'luogoDiNascita', 'codiceFiscaleGet');
        throwIfRequired(requestParameters, 'provincia', 'codiceFiscaleGet');
        throwIfRequired(requestParameters, 'dataDiNascita', 'codiceFiscaleGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.nome && { 'nome': requestParameters.nome }),
            ...(requestParameters.cognome && { 'cognome': requestParameters.cognome }),
            ...(requestParameters.sesso && { 'sesso': requestParameters.sesso }),
            ...(requestParameters.luogoDiNascita && { 'luogoDiNascita': requestParameters.luogoDiNascita }),
            ...(requestParameters.provincia && { 'provincia': requestParameters.provincia }),
            ...(requestParameters.dataDiNascita && { 'dataDiNascita': (requestParameters.dataDiNascita as any).toISOString() }),
        };

        return this.request<Output>({
            path: '/codice-fiscale',
            method: 'GET',
            headers,
            query,
        });
    };

}
