// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Success,
} from './';

/**
 * se true è necessario riscaricare il modulo informativa, se false non sono state effettuate variaizioni
 * @export
 * @interface SuccessInformativaCheck
 */
export interface SuccessInformativaCheck extends Success {
}
