// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
    ErrorObject,
    NuovaControparte,
    PagamentoInput,
    TokenCreaOutput,
} from '../models';

export interface GETTokenRequest {
    prev_id: number;
    tipoControparte?: string;
    orderId?: string;
    endingUrl?: string;
}

export interface POSTClienteRequest {
    requestBody: NuovaControparte;
    token?: string;
    canale?: string;
}

export interface POSTPagamentoRequest {
    requestBody: PagamentoInput;
}

/**
 * no description
 */
export class PartnerApi extends BaseAPI {

    /**
     * Richiedi token
     */
    gETToken = (requestParameters: GETTokenRequest): Observable<TokenCreaOutput> => {
        throwIfRequired(requestParameters, 'prev_id', 'gETToken');

        const headers: HttpHeaders = {
            ...(requestParameters.tipoControparte && { 'tipoControparte': String(requestParameters.tipoControparte) }),
            ...(requestParameters.orderId && { 'orderId': String(requestParameters.orderId) }),
            ...(requestParameters.endingUrl && { 'endingUrl': String(requestParameters.endingUrl) }),
        };

        return this.request<TokenCreaOutput>({
            path: '/token/crea/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Questa api viene utilizzata dal server del cliente, che comunica a SCB i dati del proprio                 cliente, in modo che SCB possa precompilare la pagina di caricamento dati.                   Questa chiamata non è obbligatoria
     * Crea una nuova anagrafica
     */
    pOSTCliente = (requestParameters: POSTClienteRequest): Observable<NuovaControparte> => {
        throwIfRequired(requestParameters, 'requestBody', 'pOSTCliente');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.canale && { 'canale': String(requestParameters.canale) }),
        };

        return this.request<NuovaControparte>({
            path: '/cliente',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    pOSTPagamento = (requestParameters: POSTPagamentoRequest): Observable<object> => {
        throwIfRequired(requestParameters, 'requestBody', 'pOSTPagamento');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<object>({
            path: '/pagamento',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

}
