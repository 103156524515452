// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
    Controparte,
    ErrorObject,
    ErrorOutputKO,
    ErrorOutputOK,
    Errors,
    NoteScriviInput,
    PraticaConsumo,
    PraticaConsumoKo,
    PraticaConsumoOk,
    PreventivoOutput,
} from '../models';

export interface GETClienteRequest {
    token?: string;
    as400?: boolean;
    tipoControparte?: string;
}

export interface GETErroriRequest {
    prev_id: number;
    tipoControparte?: string;
    token?: string;
}

export interface GETPreventivoRequest {
    prev_id: string;
}

export interface POSTNoteScriviRequest {
    requestBody: NoteScriviInput;
}

export interface POSTPraticaRequest {
    requestBody: PraticaConsumo;
    Organization?: string;
    token?: string;
}

/**
 * no description
 */
export class SCBPaginaCaricamentoApi extends BaseAPI {

    /**
     * Ottiene i dati dell\'\'utente, necessari per pre-compilare il form
     * Ottieni anagrafica
     */
    gETCliente = (requestParameters: GETClienteRequest): Observable<Controparte> => {

        const headers: HttpHeaders = {
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.as400 && { 'as400': String(requestParameters.as400) }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': String(requestParameters.tipoControparte) }),
        };

        return this.request<Controparte>({
            path: '/cliente',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    gETErrori = (requestParameters: GETErroriRequest): Observable<ErrorOutputOK> => {
        throwIfRequired(requestParameters, 'prev_id', 'gETErrori');

        const headers: HttpHeaders = {
            ...(requestParameters.tipoControparte && { 'tipoControparte': String(requestParameters.tipoControparte) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        return this.request<ErrorOutputOK>({
            path: '/errori/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Ottiene i dati del preventivo, necessari per visualizzarli nell\'interfaccia e per salvare lapratica.**Attenzione**: Accetta sia Token1 che Token 3, in quanto viene utilizzata sia nella SPA Caricamento che nella SPA Documenti
     * Ottieni Preventivo
     */
    gETPreventivo = (requestParameters: GETPreventivoRequest): Observable<PreventivoOutput> => {
        throwIfRequired(requestParameters, 'prev_id', 'gETPreventivo');

        return this.request<PreventivoOutput>({
            path: '/preventivo/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
        });
    };

    /**
     */
    pOSTNoteScrivi = (requestParameters: POSTNoteScriviRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'requestBody', 'pOSTNoteScrivi');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/note/scrivi',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     * Carica dati
     */
    pOSTPratica = (requestParameters: POSTPraticaRequest): Observable<PraticaConsumoOk> => {
        throwIfRequired(requestParameters, 'requestBody', 'pOSTPratica');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.Organization && { 'Organization': String(requestParameters.Organization) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        return this.request<PraticaConsumoOk>({
            path: '/pratica',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

}
