import { Button, ButtonProps } from "@material/react-button";
import classNames from "classnames";
import React, { HTMLProps, ReactElement, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import exitUrlHandler from "../../lib/utility/exitUrlHandler";
import { isAbsoluteUrl } from "../../lib/utility/isAbsoluteUrl";
import Spinner from "../spinner/spinner";
import "./button.scss";

interface IProps extends ButtonProps<HTMLAnchorElement | HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactElement<HTMLProps<HTMLOrSVGElement>>;
  isLoading?: boolean;
  isComplete?: boolean;
  size?: "normal" | "big";
  theme?: "primary" | "secondary" | "success" | "error" | "complete" | "error-outline" | "secondary-outline";
  to?: string;
  type?: string;
}

const LinkButton: React.FunctionComponent<IProps & RouteComponentProps> = ({
  className,
  disabled,
  fullWidth = false,
  history,
  icon,
  isLoading = false,
  isComplete = false,
  onClick,
  size = "normal",
  theme,
  to,
  type = "button",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  staticContext,
  ...rest
}) => {
  const classes = classNames({
    button: true,
    "button--fullwidth": fullWidth,
    "button--size-big": size === "big",
    "button--primary": theme === "primary",
    "button--secondary": theme === "secondary",
    "button--secondary-outline": theme === "secondary-outline",
    "button--success": theme === "success",
    "button--complete": isComplete,
    "button--error": theme === "error",
    "button--error-outline": theme === "error-outline",
    "button--disabled": disabled || isLoading,
    ...(className ? { [className]: true } : {}),
  });

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick(event);
    }

    if (to) {
      if (isAbsoluteUrl(to)) {
        exitUrlHandler(to);
      } else {
        history.push(to);
      }
    }
  };

  return (
    <Button
      className={classes}
      onClick={clickHandler}
      icon={icon}
      {...(isLoading ? { trailingIcon: <Spinner /> } : {})}
      data-cy={
        rest && typeof rest.children === "string"
          ? rest.children.replace(/\s/g, "_").toLowerCase()
          : `button_${theme}_${rest.type}`
      }
      type={type}
      {...rest}
    />
  );
};

export default React.memo(withRouter(LinkButton));
