// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    StartProcessDurablesInputPreventivoAssicurazioni,
    StartProcessDurablesInputPreventivoMisuraGovernativa,
    StartProcessDurablesInputPreventivoPersonalizzazioni,
} from './';

/**
 * @export
 * @interface StartProcessDurablesInputPreventivo
 */
export interface StartProcessDurablesInputPreventivo {
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    codiceBene?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    codiceProdotto?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    commissioni?: number;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    descrizioneFinanziamento?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    durata?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    finanziato?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    prezzoVendita?: number;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    tabellaFinanziaria?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    tipoBene?: number;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    marca?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    modello?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    eurotax?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    tipologiaVeicolo?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    acconto?: number;
    /**
     * @type {Array<StartProcessDurablesInputPreventivoAssicurazioni>}
     * @memberof StartProcessDurablesInputPreventivo
     */
    assicurazioni?: Array<StartProcessDurablesInputPreventivoAssicurazioni>;
    /**
     * @type {boolean}
     * @memberof StartProcessDurablesInputPreventivo
     */
    buyback?: boolean;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    chilometraggio?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    chilometriIniziali?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    cilindrata?: number;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    dataImmatricolazione?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    dataNascita?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    importoMaxirata?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    listino?: number;
    /**
     * @type {StartProcessDurablesInputPreventivoMisuraGovernativa}
     * @memberof StartProcessDurablesInputPreventivo
     */
    misuraGovernativa?: StartProcessDurablesInputPreventivoMisuraGovernativa;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    pagamento?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    percentualeCapitaleResiduo?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    percentualeMaxirata?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    percentualeRiscatto?: number;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    provincia?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    quotaEsente?: number;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    tipoAnagrafica?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInputPreventivo
     */
    tipologiaImportoFirma?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInputPreventivo
     */
    valoreEurotax?: number;
    /**
     * @type {boolean}
     * @memberof StartProcessDurablesInputPreventivo
     */
    veicoloCommerciale?: boolean;
    /**
     * @type {StartProcessDurablesInputPreventivoPersonalizzazioni}
     * @memberof StartProcessDurablesInputPreventivo
     */
    personalizzazioni?: StartProcessDurablesInputPreventivoPersonalizzazioni;
}
