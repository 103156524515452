// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ErrorOutputKOErroriErrori,
} from './';

/**
 * @export
 * @interface ErrorOutputKOErrori
 */
export interface ErrorOutputKOErrori {
    /**
     * @type {ErrorOutputKOErroriErrori}
     * @memberof ErrorOutputKOErrori
     */
    errori?: ErrorOutputKOErroriErrori;
    /**
     * @type {string}
     * @memberof ErrorOutputKOErrori
     */
    errorcode?: string;
    /**
     * @type {string}
     * @memberof ErrorOutputKOErrori
     */
    descrizione?: string;
}
