import { HelperText } from "@material/react-text-field";
import classNames from "classnames";
import { Field, FieldProps, Form, FormikProps, withFormik, yupToFormErrors } from "formik";
import get from "lodash.get";
import has from "lodash.has";
import isEmpty from "lodash.isempty";
import isObjectLike from "lodash.isobjectlike";
import uniqBy from "lodash.uniqby";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import AutocompleteField from "../../../../shared/components/autocomplete_field/autocomplete_field";
import BooleanRadio from "../../../../shared/components/boolean_radio/boolean_radio";
import Button from "../../../../shared/components/button/button";
import DateField from "../../../../shared/components/date_field/date_field";
import { FormInfo } from "../../../../shared/components/form_info/form_info";
import NumericField from "../../../../shared/components/numeric_field/numeric_field";
import SelectField from "../../../../shared/components/select_field/select_field";
import TextField from "../../../../shared/components/text_field/text_field";
import {
  CITTADINANZA_ITALIANA,
  DEFAULT_DATE_FORMAT,
  PERMESSO_DI_SOGGIORNO,
  PERSONAL_DATA,
  PRIVACY_VALUES,
  SESSO_OPTIONS,
  URL_INFORMATIVA_CONTRATTI,
  URL_INFORMATIVA_PRIVACY,
  URL_INFORMATIVA_VENDITA,
} from "../../../../shared/constants/application";
import {
  CELLULARE_HELPER,
  CODICE_FISCALE_INCONGRUENTE,
  EMAIL_HELPER,
  LABEL_IGNORA,
  LABEL_NORMALIZZATORE_AIUTAMI,
  LABEL_PROSEGUI,
} from "../../../../shared/constants/labels";
import { SCBPaginaCaricamentoETApi } from "../../../../shared/generated/caricamento";
import {
  CodiceFiscaleGetRequest,
  CreazioneCodiceFiscaleApi,
  Output,
} from "../../../../shared/generated/GETCodiceFiscale";
import { CustomizationContext } from "../../../../shared/lib/customization/CustomizationContext";
import { isFormSectionHidden, resetField } from "../../../../shared/lib/form/form";
import { error, warn } from "../../../../shared/lib/log";
import { arrayToSelectOptions } from "../../../../shared/lib/utility/data_handling";
import { convertDate } from "../../../../shared/lib/utility/date";
import { caricamentoApiConfig } from "../../../lib/caricamentoApiConfig";
import { creazioneCodiceFiscaleApiConfig } from "../../../lib/creazioneCodiceFiscaleApiConfig";
import { TFieldName, TPersonalDataValues } from "../../../lib/dataHandling/types";
import { normalizzatoreContext, useNormalizzatore, withNormalizzatore } from "../../../lib/normalizzatore";
import { getListaStradeInputParams, parseListaStradeResponse } from "../../../lib/normalizzatore/listaStrade.utils";
import {
  CapObservableGenerator,
  ComuniObservableGenerator,
  IAddress,
  IComune,
  INormalizzatoreResult,
  ListaStradeObservableGenerator,
  NormalizzatoreObservableGenerator,
  ProvinceObservableGenerator,
} from "../../../lib/normalizzatore/normalizzatore.types";
import { getNormalizzatoreInputParams, parseResponse } from "../../../lib/normalizzatore/normalizzatore.utils";
import NormalizzatoreHelperText from "../../../lib/normalizzatore/ui/normalizzatoreHelperText";
import { getZipCodeInputParams, parseZipCodeResponse } from "../../../lib/normalizzatore/zipcode.utils";
import { setCustomerEnablers, setOnReviewErrors } from "../../../logic/caricamento/actions";
import {
  getAbitazioni,
  getCap,
  getCittadinanza,
  getComuni,
  getPermessiDiSoggiorno,
  getProvince,
  getStatiCivili,
  localitaApi,
  normalizzatoreApi,
  provincegenericoApi,
  setComuniByCap,
} from "../../../logic/loader/actions";
import {
  GET_CAP,
  GET_CAP_PREVIOUS_ADDRESS,
  SET_COMUNI_PREVIOUS_ADDRESS_BY_CAP,
  SET_COMUNI_RESIDENZA_BY_CAP,
} from "../../../logic/loader/consts";
import {
  getAbitazioniOptions,
  getCapPreviousAddressSelector,
  getCapSelector,
  getCittadinanzaOptions,
  getComuniOptions,
  getComuniPreviousAddressOptionsByCap,
  getComuniResidenzaOptionsByCap,
  getPermessiDiSoggiornoOptions,
  getProvinceOptions,
  getStatiCiviliOptions,
} from "../../../logic/loader/selectors";
import { IListaZipCodeOutputFIXED } from "../../../logic/loader/types";
import { ICap } from "../../../types/capModel";
import { IStoreState } from "../../../types/store";
import { checkDataResidenza, isEuropeanCitizen } from "../client_checks";
import { evaluateSelect, scrollToError, scrollToErrorWithoutValidate } from "../form.utilities";
import { useAdForm } from "../hooks/useAdForm";
import { CodiceFiscaleObservableGenerator, useCodiceFiscale } from "../hooks/useCodiceFiscale";
import { sendGoogleTagManagerEvent, useGoogleTagManager } from "../hooks/useGoogleTagManager";
import { useSaveReviewing } from "../hooks/useSaveReviewing";
import { IDispatchProps, IStateProps, TProps } from "./types";

const creazioneCodiceFiscaleApi = new CreazioneCodiceFiscaleApi(creazioneCodiceFiscaleApiConfig());
type CreazioneCodiceFiscaleGenerator = (values: TPersonalDataValues) => Observable<Output>;

const province$: ProvinceObservableGenerator = () => {
  try {
    return provincegenericoApi.provincegenericoPost({ provinceGenericoInput: { fromcache: false } }).pipe(
      map(res => get(res, "data.province")),
      catchError(() => of(null))
    );
  } catch (err) {
    error(err);
    return of(null);
  }
};

const comuni$: ComuniObservableGenerator = (provincia: string, soppresse?: boolean) => {
  try {
    return localitaApi.loaderLocalita({ Input: { provincia, soppresse } }).pipe(
      map(res => get(res, "data.comuni")),
      catchError(() => of(null))
    );
  } catch (err) {
    error(err);
    return of(null);
  }
};

const cap$: CapObservableGenerator = (cap: string) => {
  try {
    // as IListaZipCodeOutputFIXED because YAML types are wrong.
    return (normalizzatoreApi.listaZipCode(getZipCodeInputParams(cap)) as Observable<IListaZipCodeOutputFIXED>).pipe(
      map(parseZipCodeResponse),
      catchError(() => of(null))
    );
  } catch (err) {
    error(err);
    return of(null);
  }
};

const listaStrade$: ListaStradeObservableGenerator = (address: Partial<IAddress>) => {
  try {
    return normalizzatoreApi.listaStrade(getListaStradeInputParams(address)).pipe(
      map(parseListaStradeResponse),
      catchError(() => of([]))
    );
  } catch (err) {
    error(err);
    return of([]);
  }
};

const normalizzatore$: NormalizzatoreObservableGenerator = (address: Partial<IAddress>) => {
  try {
    return normalizzatoreApi.wpNorm(getNormalizzatoreInputParams(address)).pipe(
      map(parseResponse),
      catchError(() => of({}))
    ) as Observable<INormalizzatoreResult>;
  } catch (err) {
    error(err);
    return of({}) as Observable<INormalizzatoreResult>;
  }
};

const getCreazioneCodiceFiscale$: CreazioneCodiceFiscaleGenerator = (values: TPersonalDataValues) => {
  const requestParameters: CodiceFiscaleGetRequest = {
    cognome: values.cognome ? values.cognome.toString().toUpperCase() : "",
    nome: values.nome ? values.nome.toString().toUpperCase() : "",
    sesso: values.sesso ? values.sesso.toString().toUpperCase() : "",
    dataDiNascita: values.dataNascita
      ? new Date(convertDate(values.dataNascita.toString(), "YYYYMMDD", "YYYY-MM-DD"))
      : new Date(),
    luogoDiNascita: values.luogoNascita ? values.luogoNascita.toString().toUpperCase() : "",
    provincia: values.provinciaNascita ? values.provinciaNascita.toString().toUpperCase() : "",
  };

  return creazioneCodiceFiscaleApi.codiceFiscaleGet(requestParameters).pipe();
};

const FormPersonalData: React.FC<TProps & FormikProps<TPersonalDataValues>> = ({
  abitazioni,
  cap,
  capPreviousAddress,
  cittadinanza,
  isReviewing = false,
  isReviewingShowAll = false,
  isReviewingShowForm = false,
  permessiDiSoggiorno,
  statiCivili,
  values,
  errors,
  isEnabled,
  codiceFiscaleMappingPaths,
  serverSideErrors,
  isUpperCase,
  citizenshipLoader,
  isInteger,
  setOnReviewErrors,
  getAbitazioni,
  getCap,
  getCittadinanza,
  getPermessiDiSoggiorno,
  setComuniByCap,
  getStatiCivili,
  setCustomerEnablers,
  validateForm,
  setFieldTouched,
  setFieldValue,
  onSubmit,
}) => {
  const { comuni, province, getComuniForProvincia } = useContext(normalizzatoreContext);

  const {
    data: { distanceSellingPolicyUrl, privacyPolicyUrl },
  } = useContext(CustomizationContext);

  /**
   * Initial loading
   */
  useEffect(() => {
    getAbitazioni();
    getCittadinanza();
    getPermessiDiSoggiorno();
    getStatiCivili();
  }, []);

  // When changing provinciaNascita fetch the new provincia's comuni (if needed)
  useEffect(() => {
    if (values.provinciaNascita) {
      getComuniForProvincia(values.provinciaNascita?.toString(), true);
    }
  }, [values.provinciaNascita]);

  // When changing provinciaNascita, reset luogoNascita if luogoNascita is not
  // in comuni[provinciaNascita].
  useEffect(() => {
    if (!isEmpty(comuni) && values.provinciaNascita && values.luogoNascita) {
      const comuniProvinciaNascita: IComune[] = get(comuni, values.provinciaNascita, []);
      if (
        !isEmpty(comuniProvinciaNascita) &&
        !comuniProvinciaNascita.map(comune => comune.descrizione).includes(values.luogoNascita?.toString())
      ) {
        setFieldValue("luogoNascita", "");
      }
    }
  }, [comuni, values.provinciaNascita]);

  /**
   * On load, set default cittandinanza
   */
  useEffect(() => {
    if (values.cittadinanza === "") {
      setFieldValue("cittadinanza", CITTADINANZA_ITALIANA);
    }
  }, []);

  /**
   * Update cap list when cap changes
   */
  useEffect(() => {
    if (values.residenzaCap) {
      getCap({ cap: values.residenzaCap?.toString() }, GET_CAP);
    }
  }, [values.residenzaCap]);

  /**
   * Update cap list when prevoius cap changes
   */
  useEffect(() => {
    if (values.indirizzoPrecedenteCap) {
      getCap({ cap: values.indirizzoPrecedenteCap?.toString() }, GET_CAP_PREVIOUS_ADDRESS);
    }
  }, [values.indirizzoPrecedenteCap]);

  // more startup effect here: e.g. load the right comuni/province for the active client TODO?

  /**
   * Handle fields dependent on cittadinanza
   */
  useEffect(() => {
    if (
      isEuropeanCitizen(citizenshipLoader.data?.cittadinanza, values.cittadinanza?.toString() || "") ||
      !values.cittadinanza
    ) {
      setCustomerEnablers({
        tipoPermessoSoggiorno: false,
      });
      resetField({ setFieldValue, setFieldTouched }, "tipoPermessoSoggiorno");
      resetField({ setFieldValue, setFieldTouched }, "scadenzaPermessoSoggiorno");
    } else {
      setCustomerEnablers({
        tipoPermessoSoggiorno: true,
      });
    }
  }, [values.cittadinanza, citizenshipLoader]);

  /**
   * Handle fields dependent to permesso di soggiorno
   */
  useEffect(() => {
    if (values.tipoPermessoSoggiorno === PERMESSO_DI_SOGGIORNO) {
      setCustomerEnablers({
        scadenzaPermessoSoggiorno: true,
      });
    } else {
      setCustomerEnablers({
        scadenzaPermessoSoggiorno: false,
      });
      resetField({ setFieldValue, setFieldTouched }, "scadenzaPermessoSoggiorno");
    }
  }, [values.tipoPermessoSoggiorno]);

  /**
   * Reset indirizzo precedente if data residenza doesn't require it
   */
  useEffect(() => {
    if (!checkDataResidenza(values.residenzaData?.toString() || "")) {
      resetField({ setFieldValue, setFieldTouched }, "indirizzoPrecedenteIndirizzo");
      resetField({ setFieldValue, setFieldTouched }, "indirizzoPrecedenteCap");
      resetField({ setFieldValue, setFieldTouched }, "indirizzoPrecedenteProvincia");
      resetField({ setFieldValue, setFieldTouched }, "indirizzoPrecedenteLocalita");
    }
  }, [values.residenzaData]);

  /**
   * Handle logic of suggesting correct values on field indirizzo
   */
  const {
    listaComuni: listaComuniResidenza,
    listaProvince: listaProvinceResidenza,
    listaStrade: listaStradeResidenza,
    normalizzatore: normalizzatoreResidenza,
    loading: loadingResidenza,
    errors: errorsResidenza,
  } = useNormalizzatore(
    {
      cap: values.residenzaCap?.toString(),
      comune: values.residenzaLocalita?.toString(),
      provincia: values.residenzaProvincia?.toString(),
      indirizzo: values.residenzaIndirizzo?.toString(),
    },
    { cap$, comuni$, listaStrade$, normalizzatore$ },
    {
      cap: (cap: string) => {
        setFieldValue("residenzaCap", cap);
      },
      comune: (comune: string) => {
        setFieldValue("residenzaLocalita", comune);
      },
      indirizzo: (indirizzo: string) => {
        setFieldValue("residenzaIndirizzo", indirizzo);
      },
      provincia: (provincia: string) => {
        setFieldValue("residenzaProvincia", provincia);
      },
    }
  );

  /**
   * Handle logic of suggesting correct values on field indirizzo precedente
   */
  const {
    listaComuni: listaComuniResidenzaPrecedente,
    listaProvince: listaProvinceResidenzaPrecedente,
    listaStrade: listaStradeResidenzaPrecedente,
    normalizzatore: normalizzatoreResidenzaPrecedente,
    loading: loadingResidenzaPrecedente,
    errors: errorsResidenzaPrecedente,
  } = useNormalizzatore(
    {
      cap: values.indirizzoPrecedenteCap?.toString(),
      comune: values.indirizzoPrecedenteLocalita?.toString(),
      provincia: values.indirizzoPrecedenteProvincia?.toString(),
      indirizzo: values.indirizzoPrecedenteIndirizzo?.toString(),
    },
    { cap$, comuni$, listaStrade$, normalizzatore$ },
    {
      cap: (cap: string) => {
        setFieldValue("indirizzoPrecedenteCap", cap);
      },
      comune: (comune: string) => {
        setFieldValue("indirizzoPrecedenteLocalita", comune);
      },
      indirizzo: (indirizzo: string) => {
        setFieldValue("indirizzoPrecedenteIndirizzo", indirizzo);
      },
      provincia: (provincia: string) => {
        setFieldValue("indirizzoPrecedenteProvincia", provincia);
      },
    }
  );

  const suggestionSelectHandlerResidenza = (address: IAddress) => {
    const { cap, comune, indirizzo, provincia } = address;

    if (cap) {
      setFieldValue("residenzaCap", cap);
    }

    if (comune) {
      setFieldValue("residenzaLocalita", comune);
    }

    if (indirizzo) {
      setFieldValue("residenzaIndirizzo", indirizzo);
    }

    if (provincia) {
      setFieldValue("residenzaProvincia", provincia);
    }
  };

  const suggestionSelectHandlerResidenzaPrecedente = (address: IAddress) => {
    const { cap, comune, indirizzo, provincia } = address;

    if (cap) {
      setFieldValue("indirizzoPrecedenteCap", cap);
    }

    if (comune) {
      setFieldValue("indirizzoPrecedenteLocalita", comune);
    }

    if (indirizzo) {
      setFieldValue("indirizzoPrecedenteIndirizzo", indirizzo);
    }

    if (provincia) {
      setFieldValue("indirizzoPrecedenteProvincia", provincia);
    }
  };

  /**
   * Submit form at every change when in review
   * (because there is no submit button)
   */
  useSaveReviewing(isReviewing, values, onSubmit, setOnReviewErrors, validateForm, PERSONAL_DATA, 1000);

  const caricamentoApi = new SCBPaginaCaricamentoETApi(caricamentoApiConfig());

  const postCodiceFiscale: CodiceFiscaleObservableGenerator = codiceFiscale =>
    caricamentoApi.datiCodiceFiscale({ requestBody: { codiceFiscale } });

  // The fourth argument stands for the setters that are going to
  // be used when the call response is received properly
  const { isLoading: codiceFiscaleLoading } = useCodiceFiscale(
    values.codiceFiscale as string,
    postCodiceFiscale,
    has(errors, "codiceFiscale"),
    setCustomerEnablers,
    codiceFiscaleMappingPaths,
    setFieldValue
  );

  const getRegularFormItemClass = (fieldName: TFieldName) => {
    return classNames("col-xs-12", "col-sm-6", {
      "field-hidden": serverSideErrors
        ? isFormSectionHidden(isReviewing, isReviewingShowAll, fieldName, serverSideErrors) && !isReviewingShowForm
        : false,
    });
  };

  /**
   * Update cap-list and provincia on cap change
   */
  useEffect(() => {
    if (cap && cap.length !== 0) {
      const provinceWithCap = uniqBy(cap, (c: ICap) => c.province);

      if (provinceWithCap.length > 1) {
        warn("Attenzione: la lista delle province contiene più di una provincia!");
      }

      const province = provinceWithCap[0].province || "";
      const city = provinceWithCap[0].city || "";

      setFieldValue("residenzaProvincia", province);
      setFieldValue("residenzaLocalita", city);

      setComuniByCap(cap, SET_COMUNI_RESIDENZA_BY_CAP);
    }
  }, [cap]);

  useEffect(() => {
    if (capPreviousAddress && capPreviousAddress.length !== 0) {
      const provinceWithCap = uniqBy(capPreviousAddress, (c: ICap) => c.province);
      if (provinceWithCap.length > 1) {
        warn("Attenzione: la lista delle province contiene più di una provincia!");
      }

      const { province, city } = provinceWithCap[0];

      setFieldValue("indirizzoPrecedenteProvincia", province || "");
      setFieldValue("indirizzoPrecedenteLocalita", city || "");
      setComuniByCap(capPreviousAddress, SET_COMUNI_PREVIOUS_ADDRESS_BY_CAP);
    }
  }, [capPreviousAddress]);

  useGoogleTagManager("/dati-personali", "Dati Personali");
  useAdForm("dati-personali");

  // Enables speseAffitto if...
  useEffect(() => {
    values.residenzaAbitazione === "A"
      ? setCustomerEnablers({ speseAffitto: true })
      : setCustomerEnablers({ speseAffitto: false });
  }, [values.residenzaAbitazione]);

  // Enables famigliariACarico if...
  useEffect(() => {
    Number(values.componentiNucleoFamigliare) > 1
      ? setCustomerEnablers({ famigliariACarico: true, altriRedditiNucleoFamigliare: true })
      : setCustomerEnablers({ famigliariACarico: false, altriRedditiNucleoFamigliare: false });
  }, [values.componentiNucleoFamigliare]);

  return (
    <Form>
      {/* <div className="step__helper-text">
        Inserisci qui le tue informazioni personali. Tutti i campi sono obbligatori.
      </div> */}
      <div className="row">
        <div className={getRegularFormItemClass("nome")}>
          <Field name="nome">
            {(field: FieldProps<TPersonalDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "nome" }}
                formikField={field}
                label="Nome"
                serverSideError={serverSideErrors ? serverSideErrors["nome"] : ""}
                disabled={!isEnabled.nome}
                isUpperCase={isUpperCase["nome"]}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("cognome")}>
          <Field name="cognome">
            {(field: FieldProps<TPersonalDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "cognome" }}
                formikField={field}
                label="Cognome"
                serverSideError={serverSideErrors ? serverSideErrors["cognome"] : ""}
                disabled={!isEnabled.cognome}
                isUpperCase={isUpperCase["cognome"]}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("numCellulare")}>
          <Field name="numCellulare">
            {(field: FieldProps<TPersonalDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "numCellulare" }}
                formikField={field}
                label="Numero di cellulare italiano"
                serverSideError={serverSideErrors ? serverSideErrors["numCellulare"] : ""}
                type="tel"
                helperText={CELLULARE_HELPER}
                isUpperCase={isUpperCase["numCellulare"]}
                disabled={!isEnabled.numCellulare}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("email")}>
          <Field name="email">
            {(field: FieldProps<TPersonalDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "email" }}
                formikField={field}
                label="Email"
                serverSideError={serverSideErrors ? serverSideErrors["email"] : ""}
                type="email"
                helperText={EMAIL_HELPER}
                isUpperCase={isUpperCase["email"]}
                disabled={!isEnabled.email}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("codiceFiscale")}>
          <Field name="codiceFiscale">
            {(field: FieldProps<TPersonalDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "codiceFiscale" }}
                formikField={field}
                label="Codice Fiscale"
                isLoading={codiceFiscaleLoading}
                serverSideError={serverSideErrors ? serverSideErrors["codiceFiscale"] : ""}
                disabled={!isEnabled.codiceFiscale}
                maxLength={16}
                isUpperCase={isUpperCase["codiceFiscale"]}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("dataNascita")}>
          <Field name="dataNascita">
            {(field: FieldProps<TPersonalDataValues>) => (
              <DateField
                dataAttr={{ cypressSelector: "dataNascita" }}
                formikField={field}
                label="Data di Nascita (gg/mm/aaaa)"
                serverSideError={serverSideErrors ? serverSideErrors["dataNascita"] : ""}
                disabled={!isEnabled["dataNascita"] || codiceFiscaleLoading}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("provinciaNascita")}>
          <Field name="provinciaNascita">
            {(field: FieldProps<TPersonalDataValues>) =>
              isEmpty(province) ? (
                <TextField
                  dataAttr={{ cypressSelector: "provinciaNascita" }}
                  disabled={!isEnabled["provinciaNascita"]}
                  formikField={field}
                  isLoading={codiceFiscaleLoading}
                  label="Provincia di nascita"
                  serverSideError={serverSideErrors ? serverSideErrors["provinciaNascita"] : ""}
                  isUpperCase={isUpperCase["provinciaNascita"]}
                />
              ) : (
                <SelectField
                  dataAttr={{ cypressSelector: "provinciaNascita" }}
                  disabled={!isEnabled["provinciaNascita"]}
                  formikField={field}
                  isLoading={codiceFiscaleLoading}
                  label="Provincia di nascita"
                  options={arrayToSelectOptions(province, "descrizione", "codice")}
                  serverSideError={serverSideErrors ? serverSideErrors["provinciaNascita"] : ""}
                />
              )
            }
          </Field>
        </div>

        <div className={getRegularFormItemClass("luogoNascita")}>
          <Field name="luogoNascita">
            {(field: FieldProps<TPersonalDataValues>) => {
              const comuniNascitaOptions = values.provinciaNascita
                ? arrayToSelectOptions(get(comuni, values.provinciaNascita, []), "descrizione", "descrizione")
                : [];

              return values.provinciaNascita && isEmpty(get(comuni, values.provinciaNascita)) ? (
                <TextField
                  dataAttr={{ cypressSelector: "luogoNascita" }}
                  disabled={!isEnabled["luogoNascita"]}
                  formikField={field}
                  isLoading={codiceFiscaleLoading}
                  label="Comune di nascita"
                  serverSideError={serverSideErrors ? serverSideErrors["luogoNascita"] : ""}
                  isUpperCase={isUpperCase["luogoNascita"]}
                />
              ) : (
                <SelectField
                  dataAttr={{ cypressSelector: "luogoNascita" }}
                  disabled={evaluateSelect(comuniNascitaOptions, values.luogoNascita) || !isEnabled["luogoNascita"]}
                  formikField={field}
                  isLoading={codiceFiscaleLoading}
                  label="Comune di nascita"
                  options={
                    values.provinciaNascita
                      ? arrayToSelectOptions(get(comuni, values.provinciaNascita, []), "descrizione", "descrizione")
                      : []
                  }
                  serverSideError={serverSideErrors ? serverSideErrors["luogoNascita"] : ""}
                />
              );
            }}
          </Field>
        </div>

        <div className={getRegularFormItemClass("sesso")}>
          <Field name="sesso">
            {(field: FieldProps<TPersonalDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "sesso" }}
                formikField={field}
                label="Sesso"
                options={SESSO_OPTIONS}
                serverSideError={serverSideErrors ? serverSideErrors["sesso"] : ""}
                disabled={!isEnabled["sesso"]}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("statoCivile")}>
          <Field name="statoCivile">
            {(field: FieldProps<TPersonalDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "statoCivile" }}
                formikField={field}
                label="Stato civile"
                options={statiCivili}
                serverSideError={serverSideErrors ? serverSideErrors["statoCivile"] : ""}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("cittadinanza")}>
          <Field name="cittadinanza">
            {(field: FieldProps<TPersonalDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "cittadinanza" }}
                formikField={field}
                label="Cittadinanza"
                options={cittadinanza}
                disabled={!isEnabled.cittadinanza}
                // onChange={handleChangeCittadinanza}
                serverSideError={serverSideErrors ? serverSideErrors["cittadinanza"] : ""}
              />
            )}
          </Field>
        </div>

        {isEnabled["tipoPermessoSoggiorno"] && (
          <div className={getRegularFormItemClass("tipoPermessoSoggiorno")}>
            <Field name="tipoPermessoSoggiorno">
              {(field: FieldProps<TPersonalDataValues>) => (
                <SelectField
                  dataAttr={{ cypressSelector: "tipoPermessoSoggiorno" }}
                  formikField={field}
                  label="Tipo permesso di soggiorno"
                  options={permessiDiSoggiorno}
                  // onChange={handleChangeTipoPermessoSoggiorno}
                  serverSideError={serverSideErrors ? serverSideErrors["tipoPermessoSoggiorno"] : ""}
                />
              )}
            </Field>
          </div>
        )}
        {isEnabled["scadenzaPermessoSoggiorno"] && (
          <div className={getRegularFormItemClass("scadenzaPermessoSoggiorno")}>
            <Field name="scadenzaPermessoSoggiorno">
              {(field: FieldProps<TPersonalDataValues>) => (
                <div>
                  <DateField
                    format={DEFAULT_DATE_FORMAT}
                    formikField={field}
                    label="Scadenza permesso di soggiorno (gg/mm/aaaa)"
                    serverSideError={serverSideErrors ? serverSideErrors["scadenzaPermessoSoggiorno"] : ""}
                  />
                  {moment("20210930", "YYYYMMDD").diff(moment()) >= 0 && (
                    <HelperText isValidationMessage={false} persistent={true} validation={true}>
                      Se il documento è scaduto al 31.01.2020 o data successiva, è possibile inserire come data scadenza
                      il 30.09.2021, come previsto dal Decreto Legge 56/2020 emanato nell'ambito dell'emergenza
                      sanitaria da Covid-19
                    </HelperText>
                  )}
                </div>
              )}
            </Field>
          </div>
        )}

        <div className={getRegularFormItemClass("residenzaCap")}>
          <Field name="residenzaCap">
            {(field: FieldProps<TPersonalDataValues>) => {
              const isCapInvalid = isObjectLike(listaComuniResidenza) && isEmpty(listaComuniResidenza);

              return (
                <TextField
                  dataAttr={{ cypressSelector: "residenzaCap" }}
                  formikField={field}
                  label="CAP di residenza"
                  serverSideError={serverSideErrors ? serverSideErrors["residenzaCap"] : ""}
                  type="tel"
                  maxLength={5}
                  error={isCapInvalid ? "Cap inesistente" : undefined}
                  isUpperCase={isUpperCase["residenzaCap"]}
                />
              );
            }}
          </Field>
        </div>

        <div className={getRegularFormItemClass("residenzaProvincia")}>
          <Field name="residenzaProvincia">
            {(field: FieldProps<TPersonalDataValues>) => {
              const isCapInvalid = isObjectLike(listaComuniResidenza) && isEmpty(listaComuniResidenza);

              return isCapInvalid ||
                !((listaProvinceResidenza && listaProvinceResidenza.length === 0) || isEmpty(province)) ? (
                <SelectField
                  dataAttr={{ cypressSelector: "residenzaProvincia" }}
                  disabled={!listaProvinceResidenza || listaProvinceResidenza.length <= 1 || isCapInvalid}
                  formikField={field}
                  label="Provincia di residenza"
                  options={
                    listaProvinceResidenza ? arrayToSelectOptions(listaProvinceResidenza, "descrizione", "codice") : []
                  }
                  isLoading={loadingResidenza.cap}
                  serverSideError={serverSideErrors ? serverSideErrors["residenzaProvincia"] : ""}
                />
              ) : (
                <TextField
                  dataAttr={{ cypressSelector: "residenzaProvincia" }}
                  formikField={field}
                  isLoading={loadingResidenza.cap}
                  label="Provincia di residenza"
                  serverSideError={serverSideErrors ? serverSideErrors["residenzaProvincia"] : ""}
                  isUpperCase={isUpperCase["residenzaProvincia"]}
                />
              );
            }}
          </Field>
        </div>

        <div className={getRegularFormItemClass("residenzaLocalita")}>
          <Field name="residenzaLocalita">
            {(field: FieldProps<TPersonalDataValues>) => {
              // Cases:
              // 0. startup () --> listaComuni is null
              //    --> show DISABLED select field
              //
              // 1. there are NO comuni - #500 --> listaComuni is null && errorsResidenza.comuni === true
              //    --> show text field (some error happened)
              //
              // 2. there are NO comuni --> listaComuni is {} (empty object)
              //    --> show text field.
              //
              // 3. there are comuni
              //    --> show select field
              //
              // 4. cap is invalid
              //    --> show DISABLED select field
              const comuni =
                values.residenzaProvincia && listaComuniResidenza && listaComuniResidenza[values.residenzaProvincia];

              const isListaComuniNull = listaComuniResidenza === null; // (0)
              const comuniError = listaComuniResidenza === null && errorsResidenza.comuni; // (1)
              const noComuni = isObjectLike(listaComuniResidenza) && isEmpty(listaComuniResidenza); // (2)
              const hasOnlyOneComune = !!comuni && comuni.length === 1;
              const isCapInvalid = isObjectLike(listaComuniResidenza) && isEmpty(listaComuniResidenza); // (4)

              return isCapInvalid || !(comuniError || noComuni) ? (
                <SelectField
                  dataAttr={{ cypressSelector: "residenzaLocalita" }}
                  disabled={isListaComuniNull || hasOnlyOneComune || isCapInvalid}
                  formikField={field}
                  isLoading={loadingResidenza.cap}
                  label="Comune di residenza"
                  options={comuni ? comuni : []}
                  serverSideError={serverSideErrors ? serverSideErrors["residenzaLocalita"] : ""}
                />
              ) : (
                <TextField
                  dataAttr={{ cypressSelector: "residenzaLocalita" }}
                  formikField={field}
                  isLoading={loadingResidenza.cap}
                  label="Comune di residenza"
                  serverSideError={serverSideErrors ? serverSideErrors["residenzaLocalita"] : ""}
                  isUpperCase={isUpperCase["residenzaLocalita"]}
                />
              );
            }}
          </Field>
        </div>

        <div className={getRegularFormItemClass("residenzaIndirizzo")}>
          <Field name="residenzaIndirizzo">
            {(field: FieldProps<TPersonalDataValues>) => (
              <AutocompleteField
                dataAttr={{ cypressSelector: "residenzaIndirizzo" }}
                dataSource={listaStradeResidenza}
                formikField={field}
                helperText={
                  <NormalizzatoreHelperText
                    helpMeLabel={LABEL_NORMALIZZATORE_AIUTAMI}
                    ignoraLabel={LABEL_IGNORA}
                    normalizzatoreOutput={normalizzatoreResidenza}
                    onSuggestionSelect={suggestionSelectHandlerResidenza}
                  />
                }
                isLoading={loadingResidenza.listaStrade}
                label="Indirizzo"
                serverSideError={serverSideErrors ? serverSideErrors["residenzaIndirizzo"] : ""}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("residenzaAbitazione")}>
          <Field name="residenzaAbitazione">
            {(field: FieldProps<TPersonalDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "residenzaAbitazione" }}
                formikField={field}
                label="Tipo di abitazione"
                options={abitazioni}
                serverSideError={serverSideErrors ? serverSideErrors["residenzaAbitazione"] : ""}
              />
            )}
          </Field>
        </div>

        {isEnabled["speseAffitto"] && (
          <div className={getRegularFormItemClass("speseAffitto")}>
            <Field name="speseAffitto">
              {(field: FieldProps<TPersonalDataValues>) => (
                <NumericField
                  dataAttr={{ cypressSelector: "speseAffitto" }}
                  formikField={field}
                  label="Spese affitto"
                  serverSideError={serverSideErrors ? serverSideErrors["speseAffitto"] : ""}
                  isCurrency
                  helperText="Non inserire cifre decimali"
                />
              )}
            </Field>
          </div>
        )}

        <div className={getRegularFormItemClass("componentiNucleoFamigliare")}>
          <Field name="componentiNucleoFamigliare">
            {(field: FieldProps<TPersonalDataValues>) => (
              <TextField
                type="number"
                dataAttr={{ cypressSelector: "componentiNucleoFamigliare" }}
                formikField={field}
                label="Componenti nucleo famigliare"
                serverSideError={serverSideErrors ? serverSideErrors["componentiNucleoFamigliare"] : ""}
                isInteger={isInteger.componentiNucleoFamigliare}
                // minValue={0}
              />
            )}
          </Field>
        </div>

        {isEnabled["famigliariACarico"] && (
          <div className={getRegularFormItemClass("famigliariACarico")}>
            <Field name="famigliariACarico">
              {(field: FieldProps<TPersonalDataValues>) => (
                <TextField
                  type="number"
                  dataAttr={{ cypressSelector: "famigliariACarico" }}
                  formikField={field}
                  label="Famigliari a carico"
                  serverSideError={serverSideErrors ? serverSideErrors["famigliariACarico"] : ""}
                  isInteger={isInteger.famigliariACarico}
                  // minValue={0}
                />
              )}
            </Field>
          </div>
        )}

        <div className={getRegularFormItemClass("residenzaData")}>
          <Field name="residenzaData">
            {(field: FieldProps<TPersonalDataValues>) => (
              <DateField
                dataAttr={{ cypressSelector: "residenzaData" }}
                formikField={field}
                label="Residente dal (mm/aaaa)"
                type="monthYear"
                // onChange={handleChangeResidenzaData}
                serverSideError={serverSideErrors ? serverSideErrors["residenzaData"] : ""}
              />
            )}
          </Field>
        </div>
      </div>

      {has(values, "residenzaData") &&
        values.residenzaData !== "" &&
        checkDataResidenza(values.residenzaData?.toString() || "") && (
          <div className="row">
            <div
              className={classNames("col-xs-12", {
                "field-hidden": serverSideErrors
                  ? isFormSectionHidden(
                      isReviewing,
                      isReviewingShowAll,
                      [
                        "indirizzoPrecedenteIndirizzo",
                        "indirizzoPrecedenteCap",
                        "indirizzoPrecedenteProvincia",
                        "indirizzoPrecedenteLocalita",
                      ],
                      serverSideErrors
                    )
                  : false,
              })}
            >
              <p className="form__label">Residenza Precedente</p>
            </div>

            <div className={getRegularFormItemClass("indirizzoPrecedenteCap")}>
              <Field name="indirizzoPrecedenteCap">
                {(field: FieldProps<TPersonalDataValues>) => {
                  const isCapInvalid =
                    isObjectLike(listaComuniResidenzaPrecedente) && isEmpty(listaComuniResidenzaPrecedente);

                  return (
                    <TextField
                      dataAttr={{ cypressSelector: "indirizzoPrecedenteCap" }}
                      formikField={field}
                      label="Cap residenza precedente"
                      serverSideError={serverSideErrors ? serverSideErrors["indirizzoPrecedenteCap"] : ""}
                      type="tel"
                      maxLength={5}
                      error={isCapInvalid ? "Cap inesistente" : undefined}
                      isUpperCase={isUpperCase["indirizzoPrecedenteCap"]}
                    />
                  );
                }}
              </Field>
            </div>

            <div className={getRegularFormItemClass("indirizzoPrecedenteProvincia")}>
              <Field name="indirizzoPrecedenteProvincia">
                {(field: FieldProps<TPersonalDataValues>) => {
                  const isCapInvalid =
                    isObjectLike(listaComuniResidenzaPrecedente) && isEmpty(listaComuniResidenzaPrecedente);

                  return isCapInvalid ||
                    !(
                      (listaProvinceResidenzaPrecedente && listaProvinceResidenzaPrecedente.length === 0) ||
                      isEmpty(province)
                    ) ? (
                    <SelectField
                      dataAttr={{ cypressSelector: "indirizzoPrecedenteProvincia" }}
                      disabled={
                        !listaProvinceResidenzaPrecedente ||
                        listaProvinceResidenzaPrecedente.length <= 1 ||
                        isCapInvalid
                      }
                      formikField={field}
                      label="Provincia residenza precedente"
                      options={
                        listaProvinceResidenzaPrecedente
                          ? arrayToSelectOptions(listaProvinceResidenzaPrecedente, "descrizione", "codice")
                          : []
                      }
                      isLoading={loadingResidenzaPrecedente.cap}
                      serverSideError={serverSideErrors ? serverSideErrors["indirizzoPrecedenteProvincia"] : ""}
                    />
                  ) : (
                    <TextField
                      dataAttr={{ cypressSelector: "indirizzoPrecedenteProvincia" }}
                      formikField={field}
                      isLoading={loadingResidenzaPrecedente.cap}
                      label="Provincia residenza precedente"
                      serverSideError={serverSideErrors ? serverSideErrors["indirizzoPrecedenteProvincia"] : ""}
                      isUpperCase={isUpperCase["indirizzoPrecedenteProvincia"]}
                    />
                  );
                }}
              </Field>
            </div>

            <div className={getRegularFormItemClass("indirizzoPrecedenteLocalita")}>
              <Field name="indirizzoPrecedenteLocalita">
                {(field: FieldProps<TPersonalDataValues>) => {
                  // Cases:
                  // 0. startup () --> listaComuni is null
                  //    --> show DISABLED select field
                  //
                  // 1. there are NO comuni - #500 --> listaComuni is null && errorsResidenza.comuni === true
                  //    --> show text field (some error happened)
                  //
                  // 2. there are NO comuni --> listaComuni is {} (empty object)
                  //    --> show text field.
                  //
                  // 3. there are comuni
                  //    --> show select field
                  //
                  // 4. cap is invalid
                  //    --> show DISABLED select field
                  const comuni =
                    values.indirizzoPrecedenteProvincia &&
                    listaComuniResidenzaPrecedente &&
                    listaComuniResidenzaPrecedente[values.indirizzoPrecedenteProvincia];

                  const isListaComuniNull = listaComuniResidenzaPrecedente === null; // (0)
                  const comuniError = listaComuniResidenzaPrecedente === null && errorsResidenzaPrecedente.comuni; // (1)
                  const noComuni =
                    isObjectLike(listaComuniResidenzaPrecedente) && isEmpty(listaComuniResidenzaPrecedente); // (2)
                  const hasOnlyOneComune = !!comuni && comuni.length === 1;
                  const isCapInvalid =
                    isObjectLike(listaComuniResidenzaPrecedente) && isEmpty(listaComuniResidenzaPrecedente); // (4)

                  return isCapInvalid || !(comuniError || noComuni) ? (
                    <SelectField
                      dataAttr={{ cypressSelector: "indirizzoPrecedenteLocalita" }}
                      disabled={isListaComuniNull || hasOnlyOneComune || isCapInvalid}
                      formikField={field}
                      isLoading={loadingResidenzaPrecedente.cap}
                      label="Comune di residenza"
                      options={comuni ? comuni : []}
                      serverSideError={serverSideErrors ? serverSideErrors["indirizzoPrecedenteLocalita"] : ""}
                    />
                  ) : (
                    <TextField
                      dataAttr={{ cypressSelector: "indirizzoPrecedenteLocalita" }}
                      formikField={field}
                      isLoading={loadingResidenzaPrecedente.cap}
                      label="Comune di residenza"
                      serverSideError={serverSideErrors ? serverSideErrors["indirizzoPrecedenteLocalita"] : ""}
                      isUpperCase={isUpperCase["indirizzoPrecedenteLocalita"]}
                    />
                  );
                }}
              </Field>
            </div>

            <div className={getRegularFormItemClass("indirizzoPrecedenteIndirizzo")}>
              <Field name="indirizzoPrecedenteIndirizzo">
                {(field: FieldProps<TPersonalDataValues>) => (
                  <AutocompleteField
                    dataAttr={{ cypressSelector: "indirizzoPrecedenteIndirizzo" }}
                    dataSource={listaStradeResidenzaPrecedente}
                    formikField={field}
                    helperText={
                      <NormalizzatoreHelperText
                        helpMeLabel={LABEL_NORMALIZZATORE_AIUTAMI}
                        ignoraLabel={LABEL_IGNORA}
                        normalizzatoreOutput={normalizzatoreResidenzaPrecedente}
                        onSuggestionSelect={suggestionSelectHandlerResidenzaPrecedente}
                      />
                    }
                    isLoading={loadingResidenzaPrecedente.listaStrade}
                    label="Indirizzo residenza precedente"
                    serverSideError={serverSideErrors ? serverSideErrors["indirizzoPrecedenteIndirizzo"] : ""}
                  />
                )}
              </Field>
            </div>
          </div>
        )}

      <div className="row">
        <div className="col-xs-12">
          <h3
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(
                    isReviewing,
                    isReviewingShowAll,
                    [
                      "venditaDistanza",
                      "preventivoSECCI",
                      "usoDati",
                      "privacy1",
                      "privacy2",
                      "privacy3",
                      "privacy4",
                      //"privacy5",
                      "privacy6",
                      "privacy7",
                      "privacy8",
                    ],
                    serverSideErrors
                  )
                : false,
            })}
          >
            Trasparenza bancaria e trattamento dei dati personali
          </h3>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(
                    isReviewing,
                    isReviewingShowAll,
                    [
                      "venditaDistanza",
                      "preventivoSECCI",
                      "usoDati",
                      "privacy1",
                      "privacy2",
                      "privacy3",
                      "privacy4",
                      //"privacy5",
                      "privacy6",
                      "privacy7",
                      "privacy8",
                    ],
                    serverSideErrors
                  )
                : false,
            })}
          >
            <p>
              Ti ricordiamo che per proseguire con questa richiesta è necessario prendere visione dei moduli:
              "informativa sulla vendita a distanza", "preventivo SECCI", "informativa privacy" e fornire i consensi
              sotto indicati.
            </p>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "venditaDistanza", serverSideErrors)
                : false,
            })}
          >
            <p>
              {/* eslint-disable-next-line  */}
              Ho preso visione dell'
              <a href={distanceSellingPolicyUrl || URL_INFORMATIVA_VENDITA} target="_blank" rel="noopener noreferrer">
                informativa sulla vendita
              </a>{" "}
              a distanza di servizi finanziari.
            </p>
            <Field name="venditaDistanza">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "venditaDistanza" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["venditaDistanza"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "preventivoSECCI", serverSideErrors)
                : false,
            })}
          >
            <p>Ho preso visione del preventivo/modulo SECCI "Condizioni economiche e contrattuali".</p>
            <Field name="preventivoSECCI">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "preventivoSECCI" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["preventivoSECCI"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "usoDati", serverSideErrors)
                : false,
            })}
          >
            <p>
              Ho letto l'
              <a href={privacyPolicyUrl || URL_INFORMATIVA_PRIVACY} target="_blank" rel="noopener noreferrer">
                Informativa Privacy
              </a>{" "}
              e autorizzo il trattamento dei miei dati per la gestione della presente offerta.
            </p>
            <Field name="usoDati">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "usoDati" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["usoDati"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy8", serverSideErrors)
                : false,
            })}
          >
            <p>
              <b>
                Il sottoscritto, selezionando una delle caselle sottostanti, liberamente consente o meno, che i propri
                dati siano utilizzati per le finalità di seguito sintetizzate e indicate nell'informativa consultabile{" "}
                <a href={privacyPolicyUrl || URL_INFORMATIVA_CONTRATTI} target="_blank" rel="noopener noreferrer">
                  cliccando qui
                </a>
                .
              </b>
            </p>
            <p>
              <b>Finalità B: </b>Con riferimento alle “Finalità del Trattamento cui sono destinati i dati” di cui alla
              lettera B) dell’informativa, che prevedono, tra le altre, l’invio del preavviso circa l’imminente
              registrazione dei dati in uno o più SIC anche mediante: i) comunicazione messa a disposizione nella Sua
              area riservata; ii) comunicazione telefonica con registrazione della chiamata; iii) comunicazione tramite
              messaggistica istantanea (es: SMS):
            </p>
            <Field name="privacy8">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "privacy8" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["privacy8"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy2", serverSideErrors)
                : false,
            })}
          >
            <p>
              <b>Finalità C:</b> Con riferimento alle “Finalità del Trattamento cui sono destinati i dati” di cui alla
              lettera C) dell’informativa, funzionali all'attività di SCB per le quali l'interessato ha facoltà di
              prestare o meno il consenso:
            </p>
            <p>
              1) Con riferimento alle comunicazioni di marketing relative a prodotti/servizi della Banca, inviate
              mediante tecniche di comunicazione automatizzate (es. e-mail, sms), per lo svolgimento di indagini e
              ricerche di mercato:
            </p>
            <Field name="privacy2">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "privacy2" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["privacy2"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy1", serverSideErrors)
                : false,
            })}
          >
            <p>
              2) Con riferimento alle comunicazioni di marketing relative a prodotti/servizi della Banca, inviate
              mediante tecniche di comunicazione non automatizzate (es. posta cartacea, telefonata con operatore), per
              lo svolgimento di indagini e ricerche di mercato:
            </p>
            <Field name="privacy1">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "privacy1" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["privacy1"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy4", serverSideErrors)
                : false,
            })}
          >
            <p>
              3) Con riferimento all’eventuale comunicazione dei dati dalla Banca ai terzi perché quest’ultimi possono
              trattarli per inviare comunicazioni di marketing relative a prodotti/servizi propri, inviate mediante
              tecniche di comunicazione automatizzate (es. e-mail, sms), ed in particolare con riferimento a proposte
              circa l’adesione a prodotti di primarie Compagnie assicurative:
            </p>
            <Field name="privacy4">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "privacy4" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["privacy4"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy3", serverSideErrors)
                : false,
            })}
          >
            <p>
              4) Con riferimento all’eventuale comunicazione dei dati dalla Banca ai terzi perché quest’ultimi possono
              trattarli per inviare comunicazioni di marketing relative a prodotti/servizi propri, inviate mediante
              tecniche di comunicazione non automatizzate (es. posta cartacea, telefonata con operatore), ed in
              particolare con riferimento a proposte circa l’adesione a prodotti di primarie Compagnie assicurative:
            </p>
            <Field name="privacy3">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "privacy3" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["privacy3"] : ""}
                />
              )}
            </Field>
          </div>

          <div
            className={classNames({
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy7", serverSideErrors)
                : false,
            })}
          >
            <p>
              5) Con riferimento all’attività di profilazione consistente nella individuazione di preferenze, gusti,
              abitudini, necessità e scelte di consumo e nella definizione del profilo dell’interessato, in modo da
              migliorare i prodotti o servizi offerti e soddisfare le esigenze dell’interessato medesimo, nonché per
              effettuare, previo lo specifico consenso, comunicazioni a carattere promozionale, pubblicitario o
              commerciale personalizzate, con i mezzi indicati nell’informativa:
            </p>
            <Field name="privacy7">
              {(field: FieldProps<TPersonalDataValues>) => (
                <BooleanRadio
                  dataAttr={{ cypressSelector: "privacy7" }}
                  formikField={field}
                  trueValue={PRIVACY_VALUES.true}
                  falseValue={PRIVACY_VALUES.false}
                  trueLabel="SI"
                  falseLabel="NO"
                  serverSideError={serverSideErrors ? serverSideErrors["privacy7"] : ""}
                />
              )}
            </Field>
          </div>
          {isEnabled["privacy6"] && (
            <div
              className={classNames({
                "field-hidden": serverSideErrors
                  ? isFormSectionHidden(isReviewing, isReviewingShowAll, "privacy6", serverSideErrors)
                  : false,
              })}
            >
              <p>
                <b>Finalità D:</b> Con riferimento alle “Finalità del Trattamento cui sono destinati i dati” di cui alla
                lettera D) dell’informativa, relativamente al trattamento di alcune categorie particolari di dati
                personali (nello specifico, dati relativi alla salute) al fine di sottoscrivere prodotti assicurativi
                (il consenso per tale finalità è necessario per fornire i prodotti assicurativi richiesti):
                {/* 2) PUNTO D: Con riferimento alla sottoscrizione dei prodotti assicurativi, trattiamo alcune categorie
              particolari dei Suoi dati personali (nello specifico, sulla salute). Il Suo consenso per tale finalità è
              necessario per fornirLe i prodotti assicurativi da Lei richiesti. */}
              </p>
              <Field name="privacy6">
                {(field: FieldProps<TPersonalDataValues>) => (
                  <BooleanRadio
                    dataAttr={{ cypressSelector: "privacy6" }}
                    formikField={field}
                    trueValue={PRIVACY_VALUES.true}
                    falseValue={PRIVACY_VALUES.false}
                    trueLabel="SI"
                    falseLabel="NO"
                    serverSideError={serverSideErrors ? serverSideErrors["privacy6"] : ""}
                  />
                )}
              </Field>
            </div>
          )}
        </div>
      </div>

      {!isReviewing ? (
        <React.Fragment>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                onClick={scrollToError(validateForm, isReviewing)}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </Form>
  );
};

const FormWithFormik = withFormik<TProps, TPersonalDataValues>({
  enableReinitialize: true,
  handleSubmit: (values: TPersonalDataValues, formikBag) => {
    /** API Check congruence CF with name/surname
     */
    getCreazioneCodiceFiscale$(values).subscribe(
      //200 response
      response => {
        if (
          response &&
          values.codiceFiscale &&
          response.codiceFiscale === values.codiceFiscale.toString().toUpperCase()
        ) {
          formikBag.props.onSubmit(values);
          sendGoogleTagManagerEvent("ButtonPP", "FunnelPP", "AvantiADocumenti");
        } else {
          formikBag.setFieldError("codiceFiscale", CODICE_FISCALE_INCONGRUENTE);
          scrollToErrorWithoutValidate("codiceFiscale");
        }
      },
      //Error response
      () => {
        formikBag.setFieldError("codiceFiscale", CODICE_FISCALE_INCONGRUENTE);
        scrollToErrorWithoutValidate("codiceFiscale");
      }
    );
  },
  mapPropsToValues: (props: TProps) => props.initialValues,
  validateOnChange: true,
  validate: (values: TPersonalDataValues, props: TProps) => {
    const { validationSchema, validationContext: context } = props;
    if (validationSchema) {
      return validationSchema
        .validate(values, { abortEarly: false, context })
        .then(() => ({}))
        .catch(yupToFormErrors);
    } else {
      warn("validationSchema passed to FormPersonalData is not valid.");
      return {};
    }
  },
})(FormPersonalData);

const mapStateToProps = (state: IStoreState): IStateProps => ({
  abitazioni: getAbitazioniOptions(state),
  cap: getCapSelector(state),
  capPreviousAddress: getCapPreviousAddressSelector(state),
  cittadinanza: getCittadinanzaOptions(state),
  comuni: getComuniOptions(state),
  comuniResidenza: getComuniResidenzaOptionsByCap(state),
  comuniPreviousAddress: getComuniPreviousAddressOptionsByCap(state),
  permessiDiSoggiorno: getPermessiDiSoggiornoOptions(state),
  province: getProvinceOptions(state),
  statiCivili: getStatiCiviliOptions(state),
  preventivo: state.caricamento.preventivo,
  citizenshipLoader: state.loader.cittadinanza,
});

const mapDispatchToProps: IDispatchProps = {
  getAbitazioni,
  getCap,
  getCittadinanza,
  setComuniByCap,
  getPermessiDiSoggiorno,
  getProvince,
  getComuni,
  getStatiCivili,
  setCustomerEnablers,
  setOnReviewErrors,
};

export default withNormalizzatore(province$, comuni$)(connect(mapStateToProps, mapDispatchToProps)(FormWithFormik));
