// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TipoControparteEnum,
    TipoProdottoEnum,
} from './';

/**
 * 
 * @export
 * @interface RecuperaSessioneOk
 */
export interface RecuperaSessioneOk {
    /**
     * @type {number}
     * @memberof RecuperaSessioneOk
     */
    numeroPreventivo?: number;
    /**
     * @type {number}
     * @memberof RecuperaSessioneOk
     */
    pratica?: number;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    organization?: string;
    /**
     * @type {TipoControparteEnum}
     * @memberof RecuperaSessioneOk
     */
    tipoControparte?: TipoControparteEnum;
    /**
     * @type {boolean}
     * @memberof RecuperaSessioneOk
     */
    sessioneScaduta?: boolean;
    /**
     * @type {number}
     * @memberof RecuperaSessioneOk
     */
    intermediario?: number;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    provenienza?: string;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    prodotto?: string;
    /**
     * @type {TipoProdottoEnum}
     * @memberof RecuperaSessioneOk
     */
    tipoProdotto?: TipoProdottoEnum;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    canale?: string;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    processo?: string;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    tipoAnagrafica?: string;
    /**
     * @type {string}
     * @memberof RecuperaSessioneOk
     */
    exitUrl?: string;
}
