// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroRGS610CDPPLCNAS
 */
export interface WpNormInputParametroRGS610CDPPLCNAS {
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPCNY?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPSTA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPREG?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPDPT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPCNL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPDS1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPDS2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610CDPPLCNAS
     */
    CDPDS3?: string;
}
