import { HOST, API_PREFIX } from "../../shared/config/constants";

const trim = (str: string): string => str.replace(/^\/|\/$/g, "");

export const environmentApiBasePath = (basePath = "", override?: string): string => {
  try {
    const url = new URL(basePath);
    const host = trim(HOST);
    const apiPrefix = trim(API_PREFIX || "");
    const pathName = trim(override ? override : url.pathname);
    const parts = [host, apiPrefix, pathName].filter(part => part !== "");
    return trim(parts.join("/"));
  } catch (error) {
    console.error(`Basepath "${basePath}" MUST be a valid URI`);
    throw error;
  }
};
