export const GET_PREVENTIVO = "caricamento/GET_PREVENTIVO";
export const GET_PREVENTIVO_FAILED = "caricamento/GET_PREVENTIVO_FAILED";
export const GET_PREVENTIVO_PENDING = "caricamento/GET_PREVENTIVO_PENDING";

export const GET_SERVIZI = "caricamento/GET_SERVIZI";
export const GET_SERVIZI_FAILED = "caricamento/GET_SERVIZI_FAILED";
export const GET_SERVIZI_PENDING = "caricamento/GET_SERVIZI_PENDING";

export const GET_ERRORI_SUCCEED = "caricamento/GET_ERRORI";
export const GET_ERRORI_FAILED = "caricamento/GET_ERRORI_FAILED";
export const GET_ERRORI_PENDING = "caricamento/GET_ERRORI_PENDING";

export const SAVE_PRATICA_SUCCEED = "caricamento/SAVE_PRATICA";
export const SAVE_PRATICA_FAILED = "caricamento/SAVE_PRATICA_FAILED";
export const SAVE_PRATICA_PENDING = "caricamento/SAVE_PRATICA_PENDING";
export const SAVE_PRATICA_FAILED_WITH_NO_ERRORS = "caricamento/SAVE_PRATICA_FAILED_WITH_NO_ERRORS";

export const STOP_SAVE_PRATICA = "caricamento/STOP_SAVE_PRATICA";

export const GET_CUSTOMER = "caricamento/GET_CUSTOMER";
export const GET_CUSTOMER_FAILED = "caricamento/GET_CUSTOMER_FAILED";
export const GET_CUSTOMER_PENDING = "caricamento/GET_CUSTOMER_PENDING";

export const SET_CUSTOMER_VALUES = "caricamento/SET_CUSTOMER_VALUES";
export const SET_CUSTOMER_CACHE = "caricamento/SET_CUSTOMER_CACHE";
export const RESET_CUSTOMER = "caricamento/RESET_CUSTOMER";
export const SET_CUSTOMER_ENABLERS = "caricamento/SET_CUSTOMER_ENABLERS";
export const SET_UPPER_CASE_FIELDS = "caricamento/SET_UPPER_CASE_FIELDS";

export const UPDATE_CUSTOMER = "caricamento/UPDATE_CUSTOMER";
export const MERGE_INTO_CUSTOMER = "caricamento/MERGE_INTO_CUSTOMER";

export const SET_ACTIVE_STEP = "caricamento/SET_ACTIVE_STEP";
export const SET_COMPLETE_STEP = "caricamento/SET_COMPLETE_STEP";
export const SET_ERROR_STEP = "caricamento/SET_ERROR_STEP";

export const SET_IS_REVIEWING = "caricamento/SET_IS_REVIEWING";
export const SET_IS_REVIEWING_SHOW_ALL = "caricamento/SET_IS_REVIEWING_SHOW_ALL";
export const SET_IS_REVIEWING_SHOW_FORM = "caricamento/SET_IS_REVIEWING_SHOW_FORM";

export const CURRENT_ADDRESS = "caricamento/CURRENT_ADDRESS";
export const PREVIOUS_ADDRESS = "caricamento/PREVIOUS_ADDRESS";

export const SET_ON_REVIEW_ERRORS = "caricamento/SET_ON_REVIEW_ERRORS";
export const HIDE_NOTIFICATION = "caricamento/HIDE_NOTIFICATION";

export const SET_IS_INCOMPATIBLE = "caricamento/SET_IS_INCOMPATIBLE";

export const GET_TAX_DEDUCTIONS_FORM_ACTIVATION_PENDING = "caricamento/GET_TAX_DEDUCTIONS_FORM_ACTIVATION_PENDING";
export const GET_TAX_DEDUCTIONS_FORM_ACTIVATION = "caricamento/GET_TAX_DEDUCTIONS_FORM_ACTIVATION";
export const GET_TAX_DEDUCTIONS_FORM_ACTIVATION_FAILED = "caricamento/GET_TAX_DEDUCTIONS_FORM_ACTIVATION_FAILED";

export const SET_FORM_ENABLER = "caricamento/SET_FORM_ENABLER";

export const CLEAR_CARICAMENTO_STATE = "caricamento/CLEAR_CARICAMENTO_STATE";
