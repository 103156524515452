// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PraticaConsumoOkDataControparti,
    PraticaConsumoOkDataErrori,
} from './';

/**
 * @export
 * @interface PraticaConsumoOkData
 */
export interface PraticaConsumoOkData {
    /**
     * @type {number}
     * @memberof PraticaConsumoOkData
     */
    cliente?: number;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkData
     */
    stato?: string;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkData
     */
    attributo?: string;
    /**
     * @type {PraticaConsumoOkDataErrori}
     * @memberof PraticaConsumoOkData
     */
    errori?: PraticaConsumoOkDataErrori;
    /**
     * @type {PraticaConsumoOkDataControparti}
     * @memberof PraticaConsumoOkData
     */
    controparti?: PraticaConsumoOkDataControparti;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkData
     */
    pratica?: string;
}
