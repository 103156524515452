import { TFormName } from "../../caricamento/lib/dataHandling/types";
import { TCustomFormName } from "../../custom/libraries/dataHandling/types";
import { deltaTime } from "../moment";

interface ITrueFalseValue {
  true: string;
  false: string;
}

interface ISessoValues {
  uomo: string;
  donna: string;
}

export interface IPraticaErrorsMapping {
  [key: string]: string;
}

export interface IFormIdleElements {
  formName: TFormName | TCustomFormName;
  label: string;
  isComplete?: boolean;
  hasError?: boolean;
  isActive: boolean;
  isEnabled: boolean;
  isReviewingShowForm?: boolean;
}

export const MOBILE_BREAKPOINT = 768;

export const URL_UPLOAD = "/upload";
export const EXIT_URL_QUERY_PARAMETER = "exitUrl";
export const EXIT_URL_STORAGE_KEY = EXIT_URL_QUERY_PARAMETER;
export const TO_DOCUMENTS_UPLOAD_URL_KEY = "TO_DOCUMENTS_UPLOAD_URL_KEY";

export const TOKEN_QUERY_PARAMETER = "token";
export const CODICE_FISCALE_QUERY_PARAMETER = "codiceFiscale";

export const PERSONAL_DATA: TFormName = "personalData";
export const DOCUMENTARY_DATA: TFormName = "documentaryData";
export const VEHICLE_DATA: TFormName = "vehicleData";
export const OCCUPATIONAL_DATA: TFormName = "occupationalData";
export const TAX_DEDUCTIONS: TFormName = "taxDeductions";

export const CARICAMENTO_STEPS: IFormIdleElements[] = [
  {
    formName: PERSONAL_DATA,
    label: "Dati personali",
    isActive: true,
    isEnabled: true,
    isReviewingShowForm: false,
  },
  {
    formName: DOCUMENTARY_DATA,
    label: "Documenti",
    isActive: false,
    isEnabled: true,
    isReviewingShowForm: false,
  },
  {
    formName: OCCUPATIONAL_DATA,
    label: "Occupazione",
    isActive: false,
    isEnabled: true,
    isReviewingShowForm: false,
  },
  {
    formName: VEHICLE_DATA,
    label: "Targa e Telaio",
    isActive: false,
    isEnabled: false,
    isReviewingShowForm: false,
  },
  {
    formName: TAX_DEDUCTIONS,
    label: "Detrazioni fiscali",
    isActive: false,
    isEnabled: false,
    isReviewingShowForm: false,
  },
];

export const CREDIT_LIFE_KEYS = ["CLW0B", "CLWB+"];
export enum SERVICE_GROUP_KEYS {
  CL = "CL",
  FI = "FI",
}

export const PP_ACTIVITY_FILTER = ["X", "S"];

export const INCOMPATIBLE_AMOUNT_LIMIT = 20000;
export const INCOMPATIBLE_DURATION_LIMIT = 72;

export const CARICAMENTO_INITIAL_STEP = CARICAMENTO_STEPS[0].formName;

// Replace "anagrafica." with "cliente." in the error response from the server.
// E.g. "anagrafica.avc.pep" => "cliente.avc.pep".
export const ANAGRAFICA_KEY = "anagrafica.";
export const CLIENTE_KEY = "cliente.";

export const CITTADINANZA_ITALIANA = "086";
export const PERMESSO_DI_SOGGIORNO = "PER";
export const PATENTE_PLASTIFICATA = "PAP";
export const RESIDENZA_DELTA: deltaTime = { quantity: 5, unit: "years" };
export const IMPIEGO_DELTA: deltaTime = { quantity: 6, unit: "months" };
export const DEFAULT_DATE_FORMAT = "YYYYMMDD";
export const MONTHYEAR_DATE_FORMAT = "YYYYMM";
export const DEFAULT_DATE_DISPLAY_FORMAT = "DD/MM/YYYY";
export const MONTHYEAR_DATE_DISPLAY_FORMAT = "MM/YYYY";
export const NUMERIC_INPUT_MAX_NUMBERS = 5; // Avoid numbers greater than 99.999 in <NumericField>s

export const ATTIVITA_LAVORATORE_AUTONOMO = "A";
export const ATTIVITA_PENSIONATO = "P";
export const ATTIVITA_SENZA_REDDITO = "S";
export const PROFESSIONE_PENSIONE_INVALIDITA = "PI";

export const ARRAY_ATTIVITA_NON_IMPIEGATO = [ATTIVITA_PENSIONATO, ATTIVITA_SENZA_REDDITO];

const SI_NO_VALUES: ITrueFalseValue = {
  false: "NO",
  true: "SI",
};

const S_N_VALUES: ITrueFalseValue = {
  false: "N",
  true: "S",
};

export const SESSO_VALUES: ISessoValues = { uomo: "M", donna: "F" };
export const SESSO_OPTIONS = [
  { label: "Uomo", value: SESSO_VALUES.uomo },
  { label: "Donna", value: SESSO_VALUES.donna },
];
export const PRIVACY_VALUES: ITrueFalseValue = S_N_VALUES;
export const REDDITI_SECONDARI_VALUES: ITrueFalseValue = SI_NO_VALUES;
export const PEP_VALUES: ITrueFalseValue = SI_NO_VALUES;
export const TAX_DEDUCTIONS_OPT_IN_VALUES: ITrueFalseValue = S_N_VALUES;

export const MAX_AGE = 91;
export const MIN_AGE = 18;

export const MAX_SCANDENZA_PERMESSO_SOGGIORNO = 2;

// export const AUTH_TOKEN_SESSION_KEY = "auth-token"; // moved to component config
export const ID_PREVENTIVO_SESSION_KEY = "preventivo-id";
export const ID_MEDIA_SESSION_KEY = "idMedia";
export const ID_CAMPAIGN_SESSION_KEY = "idCampaign";

export const SEND_MAIL_URL_PLACEHOLDER = "{{URL_PLACEHOLDER}}";
export const SEND_MAIL_PREVENTIVO_PLACEHOLDER = "{{PREVENTIVO_PLACEHOLDER}}";
export const SEND_MAIL_NOME_PLACEHOLDER = "{{NOME_PLACEHOLDER}}";
export const SEND_MAIL_COGNOME_PLACEHOLDER = "{{COGNOME_PLACEHOLDER}}";

export const SEND_MAIL_X_CUSTOMER_ID = "illimity";
export const SEND_MAIL_SUBJECT = `Richiesta finanziamento preventivo n.${SEND_MAIL_PREVENTIVO_PLACEHOLDER} - Santander Consumer Bank`;
export const SEND_MAIL_FROM_NAME = "Illimity";
export const SEND_MAIL_FROM_EMAIL = "noreply@illimity.it";
export const SEND_MAIL_REPLY_TO = SEND_MAIL_FROM_EMAIL;
export const URL_INFORMATIVA_VENDITA =
  "https://www.santanderconsumer.it/sites/default/files/2018-07/Informativa vendita a distanza_0.pdf";
export const URL_INFORMATIVA_PRIVACY =
  "https://www.santanderconsumer.it/sites/default/files/2018-09/20180205_SCB_Informativa_Front End.pdf";
export const URL_INFORMATIVA_CONTRATTI =
  "https://www.santanderconsumer.it/sites/default/files/2019-03/Informativa_privacy_contratti.pdf";

export const UPLOAD_AUTH_ERROR_CODE_CODICE_FISCALE = "202";
export const UPLOAD_AUTH_ERROR_CODE_TOKEN_SCADUTO = "102";

export const TARGATURA_INCAGLI_COVID_ERROR = ["9044", "4131"];
export const DOCUMENT_ALREADY_PRESENT_ERROR = "5352";
export const IBAN_NOT_SDD_ERROR = "3051";

export const PRATICA_ERRORS_MAPPING: IPraticaErrorsMapping = {
  [DOCUMENT_ALREADY_PRESENT_ERROR]:
    "Ti invitiamo a verificare i dati inseriti o ad utilizzare un altro documento fra quelli ammessi",
  [IBAN_NOT_SDD_ERROR]: "L'IBAN inserito non supporta l'addebito automatico su C/C. Inserire un altro IBAN.",
};
