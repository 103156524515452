import { combineReducers } from "redux";
import custom from "../../custom/libraries/redux/reducer";
import { IStoreState } from "../types/store";
import modificaPraticaReducer from "../../shared/logic/modificaPratica/reducer";
import authenticationReducer from "./authentication/reducer";
import caricamentoReducer from "./caricamento/reducer";
import loaderReducer from "./loader/reducer";

export default combineReducers<IStoreState>({
  authentication: authenticationReducer,
  caricamento: caricamentoReducer,
  modificaPratica: modificaPraticaReducer,
  loader: loaderReducer,
  custom,
});
