import classNames from "classnames";
import * as React from "react";

interface IOwnProps {
  label: string;
  value: string;
  info?: string;
  bold?: boolean;
}

type Props = IOwnProps;

export const SummaryItem: React.FunctionComponent<Props> = ({ label, value, info, bold }) => (
  <li className="summary__section-item">
    <span className="summary__section-item__head">{label}</span>
    <span
      className={classNames("summary__section-item__value", {
        "summary__section-item__value__bold": bold,
      })}
    >
      {value}
    </span>
    {info && <span className="summary__section-item__info">{info}</span>}
  </li>
);
