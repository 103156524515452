// tslint:disable
/**
 * entirilascio
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EntirilascioOutputDataCAR,
} from './';

/**
 * @export
 * @interface EntirilascioOutputData
 */
export interface EntirilascioOutputData {
    /**
     * @type {Array<EntirilascioOutputDataCAR>}
     * @memberof EntirilascioOutputData
     */
    CAR?: Array<EntirilascioOutputDataCAR>;
    /**
     * @type {Array<EntirilascioOutputDataCAR>}
     * @memberof EntirilascioOutputData
     */
    PAM?: Array<EntirilascioOutputDataCAR>;
    /**
     * @type {Array<EntirilascioOutputDataCAR>}
     * @memberof EntirilascioOutputData
     */
    PAT?: Array<EntirilascioOutputDataCAR>;
    /**
     * @type {Array<EntirilascioOutputDataCAR>}
     * @memberof EntirilascioOutputData
     */
    PAU?: Array<EntirilascioOutputDataCAR>;
    /**
     * @type {Array<EntirilascioOutputDataCAR>}
     * @memberof EntirilascioOutputData
     */
    PAS?: Array<EntirilascioOutputDataCAR>;
}
