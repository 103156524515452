import get from "lodash.get";
import uniqBy from "lodash.uniqby";
import { createSelector } from "reselect";
import { GenericCodiceDescrizioneItem } from "../../../shared/generated/globals";
import { LocalitaOutputDataComuni } from "../../../shared/generated/loader/localita";
import { ProfessioniOutputDataProfessioni } from "../../../shared/generated/loader/professioni";
import { ProvinceGenericoOutputDataProvince } from "../../../shared/generated/loader/provincegenerico";
import { arrayToSelectOptions, SelectOptions } from "../../../shared/lib/utility/data_handling";
import { IStoreState } from "../../types/store";

const getAbitazioni = (state: IStoreState) => state.loader.abitazioni;
const getCittadinanza = (state: IStoreState) => state.loader.cittadinanza;
const getPermessiDiSoggiorno = (state: IStoreState) => state.loader.permessiDiSoggiorno;
const getStatiCivili = (state: IStoreState) => state.loader.statiCivili;
const getDocumenti = (state: IStoreState) => state.loader.documenti;
const getEntiRilascio = (state: IStoreState) => state.loader.entiRilascio;
const getAttivita = (state: IStoreState) => state.loader.attivita;
const getProfessioni = (state: IStoreState) => state.loader.professioni;
const getCap = (state: IStoreState) => state.loader.cap;
const getCapPreviousAddress = (state: IStoreState) => state.loader.capPreviousAddress;
const getProvince = (state: IStoreState) => state.loader.province;
const getComuni = (state: IStoreState) => state.loader.comuni;
const getComuniResidenzaByCap = (state: IStoreState) => state.loader.comuniByCap.residenza;
const getComuniPreviousAddressByCap = (state: IStoreState) => state.loader.comuniPreviousAddressByCap.residenza;
const getTaxDeductions = (state: IStoreState) => state.loader.taxDeductions;

export const getCapSelector = createSelector([getCap], cap => get(cap, "data.cap"));

export const getCapPreviousAddressSelector = createSelector([getCapPreviousAddress], capPreviousAddress =>
  get(capPreviousAddress, "data.cap")
);

export const getAbitazioniOptions = createSelector([getAbitazioni], abitazioni =>
  arrayToSelectOptions(get(abitazioni, "data.abitazioni", []), "descrizione", "codice")
);

export const getCittadinanzaOptions = createSelector([getCittadinanza], cittadinanza =>
  arrayToSelectOptions(get(cittadinanza, "data.cittadinanza", []), "descrizione", "codice")
);

export const getPermessiDiSoggiornoOptions = createSelector([getPermessiDiSoggiorno], permessiDiSoggiorno =>
  arrayToSelectOptions(get(permessiDiSoggiorno, "data", []), "descrizione", "codice")
);

export const getStatiCiviliOptions = createSelector([getStatiCivili], statiCivili =>
  arrayToSelectOptions(get(statiCivili, "data", []), "descrizione", "codice")
);

export const getDocumentiOptions = createSelector([getDocumenti], documenti =>
  arrayToSelectOptions(get(documenti, "data", []), "descrizione", "codice")
);

export const getEntiRilascioOptions = createSelector([getEntiRilascio], enti => {
  const entiData = get(enti, "data", {});

  return Object.keys(entiData).reduce((curr, next) => {
    return {
      ...curr,
      [next]:
        entiData[next] && entiData[next].length ? arrayToSelectOptions(entiData[next], "descrizione", "codice") : [],
    };
  }, {});
});

export const getAttivitaOptions = createSelector([getAttivita], attivita =>
  arrayToSelectOptions(get(attivita, "data.attivita", []), "descrizione", "codice")
);

export const getProfessioniOptions = createSelector([getAttivita, getProfessioni], (attivita, professioni) => {
  const attivitaList = get(attivita, "data.attivita");
  const professioniList = get(professioni, "data.professioni", []);

  if (attivitaList && professioniList) {
    return attivitaList
      .map((att: GenericCodiceDescrizioneItem) => att.codice)
      .reduce((cur: Record<string, Array<SelectOptions>>, next: string) => {
        return {
          ...cur,
          [next]: arrayToSelectOptions(
            professioniList.filter((p: ProfessioniOutputDataProfessioni) => p.tipoattivita === next),
            "descrizione",
            "codice"
          ),
        };
      }, {});
  }

  return {};
});

export const getProvinceOptions = createSelector([getProvince], province =>
  arrayToSelectOptions(get(province, "data.province", []), "descrizione", "codice")
);

export const getComuniOptions = createSelector([getProvince, getComuni], (province, comuni) => {
  const provinceList = get(province, "data.province");
  const comuniList = get(comuni, "data.comuni", []);

  if (provinceList && comuniList) {
    return provinceList
      .map((provincia: ProvinceGenericoOutputDataProvince) => provincia.codice)
      .reduce((cur: Record<string, Array<SelectOptions>>, next: string) => {
        return {
          ...cur,
          [next]: arrayToSelectOptions(
            comuniList.filter((c: LocalitaOutputDataComuni) => c.provincia === next),
            "descrizione",
            "codice_catastale"
          ),
        };
      }, {});
  }

  return {};
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractComuniValues = (comuni: any) => {
  const comuniList = get(comuni, "data", []);
  if (comuniList) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredList = uniqBy(comuniList, (comune: any) => comune.city);
    return arrayToSelectOptions(filteredList, "city", "city");
  }

  return [];
};

export const getComuniResidenzaOptionsByCap = createSelector([getComuniResidenzaByCap], extractComuniValues);

export const getComuniPreviousAddressOptionsByCap = createSelector(
  [getComuniPreviousAddressByCap],
  extractComuniValues
);

export const getTaxDeductionsSelector = createSelector([getTaxDeductions], taxDeductions =>
  arrayToSelectOptions(get(taxDeductions, "data.agevolazioni", []), "descrizione", "codice")
);
