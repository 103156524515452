// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Provvigione,
} from './';

/**
 * @export
 * @interface ProvvigioneResponseProvvigione
 */
export interface ProvvigioneResponseProvvigione {
    /**
     * importo contributo totale di tutte le provvigioni presenti
     * @type {number}
     * @memberof ProvvigioneResponseProvvigione
     */
    contributo?: number;
    /**
     * @type {Array<Provvigione>}
     * @memberof ProvvigioneResponseProvvigione
     */
    provvigioni?: Array<Provvigione>;
}
