import MaterialTextField, { HelperText, Input } from "@material/react-text-field";
import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash.get";
import * as React from "react";
import { IDataAttributes } from "../../../caricamento/types/form";
import { LOADING_LABEL } from "../../constants/labels";
import "./text_field.scss";

type TextFieldTypes =
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "text"
  | "time"
  | "url";

interface IOwnProps {
  // Shrink the field to a smallest variant. Defaults to true
  dense?: boolean;
  // Disable the field. Defaults to false
  disabled?: boolean;
  error?: string;
  // Formik render prop
  formikField: FieldProps;
  // Defaults to true
  fullWidth?: boolean;
  helperText?: string | React.ReactNode;
  // The label for the field
  label?: string;
  maxLength?: number | undefined;
  minValue?: number | undefined;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rows?: number | undefined;
  serverSideError?: string;
  textarea?: boolean;
  type?: TextFieldTypes;
  isLoading?: boolean;
  isUpperCase?: boolean;
  isInteger?: boolean;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
}

type Props = IOwnProps;

const TextField: React.FunctionComponent<Props> = ({
  dense = false,
  disabled = false,
  error,
  formikField,
  fullWidth = true,
  helperText,
  label = "",
  maxLength,
  minValue,
  onBlur,
  onChange,
  rows = 4,
  serverSideError,
  textarea = false,
  type = "text",
  isLoading = false,
  isUpperCase,
  dataAttr,
  isInteger,
}) => {
  const isPristine = !get(formikField.form.touched, formikField.field.name);
  const isValid = !((!isPristine && get(formikField.form.errors, formikField.field.name)) || serverSideError || error);

  const classes = classNames({
    "text-field--fullwidth": fullWidth,
  });

  const { onChange: formikOnChange, onBlur: formikOnBlur, ...field } = formikField.field;

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "tel") {
      e.target.value = e.target.value.replace(/\D/g, "");
    }

    if (formikField.field.name === "iban") {
      e.target.value = e.target.value.replace(/\s/g, "").substring(0, 27);
    }

    if (type === "number" && minValue) {
      e.target.value = Math.abs(Number(e.target.value)).toString();
      e.target.value = Number(e.target.value) < minValue ? minValue.toString() : e.target.value;
    }

    if (onChange) {
      onChange(e);
    }

    formikOnChange(e);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const keyDownHandler = (e: any) => {
    if (type === "number" && isInteger) {
      ["e", "E", ",", "+", "-", "."].includes(e.key) && e.preventDefault();
    }
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }

    formikOnBlur(e);
  };

  return (
    formikField && (
      <div className="form__field text-field">
        <MaterialTextField
          outlined={false}
          className={classes}
          dense={dense}
          label={label}
          textarea={textarea}
          helperText={
            isLoading ? (
              <HelperText persistent={true} isValidationMessage={false}>
                {LOADING_LABEL}
              </HelperText>
            ) : error ? (
              <HelperText isValidationMessage={true} persistent={true} validation={true}>
                {error}
              </HelperText>
            ) : serverSideError ? (
              <HelperText isValidationMessage={true} persistent={true} validation={true}>
                {serverSideError}
              </HelperText>
            ) : isPristine || isValid ? (
              helperText ? (
                <HelperText persistent={true} isValidationMessage={false}>
                  {helperText}
                </HelperText>
              ) : (
                <HelperText>{/* Empty Component */}</HelperText>
              )
            ) : (
              <HelperText
                isValidationMessage={true}
                persistent={true}
                validation={true}
                className={classNames({ "mdc-text-field-helper-text--invalid": !isValid })}
              >
                {get(formikField.form.errors, formikField.field.name)}
              </HelperText>
            )
          }
        >
          <Input
            className={classNames({ "text-field--uppercase": isUpperCase })}
            maxLength={maxLength}
            min={minValue}
            rows={rows}
            isValid={isValid}
            onChange={changeHandler}
            onKeyDown={keyDownHandler}
            onBlur={blurHandler}
            disabled={disabled || isLoading}
            type={isInteger ? "number" : type}
            data-cy={dataAttr && dataAttr.cypressSelector}
            {...field}
          />
        </MaterialTextField>
      </div>
    )
  );
};

export default React.memo(TextField);
