// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Questo valore potra\' essere utilizzato lato FE per nascondere la voce del radio button corrispondente ad NN (per far si che non ci sia la terza opzione \"Non risponde\", ma sara\' implicito dal fatto che non si sceglie ne SI ne NO).
 * @export
 * @enum {string}
 */
export enum EnumSemanticaRisposta {
    SI = 'SI',
    NO = 'NO',
    NN = 'NN'
}

