import Picker from "pickerjs";
import "pickerjs/dist/picker.min.css";
import "./react_picker.scss";
import * as React from "react";
import "./date_field.scss";

interface IOwnProps {
  inputRef: React.RefObject<HTMLInputElement> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef: React.RefObject<any> | null;
  value?: Date | string;
  displayFormat?: string;
  handlePick?: () => void;
  handleOpen?: () => void;
  handleOpenFinish?: () => void;
  handleClose?: () => void;
  handleCloseFinish?: () => void;
}

interface IOptions {
  controls?: boolean;
  date?: Date | string;
  format?: string;
  headers?: boolean;
  increment?: number | Record<string, unknown>;
  inline?: boolean;
  language?: string;
  months?: Array<string>;
  monthsShort?: Array<string>;
  rows?: number;
  text?: Record<string, unknown>; // TODO: sistemare
  // translate: // TODO: serve?
  show?: () => void;
  shown?: () => void;
  hide?: () => void;
  hidden?: () => void;
  pick?: () => void;
}

type Props = IOwnProps;

/**
 * React wrapper of the react js plugin:
 * https://github.com/fengyuanchen/pickerjs
 */
const ReactPickerJs: React.FunctionComponent<Props> = ({
  inputRef,
  value,
  displayFormat,
  handlePick,
  handleOpen,
  handleOpenFinish,
  handleClose,
  handleCloseFinish,
  innerRef,
}) => {
  const datePickerInstance = React.useRef<Picker | null>(null);
  // datepicker options
  const options: IOptions = {
    controls: false,
    ...(value ? { date: value } : null),
    format: displayFormat,
    headers: true,
    increment: {
      year: 1,
      month: 1,
      day: 1,
      hour: 1,
      minute: 1,
      second: 1,
      millisecond: 100,
    },
    inline: false,
    language: "",
    months: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
    monthsShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    rows: 5,
    text: {
      title: "Seleziona:",
      cancel: "Annulla",
      confirm: "Conferma",
      year: "Anno",
      month: "Mese",
      day: "Giorno",
      hour: "Ore",
      minute: "Minuto",
      second: "Secondo",
      millisecond: "Millisecondo",
    },
    ...(handleOpen ? { show: handleOpen } : null),
    ...(handleOpenFinish ? { shown: handleOpenFinish } : null),
    ...(handleClose ? { hide: handleClose } : null),
    ...(handleCloseFinish ? { hidden: handleCloseFinish } : null),
    ...(handlePick ? { pick: handlePick } : null),
  };

  React.useEffect(() => {
    if (inputRef && inputRef.current) {
      datePickerInstance.current = new Picker(inputRef.current, options);

      // riferimento istanza di DatePicker
      if (innerRef) {
        // We are setting the new datePicker Instance in the parent component ref, passed throug props.
        innerRef.current = datePickerInstance.current;
      }
    }
    return () => {
      if (datePickerInstance && datePickerInstance.current) {
        datePickerInstance.current.destroy();
      }
    };
  }, [inputRef]);
  return null;
};

export default React.memo(ReactPickerJs);
