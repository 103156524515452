import { useEffect } from "react";
import { TCustomCustomer } from "../../../../custom/libraries/dataHandling/types";
import { TCustomer, TFieldName, TGetFromCustomer, TMappingPaths } from "../../../lib/dataHandling/types";

export const useLoadErrors = (
  getErrori: (prevId: string, inputMapping: Record<TFieldName, string>) => void,
  getFromCustomer: TGetFromCustomer,
  allFormsKey: string,
  loadServerSideErrors: boolean,
  customer?: TCustomer | TCustomCustomer,
  preventivoId?: string
): void => {
  useEffect(() => {
    if (preventivoId && loadServerSideErrors && customer) {
      const inputPaths = getFromCustomer(customer, "inputPath", allFormsKey) as TMappingPaths;
      getErrori(preventivoId, inputPaths);
    }
  }, [preventivoId]);
};
