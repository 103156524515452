// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * JUNIOR = CLA17 ad esempio, SENIOR = CLAGE, serve a stabilire anche la tipologia di CL da usare in base alla data di nascita o flag over64
 * @export
 * @enum {string}
 */
export enum TipologiaCreditLife {
    JUNIOR = 'JUNIOR',
    SENIOR = 'SENIOR'
}

