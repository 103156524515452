import { Middleware, RequestArgs } from "../../shared/generated/globals";
import produce from "immer";
import { AUTH_TOKEN_SESSION_KEY } from "../config/application";
import { SessionStorage } from "../../shared/lib/utility/storage-v2";

export const preTokenMiddleware: Middleware = {
  pre(request: RequestArgs): RequestArgs {
    return produce(request, draft => {
      const token = SessionStorage.read(AUTH_TOKEN_SESSION_KEY);
      const authHeader = { Authorization: `Bearer ${token}` };
      if (token) {
        if (!draft.headers) {
          draft.headers = {};
        }
        Object.keys(authHeader).forEach(key => {
          if (draft.headers !== undefined) {
            draft.headers[key] = authHeader[key];
          }
        });
      }
    });
  },
};
