// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface StatoPraticheOutputListaPratiche
 */
export interface StatoPraticheOutputListaPratiche {
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    AttributoOCS?: string;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    codiceStato?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceAG?: number;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    ImportoFinanziato?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DataEvasioneAttesa?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    ImportoRata?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DescrizioneSottoStato?: string;
    /**
     * @type {boolean}
     * @memberof StatoPraticheOutputListaPratiche
     */
    nil?: boolean;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    StatoOCS?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    NumeroRate?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DataCaricamento?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    Pratica?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    Url?: string;
    /**
     * @type {boolean}
     * @memberof StatoPraticheOutputListaPratiche
     */
    NoteInAttesaDiRisposta?: boolean;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceSA?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DataUltimaModifica?: string;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    Provenienza?: string;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DescrizioneStato?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodicePV?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DataPrimaScadenzaRata?: string;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    RagioneSocialeCliente?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceRV?: number;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    PercentualeAvanzamento?: number;
    /**
     * @type {boolean}
     * @memberof StatoPraticheOutputListaPratiche
     */
    EventiAttesi?: boolean;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceVenditore?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DescrizioneBene?: string;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DescrizioneStatoOCS?: string;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    DescrizioneAttributoOCS?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceCV?: number;
    /**
     * @type {string}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceSottoStato?: string;
    /**
     * @type {number}
     * @memberof StatoPraticheOutputListaPratiche
     */
    CodiceVE?: number;
}
