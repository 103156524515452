import { useEffect } from "react";
import { TAllFOrmsKey } from "../../../lib/dataHandling/types";

export const useLoadCustomer = (
  getCustomer: (allFormsKey: TAllFOrmsKey) => void,
  run: boolean,
  allFormsKey: TAllFOrmsKey
): void => {
  useEffect(() => {
    if (run) {
      getCustomer(allFormsKey);
    }
  }, []);
};
