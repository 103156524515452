import * as React from "react";
import { useContext, useEffect } from "react";
import {
  DEFAULT_ERROR_ADVISED_SOLUTION,
  DEFAULT_ERROR_HERO_DESCRIPTION,
  DEFAULT_ERROR_MESSAGE,
} from "../../constants/labels";
import { CustomizationContext } from "../../lib/customization/CustomizationContext";
import { customizationHookState } from "../../lib/customization/useCustomization";
import Container from "../container/container";
import Hero from "../hero/hero";
import NestedSection from "../nested_section/nested_section";
import { ErrorMessages } from "./errorPage.types";
import "./error_page.scss";
import { useErrorType } from "./useErrorType";

const ErrorPage: React.FunctionComponent<ErrorMessages> = ({
  heroDescription = DEFAULT_ERROR_HERO_DESCRIPTION,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  advisedSolution = DEFAULT_ERROR_ADVISED_SOLUTION,
}) => {
  const { setState } = useContext(CustomizationContext);

  const messages = useErrorType({ errorMessage, advisedSolution, heroDescription });

  useEffect(() => {
    setState(customizationHookState.ERROR);
  }, []);

  return (
    <div className="error-page">
      <NestedSection
        nestAmount={64}
        headerContent={<Hero title="Ci dispiace!" description={messages.heroDescription} />}
        bodyContent={
          <Container>
            <div className="error-page__description-wrapper">
              <p
                className="error-page__description"
                dangerouslySetInnerHTML={{ __html: messages.errorMessage?.toString() || "" }}
              ></p>
              <p
                className="error-page__description"
                dangerouslySetInnerHTML={{ __html: messages.advisedSolution?.toString() || "" }}
              ></p>
            </div>
          </Container>
        }
      />
    </div>
  );
};

export default ErrorPage;
