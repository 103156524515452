// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    CommissioniResponse,
} from '../models';

export interface GetCommissioniRequest {
    intermediario: string;
    provenienza: GetCommissioniProvenienzaEnum;
    codiceProdotto: string;
    tabellaFinanziaria: string;
    finanziato: number;
    durata: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

/**
 * no description
 */
export class P0Api extends BaseAPI {

    /**
     */
    getCommissioni = (requestParameters: GetCommissioniRequest): Observable<CommissioniResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getCommissioni');
        throwIfRequired(requestParameters, 'provenienza', 'getCommissioni');
        throwIfRequired(requestParameters, 'codiceProdotto', 'getCommissioni');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'getCommissioni');
        throwIfRequired(requestParameters, 'finanziato', 'getCommissioni');
        throwIfRequired(requestParameters, 'durata', 'getCommissioni');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.provenienza && { 'provenienza': requestParameters.provenienza }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.finanziato && { 'finanziato': requestParameters.finanziato }),
            ...(requestParameters.durata && { 'durata': requestParameters.durata }),
        };

        return this.request<CommissioniResponse>({
            path: '/preventivo/commissioni',
            method: 'GET',
            headers,
            query,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetCommissioniProvenienzaEnum {
    CO = 'CO',
    LE = 'LE'
}
