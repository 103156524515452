import { INA_INSURANCE_DESCRIPTION, PI_INSURANCE_DESCRIPTION } from "../../constants/labels";
import { IState as IModificaPratica } from "../../logic/modificaPratica/types";

export const insuranceDescriptionLabel = (modificaPratica: IModificaPratica): string | undefined => {
  const typology = modificaPratica.eligibilita.data?.tipologia;

  if (typology && typology === "INA") {
    return PI_INSURANCE_DESCRIPTION;
  } else if (typology && typology === "PI") {
    return INA_INSURANCE_DESCRIPTION;
  } else {
    return;
  }
};
