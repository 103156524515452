// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroCOD011,
    WpNormInputParametroCOD611,
    WpNormInputParametroCOO626,
    WpNormInputParametroINR020,
    WpNormInputParametroINR620,
    WpNormInputParametroKII,
    WpNormInputParametroKIO,
    WpNormInputParametroMCZ627,
    WpNormInputParametroPAR,
    WpNormInputParametroRGS010,
    WpNormInputParametroRGS610,
    WpNormInputParametroSEG,
    WpNormInputParametroTEL035,
    WpNormInputParametroTEL635,
} from './';

/**
 * @export
 * @interface WpNormInputParametroSOG
 */
export interface WpNormInputParametroSOG {
    /**
     * @type {WpNormInputParametroPAR}
     * @memberof WpNormInputParametroSOG
     */
    PAR?: WpNormInputParametroPAR;
    /**
     * @type {WpNormInputParametroKII}
     * @memberof WpNormInputParametroSOG
     */
    KII?: WpNormInputParametroKII;
    /**
     * @type {WpNormInputParametroRGS010}
     * @memberof WpNormInputParametroSOG
     */
    RGS_010?: WpNormInputParametroRGS010;
    /**
     * @type {Array<WpNormInputParametroCOD011>}
     * @memberof WpNormInputParametroSOG
     */
    COD_011?: Array<WpNormInputParametroCOD011>;
    /**
     * @type {WpNormInputParametroINR020}
     * @memberof WpNormInputParametroSOG
     */
    INR_020?: WpNormInputParametroINR020;
    /**
     * @type {WpNormInputParametroTEL035}
     * @memberof WpNormInputParametroSOG
     */
    TEL_035?: WpNormInputParametroTEL035;
    /**
     * @type {WpNormInputParametroKIO}
     * @memberof WpNormInputParametroSOG
     */
    KIO?: WpNormInputParametroKIO;
    /**
     * @type {WpNormInputParametroRGS610}
     * @memberof WpNormInputParametroSOG
     */
    RGS_610?: WpNormInputParametroRGS610;
    /**
     * @type {Array<WpNormInputParametroCOD611>}
     * @memberof WpNormInputParametroSOG
     */
    COD_611?: Array<WpNormInputParametroCOD611>;
    /**
     * @type {WpNormInputParametroINR620}
     * @memberof WpNormInputParametroSOG
     */
    INR_620?: WpNormInputParametroINR620;
    /**
     * @type {WpNormInputParametroCOO626}
     * @memberof WpNormInputParametroSOG
     */
    COO_626?: WpNormInputParametroCOO626;
    /**
     * @type {WpNormInputParametroMCZ627}
     * @memberof WpNormInputParametroSOG
     */
    MCZ_627?: WpNormInputParametroMCZ627;
    /**
     * @type {WpNormInputParametroTEL635}
     * @memberof WpNormInputParametroSOG
     */
    TEL_635?: WpNormInputParametroTEL635;
    /**
     * @type {WpNormInputParametroSEG}
     * @memberof WpNormInputParametroSOG
     */
    SEG?: WpNormInputParametroSEG;
}
