// tslint:disable
/**
 * caricamento
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PagamentoCartaOKPayloadUserRedirect,
} from './';

/**
 * @export
 * @interface PagamentoCartaOKPayload
 */
export interface PagamentoCartaOKPayload {
    /**
     * @type {string}
     * @memberof PagamentoCartaOKPayload
     */
    paymentToken?: string;
    /**
     * @type {string}
     * @memberof PagamentoCartaOKPayload
     */
    paymentID?: string;
    /**
     * @type {PagamentoCartaOKPayloadUserRedirect}
     * @memberof PagamentoCartaOKPayload
     */
    userRedirect?: PagamentoCartaOKPayloadUserRedirect;
}
