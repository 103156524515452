import produce from "immer";
import { Middleware, RequestArgs, ResponseArgs } from "../generated/globals";
import { SessionStorage } from "./utility/storage-v2";

export const sharedTokenMiddleware = (sessionTokenRead: string, sessionTokenWrite?: string): Middleware => {
  return {
    pre(request: RequestArgs): RequestArgs {
      return produce(request, draft => {
        const token = SessionStorage.read(sessionTokenRead);
        const authHeader = { Authorization: `Bearer ${token}` };
        if (token) {
          if (!draft.headers) {
            draft.headers = {};
          }
          Object.keys(authHeader).forEach(key => {
            if (draft.headers !== undefined) {
              draft.headers[key] = authHeader[key];
            }
          });
        }
      });
    },
    post(response: ResponseArgs): ResponseArgs {
      if (sessionTokenWrite && response.xhr.getResponseHeader("Authorization")) {
        const authHeader = response.xhr.getResponseHeader("Authorization");
        if (authHeader && sessionTokenWrite) {
          const token = authHeader.replace("Bearer ", "");
          SessionStorage.write(sessionTokenWrite, token);
        }
      }
      return response;
    },
  };
};
