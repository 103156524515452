import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { warn } from "../../../../shared/lib/log";
import { SessionStorage } from "../../../../shared/lib/utility/storage-v2";
import { PARTNER_NAME_KEY } from "../../../config/application";

declare global {
  // no need to prefix Window
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    dataLayer?: unknown[];
  }
}

interface IUseGoogleTagManager {
  (path: string, title: string): void;
}

interface ISendGoogleTagManagerEvent {
  (category: string, action: string, label: string): void;
}

let gtmOrganization: string;

export const initializeGoogleTagManager = (gtmId: string): void => {
  // prefer using already defined dataLayer
  if (!window.dataLayer) {
    const tagManagerArgs = {
      gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }
};

export const setGoogleTagManagerOrganization = (organization: string): void => {
  gtmOrganization = organization;
};

export const useGoogleTagManager: IUseGoogleTagManager = (path, title) => {
  useEffect(() => {
    if (!window.dataLayer) {
      warn("In order to use Google Tag Manager, window.dataLayer should be initialized");
      window.dataLayer = [];
    }
    const useGoogleTagManagerInterval = setInterval(() => {
      if (gtmOrganization && window.dataLayer) {
        window.dataLayer.push({
          event: "Pageview",
          pagePath: path,
          pageTitle: title,
          organization: gtmOrganization || SessionStorage.read(PARTNER_NAME_KEY),
        });
        clearInterval(useGoogleTagManagerInterval);
      }
    }, 100);
  }, []);
};

export const sendGoogleTagManagerEvent: ISendGoogleTagManagerEvent = (category, action, label) => {
  if (!window.dataLayer) {
    warn("In order to use Google Tag Manager, window.dataLayer should be initialized");
    window.dataLayer = [];
  }
  const useGoogleTagManagerInterval = setInterval(() => {
    if (gtmOrganization && window.dataLayer) {
      window.dataLayer.push({
        event: "Event",
        category,
        action,
        label,
        organization: gtmOrganization,
      });
      clearInterval(useGoogleTagManagerInterval);
    }
  }, 100);
};
