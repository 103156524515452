// tslint:disable
/**
 * servizi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    LoaderServiziOutputDataServiziDurate,
} from './';

/**
 * @export
 * @interface LoaderServiziOutputDataServiziCO
 */
export interface LoaderServiziOutputDataServiziCO {
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    codice?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    creditlife?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    descrizione?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    tipo?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    etamin?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    etamax?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    basecalcolo?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    duratarichiesta?: string;
    /**
     * @type {boolean}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    provinciarichiesta?: boolean;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    importominattivo?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    importomaxattivo?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    duratamax?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    duratamin?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    tipodurata?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    tipoprontuario?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    anzianitaveicolo?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    valorebenemax?: string;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    gruppo?: object;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    descrizioneGruppo?: object;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    help?: object;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    ordineServizio?: object;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    tipocontroparte1?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    tipocontroparte2?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    tipocontroparte3?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    gestionedurata?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    scaglionecoeff?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    firmatarionocli?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    postvendita?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    durataIO?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    nosub?: string;
    /**
     * @type {LoaderServiziOutputDataServiziDurate}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    durate?: LoaderServiziOutputDataServiziDurate;
    /**
     * @type {boolean}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    hidden?: boolean;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    upselling?: object;
    /**
     * @type {boolean}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    serviziogratuito?: boolean;
    /**
     * @type {boolean}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    carlineobbligatoria?: boolean;
    /**
     * @type {boolean}
     * @memberof LoaderServiziOutputDataServiziCO
     */
    manutenzione?: boolean;
}
