import Dialog, { DialogContent, DialogTitle } from "@material/react-dialog";
import List, { ListItem, ListItemGraphic, ListItemText } from "@material/react-list";
import React from "react";

import { IAddress } from "../normalizzatore.types";
import "./normalizzatoreModal.scss";
import Icon from "../../../../shared/components/icon/icon";
import Button from "../../../../shared/components/button/button";

interface IOwnProps {
  suggestions: IAddress[];
  ignoraLabel?: string;
  titleLabel?: string;
  onSuggestionSelect?: (address: IAddress) => void;
  onCloseModal?: () => void;
}

type Props = IOwnProps;

const NormalizzatoreModal: React.FunctionComponent<Props> = ({
  suggestions,
  ignoraLabel,
  titleLabel,
  onSuggestionSelect,
  onCloseModal,
}) => {
  const handleSelectedSuggestionIndex = (selectedIndex: number) => {
    if (onSuggestionSelect && suggestions[selectedIndex]) {
      onSuggestionSelect(suggestions[selectedIndex]);
    }
  };

  const closeSuggestionsModal = () => {
    if (onCloseModal) {
      onCloseModal();
    }
  };

  return (
    <Dialog open={true} escapeKeyAction={""} scrimClickAction={""} className="suggestions-modal">
      <DialogTitle className="suggestions-modal__dialog-title">
        <div className="suggestions-modal__title-text">{titleLabel ? titleLabel : "Forse intendevi:"}</div>
      </DialogTitle>
      <DialogContent>
        <div className="suggestions-modal__dialog-content">
          <List twoLine={true} singleSelection={true} handleSelect={handleSelectedSuggestionIndex}>
            {suggestions.map((item: IAddress, index: number) => {
              return (
                <ListItem key={index} className="suggestions-modal__item">
                  <ListItemText
                    className="suggestions-modal__item-text"
                    primaryText={item.indirizzo}
                    secondaryText={`${item.cap} - ${item.comune} (${item.provincia})`}
                  />
                  <ListItemGraphic graphic={<Icon type="keyboard_arrow_right" />} />
                </ListItem>
              );
            })}
          </List>

          <div className="suggestions-modal__dialog-actions">
            <Button theme="secondary" fullWidth={true} size="big" onClick={closeSuggestionsModal}>
              {ignoraLabel ? ignoraLabel : "Ignora"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NormalizzatoreModal;
