import { FC, MemoExoticComponent, PropsWithChildren } from "react";
import { ConnectedComponent } from "react-redux";
import { RouteProps } from "react-router-dom";

export const DEFAULT_ROUTE_KEY = "DEFAULT_ROUTE_KEY";

type TPath = string;

type TProps<P = Record<string, unknown>> = PropsWithChildren<P>;

interface IRouteConfig extends RouteProps {
  Component:
    | ConnectedComponent<FC, Pick<Record<string, unknown>, never>>
    | MemoExoticComponent<ConnectedComponent<FC, Pick<Record<string, unknown>, never>>>
    | FC;
  props?: TProps;
}

export type TRoutesConfig = Record<TPath, IRouteConfig>;

interface IAddOnItem {
  props: TProps;
}

export type TAddOn = Record<TPath, IAddOnItem>;

export type TRoute = string | ((options?: unknown) => string);
