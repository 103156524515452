// tslint:disable
/**
 * tabelle
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TabelleOutputDataTabelleCapResiduo,
    TabelleOutputDataTabelleMarcamod,
    TabelleOutputDataTabelleTassoStep,
} from './';

/**
 * @export
 * @interface TabelleOutputDataTabelleCO
 */
export interface TabelleOutputDataTabelleCO {
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    codice?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    descrizione?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    durata_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    durata_max?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    durata_step?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    tiposcaglione?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    anticipo_step?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    anticipo_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    anticipo_max?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    finanziato_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    finanziato_max?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    arrotondamento?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    posticipo?: string;
    /**
     * @type {number}
     * @memberof TabelleOutputDataTabelleCO
     */
    baloonstep?: number;
    /**
     * @type {number}
     * @memberof TabelleOutputDataTabelleCO
     */
    baloonmin?: number;
    /**
     * @type {number}
     * @memberof TabelleOutputDataTabelleCO
     */
    baloonmax?: number;
    /**
     * @type {boolean}
     * @memberof TabelleOutputDataTabelleCO
     */
    comparazione?: boolean;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    TCM?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    bloccoP?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    bloccoF?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    bloccoG?: string;
    /**
     * @type {Array<string>}
     * @memberof TabelleOutputDataTabelleCO
     */
    commissione?: Array<string>;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    tipotabella?: string;
    /**
     * @type {Array<TabelleOutputDataTabelleMarcamod>}
     * @memberof TabelleOutputDataTabelleCO
     */
    marcamod?: Array<TabelleOutputDataTabelleMarcamod>;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    tasso?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataTabelleCO
     */
    tassototale?: string;
    /**
     * @type {Array<TabelleOutputDataTabelleTassoStep>}
     * @memberof TabelleOutputDataTabelleCO
     */
    tasso_step?: Array<TabelleOutputDataTabelleTassoStep>;
    /**
     * @type {Array<TabelleOutputDataTabelleCapResiduo>}
     * @memberof TabelleOutputDataTabelleCO
     */
    cap_residuo?: Array<TabelleOutputDataTabelleCapResiduo>;
}
