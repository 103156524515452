import isEmpty from "lodash.isempty";
import moment from "moment";
import {
  ATTIVITA_LAVORATORE_AUTONOMO,
  IMPIEGO_DELTA,
  MONTHYEAR_DATE_FORMAT,
  PATENTE_PLASTIFICATA,
  RESIDENZA_DELTA,
} from "../../../shared/constants/application";
import { CittadinanzaOutputDataCittadinanza } from "../../../shared/generated/loader/cittadinanza";
import { isPassedDate } from "../../../shared/lib/utility/date";
import { deltaTime } from "../../../shared/moment";

export const isEuropeanCitizen = (loader: Array<CittadinanzaOutputDataCittadinanza> = [], value: string): boolean => {
  return loader.find(item => item.codice === value)?.extracom === "N";
};

export const patenteUcoPrefixRegExp = new RegExp("^u\\d[a-z0-9]*", "i");

export const isPatenteUCO = (documento?: string, numero = ""): boolean =>
  documento === PATENTE_PLASTIFICATA && patenteUcoPrefixRegExp.test(numero);

export const isLavoratoreAutonomo = (attivita: string): boolean => attivita === ATTIVITA_LAVORATORE_AUTONOMO;

export const checkDataResidenza = (dataAbitazione: string, delta: deltaTime = RESIDENZA_DELTA): boolean => {
  const dataAbitazioneMoment = moment(dataAbitazione, MONTHYEAR_DATE_FORMAT);
  const isDataAbitazioneDateSet = dataAbitazione && dataAbitazione !== "" && dataAbitazioneMoment.isValid();

  // If the date is invalid ("" or undefined), Residenza data is not required
  if (!isDataAbitazioneDateSet) {
    return false;
  }

  const dataAbitazioneDate = dataAbitazioneMoment.toDate();
  return isPassedDate(dataAbitazioneDate, delta);
};

export const checkDataUltimoImpiego = (dataImpiego: string, delta: deltaTime = IMPIEGO_DELTA): boolean => {
  const dataImpiegoMoment = moment(dataImpiego, MONTHYEAR_DATE_FORMAT);
  const isDataImpiegoDateSet = dataImpiego && dataImpiego !== "" && dataImpiegoMoment.isValid();

  // If the date is invalid ("" or undefined), Impiego data is not required
  if (!isDataImpiegoDateSet) {
    return false;
  }

  const dataImpiegoDate = dataImpiegoMoment.toDate();
  return isPassedDate(dataImpiegoDate, delta);
};

export const checkInvalidDate = (format: string) => (value: string): boolean => {
  if (isEmpty(value)) {
    // if value is empty, the validator should not care
    return true;
  }
  return moment(value, format, true).isValid();
};
