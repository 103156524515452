// tslint:disable
/**
 * localita-generico
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    LocalitaGenericoOutput,
} from '../models';

export interface LoaderFinalitaPrestitoRequest {
    Authorization: string;
}

/**
 * no description
 */
export class FinalitaPrestitoApi extends BaseAPI {

    /**
     */
    loaderFinalitaPrestito = (requestParameters: LoaderFinalitaPrestitoRequest): Observable<LocalitaGenericoOutput> => {
        throwIfRequired(requestParameters, 'Authorization', 'loaderFinalitaPrestito');

        const headers: HttpHeaders = {
            ...(requestParameters.Authorization && { 'Authorization': String(requestParameters.Authorization) }),
        };

        return this.request<LocalitaGenericoOutput>({
            path: '/localita/generico',
            method: 'GET',
            headers,
        });
    };

}
