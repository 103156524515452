// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    CheckPagamentoInput,
    CheckPagamentoOutput,
    ClienteRifiutoInput,
    ClienteRifiutoOutput,
    ContropartePartnerOutput,
    ControparteRifiutoOutput,
    ErrorObject,
    ErrorSalvaPraticaTessera,
    Errori,
    Errors,
    EsitoRichiestaModifica,
    GetOTPCodeOutput,
    InputPraticaExitUrl,
    InvioPecInput,
    InvioPecOutput,
    LoaderCommissioniInput,
    LoaderCommissioniOutput,
    NextStepInput,
    NextStepOutput,
    OfflineOutput,
    OutputPraticaExitUrl,
    PagamentiWLInput,
    PagamentiWLOutput,
    PraticaConsumo,
    PraticaConsumoKo,
    PraticaConsumoOk,
    PraticaContribuzioneStatiInput,
    PraticaContribuzioneStatiOutput,
    PraticaCounterInput,
    PraticaCounterOutput,
    PraticaNextStepInput,
    PraticaNextStepOutput,
    PraticaRestartInput,
    PraticaRestartOutput,
    PraticaRestartProcessWLOutput,
    PraticaSalvaTesseraSanitariaInput,
    PraticaSalvaTesseraSanitariaOutput,
    PreventivoIdSetInput,
    ReportFEInput,
    RichiestaModifica,
    RiconocimentoVoucherPostInput,
    RiconocimentoVoucherPostOutput,
    RiconoscimentoVoucherGetOutput,
    SalvaRiconoscimentoInput,
    SalvaRiconoscimentoOutput,
    ServizioPreflaggatoInput,
    ServizioPreflaggatoOutput,
    StartProcessDurablesInput,
    StartProcessDurablesOutput,
    StatoPraticheInput,
    StatoPraticheOutput,
    TipoControparte,
} from '../models';

export interface CheckPagamentoRequest {
    input: CheckPagamentoInput;
}

export interface ClienteRifiutoRequest {
    token?: string;
    Input_ClienteRifiuto?: ClienteRifiutoInput;
}

export interface ControparteRifiutoRequest {
    tipoControparte: TipoControparte;
    token?: string;
}

export interface GetContropartePartnerRequest {
    Token: string;
    tipoControparte?: string;
}

export interface GetOTPCodeRequest {
    prev_id: string;
}

export interface GetRiconocimentoVoucherRequest {
    numeroPreventivo: number;
    organization: string;
    token: string;
    X_B3_TraceId?: string;
    pratica?: number;
    tipoControparte?: string;
}

export interface LoaderCommissioniRequest {
    X_B3_TraceId?: string;
    input?: LoaderCommissioniInput;
}

export interface NextStepRequest {
    X_B3_TraceId?: string;
    token?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    seession_id?: string;
    requestBody?: NextStepInput;
}

export interface OfflineGetRequest {
    X_B3_TraceId?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    session_id?: string;
}

export interface POSTStatoPraticheRequest {
    request?: StatoPraticheInput;
}

export interface PagamentiWLRequest {
    requestBody: PagamentiWLInput;
}

export interface PostRiconocimentoVoucherRequest {
    token: string;
    requestBody: RiconocimentoVoucherPostInput;
    X_B3_TraceId?: string;
    tipoControparte?: string;
}

export interface PraticaContribuzioneStatiRequest {
    requestBody?: PraticaContribuzioneStatiInput;
}

export interface PraticaControparteRequest {
    praticaControparteInput: PraticaConsumo;
    Organization?: string;
    tipoControparte?: string;
    token?: string;
    X_B3_TraceId?: string;
}

export interface PraticaCounterRequest {
    input: PraticaCounterInput;
}

export interface PraticaExitUrlRequest {
    inputPraticaExitUrl?: InputPraticaExitUrl;
}

export interface PraticaNextStepRequest {
    token: string;
    X_B3_TraceId?: string;
    requestBody?: PraticaNextStepInput;
}

export interface PraticaRestartRequest {
    token?: string;
    body?: PraticaRestartInput;
}

export interface PraticaRichiestaModificaRequest {
    requestBody: RichiestaModifica;
}

export interface PraticaRitiroRequest {
    pratica: string;
    intermediario: string;
    codiceInternet?: string;
}

export interface PraticaSalvaTesseraSanitariaRequest {
    requestBody?: PraticaSalvaTesseraSanitariaInput;
}

export interface PreventivoSetIdPostRequest {
    token: string;
    X_B3_TraceId?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    session_id?: string;
    body?: PreventivoIdSetInput;
}

export interface ReportFERequest {
    messageBody?: ReportFEInput;
}

export interface SalvaRiconoscimentoPostRequest {
    token: string;
    X_B3_TraceID?: string;
    input?: SalvaRiconoscimentoInput;
}

export interface SendPecRequest {
    input: InvioPecInput;
}

export interface ServizioPreflaggatoPostRequest {
    requestBody?: ServizioPreflaggatoInput;
}

export interface StartProcessDurablesRequest {
    Input: StartProcessDurablesInput;
    X_B3_TraceId?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    session_id?: string;
}

/**
 * no description
 */
export class DefaultApi extends BaseAPI {

    /**
     */
    checkPagamento = (requestParameters: CheckPagamentoRequest): Observable<CheckPagamentoOutput> => {
        throwIfRequired(requestParameters, 'input', 'checkPagamento');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<CheckPagamentoOutput>({
            path: '/checkPagamento',
            method: 'POST',
            headers,
            body: requestParameters.input,
        });
    };

    /**
     */
    clienteRifiuto = (requestParameters: ClienteRifiutoRequest): Observable<ClienteRifiutoOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        return this.request<ClienteRifiutoOutput>({
            path: '/cliente/rifiuto',
            method: 'POST',
            headers,
            body: requestParameters.Input_ClienteRifiuto,
        });
    };

    /**
     */
    controparteRifiuto = (requestParameters: ControparteRifiutoRequest): Observable<ControparteRifiutoOutput> => {
        throwIfRequired(requestParameters, 'tipoControparte', 'controparteRifiuto');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        return this.request<ControparteRifiutoOutput>({
            path: '/controparte/rifiuto',
            method: 'POST',
            headers,
            body: requestParameters.tipoControparte,
        });
    };

    /**
     */
    getContropartePartner = (requestParameters: GetContropartePartnerRequest): Observable<ContropartePartnerOutput> => {
        throwIfRequired(requestParameters, 'Token', 'getContropartePartner');

        const query: HttpQuery = {
            ...(requestParameters.tipoControparte && { 'tipoControparte': requestParameters.tipoControparte }),
        };

        return this.request<ContropartePartnerOutput>({
            path: '/controparte/partner/{Token}'.replace('{Token}', encodeURI(requestParameters.Token)),
            method: 'GET',
            query,
        });
    };

    /**
     */
    getOTPCode = (requestParameters: GetOTPCodeRequest): Observable<GetOTPCodeOutput> => {
        throwIfRequired(requestParameters, 'prev_id', 'getOTPCode');

        return this.request<GetOTPCodeOutput>({
            path: '/otpCode/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
        });
    };

    /**
     */
    getRiconocimentoVoucher = (requestParameters: GetRiconocimentoVoucherRequest): Observable<RiconoscimentoVoucherGetOutput> => {
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getRiconocimentoVoucher');
        throwIfRequired(requestParameters, 'organization', 'getRiconocimentoVoucher');
        throwIfRequired(requestParameters, 'token', 'getRiconocimentoVoucher');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.pratica && { 'pratica': requestParameters.pratica }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
            ...(requestParameters.organization && { 'organization': requestParameters.organization }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': requestParameters.tipoControparte }),
        };

        return this.request<RiconoscimentoVoucherGetOutput>({
            path: '/riconoscimento/voucher',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    loaderCommissioni = (requestParameters: LoaderCommissioniRequest): Observable<LoaderCommissioniOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<LoaderCommissioniOutput>({
            path: '/loader/commissioni',
            method: 'POST',
            headers,
            body: requestParameters.input,
        });
    };

    /**
     */
    nextStep = (requestParameters: NextStepRequest): Observable<NextStepOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.seession_id && { 'seession-id': String(requestParameters.seession_id) }),
        };

        return this.request<NextStepOutput>({
            path: '/nextStep',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    offlineGet = (requestParameters: OfflineGetRequest): Observable<OfflineOutput> => {

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.session_id && { 'session-id': String(requestParameters.session_id) }),
        };

        return this.request<OfflineOutput>({
            path: '/offline',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    pOSTStatoPratiche = (requestParameters: POSTStatoPraticheRequest): Observable<StatoPraticheOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<StatoPraticheOutput>({
            path: '/stato/pratiche',
            method: 'POST',
            headers,
            body: requestParameters.request,
        });
    };

    /**
     */
    pagamentiWL = (requestParameters: PagamentiWLRequest): Observable<PagamentiWLOutput> => {
        throwIfRequired(requestParameters, 'requestBody', 'pagamentiWL');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PagamentiWLOutput>({
            path: '/pagamenti/WL',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    postRiconocimentoVoucher = (requestParameters: PostRiconocimentoVoucherRequest): Observable<RiconocimentoVoucherPostOutput> => {
        throwIfRequired(requestParameters, 'token', 'postRiconocimentoVoucher');
        throwIfRequired(requestParameters, 'requestBody', 'postRiconocimentoVoucher');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.tipoControparte && { 'tipoControparte': requestParameters.tipoControparte }),
        };

        return this.request<RiconocimentoVoucherPostOutput>({
            path: '/riconoscimento/voucher',
            method: 'POST',
            headers,
            query,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    praticaContribuzioneStati = (requestParameters: PraticaContribuzioneStatiRequest): Observable<PraticaContribuzioneStatiOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PraticaContribuzioneStatiOutput>({
            path: '/pratica/contribuzioneStati',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    praticaControparte = (requestParameters: PraticaControparteRequest): Observable<PraticaConsumoOk> => {
        throwIfRequired(requestParameters, 'praticaControparteInput', 'praticaControparte');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.Organization && { 'Organization': String(requestParameters.Organization) }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': String(requestParameters.tipoControparte) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PraticaConsumoOk>({
            path: '/pratica/controparte',
            method: 'POST',
            headers,
            body: requestParameters.praticaControparteInput,
        });
    };

    /**
     */
    praticaCounter = (requestParameters: PraticaCounterRequest): Observable<PraticaCounterOutput> => {
        throwIfRequired(requestParameters, 'input', 'praticaCounter');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PraticaCounterOutput>({
            path: '/pratica/counter',
            method: 'POST',
            headers,
            body: requestParameters.input,
        });
    };

    /**
     */
    praticaExitUrl = (requestParameters: PraticaExitUrlRequest): Observable<OutputPraticaExitUrl> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<OutputPraticaExitUrl>({
            path: '/pratica/exitUrl',
            method: 'POST',
            headers,
            body: requestParameters.inputPraticaExitUrl,
        });
    };

    /**
     */
    praticaNextStep = (requestParameters: PraticaNextStepRequest): Observable<PraticaNextStepOutput> => {
        throwIfRequired(requestParameters, 'token', 'praticaNextStep');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PraticaNextStepOutput>({
            path: '/pratica/nextStep',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    praticaRestart = (requestParameters: PraticaRestartRequest): Observable<PraticaRestartOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
        };

        return this.request<PraticaRestartOutput>({
            path: '/pratica/restart',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     */
    praticaRestartProcessWL = (): Observable<PraticaRestartProcessWLOutput> => {
        return this.request<PraticaRestartProcessWLOutput>({
            path: '/pratica/restartProcess/WL',
            method: 'POST',
        });
    };

    /**
     */
    praticaRichiestaModifica = (requestParameters: PraticaRichiestaModificaRequest): Observable<EsitoRichiestaModifica> => {
        throwIfRequired(requestParameters, 'requestBody', 'praticaRichiestaModifica');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<EsitoRichiestaModifica>({
            path: '/pratica/richiestaModifica',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    praticaRitiro = (requestParameters: PraticaRitiroRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'pratica', 'praticaRitiro');
        throwIfRequired(requestParameters, 'intermediario', 'praticaRitiro');

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.pratica && { 'pratica': requestParameters.pratica }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
        };

        return this.request<void>({
            path: '/pratica/ritiro',
            method: 'GET',
            query,
        });
    };

    /**
     */
    praticaSalvaTesseraSanitaria = (requestParameters: PraticaSalvaTesseraSanitariaRequest): Observable<PraticaSalvaTesseraSanitariaOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PraticaSalvaTesseraSanitariaOutput>({
            path: '/pratica/salva/tesseraSanitaria',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    preventivoSetIdPost = (requestParameters: PreventivoSetIdPostRequest): Observable<string> => {
        throwIfRequired(requestParameters, 'token', 'preventivoSetIdPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.session_id && { 'session-id': String(requestParameters.session_id) }),
        };

        return this.request<string>({
            path: '/preventivo/set/id',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     */
    reportFE = (requestParameters: ReportFERequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<void>({
            path: '/reportFE',
            method: 'POST',
            headers,
            body: requestParameters.messageBody,
        });
    };

    /**
     */
    salvaRiconoscimentoPost = (requestParameters: SalvaRiconoscimentoPostRequest): Observable<SalvaRiconoscimentoOutput> => {
        throwIfRequired(requestParameters, 'token', 'salvaRiconoscimentoPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_TraceID && { 'X-B3-TraceID': String(requestParameters.X_B3_TraceID) }),
        };

        return this.request<SalvaRiconoscimentoOutput>({
            path: '/salvaRiconoscimento',
            method: 'POST',
            headers,
            body: requestParameters.input,
        });
    };

    /**
     */
    sendPec = (requestParameters: SendPecRequest): Observable<InvioPecOutput> => {
        throwIfRequired(requestParameters, 'input', 'sendPec');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<InvioPecOutput>({
            path: '/sendPec',
            method: 'POST',
            headers,
            body: requestParameters.input,
        });
    };

    /**
     */
    servizioPreflaggatoPost = (requestParameters: ServizioPreflaggatoPostRequest): Observable<ServizioPreflaggatoOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<ServizioPreflaggatoOutput>({
            path: '/servizioPreflaggato',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    startProcessDurables = (requestParameters: StartProcessDurablesRequest): Observable<StartProcessDurablesOutput> => {
        throwIfRequired(requestParameters, 'Input', 'startProcessDurables');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.session_id && { 'session-id': String(requestParameters.session_id) }),
        };

        return this.request<StartProcessDurablesOutput>({
            path: '/startProcesso',
            method: 'POST',
            headers,
            body: requestParameters.Input,
        });
    };

}
