import { HelperText } from "@material/react-text-field";
import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash.get";
import * as React from "react";
import { IDataAttributes } from "../../../caricamento/types/form";
import RadioField from "../radio_field/radio_field";
import "./boolean_radio.scss";

interface IOwnProps {
  falseLabel?: string;
  falseValue?: string;
  // Formik render prop
  formikField: FieldProps;
  helperText?: string;
  label?: string | React.ReactNode;
  trueLabel?: string;
  trueValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  serverSideError?: string;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
  tooltip?: string | React.ReactNode;
}

type Props = IOwnProps;

const BooleanRadio: React.FunctionComponent<Props> = ({
  falseLabel = "N",
  falseValue = "false",
  formikField,
  helperText,
  label,
  trueLabel = "S",
  trueValue = "true",
  onChange,
  onBlur,
  serverSideError,
  dataAttr,
  tooltip,
}) => {
  const isPristine = !get(formikField.form.touched, formikField.field.name);
  const isValid = !((!isPristine && get(formikField.form.errors, formikField.field.name)) || serverSideError);

  const classes = classNames("boolean-radio", "form__field", {
    "boolean-radio--invalid": !isValid,
  });

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Show validation message on change (first click)
    e.target.blur();

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={classes}>
      {label ? (
        <div className="row">
          <div className="col-xs-12">
            <p className="boolean-radio__label">
              {label} {tooltip ? tooltip : null}
            </p>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-xs-6">
          <RadioField
            dataAttr={dataAttr}
            label={trueLabel}
            formikField={formikField}
            value={trueValue}
            onBlur={onBlur}
            onChange={changeHandler}
          />
        </div>
        <div className="col-xs-6">
          <RadioField
            dataAttr={dataAttr}
            label={falseLabel}
            formikField={formikField}
            value={falseValue}
            onBlur={onBlur}
            onChange={changeHandler}
          />
        </div>
      </div>
      {serverSideError ? (
        <HelperText isValidationMessage={true} persistent={true} validation={true}>
          {serverSideError}
        </HelperText>
      ) : isPristine || isValid ? (
        helperText ? (
          <div className="row">
            <div className="col-xs-12">
              <HelperText isValidationMessage={false} persistent={true} validation={false}>
                {helperText}
              </HelperText>
            </div>
          </div>
        ) : null
      ) : (
        <HelperText isValidationMessage={true} persistent={true} validation={true}>
          {get(formikField.form.errors, formikField.field.name)}
        </HelperText>
      )}
    </div>
  );
};

export default React.memo(BooleanRadio);
