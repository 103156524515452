import produce from "immer";
import { NUM_PRATICA_KEY } from "../../../upload/config/application";
import { CODICE_FISCALE_KEY } from "../../config/application";
import { ID_PREVENTIVO_SESSION_KEY, SERVICE_GROUP_KEYS } from "../../constants/application";
import { PreventivoDatiCheckResponse } from "../../generated/e2e";
import { LoaderServiziOutputData } from "../../generated/loaderServizi";
import { PraticaConsumoOk, PreventivoOutput } from "../../generated/whiteLabel";
import { convertStringToNumber, formatCurrency, formatNumber } from "../../lib/utility/numbers";
import { SessionStorage } from "../../lib/utility/storage-v2";
import {
  GET_ACTIVE_INSURANCE,
  GET_ALL_SERVICES_CODE,
  GET_CUSTOMER_FAILED,
  GET_CUSTOMER_PENDING,
  GET_CUSTOMER_SUCCEED,
  GET_DATI_CHECK_FAILED,
  GET_DATI_CHECK_PENDING,
  GET_DATI_CHECK_SUCCEED,
  GET_ELEGIBILITA_INFO_DOCS_FAILED,
  GET_ELEGIBILITA_INFO_DOCS_PENDING,
  GET_ELEGIBILITA_INFO_DOCS_SUCCEED,
  GET_ELIGIBILITA_CHECK_FAILED,
  GET_ELIGIBILITA_CHECK_PENDING,
  GET_ELIGIBILITA_CHECK_SUCCEED,
  GET_ELIGIBILITA_FAILED,
  GET_ELIGIBILITA_PENDING,
  GET_ELIGIBILITA_SUCCEED,
  GET_PREVENTIVO_FAILED,
  GET_PREVENTIVO_PENDING,
  GET_PREVENTIVO_SECCI_FAILED,
  GET_PREVENTIVO_SECCI_PENDING,
  GET_PREVENTIVO_SECCI_SUCCEED,
  GET_PREVENTIVO_SUCCEED,
  GET_QUESTIONNAIRES_DOWNSELLING_FAILED,
  GET_QUESTIONNAIRES_DOWNSELLING_PENDING,
  GET_QUESTIONNAIRES_DOWNSELLING_SUCCEED,
  GET_SERVIZI_FAILED,
  GET_SERVIZI_PENDING,
  GET_SERVIZI_SUCCEED,
  INCOMPATIBILITY_MODAL_DISMISS,
  INCOMPATIBILITY_MODAL_DISMISS_ALL,
  INCOMPATIBILITY_MODAL_PROCEED,
  POST_PIANO_FINANZIARIO_FAILED,
  POST_PIANO_FINANZIARIO_PENDING,
  POST_PIANO_FINANZIARIO_SUCCEED,
  POST_PREVENTIVO_RICALCOLO_FAILED,
  POST_PREVENTIVO_RICALCOLO_PENDING,
  POST_PREVENTIVO_RICALCOLO_SUCCEED,
  PRATICA_RICHIESTA_MODIFICA_FAILED,
  RETURN_TO_PERSONAL_DATA_FAILED,
  RETURN_TO_PERSONAL_DATA_PENDING,
  RETURN_TO_PERSONAL_DATA_SUCCEED,
  SET_CODICE_FISCALE,
  SET_NUM_PRATICA,
  STATUS_ERROR,
  STATUS_GET_CUSTOMER,
  STATUS_GET_DOWNSELLING,
  STATUS_GET_ELIGIBILITA,
  STATUS_GET_ELIGIBILITA_CHECK,
  STATUS_GET_INFO_DOCS,
  STATUS_GET_PREVENTIVO_DATI_CHECK,
  STATUS_GET_SECCI,
  STATUS_POST_PIANO_FINANZIARIO,
  STATUS_POST_PREVENTIVO_RICALCOLO,
  STATUS_RETURN_ONBOARDING,
  STATUS_TO_SUCCESS,
  STATUS_WAITING,
  SWITCH_CL,
  UPLOAD_CONTINUE,
} from "./consts";
import { IState, TModalType, TServicesChanges } from "./types";
import { getServiceCodes, getServiceGroup } from "./utils";

const initialState: IState = {
  status: STATUS_WAITING,
  infoDocs: {
    data: undefined,
    loading: false,
  },
  preventivo: {
    data: undefined,
    loading: false,
    updatedAt: "",
  },
  servizi: {
    data: undefined,
    loading: false,
  },
  occupationalData: {
    data: undefined,
    loading: false,
  },
  downselling: {
    data: undefined,
    loading: false,
  },
  eligibilita: {
    data: undefined,
    loading: false,
  },
  eligibilitaCheck: {
    data: undefined,
    loading: false,
  },
  datiCheck: {
    data: undefined,
    loading: false,
  },
  secci: { data: undefined, loading: false },
  pianoFinanziario: {
    data: undefined,
    loading: false,
  },
  incompatibilityModal: {
    modalClChange: false,
    modalClRemove: false,
    modalProvinceVariation: false,
  },
  isRicalcoloSuccess: false,
  incompatible: {
    isIncompatible: false,
    message: "",
  },
  preventivoId: SessionStorage.read(ID_PREVENTIVO_SESSION_KEY) || "",
  numPratica: parseInt(SessionStorage.read(NUM_PRATICA_KEY)) || undefined,
  codiceFiscale: SessionStorage.read(CODICE_FISCALE_KEY) || "",
  servicesChanges: {
    data: undefined,
    loading: false,
    proceeding: false,
  },
};

export default produce((draft: IState, action) => {
  switch (action.type) {
    case GET_ACTIVE_INSURANCE: {
      draft.preventivo.data = action.payload;
      return;
    }

    case GET_ALL_SERVICES_CODE: {
      draft.servizi.data = action.payload.data;
      return;
    }

    /** GET CUSTOMER*/
    case GET_CUSTOMER_PENDING: {
      draft.occupationalData.loading = true;
      draft.status = STATUS_WAITING;
      return;
    }

    case GET_CUSTOMER_SUCCEED: {
      draft.occupationalData.loading = false;
      draft.status = STATUS_GET_CUSTOMER;
      draft.occupationalData.data = action.payload;
      return;
    }

    case GET_CUSTOMER_FAILED: {
      draft.status = STATUS_ERROR;
      return;
    }

    /** POST PRATICA RICHIESTA MODIFICA*/
    case PRATICA_RICHIESTA_MODIFICA_FAILED: {
      draft.status = STATUS_ERROR;
      return;
    }

    /** GET ELIGIBILITA*/
    case GET_ELIGIBILITA_PENDING: {
      draft.eligibilita.loading = true;
      draft.status = STATUS_WAITING;
      return;
    }
    case GET_ELIGIBILITA_SUCCEED: {
      draft.eligibilita.loading = false;
      draft.eligibilita.data = action.payload;
      draft.status = STATUS_GET_ELIGIBILITA;

      return;
    }

    case GET_ELIGIBILITA_FAILED: {
      draft.status = STATUS_ERROR;
      return;
    }

    /** GET ELIGIBILITA CHECK*/
    case GET_ELIGIBILITA_CHECK_PENDING: {
      draft.servicesChanges.loading = true;
      draft.eligibilitaCheck.loading = true;
      draft.status = STATUS_WAITING;
      return;
    }
    case GET_ELIGIBILITA_CHECK_SUCCEED: {
      //We must wait DATI_CHECK
      // draft.servicesChanges.loading = false;

      draft.eligibilitaCheck.data = action.payload;
      draft.status = STATUS_GET_ELIGIBILITA_CHECK;

      /**Eligibilita success true -> change CL */
      if (action.payload) {
        const activeInsurances = draft.preventivo.data?.assicurazioni; //active insurances on preventivo
        const allServices = draft.servizi.data?.servizi?.CO; //list of all available services
        /** Identify the first CREDIT LIFE insurance on preventivo */
        const clCode =
          activeInsurances && allServices && getServiceCodes(activeInsurances, allServices, SERVICE_GROUP_KEYS.CL)[0];
        if (clCode) {
          draft.incompatibilityModal.modalClChange = true;
          const eligibilitaCheck: TServicesChanges = {
            [clCode]: { toChange: true, toRecalculate: false, toRemove: false, serviceGroup: SERVICE_GROUP_KEYS.CL },
          };
          draft.servicesChanges.data = { ...draft.servicesChanges.data, ...eligibilitaCheck };
        }
      }
      draft.eligibilitaCheck.loading = false;

      return;
    }
    case GET_ELIGIBILITA_CHECK_FAILED: {
      draft.servicesChanges.loading = false;
      draft.eligibilitaCheck.loading = false;
      draft.status = STATUS_ERROR;
      return;
    }

    /** SWITCH CL CHANGE TO CL REMOVE */
    case SWITCH_CL: {
      const servicesChanges = draft.servicesChanges?.data;
      let serviceToSwitch: TServicesChanges = {};
      if (servicesChanges) {
        Object.keys(servicesChanges).forEach(item => {
          if (servicesChanges[item].serviceGroup === SERVICE_GROUP_KEYS.CL) {
            serviceToSwitch = {
              [item]: { toChange: false, toRecalculate: false, toRemove: true, serviceGroup: SERVICE_GROUP_KEYS.CL },
            };
          }
        });
        draft.servicesChanges.data = { ...draft.servicesChanges.data, ...serviceToSwitch };
      }
      return;
    }

    /** GET DATI CHECK*/
    case GET_DATI_CHECK_PENDING: {
      draft.servicesChanges.loading = true;
      draft.datiCheck.loading = true;
      draft.status = STATUS_WAITING;
      return;
    }
    case GET_DATI_CHECK_SUCCEED: {
      draft.status = STATUS_GET_PREVENTIVO_DATI_CHECK;
      draft.datiCheck.data = action.payload;

      const response: PreventivoDatiCheckResponse = action.payload;
      const variationsList = response.listaCheckAssicurazioni;
      const allServices = draft.servizi.data?.servizi?.CO; //list of all available services

      if (variationsList) {
        variationsList.map(service => {
          const serviceCode = service.codiceServizio;

          if (serviceCode) {
            const serviceGroup = allServices && getServiceGroup(serviceCode, allServices);

            /** Identify if we have some FI to recalculate -> Province Variation */
            if (serviceGroup === SERVICE_GROUP_KEYS.FI && service.daRicalcolare) {
              draft.incompatibilityModal.modalProvinceVariation = true;
            }

            /** Identify if we have some CL to remove (Close CL Change Modal) */
            if (serviceGroup === SERVICE_GROUP_KEYS.CL && service.daRimuovere) {
              draft.incompatibilityModal.modalClRemove = true;
              draft.incompatibilityModal.modalClChange = false;
            }

            const datiCheck: TServicesChanges = {
              [serviceCode]: {
                toChange: false,
                toRecalculate: service.daRicalcolare ? service.daRicalcolare : false,
                toRemove: service.daRimuovere ? service.daRimuovere : false,
                serviceGroup: serviceGroup && serviceGroup,
              },
            };

            draft.servicesChanges.data = { ...draft.servicesChanges.data, ...datiCheck };
          }
        });
      }

      draft.servicesChanges.loading = false;
      draft.datiCheck.loading = false;
      return;
    }
    case GET_DATI_CHECK_FAILED: {
      draft.servicesChanges.loading = false;
      draft.datiCheck.loading = false;

      draft.status = STATUS_ERROR;
      return;
    }

    case SET_NUM_PRATICA: {
      if (action.payload) {
        draft.numPratica = action.payload;
      }
      return;
    }

    case SET_CODICE_FISCALE: {
      if (action.payload) {
        draft.codiceFiscale = action.payload;
      }
      return;
    }

    case INCOMPATIBILITY_MODAL_PROCEED: {
      const modalType = action.payload as TModalType;
      draft.incompatibilityModal[modalType] = false;
      draft.servicesChanges.proceeding = true;
      return;
    }

    case INCOMPATIBILITY_MODAL_DISMISS: {
      const modalType = action.payload as TModalType;
      draft.incompatibilityModal[modalType] = false;
      draft.servicesChanges.proceeding = false;
      return;
    }

    case INCOMPATIBILITY_MODAL_DISMISS_ALL: {
      draft.incompatibilityModal = initialState.incompatibilityModal;
      draft.servicesChanges.proceeding = false;
      return;
    }

    case GET_PREVENTIVO_SUCCEED: {
      const result: PreventivoOutput = { ...action.payload };

      result.lordo = result.lordo && formatCurrency(convertStringToNumber(result.lordo));
      result.importo_dovuto = result.importo_dovuto && formatCurrency(convertStringToNumber(result.importo_dovuto));
      result.importo_rata = result.importo_rata && formatCurrency(convertStringToNumber(result.importo_rata));
      result.tan = result.tan && formatNumber(convertStringToNumber(result.tan), { mantissa: 3 });
      result.taeg = result.taeg && formatNumber(convertStringToNumber(result.taeg));

      draft.preventivo.data = result;
      draft.preventivo.loading = false;
      return;
    }

    case GET_PREVENTIVO_PENDING: {
      draft.preventivo.loading = true;
      return;
    }

    case GET_PREVENTIVO_FAILED: {
      draft.preventivo.loading = false;
      return;
    }

    /** GET SERVIZI (INSURANCE LIST WITH DESCRIPTIONS) */
    case GET_SERVIZI_PENDING: {
      draft.servizi.loading = true;
      return;
    }
    case GET_SERVIZI_SUCCEED: {
      draft.servizi.data = action.payload.data as LoaderServiziOutputData;
      draft.servizi.loading = false;
      return;
    }
    case GET_SERVIZI_FAILED: {
      draft.servizi.loading = false;
      draft.status = STATUS_ERROR;
      return;
    }

    /**getEligibilitaInfoDocs */
    case GET_ELEGIBILITA_INFO_DOCS_PENDING: {
      draft.infoDocs.loading = true;
      draft.status = STATUS_WAITING;
      return;
    }
    case GET_ELEGIBILITA_INFO_DOCS_SUCCEED: {
      draft.infoDocs.loading = false;
      draft.status = STATUS_GET_INFO_DOCS;
      draft.servicesChanges.proceeding = false;
      return;
    }
    case GET_ELEGIBILITA_INFO_DOCS_FAILED: {
      draft.infoDocs.loading = false;
      draft.status = STATUS_ERROR;
      return;
    }

    /** GET DOWNSELLING (INSURANCE LIST TO REMOVE)*/
    case GET_QUESTIONNAIRES_DOWNSELLING_PENDING: {
      draft.downselling.loading = true;
      return;
    }
    case GET_QUESTIONNAIRES_DOWNSELLING_SUCCEED: {
      draft.downselling.loading = false;
      draft.downselling.data = action.payload.listaServizi;
      draft.status = STATUS_GET_DOWNSELLING;
      return;
    }
    case GET_QUESTIONNAIRES_DOWNSELLING_FAILED: {
      draft.downselling.loading = false;
      draft.status = STATUS_ERROR;
      return;
    }

    /** POST PREVENTIVO RICALCOLO (RECALCULATION OF THE FINANCIAL PLAN)*/
    case POST_PREVENTIVO_RICALCOLO_PENDING: {
      draft.preventivo.loading = true;
      return;
    }
    case POST_PREVENTIVO_RICALCOLO_SUCCEED: {
      const result: PreventivoOutput = { ...action.payload };
      result.lordo = result.lordo && formatCurrency(convertStringToNumber(result.lordo));
      result.importo_dovuto = result.importo_dovuto && formatCurrency(convertStringToNumber(result.importo_dovuto));
      result.importo_rata = result.importo_rata && formatCurrency(convertStringToNumber(result.importo_rata));
      result.tan = result.tan && formatNumber(convertStringToNumber(result.tan), { mantissa: 3 });
      result.taeg = result.taeg && formatNumber(convertStringToNumber(result.taeg));

      draft.preventivo.data = result;
      draft.preventivoId = result.numero_preventivo?.toString();
      draft.preventivo.loading = false;
      draft.status = STATUS_POST_PREVENTIVO_RICALCOLO;
      draft.isRicalcoloSuccess = true;
      return;
    }
    case POST_PREVENTIVO_RICALCOLO_FAILED: {
      draft.status = STATUS_ERROR;
      draft.preventivo.loading = false;
      return;
    }

    /** GET SECCI (PREVENTIVO) */
    case GET_PREVENTIVO_SECCI_PENDING: {
      draft.secci.loading = true;
      return;
    }
    case GET_PREVENTIVO_SECCI_SUCCEED: {
      draft.secci.loading = false;
      draft.status = STATUS_GET_SECCI;
      return;
    }
    case GET_PREVENTIVO_SECCI_FAILED: {
      draft.status = STATUS_ERROR;
      draft.secci.loading = false;
      return;
    }

    /** POST PIANO FINANZIARIO*/
    case POST_PIANO_FINANZIARIO_PENDING: {
      draft.pianoFinanziario.loading = true;
      return;
    }
    case POST_PIANO_FINANZIARIO_SUCCEED: {
      const result: PraticaConsumoOk = { ...action.payload };
      draft.pianoFinanziario.data = result;
      draft.pianoFinanziario.loading = false;
      draft.status = STATUS_POST_PIANO_FINANZIARIO;
      return;
    }
    case POST_PIANO_FINANZIARIO_FAILED: {
      draft.status = STATUS_ERROR;
      draft.pianoFinanziario.loading = false;
      return;
    }

    /** POST PIANO FINANZIARIO*/
    case RETURN_TO_PERSONAL_DATA_PENDING: {
      draft.status = STATUS_WAITING;
      return;
    }
    case RETURN_TO_PERSONAL_DATA_SUCCEED: {
      draft.status = STATUS_RETURN_ONBOARDING;
      return;
    }
    case RETURN_TO_PERSONAL_DATA_FAILED: {
      draft.status = STATUS_ERROR;
      return;
    }

    /** UPLOAD CONTINUE */
    case UPLOAD_CONTINUE: {
      draft.status = STATUS_TO_SUCCESS;
      return;
    }
  }
}, initialState);
