// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface NuovaControparteDataAvc
 */
export interface NuovaControparteDataAvc {
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    scoporapporto?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    pep?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    statoattivita?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    provinciaattivita?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    selezioneattivitaprecedente?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    attivitaprecedente?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    occupazioneprecedente?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    redditisecondari?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    fontisecondari?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    altrebanche?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    microimpresa?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataAvc
     */
    fasciareddito?: string;
}
