// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    SendMailInputHeaders,
    SendMailInputRecipient,
} from './';

/**
 * 
 * @export
 * @interface SendMailInput
 */
export interface SendMailInput {
    /**
     * @type {SendMailInputHeaders}
     * @memberof SendMailInput
     */
    headers?: SendMailInputHeaders;
    /**
     * @type {SendMailInputRecipient}
     * @memberof SendMailInput
     */
    recipient?: SendMailInputRecipient;
}
