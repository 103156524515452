// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PraticaConsumoContropartiCoobbligato,
    PraticaConsumoContropartiGarante1,
    PraticaConsumoContropartiGarante2,
} from './';

/**
 * @export
 * @interface PraticaConsumoControparti
 */
export interface PraticaConsumoControparti {
    /**
     * @type {PraticaConsumoContropartiCoobbligato}
     * @memberof PraticaConsumoControparti
     */
    coobbligato?: PraticaConsumoContropartiCoobbligato;
    /**
     * @type {PraticaConsumoContropartiGarante1}
     * @memberof PraticaConsumoControparti
     */
    garante1?: PraticaConsumoContropartiGarante1;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    garante2?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    garante3?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    esponente1?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    esponente2?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    esponente3?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    titolareeffettivo1?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    titolareeffettivo2?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante2}
     * @memberof PraticaConsumoControparti
     */
    titolareeffettivo3?: PraticaConsumoContropartiGarante2;
    /**
     * @type {PraticaConsumoContropartiGarante1}
     * @memberof PraticaConsumoControparti
     */
    titolareeffettivo4?: PraticaConsumoContropartiGarante1;
}
