// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetFunzionalitaOutputPreventivoDomande,
} from './';

/**
 * @export
 * @interface GetFunzionalitaOutputPreventivoAssicurazioni
 */
export interface GetFunzionalitaOutputPreventivoAssicurazioni {
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoAssicurazioni
     */
    descrizione?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoAssicurazioni
     */
    codice?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoAssicurazioni
     */
    pacchetto?: string;
    /**
     * @type {GetFunzionalitaOutputPreventivoDomande}
     * @memberof GetFunzionalitaOutputPreventivoAssicurazioni
     */
    domande?: GetFunzionalitaOutputPreventivoDomande;
    /**
     * @type {Array<number>}
     * @memberof GetFunzionalitaOutputPreventivoAssicurazioni
     */
    durate?: Array<number>;
}
