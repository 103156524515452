import { warn } from "../log";

/**
 * Safe localStorage and sessinStorage
 * use it instead of native objects:
 * - check storage existence
 * - stringify values
 * - re-hydrate values
 * - catch errors
 *
 * Caveats:
 * - accepts only JSON, string, number, boolean, null, undefined and JSON.serializable objects
 */

// Any is ok as in JSON.stringify's type. This refers to ANY JSON-serializable entity
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IStorageWrite = (key: string, value: any) => void;

// Any is ok as in JSON.stringify's type. This refers to ANY JSON-serializable entity
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IStorageRead = (key: string) => any;

type IStorageRemove = (key: string) => void;

type IStorageClear = () => void;

export interface IStorage {
  write: IStorageWrite;
  read: IStorageRead;
  remove: IStorageRemove;
  clear: IStorageClear;
}

const STORAGE_NOT_AVAILABLE = "Storage not available.";

const write = (storage?: Storage): IStorageWrite => (key, value) => {
  if (!storage) {
    console.warn(STORAGE_NOT_AVAILABLE);
    return;
  }
  try {
    const _value = JSON.stringify(value);
    storage.setItem(key, _value);
  } catch {
    warn(`Can't write in storage`);
  }
};

const read = (storage?: Storage): IStorageRead => (key: string) => {
  if (!storage) {
    console.warn(STORAGE_NOT_AVAILABLE);
    return null;
  }
  const value = storage.getItem(key);
  try {
    if (value) {
      return JSON.parse(value);
    }
    return value;
  } catch {
    return value;
  }
};

const remove = (storage: Storage): IStorageRemove => key => {
  if (!storage) {
    console.warn(STORAGE_NOT_AVAILABLE);
    return;
  }
  storage.removeItem(key);
};

const clear = (storage: Storage): IStorageClear => () => {
  if (!storage) {
    console.warn(STORAGE_NOT_AVAILABLE);
    return;
  }
  storage.clear();
};

export const LocalStorage: IStorage = {
  write: write(window.localStorage),
  read: read(window.localStorage),
  remove: remove(window.localStorage),
  clear: clear(window.localStorage),
};

export const SessionStorage = {
  write: write(window.sessionStorage),
  read: read(window.sessionStorage),
  remove: remove(window.sessionStorage),
  clear: clear(window.sessionStorage),
};
