// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface RataVariabile
 */
export interface RataVariabile {
    /**
     * @type {number}
     * @memberof RataVariabile
     */
    capitaleResiduo: number;
    /**
     * @type {number}
     * @memberof RataVariabile
     */
    importoRata: number;
    /**
     * @type {number}
     * @memberof RataVariabile
     */
    rataA: number;
    /**
     * @type {number}
     * @memberof RataVariabile
     */
    rataDa: number;
    /**
     * @type {number}
     * @memberof RataVariabile
     */
    taeg: number;
    /**
     * @type {number}
     * @memberof RataVariabile
     */
    tan: number;
}
