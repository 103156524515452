// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AbstractPreventivoInput,
    AssicurazioneLeasing,
    MisuraGovernativa,
    PreventivoLeasingInputAllOf,
    PreventivoLeasingInputAllOfPersonalizzazioni,
    TipologiaAnagrafica,
    TipologiaImportoFirma,
    TipologiaVeicolo,
    User,
} from './';

/**
 * Modello di input per il calcolo di un preventivo Leasing
 * @export
 * @interface PreventivoLeasingInput
 */
export interface PreventivoLeasingInput {
    /**
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    codiceInternet?: string;
    /**
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    intermediario: string;
    /**
     * codice del bene
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    codiceBene: string;
    /**
     * codice del prodotto
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    codiceProdotto: string;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    commissioni: number;
    /**
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    descrizioneFinanziamento: string;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    durata: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    finanziato: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    prezzoVendita: number;
    /**
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    tabellaFinanziaria: string;
    /**
     * codice del tipo di bene
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    tipoBene?: number;
    /**
     * codice della marca del veicolo
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    marca?: string;
    /**
     * codice del modello di veicolo
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    modello?: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    eurotax: string;
    /**
     * percentule dell\'importo di acconto (fino a 3 cifre decimali)
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    acconto: number;
    /**
     * @type {boolean}
     * @memberof PreventivoLeasingInput
     */
    buyback?: boolean;
    /**
     * @type {TipologiaVeicolo}
     * @memberof PreventivoLeasingInput
     */
    tipologiaVeicolo: TipologiaVeicolo;
    /**
     * @type {boolean}
     * @memberof PreventivoLeasingInput
     */
    veicoloCommerciale?: boolean;
    /**
     * @type {Date}
     * @memberof PreventivoLeasingInput
     */
    dataImmatricolazione: Date;
    /**
     * @type {TipologiaAnagrafica}
     * @memberof PreventivoLeasingInput
     */
    tipoAnagrafica: TipologiaAnagrafica;
    /**
     * calcolata in base al flag over64
     * @type {Date}
     * @memberof PreventivoLeasingInput
     */
    dataNascita: Date;
    /**
     * @type {string}
     * @memberof PreventivoLeasingInput
     */
    provincia: string;
    /**
     * @type {Array<AssicurazioneLeasing>}
     * @memberof PreventivoLeasingInput
     */
    assicurazioni: Array<AssicurazioneLeasing>;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    chilometriIniziali?: number;
    /**
     * valore dei km di riscatto espresso in migliaia
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    chilometraggio?: number;
    /**
     * recuperato dal servizio /preventivo/cassandra
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    percentualeRiscatto?: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingInput
     */
    quotaEsente?: number;
    /**
     * @type {MisuraGovernativa}
     * @memberof PreventivoLeasingInput
     */
    misuraGovernativa?: MisuraGovernativa;
    /**
     * @type {TipologiaImportoFirma}
     * @memberof PreventivoLeasingInput
     */
    tipologiaImportoFirma?: TipologiaImportoFirma;
    /**
     * @type {PreventivoLeasingInputAllOfPersonalizzazioni}
     * @memberof PreventivoLeasingInput
     */
    personalizzazioni?: PreventivoLeasingInputAllOfPersonalizzazioni;
}
