// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    StartProcessDurablesInputControparte,
    StartProcessDurablesInputDatiVeicolo,
    StartProcessDurablesInputPreventivo,
    TipoPianoFinanziarioEnum,
} from './';

/**
 * 
 * @export
 * @interface StartProcessDurablesInput
 */
export interface StartProcessDurablesInput {
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    orderId?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    exitUrl?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    landingPageUrl?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    productImageUrl?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    provenienza?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    prodotto?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    tipoProdotto?: string;
    /**
     * @type {string}
     * @memberof StartProcessDurablesInput
     */
    canale?: string;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInput
     */
    processo?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInput
     */
    intermediario?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInput
     */
    numeroPreventivo?: number;
    /**
     * @type {number}
     * @memberof StartProcessDurablesInput
     */
    pratica?: number;
    /**
     * @type {TipoPianoFinanziarioEnum}
     * @memberof StartProcessDurablesInput
     */
    tipoPianoFinanziario?: TipoPianoFinanziarioEnum;
    /**
     * @type {StartProcessDurablesInputPreventivo}
     * @memberof StartProcessDurablesInput
     */
    preventivo?: StartProcessDurablesInputPreventivo;
    /**
     * @type {StartProcessDurablesInputControparte}
     * @memberof StartProcessDurablesInput
     */
    controparte?: StartProcessDurablesInputControparte;
    /**
     * @type {StartProcessDurablesInputDatiVeicolo}
     * @memberof StartProcessDurablesInput
     */
    datiVeicolo?: StartProcessDurablesInputDatiVeicolo;
}
