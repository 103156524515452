// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
 */
export interface ListaStradeOutputWpLstZipResultZIPAREAOUTZIP {
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJCNY?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJCNY?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJSTA?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJSTA?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJREG?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJREG?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJDPT?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJDPT?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJCNL?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJCNL?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJDS1?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJDS1?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJDS2?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJDS2?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJDS3?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJDS3?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDPOBJSTR?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJSTR?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    DSXOBJLPT?: string;
    /**
     * @type {string}
     * @memberof ListaStradeOutputWpLstZipResultZIPAREAOUTZIP
     */
    CDXZIP?: string;
}
