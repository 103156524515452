// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    CancellaPromoInput,
    PreventivoAccessoriResponse,
} from '../models';

export interface PreventivoAccessoriGetRequest {
    X_B3_TraceId?: string;
}

export interface PreventivoPromoDeleteRequest {
    X_B3_TraceId?: string;
    body?: CancellaPromoInput;
}

/**
 * no description
 */
export class NAApi extends BaseAPI {

    /**
     */
    preventivoAccessoriGet = (requestParameters: PreventivoAccessoriGetRequest): Observable<PreventivoAccessoriResponse> => {

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PreventivoAccessoriResponse>({
            path: '/preventivo/accessori',
            method: 'GET',
            headers,
        });
    };

    /**
     * Cancella una promozione se esistente
     */
    preventivoPromoDelete = (requestParameters: PreventivoPromoDeleteRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/promo',
            method: 'DELETE',
            headers,
            body: requestParameters.body,
        });
    };

}
