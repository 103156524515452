// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetFunzionalitaOutputCaricamento,
    GetFunzionalitaOutputDocumenti,
    GetFunzionalitaOutputFirma,
    GetFunzionalitaOutputPagamento,
    GetFunzionalitaOutputPreventivo,
    GetFunzionalitaOutputRiconoscimento,
} from './';

/**
 * 
 * @export
 * @interface GetFunzionalitaOutput
 */
export interface GetFunzionalitaOutput {
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutput
     */
    exitUrl?: string;
    /**
     * @type {GetFunzionalitaOutputPreventivo}
     * @memberof GetFunzionalitaOutput
     */
    preventivo?: GetFunzionalitaOutputPreventivo;
    /**
     * @type {GetFunzionalitaOutputRiconoscimento}
     * @memberof GetFunzionalitaOutput
     */
    riconoscimento?: GetFunzionalitaOutputRiconoscimento;
    /**
     * @type {GetFunzionalitaOutputCaricamento}
     * @memberof GetFunzionalitaOutput
     */
    caricamento?: GetFunzionalitaOutputCaricamento;
    /**
     * @type {GetFunzionalitaOutputFirma}
     * @memberof GetFunzionalitaOutput
     */
    firma?: GetFunzionalitaOutputFirma;
    /**
     * @type {GetFunzionalitaOutputDocumenti}
     * @memberof GetFunzionalitaOutput
     */
    documenti?: GetFunzionalitaOutputDocumenti;
    /**
     * @type {GetFunzionalitaOutputPagamento}
     * @memberof GetFunzionalitaOutput
     */
    pagamento?: GetFunzionalitaOutputPagamento;
}
