// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroRGS010LOCNAS,
} from './';

/**
 * @export
 * @interface WpNormInputParametroRGS010
 */
export interface WpNormInputParametroRGS010 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    CDXISO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    TPXANA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    FLXSEX?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    DSXFRENAM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    DSXMIDNAM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    DSXNOM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    DSXTITACC?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    DSXPRENOM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    DATNAS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010
     */
    TPXCDK?: string;
    /**
     * @type {WpNormInputParametroRGS010LOCNAS}
     * @memberof WpNormInputParametroRGS010
     */
    LOCNAS?: WpNormInputParametroRGS010LOCNAS;
}
