import it from "date-fns/locale/it";
import "moment/locale/it";
import React, { FunctionComponent } from "react";
import { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { CSS_ROOT } from "../shared/config/constants";
import momentInstance from "../shared/moment";
import "./app.scss";
import { BASE_PATH } from "./config/constants";
import Routes from "./routes";

registerLocale("it", it);
momentInstance.locale("it");

const App: FunctionComponent = () => {
  return (
    <div className="app" id={CSS_ROOT}>
      <Router basename={BASE_PATH}>
        <Routes />
      </Router>
    </div>
  );
};

// const mapStateToProps = (state: IStoreState) => ({
//   authentication: state.authentication,
// });

//const mapDispatchToProps = {
//  myDispatcher
//};

export default React.memo(connect()(App));
