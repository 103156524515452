// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    StatoPraticheOutputListaPratiche,
} from './';

/**
 * 
 * @export
 * @interface StatoPraticheOutput
 */
export interface StatoPraticheOutput {
    /**
     * @type {Array<StatoPraticheOutputListaPratiche>}
     * @memberof StatoPraticheOutput
     */
    listaPratiche?: Array<StatoPraticheOutputListaPratiche>;
}
