// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    ErrorObject,
    GetListaCodiceServizi,
    PostPianofinanziarioInput,
    PostPreventivoRicalcolo,
    PraticaConsumoKo,
    PraticaConsumoOk,
    PreventivoOutput,
} from '../models';

export interface PraticaPianoFinanziarioRequest {
    praticaPianofinanziarioInput: PostPianofinanziarioInput;
    Organization?: string;
}

export interface PraticaQuestionarioAdeguatezzaDownsellingGetRequest {
    numeroPratica: number;
    provenienza: PraticaQuestionarioAdeguatezzaDownsellingGetProvenienzaEnum;
    X_B3_TraceId?: string;
    tipoControparte?: PraticaQuestionarioAdeguatezzaDownsellingGetTipoControparteEnum;
    livelloQuestionario?: PraticaQuestionarioAdeguatezzaDownsellingGetLivelloQuestionarioEnum;
}

export interface PreventivoRicalcoloPostRequest {
    X_B3_TraceId?: string;
    body?: PostPreventivoRicalcolo;
}

/**
 * no description
 */
export class SPAModificaPraticaApi extends BaseAPI {

    /**
     * Modifica il piano finanziario di una pratica
     */
    praticaPianoFinanziario = (requestParameters: PraticaPianoFinanziarioRequest): Observable<PraticaConsumoOk> => {
        throwIfRequired(requestParameters, 'praticaPianofinanziarioInput', 'praticaPianoFinanziario');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.Organization && { 'Organization': String(requestParameters.Organization) }),
        };

        return this.request<PraticaConsumoOk>({
            path: '/pratica/pianofinanziario',
            method: 'POST',
            headers,
            body: requestParameters.praticaPianofinanziarioInput,
        });
    };

    /**
     * Restituisce quali servizi possono essere rimossi dalla pratica in base al livello del questionario
     */
    praticaQuestionarioAdeguatezzaDownsellingGet = (requestParameters: PraticaQuestionarioAdeguatezzaDownsellingGetRequest): Observable<GetListaCodiceServizi> => {
        throwIfRequired(requestParameters, 'numeroPratica', 'praticaQuestionarioAdeguatezzaDownsellingGet');
        throwIfRequired(requestParameters, 'provenienza', 'praticaQuestionarioAdeguatezzaDownsellingGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.numeroPratica && { 'numeroPratica': requestParameters.numeroPratica }),
            ...(requestParameters.provenienza && { 'provenienza': requestParameters.provenienza }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': requestParameters.tipoControparte }),
            ...(requestParameters.livelloQuestionario && { 'livelloQuestionario': requestParameters.livelloQuestionario }),
        };

        return this.request<GetListaCodiceServizi>({
            path: '/pratica/questionarioAdeguatezza/downselling',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Restituisce un preventivo senza il servizi passati in input partendo da un preventivo già esistente
     */
    preventivoRicalcoloPost = (requestParameters: PreventivoRicalcoloPostRequest): Observable<PreventivoOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PreventivoOutput>({
            path: '/preventivo/ricalcolo',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum PraticaQuestionarioAdeguatezzaDownsellingGetProvenienzaEnum {
    CO = 'CO',
    LE = 'LE',
    CA = 'CA'
}
/**
 * @export
 * @enum {string}
 */
export enum PraticaQuestionarioAdeguatezzaDownsellingGetTipoControparteEnum {
    CL = 'CL',
    CO = 'CO',
    G1 = 'G1',
    G2 = 'G2',
    G3 = 'G3',
    E1 = 'E1',
    E2 = 'E2',
    E3 = 'E3'
}
/**
 * @export
 * @enum {string}
 */
export enum PraticaQuestionarioAdeguatezzaDownsellingGetLivelloQuestionarioEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3'
}
