export * from './AuthRequestWithCodiceFiscale';
export * from './AuthRequestWithToken';
export * from './AuthResponse';
export * from './CheckPagamentoInput';
export * from './CheckPagamentoOutput';
export * from './CheckPagamentoOutputData';
export * from './ClienteRifiutoInput';
export * from './ClienteRifiutoOutput';
export * from './Controparte';
export * from './ControparteData';
export * from './ContropartePagamento';
export * from './ContropartePartnerOutput';
export * from './ControparteRifiutoOutput';
export * from './DatiVeicoloInput';
export * from './DatiVeicoloOutput';
export * from './DetrazioneFiscale';
export * from './DocumentoPraticaItem';
export * from './DomandaDependency';
export * from './DomandaDescriptor';
export * from './EnumSemanticaRisposta';
export * from './EnumTipoControparte';
export * from './Error';
export * from './ErrorObject';
export * from './ErrorOutputKO';
export * from './ErrorOutputKOErrori';
export * from './ErrorOutputKOErroriErrori';
export * from './ErrorOutputKOErroriErroriPianofinanziario';
export * from './ErrorOutputOK';
export * from './ErrorSalvaPraticaTessera';
export * from './Errori';
export * from './Errors';
export * from './EsitoRichiestaModifica';
export * from './GetFunzionalitaOutput';
export * from './GetFunzionalitaOutputCaricamento';
export * from './GetFunzionalitaOutputCaricamentoDatiPersonali';
export * from './GetFunzionalitaOutputDocumenti';
export * from './GetFunzionalitaOutputFirma';
export * from './GetFunzionalitaOutputPagamento';
export * from './GetFunzionalitaOutputPreventivo';
export * from './GetFunzionalitaOutputPreventivoAcconto';
export * from './GetFunzionalitaOutputPreventivoAssicurazioni';
export * from './GetFunzionalitaOutputPreventivoDomande';
export * from './GetFunzionalitaOutputPreventivoListaPianiFinanziari';
export * from './GetFunzionalitaOutputPreventivoMarcaModelloEurotax';
export * from './GetFunzionalitaOutputRiconoscimento';
export * from './GetFunzionalitaOutputRiconoscimentoModalitaRiconoscimento';
export * from './GetListaCodiceServizi';
export * from './GetOTPCodeOutput';
export * from './GetQuestionarioAdeguatezza';
export * from './InputPraticaExitUrl';
export * from './InvioPecInput';
export * from './InvioPecInputAttachments';
export * from './InvioPecOutput';
export * from './ListaQuestionari';
export * from './LoaderCommissioniInput';
export * from './LoaderCommissioniOutput';
export * from './LoaderCommissioniOutputData';
export * from './NextStepInput';
export * from './NextStepOutput';
export * from './NoteScriviInput';
export * from './NucleoFamigliare';
export * from './NuovaControparte';
export * from './NuovaControparteControparti';
export * from './NuovaControparteContropartiCoobbligato';
export * from './NuovaControparteContropartiCoobbligatoResidenza';
export * from './NuovaControparteContropartiGarante1';
export * from './NuovaControparteContropartiGarante1Avc';
export * from './NuovaControparteContropartiGarante2';
export * from './NuovaControparteData';
export * from './NuovaControparteDataAvc';
export * from './NuovaControparteDataCciaa';
export * from './NuovaControparteDataDocumentoidentita';
export * from './NuovaControparteDataIndirizzoalternativo';
export * from './NuovaControparteDataNascita';
export * from './NuovaControparteDataOccupazione';
export * from './NuovaControparteDataOccupazioneDatore';
export * from './NuovaControparteDataPrivacy';
export * from './NuovaControparteDataResidenza';
export * from './NuovaControparteDetrazioni';
export * from './NuovaControparteDetrazioniDestinatari';
export * from './NuovaControparteDetrazioniFattura';
export * from './NuovaContropartePagamento';
export * from './NuovaContropartePagamentoNote';
export * from './OfflineOutput';
export * from './OfflineOutputOfflineResponseSchedule';
export * from './OtpOutput';
export * from './OutputPraticaExitUrl';
export * from './PagamentiWLInput';
export * from './PagamentiWLOutput';
export * from './PagamentoInput';
export * from './Partner';
export * from './PostPianofinanziarioInput';
export * from './PostPreventivoRicalcolo';
export * from './PostQuestionarioAdeguatezza';
export * from './PostQuestionarioAdeguatezzaOutput';
export * from './PostResponse';
export * from './PraticaConsumo';
export * from './PraticaConsumoCliente';
export * from './PraticaConsumoControparti';
export * from './PraticaConsumoContropartiCoobbligato';
export * from './PraticaConsumoContropartiGarante1';
export * from './PraticaConsumoContropartiGarante2';
export * from './PraticaConsumoKo';
export * from './PraticaConsumoOk';
export * from './PraticaConsumoOkData';
export * from './PraticaConsumoOkDataControparti';
export * from './PraticaConsumoOkDataErrori';
export * from './PraticaConsumoOkDataErroriServizi';
export * from './PraticaContribuzioneStatiInput';
export * from './PraticaContribuzioneStatiOutput';
export * from './PraticaContribuzioneStatiOutputProcessData';
export * from './PraticaContribuzioneStatiOutputProcessDataParameters';
export * from './PraticaCounterInput';
export * from './PraticaCounterOutput';
export * from './PraticaDocumentiOK';
export * from './PraticaNextStepInput';
export * from './PraticaNextStepOutput';
export * from './PraticaPianofinanziarioInput';
export * from './PraticaQuestionarioAdeguatezzaDownsellingOutput';
export * from './PraticaRestartInput';
export * from './PraticaRestartOutput';
export * from './PraticaRestartProcessWLOutput';
export * from './PraticaSalvaTesseraSanitariaInput';
export * from './PraticaSalvaTesseraSanitariaInputTesseraSanitaria';
export * from './PraticaSalvaTesseraSanitariaOutput';
export * from './PreventivoIdSetInput';
export * from './PreventivoOutput';
export * from './PreventivoOutputAssicurazioni';
export * from './PreventivoOutputNoteLegali';
export * from './PreventivoRicalcolo';
export * from './PreventivoRicalcoloListaServizi';
export * from './PreventivoRicalcoloOutput';
export * from './QuestionarioAdeguatezzaRequest';
export * from './QuestionarioAdeguatezzaRequestAllOf';
export * from './QuestionarioDescriptor';
export * from './QuestionarioPreference';
export * from './RecuperaSessioneOk';
export * from './ReportFEInput';
export * from './ReportFEOutput';
export * from './RiavviaOutput';
export * from './RiavviaSessioneInput';
export * from './RichiestaModifica';
export * from './RiconocimentoVoucherPostInput';
export * from './RiconocimentoVoucherPostOutput';
export * from './RiconoscimentoVoucherGetOutput';
export * from './RispostaDescriptor';
export * from './RispostaPreference';
export * from './SalvaRiconoscimentoInput';
export * from './SalvaRiconoscimentoOutput';
export * from './SendMailInput';
export * from './SendMailInputHeaders';
export * from './SendMailInputRecipient';
export * from './SendMailInputRecipientDestination';
export * from './SendMailInputRecipientDestinationTo';
export * from './SendMailInputRecipientFrom';
export * from './SendMailInputRecipientMessage';
export * from './SendMailInputRecipientMessageAttachments';
export * from './SendMailOutput';
export * from './SendMailOutputErrorlist';
export * from './SendMailOutputErrorlistErrors';
export * from './SendSMSInput';
export * from './SendSMSInputContent';
export * from './SendSMSInputDestination';
export * from './SendSMSInputDestinationTo';
export * from './SendSMSOutput';
export * from './ServizioPreflaggatoInput';
export * from './ServizioPreflaggatoOutput';
export * from './StartProcessDurablesInput';
export * from './StartProcessDurablesInputControparte';
export * from './StartProcessDurablesInputControparteNucleoFamigliare';
export * from './StartProcessDurablesInputDatiVeicolo';
export * from './StartProcessDurablesInputPreventivo';
export * from './StartProcessDurablesInputPreventivoAssicurazioni';
export * from './StartProcessDurablesInputPreventivoMisuraGovernativa';
export * from './StartProcessDurablesInputPreventivoPersonalizzazioni';
export * from './StartProcessDurablesInputPreventivoPersonalizzazioniObjDatiStorici';
export * from './StartProcessDurablesOutput';
export * from './StatoPraticheInput';
export * from './StatoPraticheOutput';
export * from './StatoPraticheOutputListaPratiche';
export * from './TipoControparte';
export * from './TipoControparteEnum';
export * from './TipoPianoFinanziarioEnum';
export * from './TipoProdottoEnum';
export * from './TipoRiconoscimentoEnum';
export * from './TokenCreaOutput';
