// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    CancellaPromoInput,
    Informativa,
    PreventivoAgevolazione,
    PreventivoConsumo,
    PreventivoLeasing,
    PreventivoListaResponse,
    PreventivoSalvaRequest,
    PreventivoSecciResponse,
    SalvaAllestimentoInput,
    SalvaDescrizioneBeneInput,
    SalvaPromoInput,
    Secci,
    Success,
} from '../models';

export interface GetInformativeRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetListaRequest {
    intermediario: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    pagina?: number;
    dimensionePagina?: number;
    ordinamentoCrescente?: boolean;
    filtroRicerca?: string;
}

export interface GetPreventivoCORequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetPreventivoLERequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetPreventivoPromoRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetSecciRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetSecciCheckRequest {
    numeroPreventivo: number;
    X_B3_TraceId?: string;
}

export interface PostPreventivoPromoRequest {
    X_B3_TraceId?: string;
    body?: SalvaPromoInput;
}

export interface PraticaInformativeGetRequest {
    intermediario: string;
    pratica: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoPromoDeleteRequest {
    X_B3_TraceId?: string;
    body?: CancellaPromoInput;
}

export interface PreventivoSalvaAllestimentoRequest {
    X_B3_TraceId?: string;
    body?: SalvaAllestimentoInput;
}

export interface PreventivoSalvaDescrizioneBeneRequest {
    X_B3_TraceId?: string;
    body?: SalvaDescrizioneBeneInput;
}

export interface SalvaPreventivoRequest {
    X_B3_TraceId?: string;
    body?: PreventivoSalvaRequest;
}

/**
 * no description
 */
export class ProcessApi extends BaseAPI {

    /**
     * Logiche: <br/> <ol> <li> esegue chiamata a GET /preventivo/adeguatezza per recuperare la tipologia di fascicolo <li> esegue stampa informative </ol>
     * Stampa informative preventivo
     */
    getInformative = (requestParameters: GetInformativeRequest): Observable<Informativa> => {
        throwIfRequired(requestParameters, 'intermediario', 'getInformative');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getInformative');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<Informativa>({
            path: '/preventivo/informative',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Ritorna la lista dei preventivi
     */
    getLista = (requestParameters: GetListaRequest): Observable<PreventivoListaResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getLista');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.pagina && { 'pagina': requestParameters.pagina }),
            ...(requestParameters.dimensionePagina && { 'dimensionePagina': requestParameters.dimensionePagina }),
            ...(requestParameters.ordinamentoCrescente && { 'ordinamentoCrescente': requestParameters.ordinamentoCrescente }),
            ...(requestParameters.filtroRicerca && { 'filtroRicerca': requestParameters.filtroRicerca }),
        };

        return this.request<PreventivoListaResponse>({
            path: '/preventivo/lista',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * carica un preventivo consumo dato il suo id
     */
    getPreventivoCO = (requestParameters: GetPreventivoCORequest): Observable<PreventivoConsumo> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoCO');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoCO');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<PreventivoConsumo>({
            path: '/preventivo/CO',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * carica un preventivo leasing dato il suo id
     */
    getPreventivoLE = (requestParameters: GetPreventivoLERequest): Observable<PreventivoLeasing> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoLE');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoLE');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<PreventivoLeasing>({
            path: '/preventivo/LE',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Recupera una promo corredandola di descrizione, immagine e date di inizio/fine validità
     */
    getPreventivoPromo = (requestParameters: GetPreventivoPromoRequest): Observable<object> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoPromo');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoPromo');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<object>({
            path: '/preventivo/promo',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Stampa il documento SECCI per un preventivo
     */
    getSecci = (requestParameters: GetSecciRequest): Observable<Secci> => {
        throwIfRequired(requestParameters, 'intermediario', 'getSecci');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getSecci');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<Secci>({
            path: '/preventivo/secci',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Verifica se il secci è stato processato per uno specifico numero preventivo
     */
    getSecciCheck = (requestParameters: GetSecciCheckRequest): Observable<PreventivoSecciResponse> => {
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getSecciCheck');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PreventivoSecciResponse>({
            path: '/preventivo/secci/{numeroPreventivo}'.replace('{numeroPreventivo}', encodeURI(requestParameters.numeroPreventivo)),
            method: 'GET',
            headers,
        });
    };

    /**
     * salva una promo corredandola di descrizione, immagine e date di inizio/fine validità
     */
    postPreventivoPromo = (requestParameters: PostPreventivoPromoRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/promo',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Stampa informativa reativa alla pratica
     */
    praticaInformativeGet = (requestParameters: PraticaInformativeGetRequest): Observable<Informativa> => {
        throwIfRequired(requestParameters, 'intermediario', 'praticaInformativeGet');
        throwIfRequired(requestParameters, 'pratica', 'praticaInformativeGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.pratica && { 'pratica': requestParameters.pratica }),
        };

        return this.request<Informativa>({
            path: '/pratica/informative',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    preventivoAgevolazioni = (): Observable<PreventivoAgevolazione> => {
        return this.request<PreventivoAgevolazione>({
            path: '/preventivo/agevolazioni',
            method: 'GET',
        });
    };

    /**
     * Cancella una promozione se esistente
     */
    preventivoPromoDelete = (requestParameters: PreventivoPromoDeleteRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/promo',
            method: 'DELETE',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * salva un allestimento su preventivo se non presente (si suppone quindi che per i casi di TCM, ad esempio, l\'allestimento sia sempre presente in fase di calcolo)
     * salva un allestimento su preventivo se non presente
     */
    preventivoSalvaAllestimento = (requestParameters: PreventivoSalvaAllestimentoRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/salva/allestimento',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * salva la descrizione del bene su preventivo solo se non presente
     * salva la descrizione del bene su preventivo solo se non presente
     */
    preventivoSalvaDescrizioneBene = (requestParameters: PreventivoSalvaDescrizioneBeneRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/salva/descrizioneBene',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Salva un nuovo preventivo associandolo ad un nomePreventivo, se vecchioNumeroPreventivo è valorizzato si sta facendo un\'operazione di modifica in cui è necessario sostituire il vecchio preventivo con quello nuovo. <br/> Il risultato in lista è che si vedrà solo il nuovo preventivo e non più il vecchio 
     * salva un preventivo correlandolo con una descrizione
     */
    salvaPreventivo = (requestParameters: SalvaPreventivoRequest): Observable<Success> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<Success>({
            path: '/preventivo/salva',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

}
