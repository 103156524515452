import produce from "immer";
import {
  AUTH_CARICAMENTO,
  AUTH_CARICAMENTO_FAILED,
  AUTH_CARICAMENTO_PENDING,
  CLEAR_AUTH_STATE,
  SET_TOKEN,
} from "./consts";
import { IState } from "./types";
import { SessionStorage } from "../../../shared/lib/utility/storage-v2";
import { ID_PREVENTIVO_SESSION_KEY } from "../../../shared/constants/application";

const initialState: IState = {
  authError: false,
  loading: false,
  preventivoId: SessionStorage.read(ID_PREVENTIVO_SESSION_KEY) || "",
};

export default produce((draft: IState, action) => {
  switch (action.type) {
    case CLEAR_AUTH_STATE: {
      return initialState;
    }
    case AUTH_CARICAMENTO: {
      draft.preventivoId = action.payload.prev_id;
      draft.loading = false;
      return;
    }

    case AUTH_CARICAMENTO_PENDING: {
      draft.loading = true;
      return;
    }

    case AUTH_CARICAMENTO_FAILED: {
      draft.authError = true;
      draft.loading = false;
      return;
    }

    case SET_TOKEN: {
      draft.jwt = action.payload;
      return;
    }
  }
}, initialState);
