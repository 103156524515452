import { AjaxError } from "rxjs/ajax";
import { warn } from "../../shared/lib/log";
import IAction, { IError } from "../../shared/types/actions";

export const errorAction = (type: string, errorData: AjaxError): IAction<IError> => {
  const errorPayload = {
    error: true,
    payload: {
      message: "Response null",
      status: 500,
    },
    type,
  };

  if (errorData.response === null) {
    return errorPayload;
  }

  const { status, message } = errorData.response;

  const alertMessage = { url: errorData.request.url, ...errorData.response };
  // alertBrowser(JSON.stringify(alertMessage, null, 4));
  warn(alertMessage);

  // This could do something with errorData before dispatching it to Redux.
  // E.g. Call to Analytics (?)

  return {
    error: true,
    payload: {
      message,
      status,
    },
    type,
  };
};
