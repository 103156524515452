// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    SendMailInputHeaders,
    SendSMSInputContent,
    SendSMSInputDestination,
} from './';

/**
 * 
 * @export
 * @interface SendSMSInput
 */
export interface SendSMSInput {
    /**
     * @type {SendMailInputHeaders}
     * @memberof SendSMSInput
     */
    headers?: SendMailInputHeaders;
    /**
     * @type {SendSMSInputDestination}
     * @memberof SendSMSInput
     */
    destination?: SendSMSInputDestination;
    /**
     * @type {SendSMSInputContent}
     * @memberof SendSMSInput
     */
    content?: SendSMSInputContent;
}
