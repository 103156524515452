import isEmpty from "lodash.isempty";
import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApiResponse } from "../../../../shared/types/actions";
import { allKeys } from "../../../lib/dataHandling/consts";
import { composeFormElementsSet, getFromCustomer } from "../../../lib/dataHandling/dataHandling";
import {
  IFieldData,
  IFormElementsSet,
  TAllValues,
  TCustomer,
  TFormName,
  TMappingPaths,
  TRegularFieldName,
} from "../../../lib/dataHandling/types";
import { savePratica } from "../../../logic/caricamento/actions";
import { IStoreState } from "../../../types/store";
import {
  renderFormDocumentaryData,
  renderFormOccupationalData,
  renderFormPersonalData,
  renderFormTaxDeductions,
  renderFormVehicleData,
} from "../../forms/formRenderers";
import { IFormRenderer } from "../../forms/types";
import Caricamento from "../caricamento";
import { IDispatchProps, IStateProps, TProps } from "./types";

const CaricamentoWrapper: FunctionComponent<TProps> = ({ customer, formsIdleElements, savePratica }) => {
  const [forms, setForms] = useState<(IFormElementsSet | undefined)[]>([]);

  /**
   * The renderers' order will define how the forms will be displayed.
   */
  const renderers: Array<{ formName: TFormName; renderer: IFormRenderer }> = [
    {
      formName: "personalData",
      renderer: renderFormPersonalData,
    },
    {
      formName: "documentaryData",
      renderer: renderFormDocumentaryData,
    },
    {
      formName: "occupationalData",
      renderer: renderFormOccupationalData,
    },
    {
      formName: "vehicleData",
      renderer: renderFormVehicleData,
    },
    {
      formName: "taxDeductions",
      renderer: renderFormTaxDeductions,
    },
  ];

  useEffect(() => {
    const hasAllFields = allKeys.every(key => customer.data?.[key]);
    if (hasAllFields) {
      const forms = formsIdleElements
        .map(formIdleElement => {
          const renderer = renderers.find(element => element.formName === formIdleElement.formName)?.["renderer"];
          if (formIdleElement.isEnabled && renderer) {
            const data =
              customer.data || ({} as Record<TRegularFieldName, IFieldData> | Record<"TOBEDEFINED", IFieldData>);
            return composeFormElementsSet(data, formIdleElement, renderer);
          }
          return;
        })
        .filter(formIdleElement => formIdleElement);

      /**
       * It sorts the forms array following the order defined by the renderers array
       */
      const keys = renderers.map(element => element.formName);
      forms.sort((formA, formB) => keys.indexOf(formA?.formName) - keys.indexOf(formB?.formName));

      setForms(forms);
    }
  }, [customer, formsIdleElements]);

  const callSenderThunk = (customer: TCustomer, preventivoId: string, intermediario: string) => {
    const values = getFromCustomer(customer, "value", "all") as TAllValues;
    const inputMapping = getFromCustomer(customer, "inputPath", "all") as TMappingPaths;
    return savePratica(values, preventivoId, inputMapping, intermediario);
  };

  if (isEmpty(forms)) {
    return null;
  }

  return (
    <Caricamento
      loadCustomerValues={true}
      loadServerSideErrors={true}
      forms={forms}
      callSenderThunk={callSenderThunk}
      allFormsKey="all"
    />
  );
};

const mapStateToProps = (state: IStoreState): IStateProps => ({
  customer: state.caricamento.customer as IApiResponse<TCustomer>,
  formsIdleElements: state.caricamento.formsIdleElements,
});

const mapDispatchToProps: IDispatchProps = {
  savePratica,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CaricamentoWrapper));
