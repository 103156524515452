// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * AG: agente <br/> CV: convenzionato <br/> PV: punto vendita <br/> VE: venditore <br/>
 * @export
 * @enum {string}
 */
export enum TipoIntermediario {
    AG = 'AG',
    CV = 'CV',
    PV = 'PV',
    VE = 'VE'
}

