import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import { DatiCodiceFiscale } from "../../../../shared/generated/caricamento";
import { TAllEnablers, TMappingPaths, TPersonalDataEnablers } from "../../../lib/dataHandling/types";

export type CodiceFiscaleObservableGenerator = (codiceFiscale: string) => Observable<DatiCodiceFiscale>;

export const useCodiceFiscale = (
  codiceFiscale: string,
  postCodiceFiscale: CodiceFiscaleObservableGenerator,
  hasError: boolean,
  setCustomerEnablers: (enablers: Partial<TAllEnablers>) => void,
  codiceFiscaleMappingPaths: TMappingPaths,
  // Formik expects the value as any: node_modules\formik\dist\types.d.ts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any) => void
): { isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setRelatedFieldsEnablers = (isEnabled: boolean) => {
    const enablers = Object.keys(codiceFiscaleMappingPaths).reduce((acc, key) => {
      acc[key] = isEnabled;
      return acc;
    }, {} as Partial<TPersonalDataEnablers>);
    setCustomerEnablers(enablers);
  };

  useEffect(() => {
    if (codiceFiscale && codiceFiscale.length === 16) {
      if (!hasError) {
        setIsLoading(true);

        // Call to CodiceFiscale service
        postCodiceFiscale(codiceFiscale).subscribe(
          res => {
            setIsLoading(false);
            if (!isEmpty(res.data)) {
              Object.keys(codiceFiscaleMappingPaths).forEach(key => {
                setFieldValue(key, get(res, codiceFiscaleMappingPaths[key]));
              });
              setRelatedFieldsEnablers(false);
            }
          },
          () => setIsLoading(false)
        );
      }
    } else {
      setRelatedFieldsEnablers(true);
    }
  }, [codiceFiscale, hasError]);

  return { isLoading };
};
