// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Accessorio
 */
export interface Accessorio {
    /**
     * @type {boolean}
     * @memberof Accessorio
     */
    daEscluderePerCalcoloPremioServiziAssicurativi: boolean;
    /**
     * @type {boolean}
     * @memberof Accessorio
     */
    importoCompresoInAnticipo: boolean;
    /**
     * @type {string}
     * @memberof Accessorio
     */
    nomeServizio: string;
    /**
     * @type {string}
     * @memberof Accessorio
     */
    note?: string;
    /**
     * es: AN, AU, LEA, MT
     * @type {string}
     * @memberof Accessorio
     */
    tipoProdotto: string;
}
