// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    CassandraResponse,
    DurateResponse,
    EurotaxResponse,
    ListaBeni,
    ListaCapitaleResiduo,
    MarcheResponse,
    ModelliResponse,
    PreventivoServiziResponse,
    ProdottoResponse,
    Secci,
    TabelleResponse,
    TipiFinanziamentoResponse,
    TipoBeneResponse,
} from '../models';

export interface GetBeniRequest {
    intermediario: string;
    tipoBene: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    dataImmatricolazione?: Date;
}

export interface GetCapitaleResiduoRequest {
    intermediario: string;
    tabellaFinanziaria: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetCassandraRequest {
    intermediario: string;
    tipoBene: number;
    codiceBene: string;
    codiceProdotto: string;
    tipoFinanziamento: GetCassandraTipoFinanziamentoEnum;
    tabellaFinanziaria: string;
    codiceEurotax: string;
    durata: number;
    tipologia: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    dataImmatricolazione?: Date;
    pratica?: string;
    km?: number;
}

export interface GetDurateRequest {
    intermediario: string;
    tipoBene: number;
    codiceBene: string;
    codiceProdotto: string;
    tipoFinanziamento: GetDurateTipoFinanziamentoEnum;
    tabellaFinanziaria: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    dataImmatricolazione?: Date;
}

export interface GetEurotaxRequest {
    tipoBene: number;
    dataImmatricolazione: Date;
    tipoFinanziamento: GetEurotaxTipoFinanziamentoEnum;
    marca: string;
    X_B3_TraceId?: string;
    veicoloCommerciale?: boolean;
    modello?: string;
}

export interface GetMarcheRequest {
    intermediario: string;
    tipoBene: number;
    dataImmatricolazione: Date;
    provenienza: GetMarcheProvenienzaEnum;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    veicoloCommerciale?: boolean;
}

export interface GetModelliRequest {
    tipoBene: number;
    dataImmatricolazione: Date;
    tipoFinanziamento: GetModelliTipoFinanziamentoEnum;
    marca: string;
    X_B3_TraceId?: string;
    veicoloCommerciale?: boolean;
}

export interface GetProdottoRequest {
    intermediario: string;
    codiceBene: string;
    tipoFinanziamento: GetProdottoTipoFinanziamentoEnum;
    tipoAnagrafica: GetProdottoTipoAnagraficaEnum;
    assicurato: boolean;
    tipoBene: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    veicoloCommerciale?: boolean;
    buyback?: boolean;
    dataImmatricolazione?: Date;
}

export interface GetSecciRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetServiziRequest {
    intermediario: string;
    tabellaFinanziaria: string;
    codiceProdotto: string;
    codiceBene: string;
    tipoFinanziamento: GetServiziTipoFinanziamentoEnum;
    valoreBene: number;
    tipoAnagrafica: GetServiziTipoAnagraficaEnum;
    dataNascita: Date;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    codiceEurotax?: string;
    dataImmatricolazione?: Date;
}

export interface GetTabelleRequest {
    intermediario: string;
    tipoBene: number;
    codiceBene: string;
    codiceProdotto: string;
    tipoFinanziamento: GetTabelleTipoFinanziamentoEnum;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    marca?: string;
    modello?: string;
    codiceEurotax?: string;
    dataImmatricolazione?: Date;
}

export interface GetTipiBeniRequest {
    intermediario: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetTipiFinanziamentoRequest {
    intermediario: string;
    codiceBene: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    dataImmatricolazione?: Date;
}

/**
 * no description
 */
export class P1Api extends BaseAPI {

    /**
     * ritorna la lista dei beni possibili per il tipoBene richiesto
     */
    getBeni = (requestParameters: GetBeniRequest): Observable<ListaBeni> => {
        throwIfRequired(requestParameters, 'intermediario', 'getBeni');
        throwIfRequired(requestParameters, 'tipoBene', 'getBeni');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
        };

        return this.request<ListaBeni>({
            path: '/preventivo/bene',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Restituisce le percentuali di maxirata centrale
     */
    getCapitaleResiduo = (requestParameters: GetCapitaleResiduoRequest): Observable<ListaCapitaleResiduo> => {
        throwIfRequired(requestParameters, 'intermediario', 'getCapitaleResiduo');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'getCapitaleResiduo');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
        };

        return this.request<ListaCapitaleResiduo>({
            path: '/preventivo/capitaleResiduo',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * restituisce l\'elenco dei chilometri finali e le percentuali di maxirata correlate ad ogni chilometraggio
     */
    getCassandra = (requestParameters: GetCassandraRequest): Observable<CassandraResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getCassandra');
        throwIfRequired(requestParameters, 'tipoBene', 'getCassandra');
        throwIfRequired(requestParameters, 'codiceBene', 'getCassandra');
        throwIfRequired(requestParameters, 'codiceProdotto', 'getCassandra');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getCassandra');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'getCassandra');
        throwIfRequired(requestParameters, 'codiceEurotax', 'getCassandra');
        throwIfRequired(requestParameters, 'durata', 'getCassandra');
        throwIfRequired(requestParameters, 'tipologia', 'getCassandra');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.codiceEurotax && { 'codiceEurotax': requestParameters.codiceEurotax }),
            ...(requestParameters.durata && { 'durata': requestParameters.durata }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.pratica && { 'pratica': requestParameters.pratica }),
            ...(requestParameters.tipologia && { 'tipologia': requestParameters.tipologia }),
            ...(requestParameters.km && { 'km': requestParameters.km }),
        };

        return this.request<CassandraResponse>({
            path: '/preventivo/cassandra',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista delle durate applicabili ad una tabella
     */
    getDurate = (requestParameters: GetDurateRequest): Observable<DurateResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getDurate');
        throwIfRequired(requestParameters, 'tipoBene', 'getDurate');
        throwIfRequired(requestParameters, 'codiceBene', 'getDurate');
        throwIfRequired(requestParameters, 'codiceProdotto', 'getDurate');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getDurate');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'getDurate');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
        };

        return this.request<DurateResponse>({
            path: '/preventivo/durate',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista degli allestimenti eurotax disponibili
     */
    getEurotax = (requestParameters: GetEurotaxRequest): Observable<EurotaxResponse> => {
        throwIfRequired(requestParameters, 'tipoBene', 'getEurotax');
        throwIfRequired(requestParameters, 'dataImmatricolazione', 'getEurotax');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getEurotax');
        throwIfRequired(requestParameters, 'marca', 'getEurotax');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.veicoloCommerciale && { 'veicoloCommerciale': requestParameters.veicoloCommerciale }),
            ...(requestParameters.marca && { 'marca': requestParameters.marca }),
            ...(requestParameters.modello && { 'modello': requestParameters.modello }),
        };

        return this.request<EurotaxResponse>({
            path: '/preventivo/eurotax',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista delle marche in riferimento all\'eurotax disponibile
     */
    getMarche = (requestParameters: GetMarcheRequest): Observable<MarcheResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getMarche');
        throwIfRequired(requestParameters, 'tipoBene', 'getMarche');
        throwIfRequired(requestParameters, 'dataImmatricolazione', 'getMarche');
        throwIfRequired(requestParameters, 'provenienza', 'getMarche');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.provenienza && { 'provenienza': requestParameters.provenienza }),
            ...(requestParameters.veicoloCommerciale && { 'veicoloCommerciale': requestParameters.veicoloCommerciale }),
        };

        return this.request<MarcheResponse>({
            path: '/preventivo/marche',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista dei modelli in riferimento all\'eurotax disponibile
     */
    getModelli = (requestParameters: GetModelliRequest): Observable<ModelliResponse> => {
        throwIfRequired(requestParameters, 'tipoBene', 'getModelli');
        throwIfRequired(requestParameters, 'dataImmatricolazione', 'getModelli');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getModelli');
        throwIfRequired(requestParameters, 'marca', 'getModelli');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.veicoloCommerciale && { 'veicoloCommerciale': requestParameters.veicoloCommerciale }),
            ...(requestParameters.marca && { 'marca': requestParameters.marca }),
        };

        return this.request<ModelliResponse>({
            path: '/preventivo/modelli',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * calcola il codice del prodotto da utilizzare
     */
    getProdotto = (requestParameters: GetProdottoRequest): Observable<ProdottoResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getProdotto');
        throwIfRequired(requestParameters, 'codiceBene', 'getProdotto');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getProdotto');
        throwIfRequired(requestParameters, 'tipoAnagrafica', 'getProdotto');
        throwIfRequired(requestParameters, 'assicurato', 'getProdotto');
        throwIfRequired(requestParameters, 'tipoBene', 'getProdotto');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.veicoloCommerciale && { 'veicoloCommerciale': requestParameters.veicoloCommerciale }),
            ...(requestParameters.tipoAnagrafica && { 'tipoAnagrafica': requestParameters.tipoAnagrafica }),
            ...(requestParameters.buyback && { 'buyback': requestParameters.buyback }),
            ...(requestParameters.assicurato && { 'assicurato': requestParameters.assicurato }),
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
        };

        return this.request<ProdottoResponse>({
            path: '/preventivo/prodotto',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Stampa il documento SECCI per un preventivo
     */
    getSecci = (requestParameters: GetSecciRequest): Observable<Secci> => {
        throwIfRequired(requestParameters, 'intermediario', 'getSecci');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getSecci');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<Secci>({
            path: '/preventivo/secci',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Restituisce la lista ordinata di raggruppamenti e servizi
     */
    getServizi = (requestParameters: GetServiziRequest): Observable<PreventivoServiziResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getServizi');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'getServizi');
        throwIfRequired(requestParameters, 'codiceProdotto', 'getServizi');
        throwIfRequired(requestParameters, 'codiceBene', 'getServizi');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getServizi');
        throwIfRequired(requestParameters, 'valoreBene', 'getServizi');
        throwIfRequired(requestParameters, 'tipoAnagrafica', 'getServizi');
        throwIfRequired(requestParameters, 'dataNascita', 'getServizi');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.codiceEurotax && { 'codiceEurotax': requestParameters.codiceEurotax }),
            ...(requestParameters.valoreBene && { 'valoreBene': requestParameters.valoreBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.tipoAnagrafica && { 'tipoAnagrafica': requestParameters.tipoAnagrafica }),
            ...(requestParameters.dataNascita && { 'dataNascita': (requestParameters.dataNascita as any).toISOString() }),
        };

        return this.request<PreventivoServiziResponse>({
            path: '/preventivo/servizi',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista di tabelle finanziarie che l\'utente può utilizzare
     */
    getTabelle = (requestParameters: GetTabelleRequest): Observable<TabelleResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getTabelle');
        throwIfRequired(requestParameters, 'tipoBene', 'getTabelle');
        throwIfRequired(requestParameters, 'codiceBene', 'getTabelle');
        throwIfRequired(requestParameters, 'codiceProdotto', 'getTabelle');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'getTabelle');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tipoBene && { 'tipoBene': requestParameters.tipoBene }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.marca && { 'marca': requestParameters.marca }),
            ...(requestParameters.modello && { 'modello': requestParameters.modello }),
            ...(requestParameters.codiceEurotax && { 'codiceEurotax': requestParameters.codiceEurotax }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
        };

        return this.request<TabelleResponse>({
            path: '/preventivo/tabelle',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista dei tipoBene che l\'utente può utilizzare
     */
    getTipiBeni = (requestParameters: GetTipiBeniRequest): Observable<TipoBeneResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getTipiBeni');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
        };

        return this.request<TipoBeneResponse>({
            path: '/preventivo/tipoBene',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna i tipi di preventivo che l\'utente può fare
     */
    getTipiFinanziamento = (requestParameters: GetTipiFinanziamentoRequest): Observable<TipiFinanziamentoResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getTipiFinanziamento');
        throwIfRequired(requestParameters, 'codiceBene', 'getTipiFinanziamento');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
        };

        return this.request<TipiFinanziamentoResponse>({
            path: '/preventivo/tipiFinanziamento',
            method: 'GET',
            headers,
            query,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetCassandraTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum GetDurateTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum GetEurotaxTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum GetMarcheProvenienzaEnum {
    CO = 'CO',
    LE = 'LE'
}
/**
 * @export
 * @enum {string}
 */
export enum GetModelliTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum GetProdottoTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum GetProdottoTipoAnagraficaEnum {
    P = 'P',
    F = 'F',
    G = 'G'
}
/**
 * @export
 * @enum {string}
 */
export enum GetServiziTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum GetServiziTipoAnagraficaEnum {
    P = 'P',
    F = 'F',
    G = 'G'
}
/**
 * @export
 * @enum {string}
 */
export enum GetTabelleTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
