export const TOKEN_SESSION_KEY = "auth-token-caricamento" || "auth-token-upload";

export const CARICAMENTO_TOKEN_SESSION_KEY = "auth-token-caricamento";
export const UPLOAD_TOKEN_SESSION_KEY = "auth-token-upload";

export const NUM_PRATICA_KEY = "num-pratica";
export const CODICE_FISCALE_KEY = "codice-fiscale";

export const MAIL_SENT = "mail-sent";

export const SESSION_ID = "session-id";
