import { isFunction } from "./types";
import { TRoute } from "../../types/routes";
import { log } from "../log";

interface IResolveRoute {
  (route: TRoute, options?: unknown): string;
}

export const resolveRoute: IResolveRoute = (route, options) => {
  if (route) {
    return isFunction(route) ? route(options) : route;
  }
  log("Route could't be resolved.");
  return "/";
};
