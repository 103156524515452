// tslint:disable
/**
 * Authentication
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CaricamentoControparteInput
 */
export interface CaricamentoControparteInput {
    /**
     * @type {string}
     * @memberof CaricamentoControparteInput
     */
    tipoControparte?: string;
    /**
     * @type {string}
     * @memberof CaricamentoControparteInput
     */
    token0?: string;
    /**
     * @type {string}
     * @memberof CaricamentoControparteInput
     */
    cellulareCliente?: string;
    /**
     * @type {string}
     * @memberof CaricamentoControparteInput
     */
    codiceFiscaleCliente?: string;
    /**
     * @type {string}
     * @memberof CaricamentoControparteInput
     */
    smsCode?: string;
}
