// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroINR620ALTRO,
    WpNormInputParametroINR620CDPSTRST1,
    WpNormInputParametroINR620CDXPLCOBJ,
    WpNormInputParametroINR620CDXSTRST1,
    WpNormInputParametroINR620CIVICOST1,
    WpNormInputParametroINR620DSXLPOROW,
    WpNormInputParametroINR620DSXSTRUFF,
    WpNormInputParametroINR620ELEFONCIV,
    WpNormInputParametroINR620FLXPLCOBJ,
    WpNormInputParametroINR620FLXSTRST1,
    WpNormInputParametroINR620INRCND,
    WpNormInputParametroINR620LNGPLCCNY,
    WpNormInputParametroINR620MRE,
    WpNormInputParametroRGS010LOCNASDSXPLCNAS,
    WpNormInputParametroRGS610CDPPLCNAS,
} from './';

/**
 * @export
 * @interface WpNormInputParametroINR620
 */
export interface WpNormInputParametroINR620 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    TP9IND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    TP9INDSOT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    CDXISO?: string;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXPLCUFF?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXPLCUSR?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXPLCABB?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXPLCSIG?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS610CDPPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    CDPPLCOBJ?: WpNormInputParametroRGS610CDPPLCNAS;
    /**
     * @type {WpNormInputParametroINR620CDXPLCOBJ}
     * @memberof WpNormInputParametroINR620
     */
    CDXPLCOBJ?: WpNormInputParametroINR620CDXPLCOBJ;
    /**
     * @type {WpNormInputParametroINR620CDXPLCOBJ}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTAPLCOBJ?: WpNormInputParametroINR620CDXPLCOBJ;
    /**
     * @type {WpNormInputParametroINR620FLXPLCOBJ}
     * @memberof WpNormInputParametroINR620
     */
    FLXPLCOBJ?: WpNormInputParametroINR620FLXPLCOBJ;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCCNY?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCSTA?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCREG?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCDPT?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCCNL?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCDS1?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCDS2?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGPLCDS3?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620MRE}
     * @memberof WpNormInputParametroINR620
     */
    MRE?: WpNormInputParametroINR620MRE;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    TPPOBJPLC?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    TPPOBJSTR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    CDXZIP?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    CDXZIPSRT?: string;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFF}
     * @memberof WpNormInputParametroINR620
     */
    DSXSTRUFF?: WpNormInputParametroINR620DSXSTRUFF;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFF}
     * @memberof WpNormInputParametroINR620
     */
    DSXSTRUSR?: WpNormInputParametroINR620DSXSTRUFF;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFF}
     * @memberof WpNormInputParametroINR620
     */
    DSXSTRABB?: WpNormInputParametroINR620DSXSTRUFF;
    /**
     * @type {WpNormInputParametroINR620CDPSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    CDPSTRST1?: WpNormInputParametroINR620CDPSTRST1;
    /**
     * @type {WpNormInputParametroINR620CDPSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    CDPSTRST2?: WpNormInputParametroINR620CDPSTRST1;
    /**
     * @type {WpNormInputParametroINR620CDXSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTRST1?: WpNormInputParametroINR620CDXSTRST1;
    /**
     * @type {WpNormInputParametroINR620CDXSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTASTRST1?: WpNormInputParametroINR620CDXSTRST1;
    /**
     * @type {WpNormInputParametroINR620CDXSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTRST2?: WpNormInputParametroINR620CDXSTRST1;
    /**
     * @type {WpNormInputParametroINR620CDXSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTASTRST2?: WpNormInputParametroINR620CDXSTRST1;
    /**
     * @type {WpNormInputParametroINR620FLXSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    FLXSTRST1?: WpNormInputParametroINR620FLXSTRST1;
    /**
     * @type {WpNormInputParametroINR620FLXSTRST1}
     * @memberof WpNormInputParametroINR620
     */
    FLXSTRST2?: WpNormInputParametroINR620FLXSTRST1;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGSTRST1?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGSTRST2?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {WpNormInputParametroINR620CIVICOST1}
     * @memberof WpNormInputParametroINR620
     */
    CIVICOST1?: WpNormInputParametroINR620CIVICOST1;
    /**
     * @type {WpNormInputParametroINR620CIVICOST1}
     * @memberof WpNormInputParametroINR620
     */
    CIVICOST2?: WpNormInputParametroINR620CIVICOST1;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    DSXBLD?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    DSXSUBBLD?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    DSXPREDIR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    DSXPSTDIR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTACIV1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    CDXSTACIV2?: string;
    /**
     * @type {WpNormInputParametroINR620ALTRO}
     * @memberof WpNormInputParametroINR620
     */
    ALTRO?: WpNormInputParametroINR620ALTRO;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXLPOUFF?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXLPOUSR?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXLPOABB?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    DSXLPOSIG?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS610CDPPLCNAS}
     * @memberof WpNormInputParametroINR620
     */
    CDPLPOOBJ?: WpNormInputParametroRGS610CDPPLCNAS;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620
     */
    LNGLPO?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    TPPOBJLPO?: string;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFF}
     * @memberof WpNormInputParametroINR620
     */
    DSXSPOUFF?: WpNormInputParametroINR620DSXSTRUFF;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFF}
     * @memberof WpNormInputParametroINR620
     */
    DSXSPOABB?: WpNormInputParametroINR620DSXSTRUFF;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFF}
     * @memberof WpNormInputParametroINR620
     */
    DSXSPOUSR?: WpNormInputParametroINR620DSXSTRUFF;
    /**
     * @type {WpNormInputParametroINR620CIVICOST1}
     * @memberof WpNormInputParametroINR620
     */
    CIVICOSPO?: WpNormInputParametroINR620CIVICOST1;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620
     */
    NR9CND?: string;
    /**
     * @type {Array<WpNormInputParametroINR620INRCND>}
     * @memberof WpNormInputParametroINR620
     */
    INRCND?: Array<WpNormInputParametroINR620INRCND>;
    /**
     * @type {Array<WpNormInputParametroINR620DSXLPOROW>}
     * @memberof WpNormInputParametroINR620
     */
    DSXLPOROW?: Array<WpNormInputParametroINR620DSXLPOROW>;
    /**
     * @type {Array<WpNormInputParametroINR620ELEFONCIV>}
     * @memberof WpNormInputParametroINR620
     */
    ELEFONCIV?: Array<WpNormInputParametroINR620ELEFONCIV>;
}
