// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TipoControparteEnum {
    CL = 'CL',
    CO = 'CO',
    G1 = 'G1',
    G2 = 'G2',
    G3 = 'G3',
    E1 = 'E1',
    E2 = 'E2',
    E3 = 'E3'
}

