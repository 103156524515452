// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * modello base per gli attributi di input di un preventivo
 * @export
 * @interface AbstractPreventivoInput
 */
export interface AbstractPreventivoInput {
    /**
     * codice del bene
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    codiceBene: string;
    /**
     * codice del prodotto
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    codiceProdotto: string;
    /**
     * @type {number}
     * @memberof AbstractPreventivoInput
     */
    commissioni: number;
    /**
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    descrizioneFinanziamento: string;
    /**
     * @type {number}
     * @memberof AbstractPreventivoInput
     */
    durata: number;
    /**
     * @type {number}
     * @memberof AbstractPreventivoInput
     */
    finanziato: number;
    /**
     * @type {number}
     * @memberof AbstractPreventivoInput
     */
    prezzoVendita: number;
    /**
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    tabellaFinanziaria: string;
    /**
     * codice del tipo di bene
     * @type {number}
     * @memberof AbstractPreventivoInput
     */
    tipoBene?: number;
    /**
     * codice della marca del veicolo
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    marca?: string;
    /**
     * codice del modello di veicolo
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    modello?: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof AbstractPreventivoInput
     */
    eurotax?: string;
}
