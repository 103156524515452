// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NuovaControparteDataOccupazioneDatore,
} from './';

/**
 * @export
 * @interface NuovaControparteDataOccupazione
 */
export interface NuovaControparteDataOccupazione {
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    attivita?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    professione?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    dataoccupazione?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    reddito?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    fatturatoanno?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    matricolainps?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazione
     */
    presenzamatricolainps?: string;
    /**
     * @type {NuovaControparteDataOccupazioneDatore}
     * @memberof NuovaControparteDataOccupazione
     */
    datore?: NuovaControparteDataOccupazioneDatore;
}
