import classNames from "classnames";
import { Field, FieldProps, Form, FormikProps, withFormik, yupToFormErrors } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import BooleanRadio from "../../../../shared/components/boolean_radio/boolean_radio";
import Button from "../../../../shared/components/button/button";
import DateField from "../../../../shared/components/date_field/date_field";
import { FormInfo } from "../../../../shared/components/form_info/form_info";
import InfoModal from "../../../../shared/components/info_modal/info_modal";
import NumericField from "../../../../shared/components/numeric_field/numeric_field";
import SelectField from "../../../../shared/components/select_field/select_field";
import TextField from "../../../../shared/components/text_field/text_field";
import Tooltip from "../../../../shared/components/tooltip/Tooltip";
import { TAX_DEDUCTIONS, TAX_DEDUCTIONS_OPT_IN_VALUES } from "../../../../shared/constants/application";
import {
  LABEL_PROSEGUI,
  TAX_DEDUCTIONS_MODAL_BODY,
  TAX_DEDUCTIONS_MODAL_ICON,
  TAX_DEDUCTIONS_MODAL_TITLE,
} from "../../../../shared/constants/labels";
import { isFormSectionHidden, resetField } from "../../../../shared/lib/form/form";
import { warn } from "../../../../shared/lib/log";
import { TDocumentaryDataValues, TFieldName, TTaxDeductionsValues } from "../../../lib/dataHandling/types";
import { setCustomerCache, setCustomerEnablers, setOnReviewErrors } from "../../../logic/caricamento/actions";
import { getTaxDeductions } from "../../../logic/loader/actions";
import { getTaxDeductionsSelector } from "../../../logic/loader/selectors";
import { IStoreState } from "../../../types/store";
import { scrollToError } from "../form.utilities";
import { useGoogleTagManager } from "../hooks/useGoogleTagManager";
import { useSaveReviewing } from "../hooks/useSaveReviewing";
import "./FormTaxDeductions.scss";
import { TProps } from "./types";

const FormTaxDeductions: React.FC<TProps & FormikProps<TTaxDeductionsValues>> = ({
  getTaxDeductions,
  fetchingTaxDeductions,
  isEnabled,
  isReviewing = false,
  isReviewingShowAll = false,
  isReviewingShowForm = false,
  onPartialSubmit,
  serverSideErrors,
  setCustomerCache,
  setCustomerEnablers,
  setOnReviewErrors,
  submitForm,
  taxDeductions,
  validateForm,
  values,
  isLoading,
  setFieldValue,
  setFieldTouched,
}) => {
  const val = React.useRef<TTaxDeductionsValues>();
  const [modal, toggleModal] = useState(false);

  useEffect(() => {
    getTaxDeductions();
    //
    return () => {
      if (val.current) {
        setCustomerCache(val.current);
      }
    };
  }, []);

  useEffect(() => {
    val.current = values;
  }, [values]);

  useEffect(() => {
    if (values.vuoiBeneficiareDellaDetrazioneFiscale === TAX_DEDUCTIONS_OPT_IN_VALUES.true) {
      setCustomerEnablers({
        tipoDiAgevolazioneFiscale: true,
        numeroFattura: true,
        importoFattura: true,
        dataFattura: true,
      });
    } else {
      setCustomerEnablers({
        tipoDiAgevolazioneFiscale: false,
        numeroFattura: false,
        importoFattura: false,
        dataFattura: false,
      });

      resetField({ setFieldValue, setFieldTouched }, "tipoDiAgevolazioneFiscale");
      resetField({ setFieldValue, setFieldTouched }, "numeroFattura");
      resetField({ setFieldValue, setFieldTouched }, "importoFattura");
      resetField({ setFieldValue, setFieldTouched }, "dataFattura");
    }
  }, [values.vuoiBeneficiareDellaDetrazioneFiscale]);

  /**
   * Submit form at every change when in review
   * (because there is no submit button)
   */
  useSaveReviewing(
    isReviewing,
    values,
    onPartialSubmit || submitForm,
    setOnReviewErrors,
    validateForm,
    TAX_DEDUCTIONS,
    1000
  );

  useGoogleTagManager("/dati-detrazioni", "Dati Detrazioni Fiscali");

  const getRegularFormItemClass = (fieldName: TFieldName) => {
    return classNames("col-xs-12", "col-sm-6", {
      "field-hidden": serverSideErrors
        ? isFormSectionHidden(isReviewing, isReviewingShowAll, fieldName, serverSideErrors) && !isReviewingShowForm
        : false,
    }); // TODO check
  };

  return (
    <Form className="tax-deductions-form">
      {modal && (
        <InfoModal
          dismissLabel="CHIUDI"
          titleLabel={TAX_DEDUCTIONS_MODAL_TITLE}
          textLabel={TAX_DEDUCTIONS_MODAL_BODY}
          isModalOpen={modal}
          onDismiss={() => toggleModal(false)}
        />
      )}

      <div className="row tax-deductions-opt-in-field">
        {/* vuoiBeneficiareDellaDetrazioneFiscale */}
        <Field name="vuoiBeneficiareDellaDetrazioneFiscale">
          {(field: FieldProps<TTaxDeductionsValues>) => (
            <BooleanRadio
              label="Vuoi beneficiare della detrazione fiscale?"
              dataAttr={{ cypressSelector: "vuoiBeneficiareDellaDetrazioneFiscale" }}
              formikField={field}
              trueValue={TAX_DEDUCTIONS_OPT_IN_VALUES.true}
              falseValue={TAX_DEDUCTIONS_OPT_IN_VALUES.false}
              trueLabel="SI"
              falseLabel="NO"
              serverSideError={serverSideErrors ? serverSideErrors["vuoiBeneficiareDellaDetrazioneFiscale"] : ""}
              tooltip={<Tooltip icon={TAX_DEDUCTIONS_MODAL_ICON} onTooltipClick={() => toggleModal(true)} />}
            />
          )}
        </Field>
      </div>

      <div className="row">
        {/* tipoDiAgevolazioneFiscale */}
        {isEnabled["tipoDiAgevolazioneFiscale"] && (
          <div className={getRegularFormItemClass("tipoDiAgevolazioneFiscale")}>
            <Field name="tipoDiAgevolazioneFiscale">
              {(field: FieldProps<TTaxDeductionsValues>) => (
                <SelectField
                  dataAttr={{ cypressSelector: "tipoDiAgevolazioneFiscale" }}
                  formikField={field}
                  label="Tipo di agevolazione fiscale"
                  options={taxDeductions}
                  serverSideError={serverSideErrors ? serverSideErrors["tipoDiAgevolazioneFiscale"] : ""}
                  isResettable={true}
                  isLoading={fetchingTaxDeductions}
                />
              )}
            </Field>
          </div>
        )}

        {/* numeroFattura */}
        {isEnabled["numeroFattura"] && (
          <div className={getRegularFormItemClass("numeroFattura")}>
            <Field name="numeroFattura">
              {(field: FieldProps<TTaxDeductionsValues>) => (
                <TextField
                  dataAttr={{ cypressSelector: "numeroFattura" }}
                  formikField={field}
                  label="Numero fattura"
                  serverSideError={serverSideErrors ? serverSideErrors["numeroFattura"] : ""}
                  helperText="Campo opzionale"
                />
              )}
            </Field>
          </div>
        )}

        {/*importoFattura */}
        {isEnabled["importoFattura"] && (
          <div className={getRegularFormItemClass("importoFattura")}>
            <Field name="importoFattura">
              {(field: FieldProps<TTaxDeductionsValues>) => (
                <NumericField
                  dataAttr={{ cypressSelector: "importoFattura" }}
                  isCurrency={true}
                  formikField={field}
                  label="Importo fattura"
                  serverSideError={serverSideErrors ? serverSideErrors["importoFattura"] : ""}
                  helperText="Campo opzionale. Non inserire cifre decimali"
                />
              )}
            </Field>
          </div>
        )}

        {/* dataFattura */}
        {isEnabled["dataFattura"] && (
          <div className={getRegularFormItemClass("dataFattura")}>
            <Field name="dataFattura">
              {(field: FieldProps<TDocumentaryDataValues>) => (
                <DateField
                  dataAttr={{ cypressSelector: "dataFattura" }}
                  formikField={field}
                  label="Data fattura (gg/mm/aaaa)"
                  serverSideError={serverSideErrors ? serverSideErrors["dataFattura"] : ""}
                  helperText="Campo opzionale"
                />
              )}
            </Field>
          </div>
        )}
      </div>

      {!isReviewing ? (
        <React.Fragment>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                onClick={scrollToError(validateForm, isReviewing)}
                isLoading={isLoading}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </React.Fragment>
      ) : onPartialSubmit ? (
        <div>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                isLoading={isLoading}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </div>
      ) : null}
    </Form>
  );
};

const FormWithFormik = withFormik<TProps, TTaxDeductionsValues>({
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
  },
  mapPropsToValues: (props: TProps) => props.initialValues,
  validateOnChange: true,
  validate: (values: TTaxDeductionsValues, props: TProps) => {
    const { validationSchema, validationContext: context } = props;
    if (validationSchema) {
      return validationSchema
        .validate(values, { abortEarly: false, context })
        .then(() => ({}))
        .catch(yupToFormErrors);
    } else {
      warn("validationSchema passed to FormTaxDeductions is not valid.");
      return {};
    }
  },
})(FormTaxDeductions);

const mapStateToProps = (state: IStoreState) => ({
  taxDeductions: getTaxDeductionsSelector(state),
  fetchingTaxDeductions: state.loader.taxDeductions.loading,
  isLoading: state.caricamento.isLoading,
});

const mapDispatchToProps = {
  setCustomerCache,
  setCustomerEnablers,
  setOnReviewErrors,
  getTaxDeductions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);
