// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroINR620LNGPLCCNY,
} from './';

/**
 * @export
 * @interface WpNormInputParametroINR620MRE
 */
export interface WpNormInputParametroINR620MRE {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620MRE
     */
    TPPOBJMRE?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620MRE
     */
    CDPOBJMRE?: string;
    /**
     * @type {WpNormInputParametroINR620LNGPLCCNY}
     * @memberof WpNormInputParametroINR620MRE
     */
    LNGMRE?: WpNormInputParametroINR620LNGPLCCNY;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620MRE
     */
    DSXUFFMRE?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620MRE
     */
    DSXUSRMRE?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620MRE
     */
    DSXABBMRE?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620MRE
     */
    DSXSIGMRE?: string;
}
