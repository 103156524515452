import React, { FunctionComponent } from "react";
import { Consumer } from "./wizard";

interface IWizardPageProps {
  whenActive: string;
  children: React.ReactNode;
}

export const WizardPage: FunctionComponent<IWizardPageProps> = ({ whenActive, children }) => (
  <Consumer>
    {({ activeStepId, forceShow }) =>
      (forceShow === true || activeStepId === whenActive) && children ? children : null
    }
  </Consumer>
);
