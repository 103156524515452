// tslint:disable
/**
 * reddito
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    RedditoOutput,
} from '../models';

export interface LoaderRedditoRequest {
    fasciareddito?: string;
}

/**
 * no description
 */
export class RedditoApi extends BaseAPI {

    /**
     */
    loaderReddito = (requestParameters: LoaderRedditoRequest): Observable<RedditoOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-IBM-Client-Id': this.configuration.apiKey('X-IBM-Client-Id') }), // clientIdHeader authentication
            ...(this.configuration.apiKey && { 'X-IBM-Client-Secret': this.configuration.apiKey('X-IBM-Client-Secret') }), // clientSecretHeader authentication
        };

        return this.request<RedditoOutput>({
            path: '/reddito',
            method: 'POST',
            headers,
            body: requestParameters.fasciareddito as any,
        });
    };

}
