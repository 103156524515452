/* ---------------------------------
LoadingScreen
--------------------------------- */

import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Spinner from "../spinner/spinner";
import "./loading_screen.scss";

interface IOwnProps {
  loadingMessage?: string;
  show?: boolean;
  spinnerColor?: string;
  withOverlay?: boolean;
}

const LoadingScreen: React.FunctionComponent<IOwnProps> = ({
  loadingMessage,
  show = true,
  spinnerColor = "#042c5c",
  withOverlay = true,
}) => {
  const body = useRef<HTMLElement>(document.body);
  const loadingScreen = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(true);
  const [style, setStyle] = useState<React.CSSProperties>({ opacity: 100 });

  // removes all DOM manipulations
  function overlayCleanup() {
    body.current.style.overflowY = "auto";

    if (!show) {
      setStyle({
        opacity: 0,
        transition: "opacity 0.4s ease-out",
      });
    }

    /* we remove the class _before_ unmounting, so that we can
      release the adjacent div from the styles that we're setting in the CSS */
    loadingScreen.current?.classList.remove(`loading-screen--overlay`);
  }

  // initial DOM manipulation on mount
  useEffect(() => {
    if (withOverlay) {
      body.current.style.overflowY = "hidden";
    }
  }, [withOverlay]);

  // reset DOM effects & unmount when requested
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!show) {
      overlayCleanup();
      timeoutId = setTimeout(() => setMounted(false), 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [show]);

  return mounted ? (
    <div ref={loadingScreen} className={`loading-screen${withOverlay ? " loading-screen--overlay" : ""}`} style={style}>
      {loadingMessage && <div className="loadingMessage">{loadingMessage}</div>}
      <Spinner color={spinnerColor} centered />
    </div>
  ) : null;
};

export default LoadingScreen;
