import isEmpty from "lodash.isempty";
import { useEffect, useRef, useState } from "react";
import { map } from "rxjs/operators";
import {
  LocalitaApi,
  LocalitaOutputDataComuni,
  LoaderLocalitaRequest,
} from "../../../../shared/generated/loader/localita";
import {
  ProvincegenericoApi,
  ProvinceGenericoOutputDataProvince,
} from "../../../../shared/generated/loader/provincegenerico";
import { loaderLocalitaApiConfig } from "../../../lib/loaderApiConfig/localitaApiConfig";
import { loaderProvincegenericoApiConfig } from "../../../lib/loaderApiConfig/provincegenericoApiConfig";

type ComuniOptions = string[];
type ProvinceOptions = { label?: string; value?: string }[];

interface IUseComuniProvincia {
  (provincia?: string, soppresse?: boolean): [ProvinceOptions, ComuniOptions];
}

export const useComuniProvincia: IUseComuniProvincia = (provincia = "", soppresse = false) => {
  const localitaApi = useRef(new LocalitaApi(loaderLocalitaApiConfig()));
  const provincegenericoApi = useRef(new ProvincegenericoApi(loaderProvincegenericoApiConfig()));

  const [province, setProvince] = useState<ProvinceOptions>([]);
  const [comuni, setComuni] = useState<ComuniOptions>([]);

  const comuniOptions = (comuni: LocalitaOutputDataComuni[]): ComuniOptions => {
    return comuni.map(comune => comune.descrizione || null).filter(comune => comune !== null) as ComuniOptions;
  };

  const provinceOptions = (province: ProvinceGenericoOutputDataProvince[]) => {
    return province.map(provincia => ({ label: provincia.descrizione, value: provincia.codice }));
  };

  useEffect(() => {
    provincegenericoApi.current
      .provincegenericoPost({ provinceGenericoInput: { fromcache: false } })
      .pipe(map(province => (province.data && province.data.province) || []))
      .subscribe(province => {
        const provinceNotSuppress = province?.filter(provincia => !provincia.soppressa);
        setProvince(provinceOptions(provinceNotSuppress));
      });
  }, []);

  useEffect(() => {
    if (provincia === "") {
      return;
    }
    if (isEmpty(province)) {
      return;
    }
    const requestParameters: LoaderLocalitaRequest = {
      Input: {
        provincia,
        soppresse,
      },
    };
    localitaApi.current
      .loaderLocalita(requestParameters)
      .pipe(map(comuni => (comuni.data && comuni.data.comuni) || []))
      .subscribe(
        comuni => {
          setComuni(comuniOptions(comuni));
        },
        () => {
          // handle error
          setComuni(comuniOptions([]));
        }
      );
  }, [provincia]);

  return [province, comuni];
};
