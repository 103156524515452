// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AbstractPreventivoOutput,
    AssicurazioneLeasing,
    PreventivoLeasingOutputAllOf,
    RataVariabile,
} from './';

/**
 * @export
 * @interface PreventivoLeasingOutput
 */
export interface PreventivoLeasingOutput {
    /**
     * codice del capo catena
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    capoCatena?: string;
    /**
     * @type {Date}
     * @memberof PreventivoLeasingOutput
     */
    dataCreazione?: Date;
    /**
     * importo totale dovuto dal cliente (finanziato + spese + assicurazioni)
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    importoDovuto?: number;
    /**
     * importo della rata
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    importoRata?: number;
    /**
     * costi di gestione della rata
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    incassoRata?: number;
    /**
     * nome del preventivo salvato
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    nomePreventivo?: string;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    numeroPreventivo?: number;
    /**
     * L\'elemento viene restituito solo se le rate sono variabili
     * @type {Array<RataVariabile>}
     * @memberof PreventivoLeasingOutput
     */
    rateVariabili?: Array<RataVariabile>;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    taeg?: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    tan?: number;
    /**
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    tipoProdotto?: PreventivoLeasingOutputTipoProdottoEnum;
    /**
     * codice del tipo di bene
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    tipoBene?: number;
    /**
     * codice della marca del veicolo
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    marca?: string;
    /**
     * codice del modello di veicolo
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    modello?: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    eurotax?: string;
    /**
     * url_secci
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    url_secci?: string;
    /**
     * importo acconto (calcolato sulla percentuale di input)
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    acconto?: number;
    /**
     * @type {Array<AssicurazioneLeasing>}
     * @memberof PreventivoLeasingOutput
     */
    assicurazioni?: Array<AssicurazioneLeasing>;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    bollo?: number;
    /**
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    interessi?: number;
    /**
     * @type {Array<object>}
     * @memberof PreventivoLeasingOutput
     */
    noteLegali?: Array<object>;
    /**
     * valore complessivo del leasing differente dal finanziato di input perché calcolato meno la quota esente
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    finanziato?: number;
    /**
     * importo correlato alla tipologia richiesta in input (vedi TipologiaImportoFirma)
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    importoFirma?: number;
    /**
     * importo che può o deve pagare il cliente per poter tenere il bene a fine finanziamento
     * @type {number}
     * @memberof PreventivoLeasingOutput
     */
    importoRiscatto?: number;
    /**
     * descrizione del errore riscontrato in fase di calcolo
     * @type {string}
     * @memberof PreventivoLeasingOutput
     */
    errore?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PreventivoLeasingOutputTipoProdottoEnum {
    PA = 'PA',
    PP = 'PP',
    PF = 'PF'
}

