import { Snackbar } from "@material/react-snackbar";
import "@material/react-snackbar/dist/snackbar.css";
import isEmpty from "lodash.isempty";
import * as React from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Container from "../../../shared/components/container/container";
import IncompatibilityModal from "../../../shared/components/incompatibility_modal/incompatibility_modal";
import InfoModal from "../../../shared/components/info_modal/info_modal";
import Summary from "../../../shared/components/summary/summary";
import { beneRichiesto, durata, summaryFormat, taegMassimo } from "../../../shared/components/summary/utils";
import Toggler from "../../../shared/components/toggler/toggler";
import { MAIL_SENT, UPLOAD_TOKEN_SESSION_KEY } from "../../../shared/config/application";
import { PartnerNames } from "../../../shared/config/customization";
import { EXIT_URL_QUERY_PARAMETER } from "../../../shared/constants/application";
import { LABEL_ERROR_HIDE, LABEL_ERROR_SHOW } from "../../../shared/constants/labels";
import {
  Middleware,
  PreventivoRicalcoloListaServizi,
  PreventivoRicalcoloPostRequest,
} from "../../../shared/generated/whiteLabel";
import { error } from "../../../shared/lib/log";
import { getElementOffset } from "../../../shared/lib/utility/dom";
import { scrollTo, scrollToTop } from "../../../shared/lib/utility/generic";
import { getFromSessionStorage, setToSessionStorage } from "../../../shared/lib/utility/storage";
import { SessionStorage } from "../../../shared/lib/utility/storage-v2";
import {
  getEligibilitaInfoDocs,
  getPreventivoEligibilita,
  handleCloseIncompatibilityModal,
  handleCloseModalAfterSavePratica,
  handleProceedIncompatibilityModal,
  handleSwitchClChangeToClRemove,
  postPreventivoRicalcolo,
  setIsIncompatible,
} from "../../../shared/logic/modificaPratica/actions";
import {
  MODAL_CL_CHANGE,
  MODAL_GENERIC_VARIATION,
  MODAL_PROVINCE_VARIATION,
  STATUS_ERROR,
} from "../../../shared/logic/modificaPratica/consts";
import { TModalType } from "../../../shared/logic/modificaPratica/types";
import { PARTNER_NAME_KEY } from "../../config/application";
import { HOME_PATH } from "../../config/constants";
import {
  getFormWithErrorsNames,
  getFromCustomer,
  getLastFormByValue,
  getNextForm,
  getOnReviewErrorFieldNames,
} from "../../lib/dataHandling/dataHandling";
import {
  IFormElementsSet,
  TAllValues,
  TCustomer,
  TOccupationalDataValues,
  TServerSideErrors,
  TSomeFormValues,
} from "../../lib/dataHandling/types";
import { preTokenMiddleware } from "../../lib/preTokenMiddleware";
import { sendMail$ } from "../../lib/sendMail";
import {
  clearState,
  getCustomer,
  getErrori,
  getFormTaxDeductionsActivation,
  getPreventivo,
  getServizi,
  hideNotification,
  setActiveStep,
  setCompleteStep,
  setCustomerValues,
  setFormHasErrorStatus,
  setIsReviewing,
  setIsReviewingShowAll,
} from "../../logic/caricamento/actions";
import { IStoreState } from "../../types/store";
import { useSendToServer } from "../forms/hooks/useSendToServer";
import StepperButton from "../stepper_button/stepper_button";
import Wizard, { WizardPage, WizardStepper } from "../wizard/wizard";
import "./caricamento.scss";
import { IDispatchProps, IStateProps, Props } from "./caricamento.types";
import { getActiveInsuranceDescriptions, modificaPraticaCaricamento, setFormCounterMessage } from "./caricamento.utils";
import { composeURL } from "./composeURL";
import { useFormTaxDeduction } from "./hooks/useFormTaxDeduction";
import { useLoadCustomer } from "./hooks/useLoadCustomer";
import { useLoadErrors } from "./hooks/useLoadErrors";
import { useLoadPreventivo } from "./hooks/useLoadPreventivo";
import { useLoadServizi } from "./hooks/useLoadServizi";

const Caricamento: React.FunctionComponent<Props> = ({
  allFormsKey,
  callSenderThunk,
  customer,
  exitToken,
  forms,
  getCustomer,
  getErrori,
  getPreventivo,
  getServizi,
  getFormTaxDeductionsActivation,
  hideNotification,
  isPraticaSuccess,
  isNotifVisible,
  isReviewing,
  isReviewingShowAll = false,
  loadCustomerValues,
  loadServerSideErrors,
  notifMessage,
  notifTimeout,
  preventivo,
  servizi,
  preventivoId,
  modificaPratica,
  incompatible,
  hasUnrecoverableError,
  scrollToServerSideError,
  setActiveStep,
  setCompleteStep,
  setCustomerValues,
  setFormHasErrorStatus,
  setIsReviewingShowAll,
  getEligibilitaInfoDocs,
  postPreventivoRicalcolo,
  handleCloseModalAfterSavePratica,
  handleProceedIncompatibilityModal,
  handleCloseIncompatibilityModal,
  clearState,
  getPreventivoEligibilita,
  handleSwitchClChangeToClRemove,
}: Props) => {
  const activeForm = forms.filter(form => form?.isActive)[0];
  const partnerName: PartnerNames = SessionStorage.read(PARTNER_NAME_KEY);
  const changeStep = (stepSlug: string) => {
    if (!isReviewing) {
      scrollToTop(300);
      setActiveStep(stepSlug);
    }
  };

  const mailAlreadySent = getFromSessionStorage(MAIL_SENT);

  const intermediario = preventivo.data?.intermediario;

  const data = (customer.data || {}) as TCustomer;

  const {
    modalClChange,
    modalClRemove,
    modalProvinceVariation,
    servicesChangesProceeding,
    servicesChangesData,
    servicesChangesLoading,
  } = modificaPraticaCaricamento(modificaPratica);

  const isIncompatibilityModalOpen = modalClChange || modalClRemove || modalProvinceVariation;

  const setIsAskingToSend = useSendToServer(callSenderThunk, forms, customer.data, preventivoId, intermediario);

  const isSpid = useSelector<IStoreState, boolean>(state => state.caricamento.isSpid);

  useLoadCustomer(getCustomer, loadCustomerValues, allFormsKey);

  useLoadPreventivo(getPreventivo, preventivoId);

  useLoadServizi(getServizi);

  useLoadErrors(getErrori, getFromCustomer, allFormsKey, loadServerSideErrors, customer.data, preventivoId);

  useFormTaxDeduction(getFormTaxDeductionsActivation, preventivoId, preventivo.data?.tipoProdotto);

  useEffect(() => {
    if (
      preventivoId &&
      isPraticaSuccess &&
      exitToken &&
      !servicesChangesLoading &&
      !isIncompatibilityModalOpen &&
      !servicesChangesProceeding &&
      modificaPratica.status !== STATUS_ERROR
    ) {
      // redirect to /upload with token
      const exitUrlQueryParameter = getFromSessionStorage(EXIT_URL_QUERY_PARAMETER);

      if (!data.codiceFiscale) {
        error("Caricamento: data.codiceFiscale must be present in customer model");
        return;
      }
      const codiceFiscaleQueryParameter = data.codiceFiscale?.value;

      const [toAppURL, toEmailURL] = composeURL(exitToken, exitUrlQueryParameter, codiceFiscaleQueryParameter);

      // send email
      const values = getFromCustomer(data, "value", allFormsKey) as TAllValues;

      SessionStorage.remove(UPLOAD_TOKEN_SESSION_KEY);

      if (!mailAlreadySent) {
        sendMail$(values, toEmailURL, preventivoId, partnerName).subscribe(
          () => {
            setToSessionStorage(MAIL_SENT, "true");
            window.location.href = toAppURL;
          },
          error => {
            console.error("Got an error while sending the email: ", error);
            window.location.href = toAppURL;
          }
        );
      } else {
        window.location.href = toAppURL;
      }
    }

    const serverSideErrors = getFromCustomer(data, "serverSideError", allFormsKey) as TServerSideErrors;
    const formsWithErrorsNames = getFormWithErrorsNames(forms, serverSideErrors);
    formsWithErrorsNames.forEach(formName => setFormHasErrorStatus(formName, true));
  }, [
    isPraticaSuccess,
    customer,
    preventivoId,
    exitToken,
    isIncompatibilityModalOpen,
    servicesChangesLoading,
    servicesChangesProceeding,
  ]);

  /**Scroll to serverSideErrors after savePratica failed */
  useEffect(() => {
    const serverSideErrors = getFromCustomer(data, "serverSideError", allFormsKey) as TServerSideErrors;
    if (!isEmpty(serverSideErrors) && !hasUnrecoverableError && scrollToServerSideError) {
      const [firstServerSideError] = Object.keys(serverSideErrors);
      const firstElementWithError: HTMLInputElement | HTMLSelectElement | null = document.querySelector(
        `[name=${firstServerSideError}`
      );
      if (firstElementWithError) {
        scrollToTop(300).then(() =>
          scrollTo(getElementOffset(firstElementWithError).top - 250, 600).then(() => firstElementWithError.focus())
        );
      }
      return;
    }
  }, [scrollToServerSideError]);

  /**Collect incompatibilityModal response and proceed */
  useEffect(() => {
    const provincia = data.residenzaProvincia.value && data.residenzaProvincia.value.toString();
    if (!isIncompatibilityModalOpen && servicesChangesProceeding && servicesChangesData && provincia) {
      /**list of services to remove */
      const servicesToRemove: PreventivoRicalcoloListaServizi[] = [];
      let recalculationRequired = false;
      Object.keys(servicesChangesData).forEach(item => {
        if (servicesChangesData[item].toRemove) {
          servicesToRemove.push({ codice: item });
          recalculationRequired = true;
        }

        if (servicesChangesData[item].toRecalculate) {
          recalculationRequired = true;
        }
      });

      if (recalculationRequired) {
        handleIncompatibilityModalRicalcolo(Number(preventivoId), preTokenMiddleware, servicesToRemove, provincia);
      } else {
        handleCloseIncompatibilityModal(MODAL_CL_CHANGE, true);
      }
    }
  }, [servicesChangesProceeding, servicesChangesData, modalClChange, modalClRemove, modalProvinceVariation]);

  /**
   * Download Info Docs & Proceed (CL Change)
   */
  const handleDownload = () => {
    const attivita = data.attivita?.value && data.attivita?.value.toString();
    const professione = data.professione?.value && data.professione?.value.toString();
    const eligibilita = modificaPratica.eligibilita?.data;
    const dataOccupazione = data.dataUltimaOccupazione?.value ? data.dataUltimaOccupazione?.value.toString() : "";
    const provinciaDatore = data.provinciaDatoreLavoro?.value ? data.provinciaDatoreLavoro?.value.toString() : "";

    if (intermediario && preventivoId && attivita && professione && eligibilita) {
      getEligibilitaInfoDocs(intermediario, Number(preventivoId), eligibilita, preTokenMiddleware, {
        attivita,
        professione,
        dataOccupazione,
        provinciaDatore,
      });
    }
  };

  /**
   * Call preventivoRicalcolo with insurance to remove list
   */
  const handleIncompatibilityModalRicalcolo = (
    preventivoId: number,
    preTokenMiddleware: Middleware,
    servicesToRemove: PreventivoRicalcoloListaServizi[],
    provincia: string
  ): void => {
    const requestParameters: PreventivoRicalcoloPostRequest = {
      body: {
        numeroPreventivo: preventivoId,
        listaServizi: servicesToRemove,
        provincia: provincia,
      },
    };
    postPreventivoRicalcolo(requestParameters, preTokenMiddleware);
  };

  /**
   * handleGetEligibilita
   */

  const handleGetEligibilita = () => {
    if (intermediario) {
      getPreventivoEligibilita(
        { intermediario: intermediario, numeroPreventivo: Number(preventivoId) },
        preTokenMiddleware
      );
    }
  };

  /** Close incompatibilityModal & Edit user data
   * we must call praticaRichiestaModifica
   */

  const handleDismissModalProvinceVariation = (modalType: TModalType) => {
    const codiceFiscale = data.codiceFiscale?.value?.toString();
    const numPratica = modificaPratica.numPratica;
    if (numPratica && codiceFiscale) {
      handleCloseModalAfterSavePratica(modalType, numPratica, exitToken, codiceFiscale, "CO");
    }
  };

  if (modificaPratica.isRicalcoloSuccess) {
    // redirect to /upload with token
    const exitUrlQueryParameter = getFromSessionStorage(EXIT_URL_QUERY_PARAMETER);
    if (!data.codiceFiscale) {
      error("Caricamento: data.codiceFiscale must be present in customer model");
    }
    const codiceFiscaleQueryParameter = data.codiceFiscale?.value;

    const [, toEmailURL] = composeURL(exitToken, exitUrlQueryParameter, codiceFiscaleQueryParameter);
    // send email
    const values = getFromCustomer(data, "value", allFormsKey) as TAllValues;
    if (preventivoId && !mailAlreadySent) {
      sendMail$(values, toEmailURL, preventivoId, partnerName).subscribe(
        () => {
          setToSessionStorage(MAIL_SENT, "true");
        },
        error => {
          console.error("Got an error while sending the email: ", error);
        }
      );
    }
    return <Redirect to="/edit" />;
  }

  /**
   * This function is passed to all forms in order to submit gathered values to Redux
   * State. It's used by the application during the review mode to store data every time
   * the values are changed. The first set of parameters is passed inside the renderer
   * function and the second inside the Form component.
   *
   * @param currentForm
   * @param values
   */
  const submitFormValues = (currentForm: IFormElementsSet) => async (values: TSomeFormValues) => {
    if (!forms) {
      error("SubmitFormValues: forms is required");
      return;
    }
    if (values) {
      setCustomerValues(values);
    }
    setCompleteStep(currentForm.formName, true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const nextForm = getNextForm(forms!, currentForm, isSpid);

    if (nextForm) {
      changeStep(nextForm.formName);
    }
  };

  /**
   * This function is used exclusively by the last Form component to signal to the parent
   * component that the values are ready to be sent to the server. It behaves in a more
   * particular way when under review mode: if there are onReviewErrors, it makes the app
   * open expand all the forms and scroll onto the first error.
   *
   * @param values
   */
  const sendToServer = async (values: TOccupationalDataValues) => {
    if (isReviewing) {
      if (!customer.data) {
        error("sendToServer: customer.data must be present");
        return;
      }
      const onReviewErrorFieldNames = getOnReviewErrorFieldNames(customer.data, allFormsKey);
      if (!isEmpty(onReviewErrorFieldNames)) {
        setIsReviewingShowAll(true);
        const firstElementWithError: HTMLInputElement | HTMLSelectElement | null = document.querySelector(
          `[name=${onReviewErrorFieldNames[0]}`
        );
        if (firstElementWithError) {
          scrollTo(getElementOffset(firstElementWithError).top - 100, 300).then(() => firstElementWithError.focus());
        }
        return;
      }
    }
    const lastForm = getLastFormByValue(forms);
    if (!lastForm) {
      error("sendToServer: last form is not defined");
      return;
    }
    submitFormValues(lastForm)(values);
    setIsAskingToSend(true);
  };

  const toggleReviewingShowAllHandler = () => {
    setIsReviewingShowAll(!isReviewingShowAll);
  };

  const stepChangeHandler = (stepKey: string) => {
    changeStep(stepKey);
  };

  const handleExit = () => {
    const exitUrl = getFromSessionStorage(EXIT_URL_QUERY_PARAMETER);
    const homePath = HOME_PATH;
    clearState();
    if (homePath) {
      window.location.href = homePath;
    } else if (exitUrl) {
      window.location.href = exitUrl;
    }
  };

  if (hasUnrecoverableError || modificaPratica.status === STATUS_ERROR) {
    clearState();
    return <Redirect to="/error/unrecoverable" />;
  }

  return (
    <div className="caricamento">
      <Snackbar
        className="notificationMessage"
        closeOnEscape
        leading
        message={notifMessage}
        open={isNotifVisible}
        timeoutMs={notifTimeout}
        onClose={hideNotification}
      />

      {preventivo && preventivo.data ? (
        <Summary
          beneRichiesto={beneRichiesto(preventivo.data.prezzo_vendita, preventivo.data.anticipo)}
          tan={preventivo.data.tan}
          taeg={preventivo.data.taeg}
          taegMassimo={taegMassimo(preventivo.data.taegMassimo)}
          rataMensile={summaryFormat(preventivo.data.importo_rata)}
          durata={durata(preventivo.data.durata)}
          importoTotale={summaryFormat(preventivo.data.importo_dovuto)}
          assicurazioni={getActiveInsuranceDescriptions(preventivo, servizi)}
        />
      ) : null}
      <Container>
        <h2>
          <div className="row">
            <div className="col-xs-9">{/* <div className="caricamento__title">CARICAMENTO DATI</div> */}</div>
            <div className="col-xs-3">
              {!isReviewing ? (
                <div className="caricamento__step-counter">{setFormCounterMessage(activeForm, forms)}</div>
              ) : null}
            </div>
          </div>
        </h2>

        {isReviewing ? (
          <div className="row">
            <div className="col-xs-12">
              <Toggler
                isOpen={isReviewingShowAll}
                openText={LABEL_ERROR_HIDE}
                closedText={LABEL_ERROR_SHOW}
                onClick={toggleReviewingShowAllHandler}
              />
            </div>
          </div>
        ) : null}

        {isPraticaSuccess && modalClChange && !servicesChangesLoading && (
          <IncompatibilityModal
            titleLabel="Attenzione!"
            onOpen={handleGetEligibilita}
            onDownload={handleDownload}
            onProceed={handleProceedIncompatibilityModal}
            onDismiss={handleCloseIncompatibilityModal}
            onSwitch={handleSwitchClChangeToClRemove}
            modificaPratica={modificaPratica}
            modalType={MODAL_CL_CHANGE}
          />
        )}
        {isPraticaSuccess && modalClRemove && !servicesChangesLoading && (
          <IncompatibilityModal
            titleLabel="Attenzione!"
            onDownload={handleDownload}
            onProceed={handleProceedIncompatibilityModal}
            onDismiss={handleCloseIncompatibilityModal}
            onSwitch={handleSwitchClChangeToClRemove}
            modificaPratica={modificaPratica}
            modalType={MODAL_GENERIC_VARIATION}
          />
        )}
        {isPraticaSuccess && modalProvinceVariation && !servicesChangesLoading && (
          <IncompatibilityModal
            titleLabel="Ops!"
            onDownload={handleDownload}
            onProceed={handleProceedIncompatibilityModal}
            onDismiss={handleDismissModalProvinceVariation}
            onSwitch={handleSwitchClChangeToClRemove}
            modificaPratica={modificaPratica}
            modalType={MODAL_PROVINCE_VARIATION}
            provincia={data.residenzaProvincia.value && data.residenzaProvincia.value.toString()}
          />
        )}

        {incompatible.isIncompatible && (
          <InfoModal
            className="incompatibility-modal"
            isModalOpen={true}
            titleLabel="Attenzione!"
            textLabel={incompatible.message}
            dismissLabel="Modifica il preventivo"
            onDismiss={handleExit}
          />
        )}

        <Wizard onStepChange={stepChangeHandler} activeStep={activeForm.formName} forceShow={isReviewing}>
          {forms.map((form, index) => {
            const order = index + 1;
            return (
              <div key={form.formName} className="caricamento-step">
                <WizardStepper id={form.formName}>
                  {({ handleClick, isActive, forceShow }) => (
                    <React.Fragment>
                      <StepperButton
                        hasErrors={form.hasError}
                        isActive={isActive || forceShow}
                        isComplete={form.isComplete}
                        onClick={handleClick(forms)}
                        stepNumber={order.toString()}
                      >
                        {form.label}
                      </StepperButton>
                    </React.Fragment>
                  )}
                </WizardStepper>

                <WizardPage whenActive={form.formName}>
                  <div className="caricamento-step__form-row" data-cy={`form-step-${order.toString()}`}>
                    {form.formName === getLastFormByValue(forms)?.formName
                      ? form.renderer(form, submitFormValues, isReviewing, isReviewingShowAll, sendToServer)
                      : form.renderer(form, submitFormValues, isReviewing, isReviewingShowAll)}
                  </div>
                </WizardPage>
              </div>
            );
          })}
        </Wizard>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: IStoreState): IStateProps => ({
  customer: state.caricamento.customer,
  isReviewing: state.caricamento.isReviewing,
  isReviewingShowAll: state.caricamento.isReviewingShowAll,
  preventivo: state.caricamento.preventivo,
  servizi: state.caricamento.servizi,
  preventivoId: state.authentication.preventivoId,
  isPraticaSuccess: state.caricamento.isPraticaSuccess,
  exitToken: state.caricamento.exitToken,
  notifMessage: state.caricamento.notifications.message,
  notifTimeout: state.caricamento.notifications.timeout,
  isNotifVisible: state.caricamento.notifications.visible,
  modificaPratica: state.modificaPratica,
  incompatible: state.caricamento.incompatible,
  hasUnrecoverableError: state.caricamento.hasUnrecoverableError,
  scrollToServerSideError: state.caricamento.scrollToServerSideError,
});

const mapDispatchToProps: IDispatchProps = {
  getCustomer,
  getErrori,
  getPreventivo,
  getServizi,
  setActiveStep,
  setCompleteStep,
  setFormHasErrorStatus,
  setCustomerValues,
  setIsReviewing,
  setIsReviewingShowAll,
  hideNotification,
  getFormTaxDeductionsActivation,
  setIsIncompatible,
  getEligibilitaInfoDocs,
  postPreventivoRicalcolo,
  handleProceedIncompatibilityModal,
  handleCloseIncompatibilityModal,
  handleCloseModalAfterSavePratica,
  clearState,
  getPreventivoEligibilita,
  handleSwitchClChangeToClRemove,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Caricamento));
