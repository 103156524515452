import { useEffect, useRef } from "react";
import { Observable, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { TSomeFormValues, TFieldName, TFormName } from "../../../lib/dataHandling/types";
import isEmpty from "lodash.isempty";
import { FormikErrors } from "formik";
import { TCustomFormName, TSomeCustomFormValues } from "../../../../custom/libraries/dataHandling/types";

interface IUseSaveReviewing {
  (
    isReviewing: boolean,
    values: TSomeFormValues | TSomeCustomFormValues,
    onSubmit: (values: unknown) => void,
    setOnReviewErrors: (errors: Record<TFieldName, string>, caller: TFormName) => void,
    // Formik expects any as a type: node_modules\formik\dist\types.d.ts
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validateForm: (values?: any) => Promise<FormikErrors<Record<TFieldName, string>>>,
    caller: TFormName | TCustomFormName,
    debounce: number
  ): void;
}
/**
 * Debounce onSubmit
 */
export const useSaveReviewing: IUseSaveReviewing = (
  isReviewing,
  values,
  onSubmit,
  setOnReviewErrors,
  validateForm,
  caller,
  debounce = 1000
) => {
  const submissions$ = useRef<Subject<TSomeFormValues>>(
    new Subject().pipe(
      debounceTime(debounce),
      switchMap(value => {
        validateForm().then((errors: Record<TFieldName, string>) => {
          if (isEmpty(errors)) {
            onSubmit(value);
          }
          setOnReviewErrors(errors, caller);
        });
        return new Observable();
      })
    ) as Subject<TSomeFormValues>
  );

  useEffect(() => {
    if (isReviewing) {
      submissions$.current.next(values);
    }
  }, [isReviewing, values]);

  useEffect(() => {
    const observable = submissions$.current.subscribe();
    return () => observable.unsubscribe();
  }, []);
};
