import IBAN from "iban";
import moment from "moment";
import { mixed, number, ref, string } from "yup";
import {
  ARRAY_ATTIVITA_NON_IMPIEGATO,
  ATTIVITA_SENZA_REDDITO,
  DEFAULT_DATE_FORMAT,
  MAX_AGE,
  MAX_SCANDENZA_PERMESSO_SOGGIORNO,
  MIN_AGE,
  MONTHYEAR_DATE_FORMAT,
  PEP_VALUES,
  PRIVACY_VALUES,
  REDDITI_SECONDARI_VALUES,
  SESSO_VALUES,
  TAX_DEDUCTIONS_OPT_IN_VALUES,
} from "../../../shared/constants/application";
import {
  ERROR_CAP,
  ERROR_DATE_AFTER_BIRTH,
  ERROR_DATE_AVOID_FUTURE,
  ERROR_DATE_NASCITA_RANGE,
  ERROR_DATE_SCADENZA_DOCUMENTO,
  ERROR_DATE_SCADENZA_PERMESSO_SOGGIORNO,
  ERROR_DOCUMENT_NUMBER_LENGTH,
  ERROR_EMAIL,
  ERROR_IBAN,
  ERROR_INVALID_DATE,
  ERROR_NUMBER_MIN_ONE,
  ERROR_ONLY_POSITIVE_NUMBERS,
  ERROR_ONLY_WHOLE_NUMBERS,
  ERROR_PHONE,
  ERROR_PHONE_MIN_NUMBERS,
  ERROR_PHONE_ONLY_NUMBER,
  ERROR_PRIVACY6_REQUIRED,
  ERROR_PRIVACY_INFORMATIVA,
  ERROR_PRIVACY_INFORMATIVA_PREVENTIVO_MODULO,
  ERROR_YES_OR_NOT,
  LABEL_REQUIRED,
  MAX_FAMIGLIARI_CARICO,
} from "../../../shared/constants/labels";
import { avoidFutureDates, avoidPastDates, isWithinYearsRange } from "../../../shared/lib/utility/date";
import { checkDataResidenza, checkDataUltimoImpiego, checkInvalidDate } from "../../components/forms/client_checks";
import {
  atLeast5CharsRegExp,
  capRegExp,
  codiceFiscaleRegexp,
  documentRegExp,
  onlyNumbersRegExp,
  telefonoRegExp,
} from "./consts";
import { TCustomer } from "./types";

const initialCustomer: TCustomer = {
  nome: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: true,
    inputPath: "data.nome",
    outputPath: "cliente.nome",
    validation: string().required(LABEL_REQUIRED),
  },
  cognome: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: true,
    inputPath: "data.cognome",
    outputPath: "cliente.cognome",
    validation: string().required(LABEL_REQUIRED),
  },
  codiceFiscale: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: true,
    inputPath: "data.codicefiscale",
    outputPath: "cliente.codicefiscale",
    validation: string().matches(codiceFiscaleRegexp, "Codice fiscale non valido").required(LABEL_REQUIRED),
  },
  dataNascita: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMMDD",
    inputPath: "data.nascita.data",
    outputPath: "cliente.nascita.data",
    validation: string()
      .test("avoidFutureDates", ERROR_DATE_AVOID_FUTURE, avoidFutureDates())
      .test("isWithinYearsRange", ERROR_DATE_NASCITA_RANGE, value => isWithinYearsRange(value, MIN_AGE, MAX_AGE))
      .test("dateIsValid", ERROR_INVALID_DATE, checkInvalidDate(DEFAULT_DATE_FORMAT))
      .required(LABEL_REQUIRED),
  },
  provinciaNascita: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.nascita.provincia",
    outputPath: "cliente.nascita.provincia",
    validation: string().required(LABEL_REQUIRED),
  },
  luogoNascita: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.nascita.luogo",
    outputPath: "cliente.nascita.luogo",
    validation: string().required(LABEL_REQUIRED),
  },
  sesso: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.sesso",
    outputPath: "cliente.sesso",
    validation: string().oneOf(Object.values(SESSO_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  cittadinanza: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.cittadinanza",
    outputPath: "cliente.cittadinanza",
    validation: string().required(LABEL_REQUIRED),
  },
  tipoPermessoSoggiorno: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.documentoidentita.tipopermesso",
    outputPath: "cliente.documentoidentita.tipopermesso",
    validation: string().when("$isEnabled.tipoPermessoSoggiorno", {
      is: true,
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  scadenzaPermessoSoggiorno: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMMDD",
    inputPath: "data.documentoidentita.permesso_data_a",
    outputPath: "cliente.documentoidentita.permesso_data_a",
    validation: string().when("$isEnabled.scadenzaPermessoSoggiorno", {
      is: true,
      then: string()
        .required(LABEL_REQUIRED)
        .test("dateIsValid", ERROR_INVALID_DATE, checkInvalidDate(DEFAULT_DATE_FORMAT))
        .test("avoidPastDates", ERROR_DATE_SCADENZA_PERMESSO_SOGGIORNO, avoidPastDates())
        .test("checkScadenzaPermesso", ERROR_INVALID_DATE, value =>
          isWithinYearsRange(value, 0, MAX_SCANDENZA_PERMESSO_SOGGIORNO)
        ),
      otherwise: string().notRequired(),
    }),
  },
  residenzaIndirizzo: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.residenza.indirizzo",
    outputPath: "cliente.residenza.indirizzo",
    validation: string().required(LABEL_REQUIRED),
  },
  residenzaCap: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.residenza.cap",
    outputPath: "cliente.residenza.cap",
    validation: string().matches(capRegExp, ERROR_CAP).required(LABEL_REQUIRED),
  },
  residenzaProvincia: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.residenza.provincia",
    outputPath: "cliente.residenza.provincia",
    validation: string().required(LABEL_REQUIRED),
  },
  residenzaLocalita: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.residenza.localita",
    outputPath: "cliente.residenza.localita",
    validation: string().required(LABEL_REQUIRED),
  },
  residenzaAbitazione: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.residenza.abitazione",
    outputPath: "cliente.residenza.abitazione",
    validation: string().required(LABEL_REQUIRED),
  },
  residenzaData: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMM",
    inputPath: "data.residenza.dataabitazione",
    outputPath: "cliente.residenza.dataabitazione",
    validation: string()
      .test("avoidFutureDates", ERROR_DATE_AVOID_FUTURE, avoidFutureDates(MONTHYEAR_DATE_FORMAT))
      .test({
        name: "checkDateOfBirth",
        exclusive: false,
        message: ERROR_DATE_AFTER_BIRTH,
        test: function (strValue) {
          const momentValue = moment(strValue, MONTHYEAR_DATE_FORMAT, true);
          if (!momentValue.isValid()) {
            // input is not valid, validator should not care
            return true;
          }
          const value = momentValue.endOf("month");
          const dataNascita = this.resolve(ref("dataNascita"));
          if (!dataNascita) {
            // data di nascita not found, validator should not care
            return true;
          }
          return value > moment(dataNascita);
        },
      })
      .test("dateIsValid", ERROR_INVALID_DATE, checkInvalidDate(MONTHYEAR_DATE_FORMAT))
      .required(LABEL_REQUIRED),
  },
  speseAffitto: {
    value: "",
    cache: "",
    isEnabled: false,
    hasOnReviewError: false,
    type: "text",
    inputPath: "data.nucleoFamigliare.speseAffitto",
    outputPath: "cliente.nucleoFamigliare.speseAffitto",
    validation: string().when("$isEnabled.speseAffitto", {
      is: true,
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  componentiNucleoFamigliare: {
    value: 1,
    cache: 1,
    isEnabled: true,
    hasOnReviewError: false,
    type: "numeric",
    isInteger: true,
    inputPath: "data.nucleoFamigliare.componentiNucleoFamigliare",
    outputPath: "cliente.nucleoFamigliare.componentiNucleoFamigliare",
    validation: number()
      // .transform(v => {
      //   const n = parseFloat(v);
      //   if (isNaN(n)) {
      //     return 1;
      //   }
      //   return n;
      // })
      .required(LABEL_REQUIRED)
      .min(1, ERROR_NUMBER_MIN_ONE)
      .integer(ERROR_ONLY_WHOLE_NUMBERS)
      .typeError(LABEL_REQUIRED),
  },
  famigliariACarico: {
    value: 0,
    cache: 0,
    isEnabled: false,
    hasOnReviewError: false,
    type: "numeric",
    isInteger: true,
    inputPath: "data.nucleoFamigliare.famigliariACarico",
    outputPath: "cliente.nucleoFamigliare.famigliariACarico",
    validation: number().when("$isEnabled.famigliariACarico", {
      is: true,
      then: number()
        .required(LABEL_REQUIRED)
        .min(0, ERROR_ONLY_POSITIVE_NUMBERS)
        .integer(ERROR_ONLY_WHOLE_NUMBERS)
        .typeError(LABEL_REQUIRED)
        .test({
          name: "checkComponentiNucleoFamigliare",
          exclusive: false,
          message: MAX_FAMIGLIARI_CARICO,
          test: function (value) {
            const componentiNucleoFamigliare = this.resolve(ref("componentiNucleoFamigliare"));
            return componentiNucleoFamigliare > value;
          },
        }),
      otherwise: number().notRequired(),
    }),
  },
  altriRedditiNucleoFamigliare: {
    value: "",
    cache: "",
    isEnabled: false,
    hasOnReviewError: false,
    type: "text",
    inputPath: "data.nucleoFamigliare.altriRedditiNucleoFamigliare",
    outputPath: "cliente.nucleoFamigliare.altriRedditiNucleoFamigliare",
    validation: string().when("$isEnabled.altriRedditiNucleoFamigliare", {
      is: true,
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  email: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.residenza.email",
    outputPath: "cliente.residenza.email",
    validation: string().email(ERROR_EMAIL).required(LABEL_REQUIRED),
  },
  numCellulare: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.residenza.cellulare",
    outputPath: "cliente.residenza.cellulare",
    validation: string().matches(telefonoRegExp, ERROR_PHONE).required(LABEL_REQUIRED),
  },
  indirizzoPrecedenteIndirizzo: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.indirizzoprecedente.indirizzo",
    outputPath: "cliente.indirizzoprecedente.indirizzo",
    validation: mixed().when("residenzaData", {
      is: (val: string) => checkDataResidenza(val),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  indirizzoPrecedenteCap: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.indirizzoprecedente.cap",
    outputPath: "cliente.indirizzoprecedente.cap",
    validation: mixed().when("residenzaData", {
      is: (val: string) => checkDataResidenza(val),
      then: string().matches(capRegExp, ERROR_CAP).required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  indirizzoPrecedenteProvincia: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.indirizzoprecedente.provincia",
    outputPath: "cliente.indirizzoprecedente.provincia",
    validation: mixed().when("residenzaData", {
      is: (val: string) => checkDataResidenza(val),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  indirizzoPrecedenteLocalita: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.indirizzoprecedente.localita",
    outputPath: "cliente.indirizzoprecedente.localita",
    validation: mixed().when("residenzaData", {
      is: (val: string) => checkDataResidenza(val),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  statoCivile: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.statocivile",
    outputPath: "cliente.statocivile",
    validation: string().required(LABEL_REQUIRED),
  },
  venditaDistanza: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    validation: string().oneOf(["S"], ERROR_PRIVACY_INFORMATIVA).required(LABEL_REQUIRED),
  },
  preventivoSECCI: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    validation: string().oneOf(["S"], ERROR_PRIVACY_INFORMATIVA_PREVENTIVO_MODULO).required(LABEL_REQUIRED),
  },
  usoDati: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    validation: string().oneOf(["S"], ERROR_PRIVACY_INFORMATIVA).required(LABEL_REQUIRED),
  },
  privacy1: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy1",
    outputPath: "cliente.privacy.privacy1",
    validation: string().oneOf(Object.values(PRIVACY_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  privacy2: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy2",
    outputPath: "cliente.privacy.privacy2",
    validation: string().oneOf(Object.values(PRIVACY_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  privacy3: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy3",
    outputPath: "cliente.privacy.privacy3",
    validation: string().oneOf(Object.values(PRIVACY_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  privacy4: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy4",
    outputPath: "cliente.privacy.privacy4",
    validation: string().oneOf(Object.values(PRIVACY_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  privacy6: {
    value: undefined,
    cache: undefined,
    isEnabled: false,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy6",
    outputPath: "cliente.privacy.privacy6",
    validation: string().when("$isEnabled.privacy6", {
      is: true,
      then: string().oneOf(["S"], ERROR_PRIVACY6_REQUIRED).required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  privacy7: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy7",
    outputPath: "cliente.privacy.privacy7",
    validation: string().oneOf(Object.values(PRIVACY_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  privacy8: {
    value: undefined,
    cache: undefined,
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.privacy.privacy8",
    outputPath: "cliente.privacy.privacy8",
    validation: string().oneOf(Object.values(PRIVACY_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  tipoDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.documentoidentita.tipo",
    outputPath: "cliente.documentoidentita.tipo",
    validation: string().required(LABEL_REQUIRED),
  },
  numeroDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: true,
    inputPath: "data.documentoidentita.numero",
    outputPath: "cliente.documentoidentita.numero",
    validation: string().matches(documentRegExp, ERROR_DOCUMENT_NUMBER_LENGTH).required(LABEL_REQUIRED),
  },
  dataRilascioDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMMDD",
    inputPath: "data.documentoidentita.datarilascio",
    outputPath: "cliente.documentoidentita.datarilascio",
    validation: string()
      .test("avoidFutureDates", ERROR_DATE_AVOID_FUTURE, avoidFutureDates())
      .test({
        name: "checkDateOfBirth",
        exclusive: false,
        message: ERROR_DATE_AFTER_BIRTH,
        test: function (strValue) {
          const momentValue = moment(strValue, MONTHYEAR_DATE_FORMAT);
          if (!momentValue.isValid()) {
            // input is not valid, validator should not care
            return true;
          }
          const value = momentValue.endOf("month");
          const dataNascita = this.resolve(ref("$value.dataNascita"));
          if (!dataNascita) {
            // data di nascita not found, validator should not care
            return true;
          }
          return value > moment(dataNascita);
        },
      })
      .test("dateIsValid", ERROR_INVALID_DATE, checkInvalidDate(DEFAULT_DATE_FORMAT))
      .required(LABEL_REQUIRED),
  },
  dataScadenzaDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMMDD",
    inputPath: "data.documentoidentita.datascadenza",
    outputPath: "cliente.documentoidentita.datascadenza",
    validation: string()
      .test("avoidPastDates", ERROR_DATE_SCADENZA_DOCUMENTO, avoidPastDates())
      .test("dateIsValid", ERROR_INVALID_DATE, checkInvalidDate(DEFAULT_DATE_FORMAT))
      .required(LABEL_REQUIRED),
  },
  provinciaRilascioDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.documentoidentita.provincia",
    outputPath: "cliente.documentoidentita.provincia",
    validation: string().required(LABEL_REQUIRED),
  },
  localitaRilascioDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.documentoidentita.localita",
    outputPath: "cliente.documentoidentita.localita",
    validation: string().required(LABEL_REQUIRED),
  },
  ufficioRilascioDocumento: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.documentoidentita.ufficio",
    outputPath: "cliente.documentoidentita.ufficio",
  },
  numeroTesseraSanitaria: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.tesserasanitaria",
    outputPath: "cliente.tesserasanitaria",
  },
  dataScadenzaTesseraSanitaria: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMMDD",
    inputPath: "data.scadenzatesserasanitaria",
    outputPath: "cliente.scadenzatesserasanitaria",
  },
  iban: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: true,
    inputPath: "pagamento.iban",
    outputPath: "pagamento.iban",
    validation: string()
      .required(LABEL_REQUIRED)
      .test("isIBANVAlid", ERROR_IBAN, value => IBAN.isValid(value)),
  },
  attivita: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.occupazione.attivita",
    outputPath: "cliente.occupazione.attivita",
    validation: string().required(LABEL_REQUIRED),
  },
  professione: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.occupazione.professione",
    outputPath: "cliente.occupazione.professione",
    validation: string().required(LABEL_REQUIRED),
  },
  dataUltimaOccupazione: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "date",
    format: "YYYYMM",
    inputPath: "data.occupazione.dataoccupazione",
    outputPath: "cliente.occupazione.dataoccupazione",
    validation: string().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string()
        .test("avoidFutureDates", ERROR_DATE_AVOID_FUTURE, avoidFutureDates())
        .test({
          name: "checkDateOfBirth",
          exclusive: false,
          message: ERROR_DATE_AFTER_BIRTH,
          test: function (strValue) {
            const momentValue = moment(strValue, MONTHYEAR_DATE_FORMAT);
            if (!momentValue.isValid()) {
              // input is not valid, validator should not care
              return true;
            }
            const value = momentValue.endOf("month");
            const dataNascita = this.resolve(ref("$value.dataNascita"));
            if (!dataNascita) {
              // data di nascita not found, validator should not care
              return true;
            }
            return value > moment(dataNascita);
          },
        })
        .test("dateIsValid", ERROR_INVALID_DATE, checkInvalidDate(MONTHYEAR_DATE_FORMAT))
        .required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  reddito: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.occupazione.reddito",
    outputPath: "cliente.occupazione.reddito",
    validation: string().when("attivita", {
      is: idAttivita => idAttivita !== ATTIVITA_SENZA_REDDITO,
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  ragioneSocialeDatoreLavoro: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.occupazione.datore.ragsoc",
    outputPath: "cliente.occupazione.datore.ragsoc",
    validation: mixed().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  indirizzoDatoreLavoro: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.occupazione.datore.indirizzo",
    outputPath: "cliente.occupazione.datore.indirizzo",
    validation: mixed().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  capDatoreLavoro: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.occupazione.datore.cap",
    outputPath: "cliente.occupazione.datore.cap",
    validation: mixed().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string().matches(capRegExp, ERROR_CAP).required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  telefonoDatoreLavoro: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.occupazione.datore.telefono",
    outputPath: "cliente.occupazione.datore.telefono",
    validation: mixed().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string()
        .matches(atLeast5CharsRegExp, ERROR_PHONE_MIN_NUMBERS)
        .matches(onlyNumbersRegExp, ERROR_PHONE_ONLY_NUMBER)
        .required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  provinciaDatoreLavoro: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.occupazione.datore.provincia",
    outputPath: "cliente.occupazione.datore.provincia",
    validation: mixed().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  localitaDatoreLavoro: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.occupazione.datore.localita",
    outputPath: "cliente.occupazione.datore.localita",
    validation: mixed().when("attivita", {
      is: idAttivita => !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(idAttivita),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  attivitaPrecedente: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.avc.attivitaprecedente",
    outputPath: "cliente.avc.attivitaprecedente",
    validation: string().when("dataUltimaOccupazione", {
      is: (val: string) => checkDataUltimoImpiego(val),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  occupazionePrecedente: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.avc.occupazioneprecedente",
    outputPath: "cliente.avc.occupazioneprecedente",
    validation: mixed().when("dataUltimaOccupazione", {
      is: (val: string) => checkDataUltimoImpiego(val),
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  redditiSecondari: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.avc.redditisecondari",
    outputPath: "cliente.avc.redditisecondari",
    validation: string().oneOf(Object.values(REDDITI_SECONDARI_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  fontiSecondari: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    type: "text",
    isUpperCase: false,
    inputPath: "data.avc.fontisecondari",
    outputPath: "cliente.avc.fontisecondari",
    validation: mixed().when("redditiSecondari", {
      is: REDDITI_SECONDARI_VALUES.true,
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  pep: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "data.avc.pep",
    outputPath: "cliente.avc.pep",
    validation: string().oneOf(Object.values(PEP_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  vuoiBeneficiareDellaDetrazioneFiscale: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "",
    // outputPath: "",
    validation: string().oneOf(Object.values(TAX_DEDUCTIONS_OPT_IN_VALUES), ERROR_YES_OR_NOT).required(LABEL_REQUIRED),
  },
  tipoDiAgevolazioneFiscale: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "",
    outputPath: "detrazioni.codice",
    validation: string().when("$isEnabled.tipoDiAgevolazioneFiscale", {
      is: true,
      then: string().required(LABEL_REQUIRED),
      otherwise: string().notRequired(),
    }),
  },
  numeroFattura: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "",
    outputPath: "detrazioni.fattura.numero",
    validation: string().notRequired(),
  },
  importoFattura: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "",
    outputPath: "detrazioni.fattura.importo",
    validation: string().notRequired(),
  },
  dataFattura: {
    value: "",
    cache: "",
    isEnabled: true,
    hasOnReviewError: false,
    inputPath: "",
    outputPath: "detrazioni.fattura.data",
    validation: string().notRequired(),
  },
};

export default initialCustomer;
