// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
    SendSMSInput,
    SendSMSOutput,
} from '../models';

export interface SendSmsRequest {
    esbBody: SendSMSInput;
}

/**
 * no description
 */
export class SendsmsApi extends BaseAPI {

    /**
     */
    sendSms = (requestParameters: SendSmsRequest): Observable<SendSMSOutput> => {
        throwIfRequired(requestParameters, 'esbBody', 'sendSms');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SendSMSOutput>({
            path: '/sendsms',
            method: 'POST',
            headers,
            body: requestParameters.esbBody,
        });
    };

}
