import React, { FunctionComponent, useContext, useEffect } from "react";
import Sticky from "react-sticky-el";
import Container from "../container/container";
import { Logo } from "../logo/logo";
import "./header.scss";
import { CustomizationContext } from "../../lib/customization/CustomizationContext";

interface IOwnProps {
  sideLogo?: string;
  sideTitle?: string;
  sideUrl?: string;
}

type Props = IOwnProps;

const Header: FunctionComponent<Props> = ({ sideLogo, sideTitle, sideUrl }) => {
  const {
    data: { hideScbLogo, logo, title, url },
  } = useContext(CustomizationContext);

  useEffect(() => {
    document.title = title && title;
  }, [title]);

  return (
    <Sticky stickyStyle={{ zIndex: 300 }} holderProps={{ className: "sticky-header" }}>
      <div className="header">
        <Container>
          <div className="header__logo">
            {/* PARTNER LOGO */}
            {logo ? (
              <div className="header__main-logo">
                {/*
                  we could have other options
                  in the customization object,
                  so we pass just what we need to
                */}
                <Logo {...{ logo, title, url }} />
              </div>
            ) : null}

            {/* VENDOR (SCB) LOGO */}
            {!hideScbLogo && sideLogo ? (
              <div className="header__side-logo">
                <Logo logo={sideLogo} title={sideTitle} url={sideUrl} />
              </div>
            ) : null}
          </div>
        </Container>
      </div>
    </Sticky>
  );
};

export default Header;
