// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DomandaDescriptor,
    EnumTipoControparte,
} from './';

/**
 * @export
 * @interface QuestionarioDescriptor
 */
export interface QuestionarioDescriptor {
    /**
     * @type {string}
     * @memberof QuestionarioDescriptor
     */
    esito?: string;
    /**
     * @type {string}
     * @memberof QuestionarioDescriptor
     */
    frase?: string;
    /**
     * @type {string}
     * @memberof QuestionarioDescriptor
     */
    codiceQuestionario?: string;
    /**
     * @type {string}
     * @memberof QuestionarioDescriptor
     */
    pacchetto?: string;
    /**
     * @type {EnumTipoControparte}
     * @memberof QuestionarioDescriptor
     */
    servizio?: EnumTipoControparte;
    /**
     * @type {string}
     * @memberof QuestionarioDescriptor
     */
    titoloQuestionario?: string;
    /**
     * @type {string}
     * @memberof QuestionarioDescriptor
     */
    descrizioneQuestionario?: string;
    /**
     * @type {Array<DomandaDescriptor>}
     * @memberof QuestionarioDescriptor
     */
    domande?: Array<DomandaDescriptor>;
}
