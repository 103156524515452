/* eslint-disable @typescript-eslint/no-explicit-any */

import get from "lodash.get";
import has from "lodash.has";
import isEqual from "lodash.isequal";
import uniqWith from "lodash.uniqwith";

// import { checkValuesRecursive } from "../utility/generic";
import { IAddress, INormalizzatoreError, INormalizzatoreOutput, INormalizzatoreResult } from "./normalizzatore.types";
import { checkValuesRecursive } from "../../../shared/lib/utility/generic";
import { WpNormRequest } from "../../../shared/generated/normalizzatore";

// Fix for DE-4600, ILL-92
// from EGON docs: FLXSEG Signal level. It can have the following values:
//   0 Ok:      No signal: datum checked and validated. No changes.
//   1 Warning: Datum checked and validated. Changes have been made.
//   2 Error.
//   3 Serious error.
const ADDRESS_NORMALIZER_ERROR_CODES = ["2", "3"];
//const ADDRESS_NORMALIZER_WARNING_CODES = ["1"];

const DEFAULT_ERROR_MESSAGE = "Indirizzo non trovato, verifica i dati inseriti.";
const ADDRESS_NOT_FOUND_ERROR_CODE = 312;

const getNormalizationError = (response: any) => get(response, "wpNormResult.SOG[0].SEG.ERR_620_ELE.ERR_620", []);
const getNormalizzatoreInput = (response: any) => get(response, "wpNormResult.SOG[0].INR_020");
const getNormalizzatoreOutput = (response: any) => get(response, "wpNormResult.SOG[0].INR_620");
const getNormalizzatoreSuggestionsList = (response: any) => get(getNormalizzatoreOutput(response), "INRCND", []);

const isAddressNormalized = (response: any) =>
  getNormalizationError(response).every((err: INormalizzatoreError) => {
    const errorFlag = get(err, "FLXSEG");
    return !ADDRESS_NORMALIZER_ERROR_CODES.includes(errorFlag); //||
    // We can ignore first element result because they are about località, and località should always be right
    // as it is selected from right values
    // Fix RISK-4483: Also ignore warning codes on all errors (commented line below)
    // (ADDRESS_NORMALIZER_WARNING_CODES.includes(errorFlag) && i != 0)
  });

const getNormalizedAddress = (response: any): IAddress => {
  const output = getNormalizzatoreOutput(response);

  return {
    provincia: get(output, "DSXLPOUFF.DSXDPT", ""),
    // Fix DE-4616. If DSXPLCUSR.DSXCNL < 24 letters, we can use this value, otherwise we have to use DSXPLCABB.DSXCN
    comune:
      has(output, "DSXPLCUFF.DSXCNL") && get(output, "DSXPLCUFF.DSXCNL").length <= 24
        ? get(output, "DSXPLCUFF.DSXCNL")
        : has(output, "DSXPLCUSR.DSXCNL") && get(output, "DSXPLCUSR.DSXCNL").length <= 24
        ? get(output, "DSXPLCUSR.DSXCNL")
        : get(output, "DSXPLCABB.DSXCNL"),
    cap: get(output, "CDXZIP", ""),
    // Fix RISK-4483: If DSXSTRUFF.DSXADD > 30 characters, use DSXSTRABB.DSXADD
    indirizzo:
      get(output, "DSXSTRUFF.DSXADD", "").length <= 30
        ? get(output, "DSXSTRUFF.DSXADD", "")
        : get(output, "DSXSTRABB.DSXADD", ""),
  };
};

const getSuggestionItem = (suggestionItem: any): IAddress | null => {
  if (checkValuesRecursive(suggestionItem, 0)) {
    return null;
  }

  // Sometimes the "wpNorm" function returns the value with an empty CAP.
  // We should REMOVE that value from the suggestion (as SCB Dealer Experience does)
  // null will be filtered out from the .filter(Boolean)
  if (!get(suggestionItem, "ZIPCND")) {
    return null;
  }

  return {
    comune: get(suggestionItem, "CNLCND"),
    cap: get(suggestionItem, "ZIPCND"),
    indirizzo: get(suggestionItem, "ST1CND"),
    provincia: get(suggestionItem, "DPTCND"),
  };
};

const getSuggestions = (response: any): IAddress[] => {
  const suggestions = getNormalizzatoreSuggestionsList(response)
    .map((suggestionItem: any) => getSuggestionItem(suggestionItem))
    .filter(Boolean);

  // DE-4579: Remove multiple results for an address over multiple fractions
  return uniqWith(suggestions, isEqual);
};

const getInput = (response: any): IAddress => {
  const input = getNormalizzatoreInput(response);

  return {
    provincia: get(input, "DSXPLC.DSXDPT"),
    indirizzo: get(input, "DSXST1.DSXVIA"),
    cap: get(input, "CDXZIP"),
    comune: get(input, "DSXPLC.DSXCNL"),
  };
};

// Fix SALES-14685
const getErrorToShow = (errors: INormalizzatoreError[]): string | undefined => {
  // from the array of errors returned by the normalizzatore, filter only the errors (with FLXSEG "2" or "3")
  const filteredErrors = errors.filter(error => ADDRESS_NORMALIZER_ERROR_CODES.includes(error.FLXSEG));

  // if there are no errors, clear errors
  if (filteredErrors.length === 0) {
    return undefined;
  }

  //get the first error
  const [firstError] = filteredErrors;

  // if the first error has CDPSEG === 312 or doesn't have a custom error
  // message, show a default message.
  if (firstError.CDPSEG === ADDRESS_NOT_FOUND_ERROR_CODE || firstError.DSXSEG === "") {
    return DEFAULT_ERROR_MESSAGE;
  }

  // otherwise show the error message for the error
  return firstError.DSXSEG;
};

export const parseResponse = (response: unknown): INormalizzatoreResult => {
  const input = getInput(response);
  const suggestions = getSuggestions(response);

  const resIsAddressNormalized = isAddressNormalized(response);
  const resHasSuggestions = suggestions.length > 0;

  const output: INormalizzatoreOutput = resIsAddressNormalized // if the address is normalized
    ? { address: getNormalizedAddress(response) } // return normalized address
    : resHasSuggestions // if has suggestions
    ? { suggestions } // return the list of suggestions
    : {};

  const errors = getNormalizationError(response);

  // Fix SALES-14685 - Return an error message to help the user choosing a correct address
  const errorToShow = getErrorToShow(errors);

  return {
    input,
    output,
    errors,
    errorToShow,
    isAddressNormalized: resIsAddressNormalized,
    hasSuggestions: resHasSuggestions,
  };
};

export const getNormalizzatoreInputParams = (address: Partial<IAddress>): WpNormRequest => ({
  Input: {
    parametro: {
      SOG: [
        {
          PAR: {
            INDRES: "S", // ?
            LIVENOR: "2", // ?
            CDXISO: "ITA", // codice ISO del paese per cui si vuole effettuare la ricerca
            WPUSER: "wp",
            WPPASW: "wp1299",
          },
          INR_020: {
            DSXPLC: {
              ...(address.provincia ? { DSXDPT: address.provincia } : {}), // provincia
              ...(address.comune ? { DSXCNL: address.comune } : {}), // comune
            },
            ...(address.cap ? { CDXZIP: address.cap } : {}), // CAP
            DSXST1: {
              DSXVIA: address.indirizzo, // stringa di ricerca
            },
          },
        },
      ],
    },
  },
});

/* eslint-enable @typescript-eslint/no-explicit-any */
