/**
 * Support functions for feature flag
 */

import { TFeatureFlagsRegister, TFeatureFlagsObject } from "../config/featureFlags";
import { FEATURE_FLAGS_KEY } from "../config/constants";
import { warn } from "./log";
import { SessionStorage } from "./utility/storage-v2";

export const evaluateEnvVar = (envVar: string | undefined): boolean => {
  if (envVar === undefined || envVar.toLowerCase() === "false") {
    return false;
  }

  if (envVar.toLowerCase() === "true") {
    return true;
  }

  warn(`Feature flag variable, if set, should be "true" or "false", given ${envVar}`);
  return false;
};

let cache = {};

export const getFeatureFlag = (key: TFeatureFlagsRegister): boolean => {
  if (cache[key] === undefined) {
    cache = SessionStorage.read(FEATURE_FLAGS_KEY) || {};
  }

  return cache[key];
};

export const setFeatureFlags = (obj: TFeatureFlagsObject): void => {
  const store = SessionStorage.read(FEATURE_FLAGS_KEY) || {};
  cache = { ...store, ...obj };

  SessionStorage.write(FEATURE_FLAGS_KEY, cache);
};
