import { EffectCallback, useEffect, useRef } from "react";

interface IUseDidUpdateEffect {
  // deps has been converted to an array of "unknown" for avoiding the "any" type
  // if that causes errors, just change it accordingly
  (effect: EffectCallback, deps?: readonly unknown[] | undefined): void;
}

export const useDidUpdateEffect: IUseDidUpdateEffect = (effect, deps): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effect();
    } else {
      didMountRef.current = true;
    }
  }, deps);
};
