// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    ErrorObject,
    GetFunzionalitaOutput,
    RecuperaSessioneOk,
    RiavviaOutput,
    RiavviaSessioneInput,
} from '../models';

export interface GetFunzionalitaRequest {
    step: string;
    token: string;
    numeroPreventivo: string;
    organization: string;
    X_B3_TraceId?: string;
    pratica?: string;
    tipoControparte?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    session_id?: string;
}

export interface RecuperaSessioneRequest {
    token: string;
    X_B3_TraceId?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    session_id?: string;
}

export interface RiavviaSessioneRequest {
    token: string;
    requestBody: RiavviaSessioneInput;
    X_B3_TraceId?: string;
    X_B3_SpanId?: string;
    app_origin?: string;
    session_id?: string;
}

/**
 * no description
 */
export class DurablesApi extends BaseAPI {

    /**
     */
    getFunzionalita = (requestParameters: GetFunzionalitaRequest): Observable<GetFunzionalitaOutput> => {
        throwIfRequired(requestParameters, 'step', 'getFunzionalita');
        throwIfRequired(requestParameters, 'token', 'getFunzionalita');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getFunzionalita');
        throwIfRequired(requestParameters, 'organization', 'getFunzionalita');

        const headers: HttpHeaders = {
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.session_id && { 'session-id': String(requestParameters.session_id) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.pratica && { 'pratica': requestParameters.pratica }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
            ...(requestParameters.organization && { 'organization': requestParameters.organization }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': requestParameters.tipoControparte }),
        };

        return this.request<GetFunzionalitaOutput>({
            path: '/funzionalita/{step}'.replace('{step}', encodeURI(requestParameters.step)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    recuperaSessione = (requestParameters: RecuperaSessioneRequest): Observable<RecuperaSessioneOk> => {
        throwIfRequired(requestParameters, 'token', 'recuperaSessione');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.session_id && { 'session-id': String(requestParameters.session_id) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.token && { 'token': requestParameters.token }),
        };

        return this.request<RecuperaSessioneOk>({
            path: '/recuperaSessione',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    riavviaSessione = (requestParameters: RiavviaSessioneRequest): Observable<RiavviaOutput> => {
        throwIfRequired(requestParameters, 'token', 'riavviaSessione');
        throwIfRequired(requestParameters, 'requestBody', 'riavviaSessione');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
            ...(requestParameters.X_B3_SpanId && { 'X-B3-SpanId': String(requestParameters.X_B3_SpanId) }),
            ...(requestParameters.app_origin && { 'app-origin': String(requestParameters.app_origin) }),
            ...(requestParameters.session_id && { 'session-id': String(requestParameters.session_id) }),
        };

        return this.request<RiavviaOutput>({
            path: '/riavviaSessione',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

}
