// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroINR020DSXST1
 */
export interface WpNormInputParametroINR020DSXST1 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020DSXST1
     */
    DSXDUG?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020DSXST1
     */
    DSXVIA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020DSXST1
     */
    NRPNUMCIV?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020DSXST1
     */
    DSXESP?: string;
}
