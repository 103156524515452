// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroRGS010LOCNASDSXPLCNAS,
    WpNormInputParametroRGS610ALTRO,
    WpNormInputParametroRGS610CDPPLCNAS,
} from './';

/**
 * @export
 * @interface WpNormInputParametroRGS610
 */
export interface WpNormInputParametroRGS610 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    CDXISO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    TPXANA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    FLXSEX?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXFRENAM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXCOG?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXMIDNAM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXNOM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXSUFNAM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXTITACC?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    CDPTITACC?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXTITAC2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    CDPTITAC2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXFRENAMABB?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXNOMABB?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXGIUUFF?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DSXGIUABB?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    CDPSPEGIU?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS610
     */
    DATNAS?: string;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroRGS610
     */
    DSXPLCNAS?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {WpNormInputParametroRGS610CDPPLCNAS}
     * @memberof WpNormInputParametroRGS610
     */
    CDPPLCNAS?: WpNormInputParametroRGS610CDPPLCNAS;
    /**
     * @type {WpNormInputParametroRGS610ALTRO}
     * @memberof WpNormInputParametroRGS610
     */
    ALTRO?: WpNormInputParametroRGS610ALTRO;
}
