import MaterialTextField, { HelperText, Input } from "@material/react-text-field";
import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash.get";
import * as React from "react";
import { Rifm } from "rifm";
import "./numeric_field.scss";
import { NUMERIC_INPUT_MAX_NUMBERS } from "../../constants/application";
import { IDataAttributes } from "../../../caricamento/types/form";

interface IOwnProps {
  // Shrink the field to a smallest variant. Defaults to true
  dense?: boolean;
  // Disable the field. Defaults to false
  disabled?: boolean;
  // Formik render prop
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikField: FieldProps<any>;
  // Defaults to true
  fullWidth?: boolean;
  helperText?: string | React.ReactNode;
  isCurrency?: boolean;
  // The label for the field
  label?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  serverSideError?: string;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
}

type Props = IOwnProps;

const NumericField: React.FunctionComponent<Props> = ({
  dense = false,
  disabled = false,
  formikField,
  fullWidth = true,
  helperText,
  isCurrency,
  label = "",
  onBlur,
  serverSideError,
  dataAttr,
}) => {
  const isPristine = !get(formikField.form.touched, formikField.field.name);
  const isValid = !((!isPristine && get(formikField.form.errors, formikField.field.name)) || serverSideError);

  const classes = classNames({
    "numeric-field--fullwidth": fullWidth,
  });

  const { onBlur: formikOnBlur } = formikField.field;

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }

    formikOnBlur(e);
  };

  const removeMantissa = (string: string): string => string.replace(/,\d+/g, "");
  const removeDots = (string: string): string => string.replace(/\./g, "");
  const truncateAtChar = (string: string, characterCount: number = NUMERIC_INPUT_MAX_NUMBERS) =>
    string.substring(0, characterCount);
  const addFakeMantissa = (string: string): string => `${string},00`;

  const formattedValue = (isCurrency ? removeMantissa(formikField.field.value) : formikField.field.value)
    .replace(/\./g, "")
    .split(",")[0];

  const changeMasked = (value: string) => {
    const sanitized = removeDots(isCurrency ? removeMantissa(value) : value);
    formikField.form.setFieldValue(formikField.field.name, sanitized);
  };

  const parseDigits = (string: string) => (removeMantissa(string).match(/\d+/g) || []).join("");

  const formatInteger = (string: string) => {
    const parsed = truncateAtChar(parseDigits(string), NUMERIC_INPUT_MAX_NUMBERS);
    const number = Number.parseInt(parsed, 10);
    if (Number.isNaN(number)) {
      return "";
    }
    const localeString = number.toLocaleString("it");
    return isCurrency ? addFakeMantissa(localeString) : localeString;
  };

  const renderInput = ({ value: rifmValue, onChange: rifmOnChange }: { value: string; onChange: () => void }) => (
    <MaterialTextField
      className={classes}
      dense={dense}
      helperText={
        serverSideError ? (
          <HelperText isValidationMessage={true} persistent={true} validation={true}>
            {serverSideError}
          </HelperText>
        ) : isPristine || isValid ? (
          helperText ? (
            <HelperText persistent={true} isValidationMessage={false}>
              {helperText}
            </HelperText>
          ) : (
            <HelperText>{/* Empty Component */}</HelperText>
          )
        ) : (
          <HelperText isValidationMessage={true} persistent={true} validation={true}>
            {get(formikField.form.errors, formikField.field.name)}
          </HelperText>
        )
      }
      label={label}
      outlined={false}
    >
      <Input
        type="tel"
        disabled={disabled}
        isValid={isValid}
        onBlur={blurHandler}
        value={rifmValue}
        onChange={rifmOnChange}
        name={formikField.field.name}
        data-cy={dataAttr && dataAttr.cypressSelector}
      />
    </MaterialTextField>
  );

  return (
    <div className="numeric-field">
      <Rifm format={formatInteger} value={formattedValue} onChange={changeMasked}>
        {renderInput}
      </Rifm>
    </div>
  );
};

export default React.memo(NumericField);
