// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    EligibilitaInput,
    PDFFile,
    PacchettiAssicurativi,
    PreventivoConsumo,
    PreventivoSecciResponse,
    ProvvigioneResponse,
    SalvaAllestimentoInput,
    SalvaDescrizioneBeneInput,
    SalvaPromoInput,
    StampeInput,
    Success,
    SuccessInformativaCheck,
    UpsellingPreventivoInput,
    UpsellingServiziInput,
    VarianteUpsellingLeasing,
    VarianteUpsellingServizi,
    VariantiUpsellingConsumo,
} from '../models';

export interface GetEligibilitaCheckRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    attivita?: string;
    professione?: string;
    statoAttivita?: string;
    neoAssunto?: string;
}

export interface GetPreventivoPromoRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetPreventivoStampeRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetSecciCheckRequest {
    numeroPreventivo: number;
    X_B3_TraceId?: string;
}

export interface PostPreventivoPromoRequest {
    X_B3_TraceId?: string;
    body?: SalvaPromoInput;
}

export interface PostPreventivoStampeRequest {
    X_B3_TraceId?: string;
    body?: StampeInput;
}

export interface PreventivoCOChecksRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoEligibilitaPostRequest {
    X_B3_TraceId?: string;
    body?: EligibilitaInput;
}

export interface PreventivoProvvigioniRequest {
    intermediario: string;
    numeroPreventivo: number;
    provenienza: PreventivoProvvigioniProvenienzaEnum;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoSalvaAllestimentoRequest {
    X_B3_TraceId?: string;
    body?: SalvaAllestimentoInput;
}

export interface PreventivoSalvaDescrizioneBeneRequest {
    X_B3_TraceId?: string;
    body?: SalvaDescrizioneBeneInput;
}

export interface PreventivoServiziCorrelatiRequest {
    intermediario: string;
    marca: string;
    codiceProdotto: string;
    tabellaFinanziaria: string;
    codiceServizio: string;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoServiziPacchettiRequest {
    intermediario: string;
    tabellaFinanziaria: string;
    codiceProdotto: string;
    codiceBene: string;
    tipoFinanziamento: PreventivoServiziPacchettiTipoFinanziamentoEnum;
    codiceEurotax: string;
    valoreBene: number;
    dataImmatricolazione: Date;
    tipoAnagrafica: PreventivoServiziPacchettiTipoAnagraficaEnum;
    dataNascita: Date;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoUpsellingCORequest {
    X_B3_TraceId?: string;
    body?: UpsellingPreventivoInput;
}

export interface PreventivoUpsellingLERequest {
    X_B3_TraceId?: string;
    body?: UpsellingPreventivoInput;
}

export interface PreventivoUpsellingServiziRequest {
    X_B3_TraceId?: string;
    body?: UpsellingServiziInput;
}

/**
 * no description
 */
export class P4Api extends BaseAPI {

    /**
     * ritorna la lista dei beni possibili per il tipoBene richiesto
     */
    getEligibilitaCheck = (requestParameters: GetEligibilitaCheckRequest): Observable<SuccessInformativaCheck> => {
        throwIfRequired(requestParameters, 'intermediario', 'getEligibilitaCheck');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getEligibilitaCheck');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
            ...(requestParameters.attivita && { 'attivita': requestParameters.attivita }),
            ...(requestParameters.professione && { 'professione': requestParameters.professione }),
            ...(requestParameters.statoAttivita && { 'statoAttivita': requestParameters.statoAttivita }),
            ...(requestParameters.neoAssunto && { 'neoAssunto': requestParameters.neoAssunto }),
        };

        return this.request<SuccessInformativaCheck>({
            path: '/preventivo/eligibilita/check',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Recupera una promo corredandola di descrizione, immagine e date di inizio/fine validità
     */
    getPreventivoPromo = (requestParameters: GetPreventivoPromoRequest): Observable<object> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoPromo');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoPromo');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<object>({
            path: '/preventivo/promo',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Logiche: <br/> <ol> <li> esegue stampa secci tramite servizio /preventivo/secci; <li> esegue stampa informative (se richiesto/necessario) tramite servizio /preventivo/informative; </ol>
     * Servizio per il download della documentazione richiesta per un preventivo
     */
    getPreventivoStampe = (requestParameters: GetPreventivoStampeRequest): Observable<PDFFile> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoStampe');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoStampe');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<PDFFile>({
            path: '/preventivo/stampe',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Verifica se il secci è stato processato per uno specifico numero preventivo
     */
    getSecciCheck = (requestParameters: GetSecciCheckRequest): Observable<PreventivoSecciResponse> => {
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getSecciCheck');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PreventivoSecciResponse>({
            path: '/preventivo/secci/{numeroPreventivo}'.replace('{numeroPreventivo}', encodeURI(requestParameters.numeroPreventivo)),
            method: 'GET',
            headers,
        });
    };

    /**
     * salva una promo corredandola di descrizione, immagine e date di inizio/fine validità
     */
    postPreventivoPromo = (requestParameters: PostPreventivoPromoRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/promo',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Logiche: <br/> <ol> <li> esegue stampa secci tramite servizio /preventivo/secci; <li> esegue stampa informative (se richiesto/necessario) tramite servizio /preventivo/informative; <li> invia la mail tramite servizio ESB /sendmail </ol>
     * Servizio per inviare via e-mail la documentazione di un preventivo
     */
    postPreventivoStampe = (requestParameters: PostPreventivoStampeRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/stampe',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * carica un preventivo consumo dato il suo id, con controlli aggiuntivi sulle configurazioni correnti per l\'utente in caso le configurazioni non siano più compatibili con quelle originali verrà rimosso il dato di output
     * carica un preventivo con controlli experience
     */
    preventivoCOChecks = (requestParameters: PreventivoCOChecksRequest): Observable<PreventivoConsumo> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoCOChecks');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'preventivoCOChecks');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
        };

        return this.request<PreventivoConsumo>({
            path: '/preventivo/CO/{numeroPreventivo}/checks'.replace('{numeroPreventivo}', encodeURI(requestParameters.numeroPreventivo)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    preventivoEligibilitaPost = (requestParameters: PreventivoEligibilitaPostRequest): Observable<Success> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<Success>({
            path: '/preventivo/eligibilita',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Restituisce l\'elenco delle provvigioni preveiste per il preventivo richiesto
     */
    preventivoProvvigioni = (requestParameters: PreventivoProvvigioniRequest): Observable<ProvvigioneResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoProvvigioni');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'preventivoProvvigioni');
        throwIfRequired(requestParameters, 'provenienza', 'preventivoProvvigioni');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
            ...(requestParameters.provenienza && { 'provenienza': requestParameters.provenienza }),
        };

        return this.request<ProvvigioneResponse>({
            path: '/preventivo/provvigioni',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * salva un allestimento su preventivo se non presente (si suppone quindi che per i casi di TCM, ad esempio, l\'allestimento sia sempre presente in fase di calcolo)
     * salva un allestimento su preventivo se non presente
     */
    preventivoSalvaAllestimento = (requestParameters: PreventivoSalvaAllestimentoRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/salva/allestimento',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * salva la descrizione del bene su preventivo solo se non presente
     * salva la descrizione del bene su preventivo solo se non presente
     */
    preventivoSalvaDescrizioneBene = (requestParameters: PreventivoSalvaDescrizioneBeneRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/salva/descrizioneBene',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Restituisce la lista dei servizi correlati, quindi da attivare, al servizio passato in input
     */
    preventivoServiziCorrelati = (requestParameters: PreventivoServiziCorrelatiRequest): Observable<object> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoServiziCorrelati');
        throwIfRequired(requestParameters, 'marca', 'preventivoServiziCorrelati');
        throwIfRequired(requestParameters, 'codiceProdotto', 'preventivoServiziCorrelati');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'preventivoServiziCorrelati');
        throwIfRequired(requestParameters, 'codiceServizio', 'preventivoServiziCorrelati');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.marca && { 'marca': requestParameters.marca }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.codiceServizio && { 'codiceServizio': requestParameters.codiceServizio }),
        };

        return this.request<object>({
            path: '/preventivo/servizi/correlati',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * api per raggruppare i servizi assicurativi in pacchetti e categorie
     */
    preventivoServiziPacchetti = (requestParameters: PreventivoServiziPacchettiRequest): Observable<PacchettiAssicurativi> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'codiceProdotto', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'codiceBene', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'codiceEurotax', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'valoreBene', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'dataImmatricolazione', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'tipoAnagrafica', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'dataNascita', 'preventivoServiziPacchetti');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.codiceEurotax && { 'codiceEurotax': requestParameters.codiceEurotax }),
            ...(requestParameters.valoreBene && { 'valoreBene': requestParameters.valoreBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.tipoAnagrafica && { 'tipoAnagrafica': requestParameters.tipoAnagrafica }),
            ...(requestParameters.dataNascita && { 'dataNascita': (requestParameters.dataNascita as any).toISOString() }),
        };

        return this.request<PacchettiAssicurativi>({
            path: '/preventivo/servizi/pacchetti',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Calcolo varianti per il consumo: <ul>   <li><strong>varianteDurataSuperiore:</strong> il calcolo del preventivo alla durata superiore se calcolabile   <li><strong>varianteDurataInferiore:</strong> il calcolo del preventivo alla durata inferiore se calcolabile </ul>
     * Calcolo varianti per il consumo
     */
    preventivoUpsellingCO = (requestParameters: PreventivoUpsellingCORequest): Observable<VariantiUpsellingConsumo> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<VariantiUpsellingConsumo>({
            path: '/preventivo/upselling/CO',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * L\'unica variante presente sarà l\'alternativa della tipologia importa alla firma
     * Calcolo varianti per il leasing
     */
    preventivoUpsellingLE = (requestParameters: PreventivoUpsellingLERequest): Observable<VarianteUpsellingLeasing> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<VarianteUpsellingLeasing>({
            path: '/preventivo/upselling/LE',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Calcolo variante con aggiunta di servizi
     * Calcolo variante con aggiunta di servizi
     */
    preventivoUpsellingServizi = (requestParameters: PreventivoUpsellingServiziRequest): Observable<VarianteUpsellingServizi> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<VarianteUpsellingServizi>({
            path: '/preventivo/upselling/servizi',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum PreventivoProvvigioniProvenienzaEnum {
    CO = 'CO',
    LE = 'LE'
}
/**
 * @export
 * @enum {string}
 */
export enum PreventivoServiziPacchettiTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum PreventivoServiziPacchettiTipoAnagraficaEnum {
    P = 'P',
    F = 'F',
    G = 'G'
}
