import { SEND_MAIL_URL_PLACEHOLDER, SEND_MAIL_NOME_PLACEHOLDER, SEND_MAIL_COGNOME_PLACEHOLDER } from "./application";

export const SEND_MAIL_MESSAGE = `
    Gentile ${SEND_MAIL_NOME_PLACEHOLDER} ${SEND_MAIL_COGNOME_PLACEHOLDER},
    <br><br>
    abbiamo ricevuto la tua richiesta di finanziamento. 
    <br><br>
    Qualora non avessi ancora caricato tutti i tuoi documenti <a href="${SEND_MAIL_URL_PLACEHOLDER}">clicca qui</a>.
    <br>
    In caso contrario, non dovrai fare altro e riceverai la mail con l'esito della tua pratica.
`;
export const OLD_SEND_MAIL_MESSAGE = `
<html>

<head>
    <title>Santander</title>

    <meta name="viewport" content="height=device-height, width=device-width, minimum-scale=1.0, user-scalable=no" />
    <meta name="format-detection" content="telephone=no">
    <meta charset="utf-8" />

    <style type="text/css">
        html {
            -webkit-text-size-adjust: none;
        }
        
        body,
        .ReadMsgBody,
        .ExternalClass,
        .multi-col .column {
            width: 100%;
            width: 100% !important;
            margin: 0px;
            padding: 0px;
            display: block;
        }
        
        .ExternalClass * {
            line-height: 100%;
        }
        
        table,
        tr,
        td {
            margin: 0;
            padding: 0;
            border-collapse: collapse;
            mso-line-height-alt: 0px;
            mso-margin-top-alt: 0px;
            mso-table-lspace: 0px;
            mso-table-rspace: 0px;
        }
        
        td {
            vertical-align: top;
        }
        /* a img, img{margin: 0px; padding: 0px; display: block;}*/
        
        a[href^="tel"],
        a[href^="sms"] {
            color: inherit;
            cursor: default;
            text-decoration: none;
        }
        
        .two-col .column {
            width: 300px;
        }
        
        .two-col .padded {
            width: 280px;
        }
        
        .three-col .column {
            width: 200px;
        }
        
        .three-col .padded {
            width: 188px;
        }
        
        .multi-col .padded {
            min-width: 65px;
        }
        
        @media only screen and (max-width: 568px) {
            /*[class*=mobileversion] {display:block !important; float:none !important; width:100% !important; height:auto !important; overflow:visible !important; line-height: normal !important; max-height:none !important; margin: 0; padding: 0px;}
      [class*=onlydesktop] {display:none !important; width:0 !important;}*/
            [class*=wrp] {
                min-width: 320px !important;
            }
            [class*=wrp] .padded320 {
                width: 320px !important;
                height: auto !important;
            }
            [class*=wrp] img {
                max-width: 100%;
                height: auto;
            }
            [class*=wrp] .img_social {
                max-width: 100%;
                height: 30px;
            }
            [class*=wrp] .image_responsive320 {
                -ms-interpolation-mode: bicubic;
                width: 100%;
                width: 320px;
                height: auto!important;
            }
            [class*=wrp] .image_responsive {
                -ms-interpolation-mode: bicubic;
                width: 100%;
                width: 280px;
                height: auto!important;
            }
            [class*=wrp] .columns,
            [class*=wrp] .column,
            [class*=wrp] .column-left,
            [class*=wrp] .column-right,
            [class*=wrp] .padded,
            [class*=wrp] .padded-left,
            [class*=wrp] .padded-right {
                width: 280px !important;
                height: auto !important;
            }
            [class*=wrp] .button {
                width: 260px !important;
                height: auto !important;
            }
            [class*=wrp] .padded-internal {
                width: 254px !important;
                height: auto !important;
            }
            [class*=wrp] .padding {
                width: 20px !important;
            }
            [class*=wrp] .center {
                text-align: center !important;
                margin: 0 auto;
            }
            [class*=wrp] .separator {
                border-bottom: 1px solid #6f6f6e;
            }
            [class*=wrp] .separator_dark {
                border-bottom: 1px solid #818181;
            }
        }
        
    </style>

</head>

<body style="margin: 0; padding: 0px; ">
    <table width="100%" cellpadding="0" cellspacing="0" class="wrp" style="border-collapse:collapse; border-spacing:0; min-width:618px; -webkit-text-size-adjust:none;">
        <tr>
            <td style="padding: 0px; vertical-align:top; width:9px;"><img style="margin: 0px; padding: 0px; display: block;" width="9" height="1"></td>
            <td width="100%" class="wrp" align="center" style="padding: 0px; margin: 0px; vertical-align:top; min-width:600px;">

                <table border="0" class="" cellpadding="0" cellspacing="0" align="center" style="border-collapse:collapse; border-spacing:0;" group_name="Header">
                    <tr>

                        <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;">
                            <table width="100%" cellpadding="0" cellspacing="0" bgcolor="#009fe3" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none; ">

                                <tr block_name="Image">
                                    <td align="center" valign="top" style="font-size:0; line-height:0px; height: 0px;" bgcolor="#FFFFFF">

                                        <!-- *** Immagine Responsive -->

                                        <!-- /Immagine Responsive -->

                                        <!-- *** Immagine Versione Desktop -->

                                        <a href="mailto:santanderconsumer@santanderconsumer.it">
                                            <br> </a><img style="margin: 0px; padding: 0px 0px 0px 0px; display: block" src="https://www.santanderconsumer.it/themes/custom/santander/images/top.jpg" title="Santander" border="0" class="onlydesktop" align="left">

                                    </td>
                                </tr>

                            </table>
                        </td>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:0px;"><img style="margin: 0px; padding: 0px; display: block;" width="10" height="1"></td>
                    </tr>
                </table>

                <table border="0" cellpadding="0" cellspacing="0" align="center" style="border-collapse:collapse; border-spacing:0;" group_name="Box Image Full 1" class="">
                    <tr>
                        <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;">
                            <!-- *** E -->
                            <table width="100%" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;">

                                <tr>
                                    <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;"><img style="margin: 0px; padding: 0px; display: block; height: 20px;" width="1" height="20" border="0"></td>
                                </tr>
                                <tr>
                                    <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;"><img style="margin: 0px; padding: 0px; display: block; height: 20px;" width="1" height="20" border="0"></td>
                                </tr>
                                <tr>
                                    <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;"><img style="margin: 0px; padding: 0px; display: block; height: 20px;" width="1" height="20" border="0"></td>
                                </tr>

                                <tr block_name="Copy" class="">
                                    <td style="vertical-align:top; width:580px; font-family:  Arial, Helvetica, sans-serif; font-size: 15px; line-height: 22px; color:#000000; text-align: left;">
                                        <br>



                                        


                                        Gentile ${SEND_MAIL_NOME_PLACEHOLDER} ${SEND_MAIL_COGNOME_PLACEHOLDER},
                                        <br><br>
                                        abbiamo ricevuto la tua richiesta di finanziamento. 
                                        <br><br>
                                        Qualora non avessi ancora caricato tutti i tuoi documenti <a href="${SEND_MAIL_URL_PLACEHOLDER}">clicca qui</a>.
                                        <br>
                                        In caso contrario, non dovrai fare altro e riceverai la mail con l'esito della tua pratica.
                                        <br><br>
                                        Per ulteriori informazioni, contattaci al numero 0116318043 oppure scrivi a <a href="mailto:adpp@santanderconsumer.it">adpp@santanderconsumer.it</a>           
                                        <br><br>
                                        Grazie per averci scelto.






                      
                                        <br />
                                        <br />
                                        <font color="#e30613">
                                            Santander Consumer Bank S.p.A. 
                                        </font>
                                        <br />
                                    </td>
                                </tr>

                                <tr>

                                </tr>

                            </table>
                            <!-- /E -->
                        </td>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                    </tr>
                </table>

                <table border="0" cellpadding="0" cellspacing="0" align="center" style="border-collapse:collapse; border-spacing:0;" group_name="Box Image Full 2" class="">
                    <tr>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                        <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;">
                            <!-- *** E -->
                            <table width="100%" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;">

                            </table>
                            <!-- /E -->
                        </td>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                    </tr>
                </table>

                <table border="0" cellpadding="0" cellspacing="0" align="center" style="border-collapse:collapse; border-spacing:0;" group_name="Box Image Full 2" class="">
                    <tr>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                        <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;">
                            <!-- *** E -->
                            <table width="100%" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;">

                            </table>
                            <!-- /E -->
                        </td>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                    </tr>
                </table>

                <table border="0" cellpadding="0" cellspacing="0" align="center" style="border-collapse:collapse; border-spacing:0;" group_name="Box Image Full 2" class="">
                    <tr>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                        <td class="padded" align="center" style="padding: 0px; vertical-align:top; width:580px;">
                            <!-- *** E -->
                            <table width="100%" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;">

                                <tr block_name="CTA 1" class="">
                                    <td align="center">
                                        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse; border-spacing:0;">
                                            <tr> </tr>

                                        </table>
                                    </td>
                                </tr>

                            </table>
                            <!-- /E -->
                        </td>
                        <td class="padding" style="padding: 0px; vertical-align:top; width:10px;"></td>
                    </tr>
                </table>

                <table group_name="DISCLAIMER" class="wrp" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none; min-width:600px;" border="0" cellpadding="0" cellspacing="0" align="center" width="100%" class="">

                    <tr>
                        <td class="wrp" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none; min-width:600px;" border="0" cellpadding="0" cellspacing="0" align="center" width="100%">

                            <table style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;" border="0" cellpadding="0" cellspacing="0" align="center">
                                <tr>
                                    <td bgcolor="#ffffff" style="padding: 0px; vertical-align:top; width:580px;" class="padded" align="center">
                                        <table style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;" border="0" cellpadding="0" cellspacing="0" align="center" width="100%">

                                            <tr block_name="Copy" class="">
                                                <td style="padding: 0px; vertical-align:top; width:580px; font-family:  Arial, Helvetica, sans-serif; font-size: 10px; line-height: 14px; color:#000000; text-align: center;">

                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table width="100%" style="border-collapse:collapse; border-spacing:0; -webkit-text-size-adjust:none;">

                                                        <tr>
                                                            <td style="padding: 0px; vertical-align:top; height:20px;" class="padding">&nbsp;</td>
                                                        </tr>

                                                        <tr block_name="Copy" class="">
                                                            <td style=" padding-right: 100px; vertical-align:top; width:580px; font-family:  Arial, Helvetica, sans-serif; font-size: 8px; line-height: 14px; color:#000000; text-align: justify;">
                                                                <p class="p1" style="text-align: justify;" data-mce-style="text-align: justify;">Questa è un'e-mail generata automaticamente. Ti preghiamo di non rispondere a questo messaggio poiché inviato da un indirizzo e-mail non programmato per la ricezione. TI informiamo che il presente messaggio ha carattere riservato e confidenziale ed è rivolto esclusivamente al destinatario. Qualora fosse stato ricevuto per errore esso non deve essere diffuso, distribuito, copiato, inoltrato o salvato. In tale caso, ti preghiamo di cancellare il messaggio e tutti i suoi allegati dagli archivi.</p>
                                                                <br>
                                                                <p class="p1" style="text-align: justify;" data-mce-style="text-align: justify;">&nbsp;</p>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td style="padding: 0px; vertical-align:top; width:10px;" class="padding"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>

            </td>

            <td style="padding: 0px; vertical-align:top; width:9px;"></td>
        </tr>
    </table>
</body>

</html>
`;
