// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    DatiVeicoloInput,
    DatiVeicoloOutput,
    DetrazioneFiscale,
    ErrorObject,
    GetQuestionarioAdeguatezza,
    Partner,
    PostQuestionarioAdeguatezzaOutput,
    QuestionarioAdeguatezzaRequest,
} from '../models';

export interface DatiVeicoloPrevIdGetRequest {
    prev_id: number;
    X_B3_TraceId?: string;
}

export interface DatiVeicoloPrevIdPostRequest {
    prev_id: number;
    datiVeicolo: DatiVeicoloInput;
    X_B3_TraceId?: string;
}

export interface DetrazioniFiscaliPrevIdGetRequest {
    prev_id: number;
    X_B3_TraceId?: string;
}

export interface PraticaQuestionarioAdeguatezzaGetRequest {
    numeroPratica: number;
    provenienza: PraticaQuestionarioAdeguatezzaGetProvenienzaEnum;
    X_B3_TraceId?: string;
    tipoControparte?: PraticaQuestionarioAdeguatezzaGetTipoControparteEnum;
}

export interface PraticaQuestionarioAdeguatezzaPostRequest {
    X_B3_TraceId?: string;
    body?: QuestionarioAdeguatezzaRequest;
}

export interface PreventivoPartnerPrevIdGetRequest {
    prev_id: number;
    X_B3_TraceId?: string;
}

/**
 * no description
 */
export class AdeguamentoSPACaricamentoApi extends BaseAPI {

    /**
     * Vengono restuiti, se prensenti a livello di preventivo, i dati di marca, modello, ecc ecc In caso di dati presenti verr� mostrata sulla SPA di caricamento una sezione per mostrarli in read-only
     */
    datiVeicoloPrevIdGet = (requestParameters: DatiVeicoloPrevIdGetRequest): Observable<DatiVeicoloOutput> => {
        throwIfRequired(requestParameters, 'prev_id', 'datiVeicoloPrevIdGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<DatiVeicoloOutput>({
            path: '/datiVeicolo/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Verr� invocata dal partner per associare
     */
    datiVeicoloPrevIdPost = (requestParameters: DatiVeicoloPrevIdPostRequest): Observable<void> => {
        throwIfRequired(requestParameters, 'prev_id', 'datiVeicoloPrevIdPost');
        throwIfRequired(requestParameters, 'datiVeicolo', 'datiVeicoloPrevIdPost');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/datiVeicolo/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'POST',
            headers,
            body: requestParameters.datiVeicolo,
        });
    };

    /**
     * A fronte di un preventivo, pilota la possibilita\' di richiedere delle detrazioni fiscali
     */
    detrazioniFiscaliPrevIdGet = (requestParameters: DetrazioniFiscaliPrevIdGetRequest): Observable<DetrazioneFiscale> => {
        throwIfRequired(requestParameters, 'prev_id', 'detrazioniFiscaliPrevIdGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<DetrazioneFiscale>({
            path: '/detrazioniFiscali/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Restituisce i questionari di adeguatezza per la pratica in oggetto. Qualora non vi fosse alcun questionario da compilare, restituisce array \"questionari\" vuoto
     */
    praticaQuestionarioAdeguatezzaGet = (requestParameters: PraticaQuestionarioAdeguatezzaGetRequest): Observable<GetQuestionarioAdeguatezza> => {
        throwIfRequired(requestParameters, 'numeroPratica', 'praticaQuestionarioAdeguatezzaGet');
        throwIfRequired(requestParameters, 'provenienza', 'praticaQuestionarioAdeguatezzaGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.numeroPratica && { 'numeroPratica': requestParameters.numeroPratica }),
            ...(requestParameters.provenienza && { 'provenienza': requestParameters.provenienza }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': requestParameters.tipoControparte }),
        };

        return this.request<GetQuestionarioAdeguatezza>({
            path: '/pratica/questionarioAdeguatezza',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ...
     */
    praticaQuestionarioAdeguatezzaPost = (requestParameters: PraticaQuestionarioAdeguatezzaPostRequest): Observable<PostQuestionarioAdeguatezzaOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PostQuestionarioAdeguatezzaOutput>({
            path: '/pratica/questionarioAdeguatezza',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Restituisce i questionari di adeguatezza per la pratica in oggetto
     */
    preventivoPartnerPrevIdGet = (requestParameters: PreventivoPartnerPrevIdGetRequest): Observable<Partner> => {
        throwIfRequired(requestParameters, 'prev_id', 'preventivoPartnerPrevIdGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<Partner>({
            path: '/preventivo/partner/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
            headers,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum PraticaQuestionarioAdeguatezzaGetProvenienzaEnum {
    CO = 'CO',
    LE = 'LE',
    CA = 'CA'
}
/**
 * @export
 * @enum {string}
 */
export enum PraticaQuestionarioAdeguatezzaGetTipoControparteEnum {
    CL = 'CL',
    CO = 'CO',
    G1 = 'G1',
    G2 = 'G2',
    G3 = 'G3',
    E1 = 'E1',
    E2 = 'E2',
    E3 = 'E3'
}
