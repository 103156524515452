import { FormikConfig, FormikHelpers } from "formik";
import { checkDataUltimoImpiego } from "../../../caricamento/components/forms/client_checks";
import { hasCommonFieldName } from "../../../caricamento/lib/dataHandling/dataHandling";
import { TFieldName, TServerSideErrors } from "../../../caricamento/lib/dataHandling/types";

export const isInitialValid = <T = Record<string, unknown>>(props: FormikConfig<T>): boolean => {
  if (!props.validationSchema) return true;
  return props.validationSchema.isValidSync(props.initialValues);
};

export const resetField: <T = Record<string, unknown>>(
  formikBag: Pick<FormikHelpers<T>, "setFieldValue" | "setFieldTouched">,
  fieldName: string,
  emptyValue?: string
) => void = (formikBag, fieldName, emptyValue = "") => {
  if (formikBag && fieldName) {
    formikBag.setFieldValue(fieldName, emptyValue);
    formikBag.setFieldTouched(fieldName, false);
  }
};

export const isFormSectionHidden = (
  isReviewing: boolean,
  isReviewingShowAll: boolean,
  fieldNames: TFieldName | TFieldName[],
  serverSideErrors: Partial<TServerSideErrors>
): boolean => {
  // allow fields to be also a single string
  const fieldNamesArray: TFieldName[] = typeof fieldNames === "string" ? [fieldNames] : (fieldNames as TFieldName[]);
  const hasErrors = hasCommonFieldName(fieldNamesArray, serverSideErrors);

  return isReviewing && !(isReviewingShowAll || hasErrors);
};

export const getNeoAssunto = (dataOccupazione?: string): boolean => {
  if (dataOccupazione) {
    return checkDataUltimoImpiego(dataOccupazione) ? true : false;
  }
  return false;
};
