// tslint:disable
/**
 * caricamento
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    CodiceIntermediario,
    DatiCFInput,
    DatiCodiceFiscale,
    DatiStorici,
    ErrorObj,
    Esito,
    EsitoPraticaInput,
    Files,
    InvioScbOK,
    PagamentiWL,
    PagamentoCarta,
    PagamentoCartaOK,
    Pratica,
    Preventivo,
    SuggerimentiDati,
    UploadDocVerifiedKO,
} from '../models';

export interface DatiCodiceFiscaleRequest {
    requestBody: DatiCFInput;
}

export interface EsitoPagamentoCartaRequest {
    requestBody: Pratica;
}

export interface GetEsitoPraticaRequest {
    requestBody: Pratica;
}

export interface InizializzaPagamentoCartaRequest {
    requestBody: PagamentoCarta;
}

export interface PagamentiWLRequest {
    requestBody: Preventivo;
}

export interface PutEsitoPraticaRequest {
    requestBody: EsitoPraticaInput;
}

export interface SalvaDatiStoriciRequest {
    requestBody: DatiStorici;
}

export interface SalvataggioPANCifratoRequest {
    a?: string;
    Status?: string;
    paymentID?: string;
    paymentToken?: string;
}

export interface UploadDocVerifiedRequest {
    requestBody: Files;
}

/**
 * no description
 */
export class SCBPaginaCaricamentoETApi extends BaseAPI {

    /**
     */
    codiceIntermediario = (): Observable<CodiceIntermediario> => {
        return this.request<CodiceIntermediario>({
            path: '/codice/intermediario',
            method: 'GET',
        });
    };

    /**
     */
    datiCodiceFiscale = (requestParameters: DatiCodiceFiscaleRequest): Observable<DatiCodiceFiscale> => {
        throwIfRequired(requestParameters, 'requestBody', 'datiCodiceFiscale');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<DatiCodiceFiscale>({
            path: '/dati/codice-fiscale',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    esitoPagamentoCarta = (requestParameters: EsitoPagamentoCartaRequest): Observable<Esito> => {
        throwIfRequired(requestParameters, 'requestBody', 'esitoPagamentoCarta');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Esito>({
            path: '/esito/pagamento/carta',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    getEsitoPratica = (requestParameters: GetEsitoPraticaRequest): Observable<Esito> => {
        throwIfRequired(requestParameters, 'requestBody', 'getEsitoPratica');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Esito>({
            path: '/pratica/esito',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    inizializzaPagamentoCarta = (requestParameters: InizializzaPagamentoCartaRequest): Observable<PagamentoCartaOK> => {
        throwIfRequired(requestParameters, 'requestBody', 'inizializzaPagamentoCarta');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PagamentoCartaOK>({
            path: '/inizializza/pagamento/carta',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    invioScb = (): Observable<InvioScbOK> => {
        return this.request<InvioScbOK>({
            path: '/invio/scb',
            method: 'GET',
        });
    };

    /**
     */
    pagamentiWL = (requestParameters: PagamentiWLRequest): Observable<PagamentiWL> => {
        throwIfRequired(requestParameters, 'requestBody', 'pagamentiWL');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<PagamentiWL>({
            path: '/pagamenti/wl',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    putEsitoPratica = (requestParameters: PutEsitoPraticaRequest): Observable<boolean> => {
        throwIfRequired(requestParameters, 'requestBody', 'putEsitoPratica');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<boolean>({
            path: '/pratica/esito',
            method: 'PUT',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    salvaDatiStorici = (requestParameters: SalvaDatiStoriciRequest): Observable<boolean> => {
        throwIfRequired(requestParameters, 'requestBody', 'salvaDatiStorici');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<boolean>({
            path: '/salva/dati/storici',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    salvataggioPANCifrato = (requestParameters: SalvataggioPANCifratoRequest): Observable<boolean> => {

        const query: HttpQuery = {
            ...(requestParameters.a && { 'a': requestParameters.a }),
            ...(requestParameters.Status && { 'Status': requestParameters.Status }),
            ...(requestParameters.paymentID && { 'paymentID': requestParameters.paymentID }),
            ...(requestParameters.paymentToken && { 'paymentToken': requestParameters.paymentToken }),
        };

        return this.request<boolean>({
            path: '/salvataggio/PAN/cifrato',
            method: 'GET',
            query,
        });
    };

    /**
     */
    suggerimentiDati = (): Observable<SuggerimentiDati> => {
        return this.request<SuggerimentiDati>({
            path: '/dati/suggerimenti',
            method: 'GET',
        });
    };

    /**
     */
    uploadDocVerified = (requestParameters: UploadDocVerifiedRequest): Observable<boolean> => {
        throwIfRequired(requestParameters, 'requestBody', 'uploadDocVerified');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<boolean>({
            path: '/verified-doc/upload',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

}
