// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    DocumentoPraticaItem,
    Errors,
    PreventivoOutput,
    SendMailInput,
    SendMailOutput,
} from '../models';

export interface GETPraticaDocumentiRequest {
    numeroPratica?: number;
    codiceIntermediario?: string;
    tipoControparte?: string;
}

export interface GETPreventivoRequest {
    prev_id: string;
}

export interface POSTPraticaDocumentiRequest {
    requestBody: DocumentoPraticaItem;
    numeroPratica?: number;
    tipoControparte?: string;
    token?: string;
    X_B3_TraceId?: string;
}

export interface POSTSendMailRequest {
    requestBody?: SendMailInput;
}

/**
 * no description
 */
export class SCBPaginaDocumentiApi extends BaseAPI {

    /**
     * Ritorna un elenco di documenti (mancanti)
     * Elenca i documenti mancanti
     */
    gETPraticaDocumenti = (requestParameters: GETPraticaDocumentiRequest): Observable<Array<DocumentoPraticaItem>> => {

        const headers: HttpHeaders = {
            ...(requestParameters.tipoControparte && { 'tipoControparte': String(requestParameters.tipoControparte) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.numeroPratica && { 'numeroPratica': requestParameters.numeroPratica }),
            ...(requestParameters.codiceIntermediario && { 'codiceIntermediario': requestParameters.codiceIntermediario }),
        };

        return this.request<Array<DocumentoPraticaItem>>({
            path: '/pratica/documenti/attesi',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Ottiene i dati del preventivo, necessari per visualizzarli nell\'interfaccia e per salvare lapratica.**Attenzione**: Accetta sia Token1 che Token 3, in quanto viene utilizzata sia nella SPA Caricamento che nella SPA Documenti
     * Ottieni Preventivo
     */
    gETPreventivo = (requestParameters: GETPreventivoRequest): Observable<PreventivoOutput> => {
        throwIfRequired(requestParameters, 'prev_id', 'gETPreventivo');

        return this.request<PreventivoOutput>({
            path: '/preventivo/{prev_id}'.replace('{prev_id}', encodeURI(requestParameters.prev_id)),
            method: 'GET',
        });
    };

    /**
     * Carica un singolo documento
     * Upload documenti
     */
    pOSTPraticaDocumenti = (requestParameters: POSTPraticaDocumentiRequest): Observable<object> => {
        throwIfRequired(requestParameters, 'requestBody', 'pOSTPraticaDocumenti');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.numeroPratica && { 'numeroPratica': String(requestParameters.numeroPratica) }),
            ...(requestParameters.tipoControparte && { 'tipoControparte': String(requestParameters.tipoControparte) }),
            ...(requestParameters.token && { 'token': String(requestParameters.token) }),
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<object>({
            path: '/pratica/documenti',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

    /**
     */
    pOSTSendMail = (requestParameters: POSTSendMailRequest): Observable<SendMailOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<SendMailOutput>({
            path: '/send/mail',
            method: 'POST',
            headers,
            body: requestParameters.requestBody,
        });
    };

}
