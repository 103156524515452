// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface ReportFEInput
 */
export interface ReportFEInput {
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    preventivo?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    sessionId?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    timeCode?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    lead?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    send?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    assicurazione?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    clientType?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    idMedia?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    csrfToken?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    typEnabled?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    message?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    autorizzo?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    fromCampaign?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    idCampaign?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    campaign?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    errors?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    hasProduct?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    product?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    filiale?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    fToken?: string;
    /**
     * @type {string}
     * @memberof ReportFEInput
     */
    gtp?: string;
}
