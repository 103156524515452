// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroRGS010LOCNASDSXPLCNAS
 */
export interface WpNormInputParametroRGS010LOCNASDSXPLCNAS {
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXCNY?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXSTA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXREG?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXDPT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXCNL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXDS1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXDS2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroRGS010LOCNASDSXPLCNAS
     */
    DSXDS3?: string;
}
