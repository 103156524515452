import { TCustomFormName, TCustomCustomer } from "./types";

export const getCustomKeys = (formName: TCustomFormName): Array<TCustomFormName> | undefined => {
  let keys: Array<TCustomFormName>;

  switch (formName) {
    //To be defined
    default:
      break;
  }

  return keys;
};

// the author wants it this way: src\caricamento\lib\dataHandling\dataHandling.ts @596
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCustomFormElements = (customer: TCustomCustomer, formName: TCustomFormName): Record<string, any> => {
  const customValidationContext = {};
  const customExtra = {};

  switch (formName) {
    //To be defined
    default:
      break;
  }

  return { customValidationContext, customExtra };
};
