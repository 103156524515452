import { Observable } from "rxjs";
import { PartnerNames } from "../../shared/config/customization";
import {
  SEND_MAIL_COGNOME_PLACEHOLDER,
  SEND_MAIL_NOME_PLACEHOLDER,
  SEND_MAIL_PREVENTIVO_PLACEHOLDER,
  SEND_MAIL_SUBJECT,
  SEND_MAIL_URL_PLACEHOLDER,
} from "../../shared/constants/application";
import { SEND_MAIL_MESSAGE } from "../../shared/constants/email_message";
import { SCBPaginaDocumentiApi, SendMailInput, SendMailOutput } from "../../shared/generated/whiteLabel";
import { TAllValues } from "./dataHandling/types";
import { whitelabelApiConfig } from "./whitelabelApiConfig";

const api = new SCBPaginaDocumentiApi(whitelabelApiConfig());

const getMailMessage = (dynamicUrl: string, values: TAllValues) =>
  SEND_MAIL_MESSAGE.replace(SEND_MAIL_URL_PLACEHOLDER, dynamicUrl)
    .replace(SEND_MAIL_NOME_PLACEHOLDER, values.nome ? values.nome.toString().toUpperCase() : "")
    .replace(SEND_MAIL_COGNOME_PLACEHOLDER, values.cognome ? values.cognome.toString().toUpperCase() : "");

const getMailSubject = (preventivoId: string) =>
  SEND_MAIL_SUBJECT.replace(SEND_MAIL_PREVENTIVO_PLACEHOLDER, preventivoId);

export const sendMail$ = (
  values: TAllValues,
  dynamicUrl: string,
  preventivoId: string,
  partnerName?: PartnerNames
): Observable<SendMailOutput> => {
  //TODO verify if it's working fine
  const sendMailParams: SendMailInput = ({
    headers: {
      "X-Customer-Campaign-ID": "illimity",
    },
    recipient: {
      subject: getMailSubject(preventivoId),
      destination: {
        to: [
          {
            "X-name": `${values.nome} ${values.cognome}`,
            "X-email": values.email,
          },
        ],
      },
      message: {
        template: partnerName ? partnerName : "",
        type: "text/html",
        textbinary: btoa(getMailMessage(dynamicUrl, values)),
      },
    },
  } as unknown) as SendMailInput;

  return api.pOSTSendMail({ requestBody: sendMailParams });
};
