// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroINR020DSXST1,
    WpNormInputParametroRGS010LOCNASDSXPLCNAS,
} from './';

/**
 * @export
 * @interface WpNormInputParametroINR020
 */
export interface WpNormInputParametroINR020 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    TP9IND?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    TP9INDSOT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    CDXISO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    TPXCDK?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    TPXTED?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    CDPLNG?: string;
    /**
     * @type {WpNormInputParametroRGS010LOCNASDSXPLCNAS}
     * @memberof WpNormInputParametroINR020
     */
    DSXPLC?: WpNormInputParametroRGS010LOCNASDSXPLCNAS;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    CDXZIP?: string;
    /**
     * @type {WpNormInputParametroINR020DSXST1}
     * @memberof WpNormInputParametroINR020
     */
    DSXST1?: WpNormInputParametroINR020DSXST1;
    /**
     * @type {WpNormInputParametroINR020DSXST1}
     * @memberof WpNormInputParametroINR020
     */
    DSXST2?: WpNormInputParametroINR020DSXST1;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXPREINR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXBLD?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXSUBBLD?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXCRFINR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXPBXINR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXPREDIR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXPSTDIR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXCPNINR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXDPTINR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR020
     */
    DSXFMLINR?: string;
}
