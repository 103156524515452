/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from "react";
import { setGoogleTagManagerOrganization } from "../../../caricamento/components/forms/hooks/useGoogleTagManager";
import { PARTNER_NAME_KEY } from "../../../caricamento/config/application";
import { getPartnerCustomizations, initialCustomization } from "../../config/customization";
import { error } from "../log";
import { SessionStorage } from "../utility/storage-v2";
import { IUseCustomization, ICustomizationOptions } from "./customization.types";

export enum customizationHookState {
  // 0. initial state, waits for events
  IDLE,

  // 1. waits for some event to complete
  LOADING,

  // 2. successful operation
  SUCCESS,

  // 3. unsuccessful operation
  ERROR,
}

const applyPartnerTheme = (theme?: string): Promise<boolean> => {
  return new Promise(resolve => {
    theme && document.body.classList.add(theme);
    resolve(true);
  });
};

export const useCustomization: IUseCustomization = (prevId, getPartnerName, enabled, jwt, partner) => {
  const [data, setData] = useState<ICustomizationOptions>(initialCustomization);
  const [state, setState] = useState<customizationHookState>(customizationHookState.IDLE);

  /**
   * listens for prev_id and makes the API
   * call if it's truthy
   */
  useEffect(() => {
    if (!enabled) {
      setState(customizationHookState.ERROR);
      return;
    }
    if (partner) {
      const customization = getPartnerCustomizations(partner);
      applyPartnerTheme(customization?.theme).then(() => {
        setData(customization);
        setState(customizationHookState.SUCCESS);
      });
    }
    if (enabled && prevId && jwt) {
      setState(customizationHookState.LOADING);

      getPartnerName({ prev_id: prevId }).subscribe(
        // success
        ({ nome }) => {
          SessionStorage.write(PARTNER_NAME_KEY, nome);
          nome && setGoogleTagManagerOrganization(nome);

          const customization = getPartnerCustomizations(nome);

          applyPartnerTheme(customization?.theme).then(() => {
            setData(customization);
            setState(customizationHookState.SUCCESS);
          });
        },

        // error
        err => {
          setState(customizationHookState.ERROR);
          error("@useCustomization", err);
        }
      );
    }
  }, [prevId, enabled, jwt, partner]);

  return { data, state, setState };
};
