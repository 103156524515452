// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RaggruppamentoAssicurativo,
} from './';

/**
 * @export
 * @interface CategoriaAssicurativa
 */
export interface CategoriaAssicurativa {
    /**
     * @type {string}
     * @memberof CategoriaAssicurativa
     */
    codice?: CategoriaAssicurativaCodiceEnum;
    /**
     * @type {Array<RaggruppamentoAssicurativo>}
     * @memberof CategoriaAssicurativa
     */
    raggruppamenti?: Array<RaggruppamentoAssicurativo>;
}

/**
 * @export
 * @enum {string}
 */
export enum CategoriaAssicurativaCodiceEnum {
    POLIZZA = 'POLIZZA',
    MANUTENZIONE = 'MANUTENZIONE'
}

