import { PERSONAL_DATA_PATH, UPLOAD_PATH } from "../config/constants";
import {
  CODICE_FISCALE_QUERY_PARAMETER,
  EXIT_URL_QUERY_PARAMETER,
  ID_CAMPAIGN_SESSION_KEY,
  ID_MEDIA_SESSION_KEY,
} from "../constants/application";

export function makeUrlParams(params: Record<string, string | number | boolean | undefined>): string {
  // no undefined
  // no empty strings
  // we need to let `false` pass as a value so we can't `!val`
  const queryString = Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, val]) => val !== undefined && val !== "")
    .map(([key, val]) => `${key}=${val}`)
    .join("&");

  if (queryString.length) {
    return "?" + queryString;
  }

  return "";
}

export const genericComposeURL = (
  token: string | undefined,
  exitUrl: string | undefined,
  codiceFiscale: string | number | undefined,
  appPath: typeof UPLOAD_PATH | typeof PERSONAL_DATA_PATH,
  idMedia?: string,
  idCampaign?: string
): readonly string[] => {
  const toAppURL =
    `${appPath}/authenticate/${token}` +
    makeUrlParams({
      [EXIT_URL_QUERY_PARAMETER]: exitUrl,
      [CODICE_FISCALE_QUERY_PARAMETER]: codiceFiscale,
      [ID_MEDIA_SESSION_KEY]: idMedia,
      [ID_CAMPAIGN_SESSION_KEY]: idCampaign,
    });

  const toEmailURL =
    `${appPath}/authenticate/${token}` +
    makeUrlParams({
      [EXIT_URL_QUERY_PARAMETER]: exitUrl,
      [ID_MEDIA_SESSION_KEY]: idMedia,
      [ID_CAMPAIGN_SESSION_KEY]: idCampaign,
    });

  return [toAppURL, toEmailURL] as const;
};
