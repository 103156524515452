import { useEffect, useState } from "react";
import { TCustomer, TForms } from "../../../lib/dataHandling/types";
import { ISavePratica } from "../../../logic/caricamento/types";
import { TCustomCustomer } from "../../../../custom/libraries/dataHandling/types";

interface IUseSendToServer {
  (
    senderThunkCaller: (
      customer: TCustomer | TCustomCustomer,
      preventivoId: string,
      intermediario: string
    ) => ISavePratica,
    forms: Partial<TForms>,
    customer?: TCustomer | TCustomCustomer,
    preventivoId?: string,
    intermediario?: string
  ): (flag: boolean) => void;
}

export const useSendToServer: IUseSendToServer = (senderThunkCaller, forms, customer, preventivoId, intermediario) => {
  const [isAskingToSend, setIsAskingToSend] = useState(false);
  useEffect(() => {
    const isFormComplete = forms.every(form => form?.isComplete);
    // This condition guards the proper completion of the form and the objective
    // demand to submit it.The application dispatches the API calls to send data
    // to server and related effects only if it is verified.
    if (isAskingToSend && isFormComplete && customer && preventivoId && intermediario) {
      senderThunkCaller(customer, preventivoId, intermediario);
      setIsAskingToSend(false);
    }
  }, [isAskingToSend, forms, customer, preventivoId]);

  return setIsAskingToSend;
};
