import get from "lodash.get";
import moment from "moment";
import { Dispatch } from "redux";
import { Observable } from "rxjs/internal/Observable";
import { concatMap } from "rxjs/operators";
import { STOP_SAVE_PRATICA } from "../../../caricamento/logic/caricamento/consts";
import { errorAction } from "../../../upload/lib/handlers";
import { CARICAMENTO_TOKEN_SESSION_KEY, UPLOAD_TOKEN_SESSION_KEY } from "../../config/application";
import { ID_CAMPAIGN_SESSION_KEY, ID_MEDIA_SESSION_KEY, ID_PREVENTIVO_SESSION_KEY } from "../../constants/application";
import { AuthDocumentiRequest, PartnerApi } from "../../generated/Authentication";
import {
  BASE_PATH,
  CaricamentoPraticaApi,
  Configuration,
  DefaultApi as E2eApi,
  Eligibilita,
  GetEligibilitaCheckRequest,
  GetSecciRequest,
  PDFFile,
  PreventivoDatiCheckGetRequest,
  PreventivoDatiCheckResponse,
  PreventivoEligibilitaGetRequest,
  PreventivoEligibilitaPostRequest,
  ProcessApi,
  Secci,
  Success,
  SuccessInformativaCheck,
} from "../../generated/e2e";
import { Middleware } from "../../generated/globals";
import { DefaultApi, LoaderServiziOutput } from "../../generated/loaderServizi";
import {
  Controparte,
  DefaultApi as WhitelabelApi,
  GetListaCodiceServizi,
  GETPreventivoRequest,
  NuovaControparteDataOccupazione,
  PraticaConsumoOk,
  PraticaPianoFinanziarioRequest,
  PraticaQuestionarioAdeguatezzaDownsellingGetRequest,
  PraticaRichiestaModificaRequest,
  PreventivoOutput,
  PreventivoRicalcoloPostRequest,
  SCBPaginaCaricamentoApi,
  SPAModificaPraticaApi,
} from "../../generated/whiteLabel";
import { addTracingHeaders } from "../../lib/addTracingHeaders";
import { authenticationApiConfig } from "../../lib/authenticationApiConfig";
import { environmentApiBasePath } from "../../lib/environmentApiBasePath";
import { getFeatureFlag } from "../../lib/featureFlagsFunctions";
import downloadDocumento from "../../lib/file.utils";
import { getNeoAssunto } from "../../lib/form/form";
import { loaderServiziApiConfig } from "../../lib/loaderServiziApiConfig";
import { error, log } from "../../lib/log";
import { sharedTokenMiddleware } from "../../lib/sharedTokenMiddleware";
import { SessionStorage } from "../../lib/utility/storage-v2";
import { whitelabelApiConfig } from "../../lib/whitelabelApiConfig";
import IAction, { AsyncActionReturnType } from "../../types/actions";
import {
  GET_CUSTOMER_FAILED,
  GET_CUSTOMER_PENDING,
  GET_CUSTOMER_SUCCEED,
  GET_DATI_CHECK_FAILED,
  GET_DATI_CHECK_PENDING,
  GET_DATI_CHECK_SUCCEED,
  GET_ELEGIBILITA_INFO_DOCS_FAILED,
  GET_ELEGIBILITA_INFO_DOCS_PENDING,
  GET_ELEGIBILITA_INFO_DOCS_SUCCEED,
  GET_ELIGIBILITA_CHECK_FAILED,
  GET_ELIGIBILITA_CHECK_PENDING,
  GET_ELIGIBILITA_CHECK_SUCCEED,
  GET_ELIGIBILITA_FAILED,
  GET_ELIGIBILITA_PENDING,
  GET_ELIGIBILITA_SUCCEED,
  GET_PREVENTIVO_FAILED,
  GET_PREVENTIVO_PENDING,
  GET_PREVENTIVO_SECCI_FAILED,
  GET_PREVENTIVO_SECCI_PENDING,
  GET_PREVENTIVO_SECCI_SUCCEED,
  GET_PREVENTIVO_SUCCEED,
  GET_QUESTIONNAIRES_DOWNSELLING_FAILED,
  GET_QUESTIONNAIRES_DOWNSELLING_PENDING,
  GET_QUESTIONNAIRES_DOWNSELLING_SUCCEED,
  GET_SERVIZI_FAILED,
  GET_SERVIZI_PENDING,
  GET_SERVIZI_SUCCEED,
  INCOMPATIBILITY_MODAL_DISMISS,
  INCOMPATIBILITY_MODAL_DISMISS_ALL,
  INCOMPATIBILITY_MODAL_PROCEED,
  POST_PIANO_FINANZIARIO_FAILED,
  POST_PIANO_FINANZIARIO_PENDING,
  POST_PIANO_FINANZIARIO_SUCCEED,
  POST_PREVENTIVO_RICALCOLO_FAILED,
  POST_PREVENTIVO_RICALCOLO_PENDING,
  POST_PREVENTIVO_RICALCOLO_SUCCEED,
  PRATICA_RICHIESTA_MODIFICA_FAILED,
  RETURN_TO_PERSONAL_DATA_FAILED,
  RETURN_TO_PERSONAL_DATA_PENDING,
  RETURN_TO_PERSONAL_DATA_SUCCEED,
  SET_IS_INCOMPATIBLE,
  SWITCH_CL,
  UPLOAD_CONTINUE,
} from "./consts";
import { getAnswers } from "./selectors";
import {
  AuthDocumentiObservableGenerator,
  AuthRecallObservableGenerator,
  EligibilitaObservableGenerator,
  GetCustomerObservableGenerator,
  TModalType,
} from "./types";
import { randomSessionId } from "./utils";

/**API CONFIG */
const caricamentoApi = (preTokenMiddleware: Middleware): SCBPaginaCaricamentoApi =>
  new SCBPaginaCaricamentoApi(whitelabelApiConfig({ middleware: [addTracingHeaders, preTokenMiddleware] }));
const modificaPraticaApi = (preTokenMiddleware: Middleware): SPAModificaPraticaApi =>
  new SPAModificaPraticaApi(whitelabelApiConfig({ middleware: [addTracingHeaders, preTokenMiddleware] }));
const processApi = (preTokenMiddleware: Middleware): ProcessApi =>
  new ProcessApi(
    new Configuration({
      basePath: environmentApiBasePath(BASE_PATH),
      middleware: [addTracingHeaders, preTokenMiddleware],
    })
  );
const caricamentoPraticaApi = (preTokenMiddleware: Middleware): CaricamentoPraticaApi =>
  new CaricamentoPraticaApi(
    new Configuration({
      basePath: environmentApiBasePath(BASE_PATH),
      middleware: [addTracingHeaders, preTokenMiddleware],
    })
  );
const serviziApi = (preTokenMiddleware: Middleware): DefaultApi =>
  new DefaultApi(loaderServiziApiConfig({ middleware: [addTracingHeaders, preTokenMiddleware] }));

const e2eApi = (preTokenMiddleware: Middleware): E2eApi =>
  new E2eApi(
    new Configuration({
      basePath: environmentApiBasePath(BASE_PATH),
      middleware: [addTracingHeaders, preTokenMiddleware],
    })
  );

const whitelabelApi = new WhitelabelApi(
  whitelabelApiConfig({ middleware: [addTracingHeaders, sharedTokenMiddleware(UPLOAD_TOKEN_SESSION_KEY)] })
);

const partnerApi = (preTokenMiddleware: Middleware): PartnerApi =>
  new PartnerApi(
    authenticationApiConfig({
      middleware: [addTracingHeaders, preTokenMiddleware],
    })
  );

/**OBSERVABLES*/
const eligibilita$: EligibilitaObservableGenerator = (requestParameters, preTokenMiddleware) =>
  caricamentoPraticaApi(preTokenMiddleware).preventivoEligibilitaGet(requestParameters);

const authDocumenti$: AuthDocumentiObservableGenerator = (preTokenMiddleware, requestParameters) =>
  partnerApi(preTokenMiddleware).authDocumenti(requestParameters);

const authRecall$: AuthRecallObservableGenerator = preTokenMiddleware =>
  partnerApi(preTokenMiddleware).authCaricamentoRecall();

const getCustomer$: GetCustomerObservableGenerator = preTokenMiddleware =>
  caricamentoApi(preTokenMiddleware).gETCliente({ as400: true });

/**ACTIONS*/

export const setIsIncompatible = (
  isIncompatible: boolean,
  message: string
): IAction<{ isIncompatible: boolean; message: string }> => ({
  type: SET_IS_INCOMPATIBLE,
  payload: { isIncompatible, message },
});

export const getPreventivo = (prevId: string, preTokenMiddleware: Middleware) => async (
  dispatch: Dispatch<IAction<PreventivoOutput>>
): Promise<void> => {
  const requestParameters: GETPreventivoRequest = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    prev_id: prevId,
  };
  dispatch({
    type: GET_PREVENTIVO_PENDING,
  });
  caricamentoApi(preTokenMiddleware)
    .gETPreventivo(requestParameters)
    .subscribe(
      payload => {
        dispatch({
          payload,
          type: GET_PREVENTIVO_SUCCEED,
        });
      },
      errorPayload => dispatch(errorAction(GET_PREVENTIVO_FAILED, errorPayload))
    );
};

export const getServizi = (preTokenMiddleware: Middleware) => async (
  dispatch: Dispatch<IAction<LoaderServiziOutput>>
): Promise<void> => {
  dispatch({
    type: GET_SERVIZI_PENDING,
  });
  serviziApi(preTokenMiddleware)
    .loaderServiziGet()
    .subscribe(
      payload => {
        dispatch({
          payload,
          type: GET_SERVIZI_SUCCEED,
        });
      },
      errorPayload => dispatch(errorAction(GET_SERVIZI_FAILED, errorPayload))
    );
};

/**
 * preventivoEligibilitaGet -> preventivoEligibilitaPost -> getSecci
 */
export interface IAnswerParams {
  attivita: string;
  professione: string;
  dataOccupazione?: string;
  provinciaDatore?: string;
}
export const getEligibilitaInfoDocs = (
  intermediario: string,
  numeroPreventivo: number,
  eligibilita: Eligibilita,
  preTokenMiddleware: Middleware,
  answerParams: IAnswerParams
): AsyncActionReturnType<Eligibilita | Success> => async dispatch => {
  dispatch({
    type: GET_ELEGIBILITA_INFO_DOCS_PENDING,
  });

  const questionario = eligibilita.questionario;
  const { attivita, professione, dataOccupazione, provinciaDatore } = answerParams;

  if (questionario) {
    const neoAssunto = getNeoAssunto(dataOccupazione) ? "S" : "N";
    const statoDatore = provinciaDatore === "*" ? "E" : "I";

    const preventivoEligibilitaPostRequest: PreventivoEligibilitaPostRequest = {
      body: {
        intermediario: intermediario,
        numeroPreventivo: numeroPreventivo,
        risposte: getAnswers(questionario, attivita, professione, neoAssunto, statoDatore),
      },
    };
    caricamentoPraticaApi(preTokenMiddleware)
      .preventivoEligibilitaPost(preventivoEligibilitaPostRequest)
      .subscribe(
        () => {
          const preventivoSecciGetRequest: GetSecciRequest = {
            intermediario: intermediario,
            numeroPreventivo: numeroPreventivo,
          };

          processApi(preTokenMiddleware)
            .getSecci(preventivoSecciGetRequest)
            .subscribe(
              preventivoSecciGetResponse => {
                let nome = "";
                let stream = "";
                if (Array.isArray(preventivoSecciGetResponse.secci?.file)) {
                  const fileSecci: PDFFile = preventivoSecciGetResponse.secci?.file[0];
                  nome = get(fileSecci, "nome", "download");
                  stream = get(fileSecci, "stream", "");
                } else {
                  nome = get(preventivoSecciGetResponse, "secci.file.nome", "download");
                  stream = get(preventivoSecciGetResponse, "secci.file.stream");
                }

                if (!stream) {
                  dispatch({
                    type: GET_ELEGIBILITA_INFO_DOCS_FAILED,
                    payload: new Error("File download is unavailable"),
                  });

                  return;
                }

                dispatch({
                  type: GET_ELEGIBILITA_INFO_DOCS_SUCCEED,
                });

                downloadDocumento(stream, "application/pdf", nome);
              },
              errorResponse => {
                dispatch(errorAction(GET_ELEGIBILITA_INFO_DOCS_FAILED, errorResponse));
              }
            );
        },
        errorResponse => {
          dispatch(errorAction(GET_ELEGIBILITA_INFO_DOCS_FAILED, errorResponse));
        }
      );
  } else {
    error("Questionario not provided");
  }
};

/**
 * getQuestionarioAdeguatezzaDownselling
 */
export const getQuestionarioAdeguatezzaDownselling = (
  requestParameters: PraticaQuestionarioAdeguatezzaDownsellingGetRequest,
  preTokenMiddleware: Middleware
): AsyncActionReturnType<GetListaCodiceServizi> => async dispatch => {
  dispatch({
    type: GET_QUESTIONNAIRES_DOWNSELLING_PENDING,
  });

  modificaPraticaApi(preTokenMiddleware)
    .praticaQuestionarioAdeguatezzaDownsellingGet(requestParameters)
    .subscribe(
      payload => {
        dispatch({
          payload,
          type: GET_QUESTIONNAIRES_DOWNSELLING_SUCCEED,
        });
      },
      errorPayload => dispatch(errorAction(GET_QUESTIONNAIRES_DOWNSELLING_FAILED, errorPayload))
    );
};

/**
 * postPreventivoRicalcoloPost
 */
export const postPreventivoRicalcolo = (
  requestParameters: PreventivoRicalcoloPostRequest,
  preTokenMiddleware: Middleware
): AsyncActionReturnType<PreventivoOutput> => async dispatch => {
  dispatch({
    type: POST_PREVENTIVO_RICALCOLO_PENDING,
  });

  modificaPraticaApi(preTokenMiddleware)
    .preventivoRicalcoloPost(requestParameters)
    .subscribe(
      payload => {
        if (payload.numero_preventivo) {
          const preventivoId = payload.numero_preventivo.toString();
          SessionStorage.write(ID_PREVENTIVO_SESSION_KEY, preventivoId);
        }
        dispatch({
          payload,
          type: POST_PREVENTIVO_RICALCOLO_SUCCEED,
        });
      },
      errorPayload => dispatch(errorAction(POST_PREVENTIVO_RICALCOLO_FAILED, errorPayload))
    );
};

/** getPreventivoSecci */
export const getPreventivoSecci = (
  requestParameters: GetSecciRequest,
  preTokenMiddleware: Middleware
): AsyncActionReturnType<Secci> => async dispatch => {
  dispatch({
    type: GET_PREVENTIVO_SECCI_PENDING,
  });

  processApi(preTokenMiddleware)
    .getSecci(requestParameters)
    .subscribe(
      preventivoSecciGetResponse => {
        let nome = "";
        let stream = "";
        if (Array.isArray(preventivoSecciGetResponse.secci?.file)) {
          const fileSecci: PDFFile = preventivoSecciGetResponse.secci?.file[0];
          nome = get(fileSecci, "nome", "download");
          stream = get(fileSecci, "stream", "");
        } else {
          nome = get(preventivoSecciGetResponse, "secci.file.nome", "download");
          stream = get(preventivoSecciGetResponse, "secci.file.stream");
        }

        if (!stream) {
          dispatch({
            type: GET_PREVENTIVO_SECCI_FAILED,
            payload: new Error("File download is unavailable"),
          });

          return;
        }

        dispatch({
          type: GET_PREVENTIVO_SECCI_SUCCEED,
        });

        downloadDocumento(stream, "application/pdf", nome);
      },
      errorResponse => {
        dispatch(errorAction(GET_PREVENTIVO_SECCI_FAILED, errorResponse));
      }
    );
};

/** getPreventivoeligibilita */
export const getPreventivoEligibilita = (
  requestParameters: PreventivoEligibilitaGetRequest,
  preTokenMiddleware: Middleware
): AsyncActionReturnType<Eligibilita> => async dispatch => {
  dispatch({
    type: GET_ELIGIBILITA_PENDING,
  });
  eligibilita$(requestParameters, preTokenMiddleware).subscribe(
    response => {
      dispatch({
        type: GET_ELIGIBILITA_SUCCEED,
        payload: response,
      });
    },
    errorResponse => {
      dispatch(errorAction(GET_ELIGIBILITA_FAILED, errorResponse));
    }
  );
};

/** getEligibilitaCheck */
export const getEligibilitaCheck = (
  requestParameters: GetEligibilitaCheckRequest,
  preTokenMiddleware: Middleware
): AsyncActionReturnType<boolean> => async dispatch => {
  dispatch({
    type: GET_ELIGIBILITA_CHECK_PENDING,
  });
  caricamentoPraticaApi(preTokenMiddleware)
    .getEligibilitaCheck(requestParameters)
    .subscribe(
      (response: SuccessInformativaCheck) => {
        dispatch({
          payload: response.success,
          type: GET_ELIGIBILITA_CHECK_SUCCEED,
        });
      },
      errorResponse => {
        dispatch(errorAction(GET_ELIGIBILITA_CHECK_FAILED, errorResponse));
      }
    );
};

/** getDatiCheck */
export const getDatiCheck = (
  requestParameters: PreventivoDatiCheckGetRequest,
  preTokenMiddleware: Middleware
): AsyncActionReturnType<PreventivoDatiCheckResponse> => async dispatch => {
  dispatch({
    type: GET_DATI_CHECK_PENDING,
  });
  e2eApi(preTokenMiddleware)
    .preventivoDatiCheckGet(requestParameters)
    .subscribe(
      (response: PreventivoDatiCheckResponse) => {
        dispatch({
          payload: response,
          type: GET_DATI_CHECK_SUCCEED,
        });
      },
      errorResponse => {
        dispatch(errorAction(GET_DATI_CHECK_FAILED, errorResponse));
      }
    );
};

/**getCustomer */
export const getCustomer = (
  recallPreTokenMiddleware: Middleware,
  customerPreTokenMiddleware: Middleware
): AsyncActionReturnType<NuovaControparteDataOccupazione> => async dispatch => {
  dispatch({
    type: GET_CUSTOMER_PENDING,
  });

  const observable: Observable<Controparte> = authRecall$(recallPreTokenMiddleware).pipe(
    concatMap(() => getCustomer$(customerPreTokenMiddleware))
  );

  observable.subscribe(
    response => {
      dispatch({
        payload: response.data?.occupazione,
        type: GET_CUSTOMER_SUCCEED,
      });
    },
    errorResponse => {
      dispatch(errorAction(GET_CUSTOMER_FAILED, errorResponse));
    }
  );
};

/**
 * closeIncompatibilityModal & Proceed
 */
export const handleProceedIncompatibilityModal = (modalType: TModalType) => (
  dispatch: Dispatch<IAction<TModalType>>
): void => {
  dispatch({
    type: INCOMPATIBILITY_MODAL_PROCEED,
    payload: modalType,
  });
};

/**
 *Switch CLChange to CLRemove
 */
export const handleSwitchClChangeToClRemove = () => (dispatch: Dispatch<IAction<TModalType>>): void => {
  dispatch({
    type: SWITCH_CL,
  });
};

/**
 * ONLY closeIncompatibilityModal (stop save pratica)
 */
export const handleCloseIncompatibilityModal = (modalType: TModalType, proceed?: boolean) => (
  dispatch: Dispatch<IAction<TModalType>>
): void => {
  if (!proceed) {
    dispatch({
      type: STOP_SAVE_PRATICA,
    });
  }
  dispatch({
    type: INCOMPATIBILITY_MODAL_DISMISS,
    payload: modalType,
  });
};

/**
 * Move the practice to an editable state (05) and redirect user to personal data
 */
export const handleReturnToPersonalData = (
  requestParameters: PraticaRichiestaModificaRequest
): AsyncActionReturnType<void> => async dispatch => {
  dispatch({
    type: RETURN_TO_PERSONAL_DATA_PENDING,
  });
  whitelabelApi.praticaRichiestaModifica(requestParameters).subscribe(
    res => {
      if (res.success) {
        dispatch({
          type: RETURN_TO_PERSONAL_DATA_SUCCEED,
        });
      } else {
        dispatch({
          type: RETURN_TO_PERSONAL_DATA_FAILED,
        });
      }
    },
    errorPayload => dispatch(errorAction(RETURN_TO_PERSONAL_DATA_FAILED, errorPayload))
  );
};

/**
 * closeIncompatibilityModal and move pratica on editable state (05)
 */
export const handleCloseModalAfterSavePratica = (
  modalType: TModalType,
  pratica: number,
  token2: string,
  codiceFiscale: string,
  provenienza: string
): AsyncActionReturnType<TModalType> => async dispatch => {
  const authDocumentiRequest: AuthDocumentiRequest = {
    Input: {
      authToken: token2,
      codiceFiscale: codiceFiscale,
    },
  };

  const RichiestaModificaRequest: PraticaRichiestaModificaRequest = {
    requestBody: {
      pratica: pratica,
      provenienza: provenienza,
    },
  };

  authDocumenti$(
    sharedTokenMiddleware(CARICAMENTO_TOKEN_SESSION_KEY, UPLOAD_TOKEN_SESSION_KEY),
    authDocumentiRequest
  ).subscribe(
    payload => {
      if (payload.numPratica) {
        authRecall$(sharedTokenMiddleware(UPLOAD_TOKEN_SESSION_KEY, CARICAMENTO_TOKEN_SESSION_KEY)).subscribe(
          payload => {
            if (payload.success) {
              whitelabelApi.praticaRichiestaModifica(RichiestaModificaRequest).subscribe(
                res => {
                  if (res.success) {
                    dispatch({
                      type: STOP_SAVE_PRATICA,
                    });
                    dispatch({
                      type: INCOMPATIBILITY_MODAL_DISMISS_ALL,
                      payload: modalType,
                    });
                  } else {
                    dispatch({
                      type: PRATICA_RICHIESTA_MODIFICA_FAILED,
                    });
                  }
                },
                errorPayload => dispatch(errorAction(PRATICA_RICHIESTA_MODIFICA_FAILED, errorPayload))
              );
            }
          },
          errorPayload => dispatch(errorAction(PRATICA_RICHIESTA_MODIFICA_FAILED, errorPayload))
        );
      }
    },
    errorPayload => dispatch(errorAction(PRATICA_RICHIESTA_MODIFICA_FAILED, errorPayload))
  );
};

/**
 * After checks on upload/check is ok
 */
export const handleContinue = (): AsyncActionReturnType<void> => dispatch => {
  dispatch({
    type: UPLOAD_CONTINUE,
  });
};

/**
 * postPianoFinanziario
 */
export const postPianoFinanziario = (
  requestParameters: PraticaPianoFinanziarioRequest,
  preTokenMiddleware: Middleware,
  hasInsurances: boolean | undefined
): AsyncActionReturnType<PraticaConsumoOk> => async dispatch => {
  dispatch({
    type: POST_PIANO_FINANZIARIO_PENDING,
  });

  modificaPraticaApi(preTokenMiddleware)
    .praticaPianoFinanziario(requestParameters)
    .subscribe(
      payload => {
        /**Call api /reportFE on AreaRis & Component */
        if (getFeatureFlag("handleIdMedia") && getFeatureFlag("handleIdCampaign")) {
          const preventivo = requestParameters.praticaPianofinanziarioInput.numeroPreventivo;
          const idMedia = SessionStorage.read(ID_MEDIA_SESSION_KEY);
          const idCampaign = SessionStorage.read(ID_CAMPAIGN_SESSION_KEY);

          if (preventivo && idMedia) {
            whitelabelApi
              .reportFE({
                messageBody: {
                  idMedia: idMedia.toString(),
                  idCampaign: idCampaign ? idCampaign.toString() : "", //Not mandatory
                  preventivo: preventivo.toString(),
                  sessionId: randomSessionId(),
                  timeCode: moment().format("DD/MM/YYYY HH:MM"),
                  assicurazione: hasInsurances ? "S" : "N",
                },
              })
              .subscribe(
                () => {
                  dispatch({
                    payload,
                    type: POST_PIANO_FINANZIARIO_SUCCEED,
                  });
                },
                error => log(error)
              );
          } else {
            dispatch({
              payload,
              type: POST_PIANO_FINANZIARIO_SUCCEED,
            });
          }
        } else {
          dispatch({
            payload,
            type: POST_PIANO_FINANZIARIO_SUCCEED,
          });
        }
      },
      errorPayload => dispatch(errorAction(POST_PIANO_FINANZIARIO_FAILED, errorPayload))
    );
};
