// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroSEGERRKIOELE,
} from './';

/**
 * @export
 * @interface WpNormInputParametroSEGERR620ELE
 */
export interface WpNormInputParametroSEGERR620ELE {
    /**
     * @type {string}
     * @memberof WpNormInputParametroSEGERR620ELE
     */
    ERR_620_S?: string;
    /**
     * @type {Array<WpNormInputParametroSEGERRKIOELE>}
     * @memberof WpNormInputParametroSEGERR620ELE
     */
    ERR_620?: Array<WpNormInputParametroSEGERRKIOELE>;
}
