import classNames from "classnames";
import * as React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { isMobile } from "../../lib/utility/responsive";
import CollapseText from "../collapseText/CollapseText";
import Container from "../container/container";
import "./summary.scss";

interface ISkelSummaryItemProps {
  label: string;
}

const SkelSummaryItem: React.FunctionComponent<ISkelSummaryItemProps> = ({ label }) => {
  return (
    <li className="summary__section-item">
      <SkeletonTheme color="#c9c9c9" highlightColor="#b1aeae">
        <span className="summary__section-item__head">{label}</span>
        <Skeleton style={{ borderRadius: "2px", marginTop: "0.5rem" }} />
      </SkeletonTheme>
    </li>
  );
};

interface IOwnProps {
  collapseWidth?: number;
  theme?: "gradient" | "flat";
  loading?: boolean;
}

type Props = IOwnProps;

const SkelSummary: React.FunctionComponent<Props> = ({ collapseWidth = 480, theme = "flat" }) => {
  const classes = classNames({
    summary: true,
    "summary--flat": theme === "flat",
    "summary--gradient": theme === "gradient",
  });

  const width = useWindowWidth();

  return (
    <div className={classes} style={{ animation: "fadeIn 1s" }}>
      <Container className={theme === "gradient" ? "summary__translucid-section" : ""}>
        <>
          {isMobile(width, collapseWidth) ? (
            <CollapseText
              collapseText={
                <>
                  <ul className={classNames("summary__section-list")}>
                    <SkelSummaryItem label="Importo richiesto" />
                    <SkelSummaryItem label="TAN Fisso" />
                    <SkelSummaryItem label="TAEG Fisso" />
                    <SkelSummaryItem label="Rata Mensile" />
                  </ul>
                </>
              }
              expandText={
                <>
                  <ul className={classNames("summary__section-list")}>
                    <SkelSummaryItem label="Importo richiesto" />
                    <SkelSummaryItem label="TAN Fisso" />
                    <SkelSummaryItem label="TAEG Fisso" />
                    <SkelSummaryItem label="Rata Mensile" />
                    <SkelSummaryItem label="Importo richiesto" />
                    <SkelSummaryItem label="Mesi" />
                    <SkelSummaryItem label="Importo tot. dovuto" />
                  </ul>
                </>
              }
            />
          ) : (
            <>
              <ul className="summary__section-list">
                <SkelSummaryItem label="Importo richiesto" />
                <SkelSummaryItem label="TAN Fisso" />
                <SkelSummaryItem label="TAEG Fisso" />
                <SkelSummaryItem label="Rata Mensile" />
                <SkelSummaryItem label="Importo richiesto" />
                <SkelSummaryItem label="Mesi" />
                <SkelSummaryItem label="Importo tot. dovuto" />
              </ul>
            </>
          )}
        </>
      </Container>
    </div>
  );
};

export default React.memo(SkelSummary);
