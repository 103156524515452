// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroMCZ627
 */
export interface WpNormInputParametroMCZ627 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    CDPOBJSTR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_TPXGEO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_DSXARCIDX?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_DSXINF?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_ISTPRO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_ISTCOM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_ISTSEZ?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_1_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_DSXARCIDX?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_DSXINF?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_ISTPRO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_ISTCOM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_ISTSEZ?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    MCZ_2_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    HOME_ZONE?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroMCZ627
     */
    GU3?: string;
}
