// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ServizioAssicurativo,
} from './';

/**
 * descrive un raggruppamento di servizi
 * @export
 * @interface GruppoServizi
 */
export interface GruppoServizi {
    /**
     * @type {string}
     * @memberof GruppoServizi
     */
    codice?: string;
    /**
     * @type {string}
     * @memberof GruppoServizi
     */
    descrizione?: string;
    /**
     * @type {boolean}
     * @memberof GruppoServizi
     */
    obbligatorio?: boolean;
    /**
     * @type {number}
     * @memberof GruppoServizi
     */
    ordine?: number;
    /**
     * @type {Array<ServizioAssicurativo>}
     * @memberof GruppoServizi
     */
    servizi?: Array<ServizioAssicurativo>;
    /**
     * <ul>   <li><strong>CL</strong> = Credit Life   <li><strong>FI</strong> = Furto Incendio   <li><strong>GM</strong> = Guasti Meccanici   <li><strong>LI</strong> = Libero   <li><strong>CA</strong> = Carte   <li><strong>PA</strong> = Importo fisso per durata </ul>
     * @type {string}
     * @memberof GruppoServizi
     */
    tipoProntuario?: string;
    /**
     * @type {boolean}
     * @memberof GruppoServizi
     */
    upselling?: boolean;
}
