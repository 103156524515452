import classNames from "classnames";
import * as React from "react";
import "./intro.scss";

interface IOwnProps {
  children?: React.ReactNode;
  className?: string;
  padded?: boolean;
}

type Props = IOwnProps;

const Intro: React.FunctionComponent<Props> = ({ children, className = "", padded }) => {
  const classes = classNames({
    intro: true,
    "intro--padded": padded,
    ...(className ? { [className]: true } : {}),
  });

  return <div className={classes}>{children}</div>;
};

export default Intro;
