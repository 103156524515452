import { ErrorPageMessages } from "../components/error_page/errorPage.types";

export enum ERROR_PAGE_TYPES {
  "AUTHENTICATION_FAILED" = "authentication-failed",
  "TARGATURA_INCAGLI_COVID_ERROR" = "unrecoverable",
}

export const ERROR_PAGE_MESSAGES: ErrorPageMessages = {
  [ERROR_PAGE_TYPES.AUTHENTICATION_FAILED]: {
    heroDescription: "Si è verificato un errore",
    errorMessage: "Autenticazione non riuscita",
    advisedSolution: "Contatta l'assistenza",
  },
  [ERROR_PAGE_TYPES.TARGATURA_INCAGLI_COVID_ERROR]: {
    heroDescription: " ",
    errorMessage: "La Tua richiesta non è procedibile.",
    advisedSolution:
      "Per ottenere maggiori informazioni ti invitiamo a contattarci al numero <b>011.6318862</b> da LUNEDI’ a VENERDI’ negli orari <b>9.00 - 13.00</b> e <b>14.00 - 17.30</b>, oppure via email a <a className='link link--light-blue' href='mailto:filialeonline@santanderconsumer.it'>filialeonline@santanderconsumer.it</a>",
  },
};
