// tslint:disable
/**
 * servizi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface LoaderServiziOutputDataServiziLE
 */
export interface LoaderServiziOutputDataServiziLE {
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    codice?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    descrizione?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    tipo?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    duratarichiesta?: string;
    /**
     * @type {boolean}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    provinciarichiesta?: boolean;
    /**
     * @type {number}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    importominattivo?: number;
    /**
     * @type {number}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    importomaxattivo?: number;
    /**
     * @type {number}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    duratamax?: number;
    /**
     * @type {number}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    duratamin?: number;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    gruppo?: object;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    descrizioneGruppo?: object;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    tipoanagrafica?: string;
    /**
     * @type {string}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    recuperabile?: string;
    /**
     * @type {object}
     * @memberof LoaderServiziOutputDataServiziLE
     */
    upselling?: object;
}
