export const GET_ABITAZIONI = "loader/GET_ABITAZIONI";
export const GET_ABITAZIONI_FAILED = "loader/GET_ABITAZIONI_FAILED";
export const GET_ABITAZIONI_PENDING = "loader/GET_ABITAZIONI_PENDING";
export const GET_ATTIVITA = "loader/GET_ATTIVITA";
export const GET_ATTIVITA_FAILED = "loader/GET_ATTIVITA_FAILED";
export const GET_ATTIVITA_PENDING = "loader/GET_ATTIVITA_PENDING";
export const GET_BENI = "loader/GET_BENI";
export const GET_BENI_FAILED = "loader/GET_BENI_FAILED";
export const GET_BENI_PENDING = "loader/GET_BENI_PENDING";
export const GET_CAP = "loader/GET_CAP";
export const GET_CAP_PREVIOUS_ADDRESS = "loader/GET_CAP_PREVIOUS_ADDRESS";
export const GET_CAP_FAILED = "loader/GET_CAP_FAILED";
export const GET_CAP_PENDING = "loader/GET_CAP_PENDING";
export const GET_CITTADINANZA = "loader/GET_CITTADINANZA";
export const GET_CITTADINANZA_FAILED = "loader/GET_CITTADINANZA_FAILED";
export const GET_CITTADINANZA_PENDING = "loader/GET_CITTADINANZA_PENDING";
export const GET_COMUNI = "loader/GET_COMUNI";
export const GET_COMUNI_FAILED = "loader/GET_COMUNI_FAILED";
export const GET_COMUNI_PENDING = "loader/GET_COMUNI_PENDING";
export const SET_COMUNI_RESIDENZA_BY_CAP = "loader/SET_COMUNI_RESIDENZA_BY_CAP";
export const GET_DOCUMENTI = "loader/GET_DOCUMENTI";
export const GET_DOCUMENTI_FAILED = "loader/GET_DOCUMENTI_FAILED";
export const GET_DOCUMENTI_PENDING = "loader/GET_DOCUMENTI_PENDING";
export const GET_ENTI_RILASCIO = "loader/GET_ENTI_RILASCIO";
export const GET_ENTI_RILASCIO_FAILED = "loader/GET_ENTI_RILASCIO_FAILED";
export const GET_ENTI_RILASCIO_PENDING = "loader/GET_ENTI_RILASCIO_PENDING";
export const GET_FORME_GIURIDICHE = "loader/GET_FORME_GIURIDICHE";
export const GET_FORME_GIURIDICHE_FAILED = "loader/GET_FORME_GIURIDICHE_FAILED";
export const GET_FORME_GIURIDICHE_PENDING = "loader/GET_FORME_GIURIDICHE_PENDING";
export const GET_PAGAMENTI = "loader/GET_PAGAMENTI";
export const GET_PAGAMENTI_FAILED = "loader/GET_PAGAMENTI_FAILED";
export const GET_PAGAMENTI_PENDING = "loader/GET_PAGAMENTI_PENDING";
export const GET_PERMESSI_DI_SOGGIORNO = "loader/GET_PERMESSI_DI_SOGGIORNO";
export const GET_PERMESSI_DI_SOGGIORNO_FAILED = "loader/GET_PERMESSI_DI_SOGGIORNO_FAILED";
export const GET_PERMESSI_DI_SOGGIORNO_PENDING = "loader/GET_PERMESSI_DI_SOGGIORNO_PENDING";
export const GET_PRODOTTI = "loader/GET_PRODOTTI";
export const GET_PRODOTTI_FAILED = "loader/GET_PRODOTTI_FAILED";
export const GET_PRODOTTI_PENDING = "loader/GET_PRODOTTI_PENDING";
export const GET_PROFESSIONI = "loader/GET_PROFESSIONI";
export const GET_PROFESSIONI_FAILED = "loader/GET_PROFESSIONI_FAILED";
export const GET_PROFESSIONI_PENDING = "loader/GET_PROFESSIONI_PENDING";
export const GET_PROVINCE = "loader/GET_PROVINCE";
export const GET_PROVINCE_FAILED = "loader/GET_PROVINCE_FAILED";
export const GET_PROVINCE_PENDING = "loader/GET_PROVINCE_PENDING";
export const GET_REDDITO = "loader/GET_REDDITO";
export const GET_REDDITO_FAILED = "loader/GET_REDDITO_FAILED";
export const GET_REDDITO_PENDING = "loader/GET_REDDITO_PENDING";
export const GET_STATI_CIVILI = "loader/GET_STATI_CIVILI";
export const GET_STATI_CIVILI_FAILED = "loader/GET_STATI_CIVILI_FAILED";
export const GET_STATI_CIVILI_PENDING = "loader/GET_STATI_CIVILI_PENDING";
export const GET_STRADE = "loader/GET_STRADE";
export const GET_STRADE_FAILED = "loader/GET_STRADE_FAILED";
export const GET_STRADE_PENDING = "loader/GET_STRADE_PENDING";
export const GET_TABELLE = "loader/GET_TABELLE";
export const GET_TABELLE_FAILED = "loader/GET_TABELLE_FAILED";
export const GET_TABELLE_PENDING = "loader/GET_TABELLE_PENDING";

export const RESET_ABITAZIONI = "loader/RESET_ABITAZIONI";
export const RESET_AGEVOLAZIONI = "loader/RESET_AGEVOLAZIONI";
export const RESET_ATTIVITA = "loader/RESET_ATTIVITA";
export const RESET_BENI = "loader/RESET_BENI";
export const RESET_CAP = "loader/RESET_CAP";
export const RESET_CITTADINANZA = "loader/RESET_CITTADINANZA";
export const RESET_COMUNI = "loader/RESET_COMUNI";
export const RESET_COMUNI_RESIDENZA_BY_CAP = "loader/RESET_COMUNI_RESIDENZA_BY_CAP";
export const SET_COMUNI_PREVIOUS_ADDRESS_BY_CAP = "loader/SET_COMUNI_PREVIOUS_ADDRESS_BY_CAP";
export const RESET_DOCUMENTI = "loader/RESET_DOCUMENTI";
export const RESET_ENTI_RILASCIO = "loader/RESET_ENTI_RILASCIO";
export const RESET_FORME_GIURIDICHE = "loader/RESET_FORME_GIURIDICHE";
export const RESET_PAGAMENTI = "loader/RESET_PAGAMENTI";
export const RESET_PERMESSI_DI_SOGGIORNO = "loader/RESET_PERMESSI_DI_SOGGIORNO";
export const RESET_PRODOTTI = "loader/RESET_PRODOTTI";
export const RESET_PROFESSIONI = "loader/RESET_PROFESSIONI";
export const RESET_PROVINCE = "loader/RESET_PROVINCE";
export const RESET_REDDITO = "loader/RESET_REDDITO";
export const RESET_STATI_CIVILI = "loader/RESET_STATI_CIVILI";
export const RESET_STRADE = "loader/RESET_STRADE";
export const RESET_TABELLE = "loader/RESET_TABELLE";

export const GET_TAX_DEDUCTIONS_PENDING = "loader/GET_TAX_DEDUCTIONS_PENDING";
export const GET_TAX_DEDUCTIONS = "loader/GET_TAX_DEDUCTIONS";
export const GET_TAX_DEDUCTIONS_FAILED = "loader/GET_TAX_DEDUCTIONS_FAILED";
