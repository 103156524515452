import * as React from "react";
import { useState } from "react";
import Icon from "../icon/icon";
import "./collapseText.scss";

interface IOwnProps {
  collapseText: React.ReactNode;
  expandText: React.ReactNode;
}

type Props = IOwnProps;

const CollapseText: React.FunctionComponent<Props> = ({ collapseText, expandText }) => {
  const [isInfoCollapsed, setIsInfoCollapsed] = useState<boolean>(true);

  const toggleHandler = () => {
    setIsInfoCollapsed(!isInfoCollapsed);
  };

  if (isInfoCollapsed) {
    return (
      <div className="collapsed">
        <div className="collapsed__content">{collapseText}</div>
        <div className="collapsed__actions">
          <a className="more" onClick={toggleHandler}>
            <Icon type="expand_more" size={30} />
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="expanded">
          <div className="expanded__content">{expandText}</div>
        </div>
        <div className="expanded__actions">
          <a className="less" onClick={toggleHandler}>
            <Icon type="expand_less" size={30} />
          </a>
        </div>
      </>
    );
  }
};

export default React.memo(CollapseText);
