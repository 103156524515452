import "core-js/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { GTM_ID } from "../shared/config/constants";
import "../shared/config/featureFlags";
// import registerServiceWorker from './registerServiceWorker';
import ErrorHandler from "../shared/lib/error-handler";
import App from "./app";
import { initializeGoogleTagManager } from "./components/forms/hooks/useGoogleTagManager";
import { store } from "./sharedStore";

export const renderReactApp = (): void => {
  // Google Tag Manager
  initializeGoogleTagManager(GTM_ID);

  ReactDOM.render(
    <ErrorHandler>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorHandler>,
    document.getElementById("root") as HTMLElement
  );

  // registerServiceWorker();
};
