// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroCOO626
 */
export interface WpNormInputParametroCOO626 {
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    CDPOBJSTR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DSXNUMCIV_1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DSXEXPCIV_1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    ATL_1_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    ATL_1_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    ATL_1_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    ATL_1_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DSXNUMCIV_2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DSXEXPCIV_2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    NAV_2_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    NAV_2_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    NAV_2_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    NAV_2_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_TPXGEO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_FLXCNL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_FLXDS1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_FLXZIP?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_1_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_1_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_1_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_1_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_3_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_3_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_3_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_3_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_2_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_2_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_2_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    DEF_2_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    BES_1_X_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    BES_1_Y_M?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    BES_1_X_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    BES_1_Y_G?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    BES_VLXBES?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroCOO626
     */
    ATT_VLXATT?: string;
}
