// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    BuyBackResponse,
    Informativa,
    MisureGovernativeResponse,
    PreventivoLeasing,
    PreventivoLeasingInput,
    PreventivoLeasingOutput,
} from '../models';

export interface GetBuyBackRequest {
    intermediario: string;
    codiceBene: string;
    tipoAnagrafica: GetBuyBackTipoAnagraficaEnum;
    X_B3_TraceId?: string;
    codiceInternet?: string;
    veicoloCommerciale?: boolean;
}

export interface GetInformativeRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface GetMisureGovernativeRequest {
    X_B3_TraceId?: string;
}

export interface GetPreventivoLERequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PostPreventivoLERequest {
    X_B3_TraceId?: string;
    body?: PreventivoLeasingInput;
}

/**
 * no description
 */
export class P3Api extends BaseAPI {

    /**
     * abilita la possibilità a livello di interfaccia di usare il buyback (riacquisto del bene da parte del Dealer in caso di restituzione al termine della locazione)
     */
    getBuyBack = (requestParameters: GetBuyBackRequest): Observable<BuyBackResponse> => {
        throwIfRequired(requestParameters, 'intermediario', 'getBuyBack');
        throwIfRequired(requestParameters, 'codiceBene', 'getBuyBack');
        throwIfRequired(requestParameters, 'tipoAnagrafica', 'getBuyBack');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.tipoAnagrafica && { 'tipoAnagrafica': requestParameters.tipoAnagrafica }),
            ...(requestParameters.veicoloCommerciale && { 'veicoloCommerciale': requestParameters.veicoloCommerciale }),
        };

        return this.request<BuyBackResponse>({
            path: '/preventivo/buyBack',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Logiche: <br/> <ol> <li> esegue chiamata a GET /preventivo/adeguatezza per recuperare la tipologia di fascicolo <li> esegue stampa informative </ol>
     * Stampa informative preventivo
     */
    getInformative = (requestParameters: GetInformativeRequest): Observable<Informativa> => {
        throwIfRequired(requestParameters, 'intermediario', 'getInformative');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getInformative');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<Informativa>({
            path: '/preventivo/informative',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * ritorna la lista delle misure governative e se richiedono o no un importo
     */
    getMisureGovernative = (requestParameters: GetMisureGovernativeRequest): Observable<MisureGovernativeResponse> => {

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<MisureGovernativeResponse>({
            path: '/preventivo/misureGovernative',
            method: 'GET',
            headers,
        });
    };

    /**
     * carica un preventivo leasing dato il suo id
     */
    getPreventivoLE = (requestParameters: GetPreventivoLERequest): Observable<PreventivoLeasing> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoLE');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoLE');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<PreventivoLeasing>({
            path: '/preventivo/LE',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Calcola un preventivo leasing
     */
    postPreventivoLE = (requestParameters: PostPreventivoLERequest): Observable<PreventivoLeasingOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<PreventivoLeasingOutput>({
            path: '/preventivo/LE',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetBuyBackTipoAnagraficaEnum {
    P = 'P',
    F = 'F',
    G = 'G'
}
