import { useParams } from "react-router-dom";
import { ERROR_PAGE_MESSAGES, ERROR_PAGE_TYPES } from "../../constants/errorPageLabels";
import { ErrorMessages } from "./errorPage.types";

export const useErrorType = (defaults: ErrorMessages): ErrorMessages => {
  const params = useParams<{ type?: ERROR_PAGE_TYPES }>();
  const type: ERROR_PAGE_TYPES = params.type as ERROR_PAGE_TYPES;

  if (ERROR_PAGE_MESSAGES[type]) {
    return ERROR_PAGE_MESSAGES[type];
  }
  return defaults;
};
