// tslint:disable
/**
 * caricamento
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DatiStoriciObjDatiStorici,
} from './';

/**
 * 
 * @export
 * @interface DatiStorici
 */
export interface DatiStorici {
    /**
     * @type {number}
     * @memberof DatiStorici
     */
    numPratica?: number;
    /**
     * @type {number}
     * @memberof DatiStorici
     */
    id_prev?: number;
    /**
     * @type {DatiStoriciObjDatiStorici}
     * @memberof DatiStorici
     */
    ObjDatiStorici?: DatiStoriciObjDatiStorici;
}
