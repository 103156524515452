// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ServizioAssicurativo,
} from './';

/**
 * @export
 * @interface RaggruppamentoAssicurativo
 */
export interface RaggruppamentoAssicurativo {
    /**
     * @type {string}
     * @memberof RaggruppamentoAssicurativo
     */
    descrizione?: string;
    /**
     * @type {boolean}
     * @memberof RaggruppamentoAssicurativo
     */
    obbligatorio?: boolean;
    /**
     * @type {Array<ServizioAssicurativo>}
     * @memberof RaggruppamentoAssicurativo
     */
    servizi?: Array<ServizioAssicurativo>;
    /**
     * \'CL\' = Credit Life, \'FI\' = Furto Incendio, \'GM\' = Guasti Meccanici, \'LI\' = Libero
     * @type {string}
     * @memberof RaggruppamentoAssicurativo
     */
    tipoProntuario?: RaggruppamentoAssicurativoTipoProntuarioEnum;
    /**
     * @type {boolean}
     * @memberof RaggruppamentoAssicurativo
     */
    upselling?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RaggruppamentoAssicurativoTipoProntuarioEnum {
    CL = 'CL',
    FI = 'FI',
    GM = 'GM',
    LI = 'LI'
}

