import { applyMiddleware, compose, createStore, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import { ENV_NAME } from "../shared/config/constants";
import rootReducer from "./logic/root.reducer";

const defaultMiddlewares = [thunkMiddleware];

const composedMiddlewares = (middlewares: Middleware[]) =>
  ENV_NAME !== "production"
    ? composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares))
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

// TODO: Add initialState?: IStoreState to initialize function
// Redux's 'createStore' output type is not trivial.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialize = (middlewares: Middleware[] = []): any => createStore(rootReducer, composedMiddlewares(middlewares));

export default initialize;
