// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Descrizione standard di un errore
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * Tipicamente è associato 1-1 con l\'\'error code, stringa esplicativa del codice di errore
     * @type {string}
     * @memberof Error
     */
    name?: string;
    /**
     * Codice di errore
     * @type {string}
     * @memberof Error
     */
    code: string;
    /**
     * Tipo di errore: error, warning
     * @type {string}
     * @memberof Error
     */
    type: ErrorTypeEnum;
    /**
     * permette di associare l\'errore a uno specifico parametro, ad uso del client
     * @type {string}
     * @memberof Error
     */
    param?: string;
    /**
     * Messaggio di errore
     * @type {string}
     * @memberof Error
     */
    message: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ErrorTypeEnum {
    Warning = 'warning',
    Error = 'error'
}

