import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { IListaZipCodeOutputFIXED, IZipCodeOutputFIXED } from "../../logic/loader/types";
import { IAddress } from "./normalizzatore.types";
import { ListaZipCodeRequest } from "../../../shared/generated/normalizzatore";

export interface IZipCodeInputOptions {
  soppresse?: boolean;
  numResults?: number;
  fullName?: boolean;
}
export const getZipCodeInputParams = (cap: string, options?: IZipCodeInputOptions): ListaZipCodeRequest => ({
  Input: {
    parametro: {
      LSTPAR: {
        ...(options && options.numResults ? { NR9CND: options.numResults.toString() } : { NR9CND: "99" }),
        CDXISO: "ITA",
        ...(options && options.fullName ? { FLXDSXDPT: options.fullName ? "0" : "1" } : { FLXDSXDPT: "1" }),
        WPUSER: "wp",
        WPPASW: "wp1299",
      },
      ZIP_AREA_INP: {
        CDXZIP: cap, // zip code (CAP)
        ...(options && options.soppresse === true ? { MAXLIVLOC: "5" } : {}), // escludi le località soppresse
        TPXEXT: "L", // "T" ritorna tutte le località, "L" ritorna un subset (corretto, come su Dealer)
      },
    },
  },
});

export const parseZipCodeResponse = (response: IListaZipCodeOutputFIXED): Partial<IAddress>[] => {
  const isValid = (cap: IZipCodeOutputFIXED) => cap.DSXOBJDPT !== "" && cap.DSXOBJCNL !== "";

  const outZipCodeList: IZipCodeOutputFIXED[] = get(
    response,
    "wpLstZipResult.ZIP_AREA_OUT.ZIP",
    []
  ).filter((cap: IZipCodeOutputFIXED) => isValid(cap));

  if (
    isEmpty(outZipCodeList) ||
    (outZipCodeList.length === 1 && (isEmpty(outZipCodeList[0]) || !isValid(outZipCodeList[0])))
  ) {
    return [];
  }

  // TODO: Add province for multi-province cap
  return outZipCodeList.map(({ DSXOBJSTR, DSXOBJCNL, DSXOBJDPT, CDXZIP }) => ({
    indirizzo: DSXOBJSTR,
    cap: CDXZIP,
    provincia: DSXOBJDPT,
    comune: DSXOBJCNL,
  }));
};
