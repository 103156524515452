// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface NuovaControparteDataOccupazioneDatore
 */
export interface NuovaControparteDataOccupazioneDatore {
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    ragsoc?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    cap?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    indirizzo?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    provincia?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    localita?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    telefono?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    piva?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteDataOccupazioneDatore
     */
    presenzapiva?: string;
}
