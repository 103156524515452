import { Dispatch } from "redux";
import { ID_PREVENTIVO_SESSION_KEY } from "../../../shared/constants/application";
import { CaricamentoOutput, PartnerApi } from "../../../shared/generated/Authentication";
import { log } from "../../../shared/lib/log";
import { SessionStorage } from "../../../shared/lib/utility/storage-v2";
import IAction from "../../../shared/types/actions";
import { AUTH_TOKEN_SESSION_KEY } from "../../config/application";
import { authenticationApiConfig } from "../../lib/authenticationApiConfig";
import { errorAction } from "../../lib/handlers";
import { tokenMiddleware } from "../../lib/tokenMiddleware";
import { AUTH_CARICAMENTO, AUTH_CARICAMENTO_FAILED, AUTH_CARICAMENTO_PENDING, SET_TOKEN } from "./consts";

const authApi = new PartnerApi(authenticationApiConfig({ middleware: [tokenMiddleware] }));

export const setToken = (): IAction => {
  const token = SessionStorage.read(AUTH_TOKEN_SESSION_KEY) || "";
  if (!token) {
    log("No JWT token was found in SessionStorage.");
  }
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const authCaricamento = (
  authToken: string
): ((dispatch: Dispatch<IAction<CaricamentoOutput>>) => PromiseLike<void>) => async dispatch => {
  dispatch({
    type: AUTH_CARICAMENTO_PENDING,
  });

  authApi.authCaricamento({ Input: { authToken } }).subscribe(
    value => {
      if (value.prev_id) {
        const preventivoId = value.prev_id.toString();
        SessionStorage.write(ID_PREVENTIVO_SESSION_KEY, preventivoId);
      }
      dispatch({
        payload: value,
        type: AUTH_CARICAMENTO,
      });
      dispatch(setToken());
    },
    errorPayload => dispatch(errorAction(AUTH_CARICAMENTO_FAILED, errorPayload))
  );
};

export const authFatalError = (): IAction => ({
  type: AUTH_CARICAMENTO_FAILED,
});
