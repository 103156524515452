// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
    PDFFile,
    PacchettiAssicurativi,
    PreventivoConsumo,
    StampeInput,
    UpsellingPreventivoInput,
    UpsellingServiziInput,
    VarianteUpsellingLeasing,
    VarianteUpsellingServizi,
    VariantiUpsellingConsumo,
} from '../models';

export interface GetPreventivoStampeRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PostPreventivoStampeRequest {
    X_B3_TraceId?: string;
    body?: StampeInput;
}

export interface PreventivoCOChecksRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoServiziPacchettiRequest {
    intermediario: string;
    tabellaFinanziaria: string;
    codiceProdotto: string;
    codiceBene: string;
    tipoFinanziamento: PreventivoServiziPacchettiTipoFinanziamentoEnum;
    codiceEurotax: string;
    valoreBene: number;
    dataImmatricolazione: Date;
    tipoAnagrafica: PreventivoServiziPacchettiTipoAnagraficaEnum;
    dataNascita: Date;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoUpsellingCORequest {
    X_B3_TraceId?: string;
    body?: UpsellingPreventivoInput;
}

export interface PreventivoUpsellingLERequest {
    X_B3_TraceId?: string;
    body?: UpsellingPreventivoInput;
}

export interface PreventivoUpsellingServiziRequest {
    X_B3_TraceId?: string;
    body?: UpsellingServiziInput;
}

/**
 * no description
 */
export class ExperienceApi extends BaseAPI {

    /**
     * Logiche: <br/> <ol> <li> esegue stampa secci tramite servizio /preventivo/secci; <li> esegue stampa informative (se richiesto/necessario) tramite servizio /preventivo/informative; </ol>
     * Servizio per il download della documentazione richiesta per un preventivo
     */
    getPreventivoStampe = (requestParameters: GetPreventivoStampeRequest): Observable<PDFFile> => {
        throwIfRequired(requestParameters, 'intermediario', 'getPreventivoStampe');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'getPreventivoStampe');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<PDFFile>({
            path: '/preventivo/stampe',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Logiche: <br/> <ol> <li> esegue stampa secci tramite servizio /preventivo/secci; <li> esegue stampa informative (se richiesto/necessario) tramite servizio /preventivo/informative; <li> invia la mail tramite servizio ESB /sendmail </ol>
     * Servizio per inviare via e-mail la documentazione di un preventivo
     */
    postPreventivoStampe = (requestParameters: PostPreventivoStampeRequest): Observable<void> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<void>({
            path: '/preventivo/stampe',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * carica un preventivo consumo dato il suo id, con controlli aggiuntivi sulle configurazioni correnti per l\'utente in caso le configurazioni non siano più compatibili con quelle originali verrà rimosso il dato di output
     * carica un preventivo con controlli experience
     */
    preventivoCOChecks = (requestParameters: PreventivoCOChecksRequest): Observable<PreventivoConsumo> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoCOChecks');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'preventivoCOChecks');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
        };

        return this.request<PreventivoConsumo>({
            path: '/preventivo/CO/{numeroPreventivo}/checks'.replace('{numeroPreventivo}', encodeURI(requestParameters.numeroPreventivo)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * api per raggruppare i servizi assicurativi in pacchetti e categorie
     */
    preventivoServiziPacchetti = (requestParameters: PreventivoServiziPacchettiRequest): Observable<PacchettiAssicurativi> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'tabellaFinanziaria', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'codiceProdotto', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'codiceBene', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'tipoFinanziamento', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'codiceEurotax', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'valoreBene', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'dataImmatricolazione', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'tipoAnagrafica', 'preventivoServiziPacchetti');
        throwIfRequired(requestParameters, 'dataNascita', 'preventivoServiziPacchetti');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.tabellaFinanziaria && { 'tabellaFinanziaria': requestParameters.tabellaFinanziaria }),
            ...(requestParameters.codiceProdotto && { 'codiceProdotto': requestParameters.codiceProdotto }),
            ...(requestParameters.codiceBene && { 'codiceBene': requestParameters.codiceBene }),
            ...(requestParameters.tipoFinanziamento && { 'tipoFinanziamento': requestParameters.tipoFinanziamento }),
            ...(requestParameters.codiceEurotax && { 'codiceEurotax': requestParameters.codiceEurotax }),
            ...(requestParameters.valoreBene && { 'valoreBene': requestParameters.valoreBene }),
            ...(requestParameters.dataImmatricolazione && { 'dataImmatricolazione': (requestParameters.dataImmatricolazione as any).toISOString() }),
            ...(requestParameters.tipoAnagrafica && { 'tipoAnagrafica': requestParameters.tipoAnagrafica }),
            ...(requestParameters.dataNascita && { 'dataNascita': (requestParameters.dataNascita as any).toISOString() }),
        };

        return this.request<PacchettiAssicurativi>({
            path: '/preventivo/servizi/pacchetti',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Calcolo varianti per il consumo: <ul>   <li><strong>varianteDurataSuperiore:</strong> il calcolo del preventivo alla durata superiore se calcolabile   <li><strong>varianteDurataInferiore:</strong> il calcolo del preventivo alla durata inferiore se calcolabile </ul>
     * Calcolo varianti per il consumo
     */
    preventivoUpsellingCO = (requestParameters: PreventivoUpsellingCORequest): Observable<VariantiUpsellingConsumo> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<VariantiUpsellingConsumo>({
            path: '/preventivo/upselling/CO',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * L\'unica variante presente sarà l\'alternativa della tipologia importa alla firma
     * Calcolo varianti per il leasing
     */
    preventivoUpsellingLE = (requestParameters: PreventivoUpsellingLERequest): Observable<VarianteUpsellingLeasing> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<VarianteUpsellingLeasing>({
            path: '/preventivo/upselling/LE',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

    /**
     * Calcolo variante con aggiunta di servizi
     * Calcolo variante con aggiunta di servizi
     */
    preventivoUpsellingServizi = (requestParameters: PreventivoUpsellingServiziRequest): Observable<VarianteUpsellingServizi> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        return this.request<VarianteUpsellingServizi>({
            path: '/preventivo/upselling/servizi',
            method: 'POST',
            headers,
            body: requestParameters.body,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum PreventivoServiziPacchettiTipoFinanziamentoEnum {
    Standard = 'standard',
    Campagne = 'campagne',
    Baloon = 'baloon',
    Leasing = 'leasing'
}
/**
 * @export
 * @enum {string}
 */
export enum PreventivoServiziPacchettiTipoAnagraficaEnum {
    P = 'P',
    F = 'F',
    G = 'G'
}
