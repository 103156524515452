import { HelperText } from "@material/react-text-field";
import classNames from "classnames";
import { Field, FieldProps, Form, FormikProps, withFormik, yupToFormErrors } from "formik";
import isEmpty from "lodash.isempty";
import moment from "moment";
import * as React from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Button from "../../../../shared/components/button/button";
import DateField from "../../../../shared/components/date_field/date_field";
import { FormInfo } from "../../../../shared/components/form_info/form_info";
import SelectField from "../../../../shared/components/select_field/select_field";
import TextField from "../../../../shared/components/text_field/text_field";
import { DOCUMENTARY_DATA } from "../../../../shared/constants/application";
import { LABEL_PROSEGUI } from "../../../../shared/constants/labels";
import { isFormSectionHidden, resetField } from "../../../../shared/lib/form/form";
import { warn } from "../../../../shared/lib/log";
import { TDocumentaryDataValues, TFieldName } from "../../../lib/dataHandling/types";
import { setCustomerCache, setCustomerEnablers, setOnReviewErrors } from "../../../logic/caricamento/actions";
import { getDocumenti, getEntiRilascio } from "../../../logic/loader/actions";
import { getDocumentiOptions, getEntiRilascioOptions } from "../../../logic/loader/selectors";
import { IStoreState } from "../../../types/store";
import { isPatenteUCO } from "../client_checks";
import { scrollToError } from "../form.utilities";
import { useAdForm } from "../hooks/useAdForm";
import { useComuniProvincia } from "../hooks/useComuniProvincia";
import { sendGoogleTagManagerEvent, useGoogleTagManager } from "../hooks/useGoogleTagManager";
import { useSaveReviewing } from "../hooks/useSaveReviewing";
import { TProps } from "./types";

const FormDocumentaryData: React.FC<TProps & FormikProps<TDocumentaryDataValues>> = ({
  documenti,
  getDocumenti,
  getEntiRilascio,
  isReviewing = false,
  isReviewingShowAll = false,
  isReviewingShowForm = false,
  setFieldValue,
  setFieldTouched,
  submitForm,
  validateForm,
  values,
  setCustomerCache,
  isEnabled,
  setCustomerEnablers,
  serverSideErrors,
  isUpperCase,
  setOnReviewErrors,
}) => {
  const val = React.useRef<TDocumentaryDataValues>();

  const isSpid = useSelector<IStoreState, boolean>(state => state.caricamento.isSpid);

  useEffect(() => {
    getDocumenti();
    getEntiRilascio();
    return () => {
      if (val.current) {
        setCustomerCache(val.current);
      }
    };
  }, []);

  useEffect(() => {
    val.current = values;
  }, [values]);

  useEffect(() => {
    if (!isSpid) {
      let shouldEnableRelated = true;
      if (isPatenteUCO(values.tipoDocumento?.toString(), values.numeroDocumento?.toString())) {
        setFieldValue("provinciaRilascioDocumento", "RM");
        setFieldValue("localitaRilascioDocumento", "ROMA");
        shouldEnableRelated = false;
      }
      setCustomerEnablers({
        provinciaRilascioDocumento: shouldEnableRelated,
        localitaRilascioDocumento: shouldEnableRelated,
      });
    }
  }, [values.tipoDocumento, values.numeroDocumento, isSpid]);

  const [provinciaRilascioOptions, comuniRilascioOptions] = useComuniProvincia(
    values.provinciaRilascioDocumento?.toString(),
    true
  );

  /**
   * Submit form at every change when in review
   * (because there is no submit button)
   */
  useSaveReviewing(isReviewing, values, submitForm, setOnReviewErrors, validateForm, DOCUMENTARY_DATA, 1000);

  useGoogleTagManager("/documenti", "Documenti");
  useAdForm("documenti");

  /**
   * Reset tipoDocumento when it is not mapped in its loader
   */
  useEffect(() => {
    if (documenti.length) {
      const isValid = documenti.some(a => a.value === values.tipoDocumento);
      if (!isValid) {
        resetField({ setFieldValue, setFieldTouched }, "tipoDocumento");
      }
    }
  }, [documenti, values.tipoDocumento]);

  const getRegularFormItemClass = (fieldName: TFieldName) => {
    return classNames("col-xs-12", "col-sm-6", {
      "field-hidden":
        isFormSectionHidden(isReviewing, isReviewingShowAll, fieldName, serverSideErrors ?? {}) && !isReviewingShowForm,
    });
  };

  return (
    <Form>
      <div className="row">
        <div className={getRegularFormItemClass("tipoDocumento")}>
          <Field name="tipoDocumento">
            {(field: FieldProps<TDocumentaryDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "tipoDocumento" }}
                formikField={field}
                label="Tipo di documento"
                options={documenti}
                disabled={!isEnabled.tipoDocumento}
                serverSideError={serverSideErrors?.["tipoDocumento"] ?? ""}
                isResettable={true}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("numeroDocumento")}>
          <Field name="numeroDocumento">
            {(field: FieldProps<TDocumentaryDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "numeroDocumento" }}
                formikField={field}
                label="Numero documento"
                serverSideError={serverSideErrors?.["numeroDocumento"] ?? ""}
                maxLength={10}
                disabled={!isEnabled.numeroDocumento}
                isUpperCase={isUpperCase["numeroDocumento"]}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("dataRilascioDocumento")}>
          <Field name="dataRilascioDocumento">
            {(field: FieldProps<TDocumentaryDataValues>) => (
              <DateField
                dataAttr={{ cypressSelector: "dataRilascioDocumento" }}
                formikField={field}
                label="Data di rilascio (gg/mm/aaaa)"
                disabled={!isEnabled.dataRilascioDocumento}
                serverSideError={serverSideErrors?.["dataRilascioDocumento"] ?? ""}
              />
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("dataScadenzaDocumento")}>
          <Field name="dataScadenzaDocumento">
            {(field: FieldProps<TDocumentaryDataValues>) => (
              <div>
                <DateField
                  dataAttr={{ cypressSelector: "dataScadenzaDocumento" }}
                  formikField={field}
                  label="Data di scadenza (gg/mm/aaaa)"
                  disabled={!isEnabled.dataScadenzaDocumento}
                  serverSideError={serverSideErrors?.["dataScadenzaDocumento"] ?? ""}
                />
                {moment("20210930", "YYYYMMDD").diff(moment()) >= 0 && (
                  <HelperText isValidationMessage={false} persistent={true} validation={true}>
                    Se il documento è scaduto al 31.01.2020 o data successiva, è possibile inserire come data scadenza
                    il 30.09.2021, come previsto dal Decreto Legge 56/2020 emanato nell'ambito dell'emergenza sanitaria
                    da Covid-19
                  </HelperText>
                )}
              </div>
            )}
          </Field>
        </div>

        <div className={getRegularFormItemClass("provinciaRilascioDocumento")}>
          <Field name="provinciaRilascioDocumento">
            {(field: FieldProps<TDocumentaryDataValues>) =>
              isEmpty(provinciaRilascioOptions) ? (
                <TextField
                  dataAttr={{ cypressSelector: "provinciaRilascioDocumento" }}
                  formikField={field}
                  label="Provincia di rilascio"
                  disabled={!isEnabled.provinciaRilascioDocumento}
                  serverSideError={serverSideErrors?.["provinciaRilascioDocumento"] ?? ""}
                  isUpperCase={isUpperCase["provinciaRilascioDocumento"]}
                />
              ) : (
                <SelectField
                  dataAttr={{ cypressSelector: "provinciaRilascioDocumento" }}
                  disabled={!isEnabled.provinciaRilascioDocumento}
                  formikField={field}
                  label="Provincia di rilascio"
                  options={provinciaRilascioOptions}
                  serverSideError={serverSideErrors?.["provinciaRilascioDocumento"] ?? ""}
                />
              )
            }
          </Field>
        </div>

        <div className={getRegularFormItemClass("localitaRilascioDocumento")}>
          <Field name="localitaRilascioDocumento">
            {(field: FieldProps<TDocumentaryDataValues>) =>
              !isEnabled["localitaRilascioDocumento"] ? (
                <TextField
                  dataAttr={{ cypressSelector: "localitaRilascioDocumento" }}
                  formikField={field}
                  label="Comune di rilascio"
                  disabled={true}
                  serverSideError={serverSideErrors?.["localitaRilascioDocumento"] ?? ""}
                  isUpperCase={isUpperCase["localitaRilascioDocumento"]}
                />
              ) : isEmpty(comuniRilascioOptions) ? (
                <TextField
                  dataAttr={{ cypressSelector: "localitaRilascioDocumento" }}
                  formikField={field}
                  label="Comune di rilascio"
                  disabled={!isEnabled.localitaRilascioDocumento}
                  serverSideError={serverSideErrors?.["localitaRilascioDocumento"] ?? ""}
                  isUpperCase={isUpperCase["localitaRilascioDocumento"]}
                />
              ) : (
                <SelectField
                  dataAttr={{ cypressSelector: "localitaRilascioDocumento" }}
                  formikField={field}
                  label="Comune di rilascio"
                  disabled={!isEnabled.localitaRilascioDocumento}
                  options={comuniRilascioOptions}
                  serverSideError={serverSideErrors?.["localitaRilascioDocumento"] ?? ""}
                />
              )
            }
          </Field>
        </div>

        <div className={getRegularFormItemClass("iban")}>
          <Field name="iban">
            {(field: FieldProps<TDocumentaryDataValues>) => (
              <TextField
                dataAttr={{ cypressSelector: "iban" }}
                formikField={field}
                label="Codice Iban"
                helperText={
                  <span>
                    Il codice IBAN inizia con IT ed è composto da 27 caratteri alfanumerici. <br />
                    (Es: IT88A0123456789012345678901)
                  </span>
                }
                serverSideError={serverSideErrors?.["iban"] ?? ""}
                disabled={!isEnabled["iban"]}
                isUpperCase={isUpperCase["iban"]}
              />
            )}
          </Field>
        </div>
      </div>
      {!isReviewing ? (
        <React.Fragment>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                onClick={scrollToError(validateForm, isReviewing)}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </Form>
  );
};

const FormWithFormik = withFormik<TProps, TDocumentaryDataValues>({
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
    sendGoogleTagManagerEvent("ButtonPP", "FunnelPP", "AvantiAOccupazione");
  },
  mapPropsToValues: (props: TProps) => props.initialValues,
  validateOnChange: true,
  validate: async (values: TDocumentaryDataValues, props: TProps) => {
    const { validationSchema, validationContext: context } = props;
    if (validationSchema) {
      try {
        await validationSchema.validate(values, { abortEarly: false, context });
        return {};
      } catch (formErrors) {
        return yupToFormErrors(formErrors);
      }
    } else {
      warn("validationSchema passed to FormDocuentaryData is not valid.");
      return {};
    }
  },
})(FormDocumentaryData);

const mapStateToProps = (state: IStoreState) => ({
  documenti: getDocumentiOptions(state),
  entiRilascio: getEntiRilascioOptions(state),
});

const mapDispatchToProps = {
  getDocumenti,
  getEntiRilascio,
  setCustomerCache,
  setCustomerEnablers,
  setOnReviewErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);
