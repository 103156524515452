import chromeLogo from "../assets/images/chrome-logo.png";
import firefoxLogo from "../assets/images/firefox-logo.png";
import edgeLogo from "../assets/images/edge-logo.png";

export function renderIncompatibilityDialog(): void {
  const element = document.getElementById("root");

  if (element) {
    element.innerHTML = `
      <div style="display: flex; justify-content: center; align-items: center; margin-top: 90px;">
          <div style="background-color: #fafafa; border-radius: 10px; width: 800px; padding: 15px 30px 15px 30px;">
  
          <div style="display: flex; justify-content: center; align-items: flex-end; margin: 30px;">
              <div style="display: flex; flex-direction: column;">
              <div style="margin-bottom: 15px; text-align: center;">Browsers supportati:</div>
              <div style="display: flex; align-items: center;">
                  <img alt="Chrome" src="${chromeLogo}" height="30" />
                  <div style="margin-left: 5px; margin-right: 10px;">Chrome</div>
                  <img alt="Firefox" src="${firefoxLogo}" height="30" />
                  <div style="margin-left: 5px; margin-right: 10px;">Firefox</div>
                  <img alt="Edge" src="${edgeLogo}" height="30" />
                  <div style="margin-left: 5px;">Edge</div>
              </div>
              </div>
          </div>
          </div>
      </div>
      `;
  }
}
