// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NuovaControparteContropartiCoobbligatoResidenza,
    NuovaControparteDataAvc,
    NuovaControparteDataCciaa,
    NuovaControparteDataDocumentoidentita,
    NuovaControparteDataIndirizzoalternativo,
    NuovaControparteDataNascita,
    NuovaControparteDataOccupazione,
    NuovaControparteDataPrivacy,
} from './';

/**
 * @export
 * @interface NuovaControparteContropartiGarante2
 */
export interface NuovaControparteContropartiGarante2 {
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    tipoanagrafica?: string;
    /**
     * @type {number}
     * @memberof NuovaControparteContropartiGarante2
     */
    codice?: number;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    parentela?: string;
    /**
     * @type {NuovaControparteContropartiCoobbligatoResidenza}
     * @memberof NuovaControparteContropartiGarante2
     */
    residenza?: NuovaControparteContropartiCoobbligatoResidenza;
    /**
     * @type {NuovaControparteDataIndirizzoalternativo}
     * @memberof NuovaControparteContropartiGarante2
     */
    indirizzoalternativo?: NuovaControparteDataIndirizzoalternativo;
    /**
     * @type {NuovaControparteDataAvc}
     * @memberof NuovaControparteContropartiGarante2
     */
    avc?: NuovaControparteDataAvc;
    /**
     * @type {NuovaControparteDataPrivacy}
     * @memberof NuovaControparteContropartiGarante2
     */
    privacy?: NuovaControparteDataPrivacy;
    /**
     * @type {boolean}
     * @memberof NuovaControparteContropartiGarante2
     */
    titolareeffettivo?: boolean;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    cognome?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    nome?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    sesso?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    ragsociale?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    statocivile?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    partitaiva?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    codicefiscale?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    codicefiscalesoc?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    cittadinanza?: string;
    /**
     * @type {NuovaControparteDataNascita}
     * @memberof NuovaControparteContropartiGarante2
     */
    nascita?: NuovaControparteDataNascita;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    tesserasanitaria?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    scadenzatesserasanitaria?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    presenzatesserasanitaria?: string;
    /**
     * @type {NuovaControparteDataIndirizzoalternativo}
     * @memberof NuovaControparteContropartiGarante2
     */
    indirizzoprecedente?: NuovaControparteDataIndirizzoalternativo;
    /**
     * @type {NuovaControparteDataDocumentoidentita}
     * @memberof NuovaControparteContropartiGarante2
     */
    documentoidentita?: NuovaControparteDataDocumentoidentita;
    /**
     * @type {NuovaControparteDataOccupazione}
     * @memberof NuovaControparteContropartiGarante2
     */
    occupazione?: NuovaControparteDataOccupazione;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    datainizioattivita?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    formagiuridica?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    ateco?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    sae?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    rae?: string;
    /**
     * @type {NuovaControparteDataCciaa}
     * @memberof NuovaControparteContropartiGarante2
     */
    cciaa?: NuovaControparteDataCciaa;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    datacostituzione?: string;
    /**
     * @type {string}
     * @memberof NuovaControparteContropartiGarante2
     */
    coabitante?: string;
}
