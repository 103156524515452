import classNames from "classnames";
import * as React from "react";
import "./container.scss";

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

const Container: React.FunctionComponent<IProps> = ({ children, className }) => {
  const classes = classNames({
    containerComponent: true,
    ...(className ? { [className]: true } : {}),
  });
  return <div className={classes}>{children ? children : null}</div>;
};

export default Container;
