// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ControparteData,
    ContropartePagamento,
} from './';

/**
 * 
 * @export
 * @interface Controparte
 */
export interface Controparte {
    /**
     * @type {boolean}
     * @memberof Controparte
     */
    spid?: boolean;
    /**
     * @type {ControparteData}
     * @memberof Controparte
     */
    data?: ControparteData;
    /**
     * @type {ContropartePagamento}
     * @memberof Controparte
     */
    pagamento?: ContropartePagamento;
}
