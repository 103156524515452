import {
  TDocumentaryDataFieldName,
  TOccupationalDataFieldName,
  TPersonalDataFieldName,
  TFieldAddress,
  TFormName,
  TFieldName,
  TTaxDeductionsFieldName,
} from "./types";

export const documentRegExp = /^[A-Z0-9]{9,10}$/i;
export const onlyNumbersRegExp = /^[0-9]+$/;
export const capRegExp = /^[0-9]{5}$/;
export const atLeast5CharsRegExp = /^.{5,}$/;
export const telefonoRegExp = /^3[0-9]{8,}$/;
export const codiceFiscaleRegexp = /^([A-Z]{6})([0-9]{2})([A-Z]{1})([0-9]{2})([A-Z]{1})([A-Z0-9]{3})([A-Z]{1})$/i;

export const personalDataKeys: Array<TPersonalDataFieldName> = [
  "nome",
  "cognome",
  "codiceFiscale",
  "dataNascita",
  "provinciaNascita",
  "luogoNascita",
  "sesso",
  "cittadinanza",
  "tipoPermessoSoggiorno",
  "scadenzaPermessoSoggiorno",
  "residenzaIndirizzo",
  "residenzaCap",
  "residenzaProvincia",
  "residenzaLocalita",
  "residenzaAbitazione",
  "residenzaData",
  "email",
  "numCellulare",
  "indirizzoPrecedenteIndirizzo",
  "indirizzoPrecedenteCap",
  "indirizzoPrecedenteProvincia",
  "indirizzoPrecedenteLocalita",
  "statoCivile",
  "venditaDistanza",
  "preventivoSECCI",
  "usoDati",
  "privacy1",
  "privacy2",
  "privacy3",
  "privacy4",
  "privacy6",
  "privacy7",
  "privacy8",
  "speseAffitto",
  "componentiNucleoFamigliare",
  "famigliariACarico",
];

export const documentaryDataKeys: Array<TDocumentaryDataFieldName> = [
  "tipoDocumento",
  "numeroDocumento",
  "dataRilascioDocumento",
  "dataScadenzaDocumento",
  "provinciaRilascioDocumento",
  "localitaRilascioDocumento",
  "ufficioRilascioDocumento",
  "numeroTesseraSanitaria",
  "dataScadenzaTesseraSanitaria",
  "iban",
];

export const vehicleDataKeys: Array<unknown> = [];

export const occupationalDataKeys: Array<TOccupationalDataFieldName> = [
  "attivita",
  "professione",
  "dataUltimaOccupazione",
  "reddito",
  "ragioneSocialeDatoreLavoro",
  "indirizzoDatoreLavoro",
  "capDatoreLavoro",
  "telefonoDatoreLavoro",
  "provinciaDatoreLavoro",
  "localitaDatoreLavoro",
  "attivitaPrecedente",
  "occupazionePrecedente",
  "redditiSecondari",
  "fontiSecondari",
  "pep",
  "altriRedditiNucleoFamigliare",
];

export const taxDeductionsKeys: Array<TTaxDeductionsFieldName> = [
  "vuoiBeneficiareDellaDetrazioneFiscale",
  "tipoDiAgevolazioneFiscale",
  "numeroFattura",
  "importoFattura",
  "dataFattura",
];

export const allKeys = [...personalDataKeys, ...documentaryDataKeys, ...occupationalDataKeys, ...taxDeductionsKeys];

export const formKeysDistribution: Partial<Record<TFormName, Array<TFieldName>>> = {
  personalData: personalDataKeys,
  documentaryData: documentaryDataKeys,
  occupationalData: occupationalDataKeys,
  taxDeductions: taxDeductionsKeys,
};

export const undefinedDatasetElementNotAllowed: Array<TFieldAddress> = [
  "inputPath",
  "outputPath",
  "validation",
  "serverSideError",
  "hasOnReviewError",
];
