// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    InvioPecInputAttachments,
} from './';

/**
 * 
 * @export
 * @interface InvioPecInput
 */
export interface InvioPecInput {
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    email_mittente?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    email_destinatario?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    email_cc?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    oggetto?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    testo?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    testohtml?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    codice_gruppo?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    codice_identificativo?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    codice_tipo_chiamata?: string;
    /**
     * @type {string}
     * @memberof InvioPecInput
     */
    codice_attivita?: string;
    /**
     * @type {InvioPecInputAttachments}
     * @memberof InvioPecInput
     */
    Attachments?: InvioPecInputAttachments;
}
