// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Assicurazione,
} from './';

/**
 * @export
 * @interface PreventivoConsumoOutputAllOf
 */
export interface PreventivoConsumoOutputAllOf {
    /**
     * Valore della maxirata
     * @type {number}
     * @memberof PreventivoConsumoOutputAllOf
     */
    maxirata?: number;
    /**
     * @type {Array<Assicurazione>}
     * @memberof PreventivoConsumoOutputAllOf
     */
    assicurazioni?: Array<Assicurazione>;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutputAllOf
     */
    bollo?: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutputAllOf
     */
    interessi?: number;
    /**
     * finanziato lordo istanziato dalla banca
     * @type {number}
     * @memberof PreventivoConsumoOutputAllOf
     */
    lordo?: number;
    /**
     * @type {Array<object>}
     * @memberof PreventivoConsumoOutputAllOf
     */
    noteLegali?: Array<object>;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutputAllOf
     */
    spese?: number;
    /**
     * descrizione del errore riscontrato in fase di calcolo
     * @type {string}
     * @memberof PreventivoConsumoOutputAllOf
     */
    errore?: string;
}
