import classNames from "classnames";
import { Field, FieldProps, Form, FormikProps, withFormik, yupToFormErrors } from "formik";
import has from "lodash.has";
import isEmpty from "lodash.isempty";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import BooleanRadio from "../../../../shared/components/boolean_radio/boolean_radio";
import Button from "../../../../shared/components/button/button";
import DateField from "../../../../shared/components/date_field/date_field";
import { FormInfo } from "../../../../shared/components/form_info/form_info";
import InfoModal from "../../../../shared/components/info_modal/info_modal";
import NumericField from "../../../../shared/components/numeric_field/numeric_field";
import SelectField from "../../../../shared/components/select_field/select_field";
import TextField from "../../../../shared/components/text_field/text_field";
import Tooltip from "../../../../shared/components/tooltip/Tooltip";
import {
  ARRAY_ATTIVITA_NON_IMPIEGATO,
  ATTIVITA_SENZA_REDDITO,
  OCCUPATIONAL_DATA,
  PEP_VALUES,
  REDDITI_SECONDARI_VALUES,
} from "../../../../shared/constants/application";
import {
  LABEL_PROSEGUI,
  PEP_DIALOG_ICON,
  PEP_DIALOG_TEXT,
  PEP_DIALOG_TITLE,
  ULTIMA_OCCUPAZIONE_DIALOG_TEXT,
} from "../../../../shared/constants/labels";
import { useWindowWidth } from "../../../../shared/hooks/useWindowWidth";
import { isFormSectionHidden, resetField } from "../../../../shared/lib/form/form";
import { warn } from "../../../../shared/lib/log";
import { isMobile } from "../../../../shared/lib/utility/responsive";
import { TFieldName, TOccupationalDataValues } from "../../../lib/dataHandling/types";
import {
  setCustomerCache,
  setCustomerEnablers,
  setIsIncompatible,
  setOnReviewErrors,
} from "../../../logic/caricamento/actions";
import { getAttivita, getComuni, getProfessioni, getProvince } from "../../../logic/loader/actions";
import { getAttivitaOptions, getProfessioniOptions } from "../../../logic/loader/selectors";
import { IStoreState } from "../../../types/store";
import { useIncompatibilityCustomer } from "../../caricamento/hooks/useIncompatibilityCustomer";
import { checkDataUltimoImpiego, isLavoratoreAutonomo } from "../client_checks";
import { scrollToError } from "../form.utilities";
import { sendAdFormEvent, useAdForm } from "../hooks/useAdForm";
import { useCapDatoreLavoro } from "../hooks/useCapDatoreLavoro";
import { useComuniProvincia } from "../hooks/useComuniProvincia";
import { useDidUpdateEffect } from "../hooks/useDidUpdateEffect";
import { sendGoogleTagManagerEvent, useGoogleTagManager } from "../hooks/useGoogleTagManager";
import { useSaveReviewing } from "../hooks/useSaveReviewing";
import { TProps } from "./types";

// Rule react/display-name is suppressed because this is an eslint-typescript error, as the component is named
// FormOccupationalDataComp (below)
// eslint-disable-next-line react/display-name
const FormOccupationalData: React.FC<TProps & FormikProps<TOccupationalDataValues>> = ({
  attivita,
  getAttivita,
  getProfessioni,
  isReviewing = false,
  isReviewingShowAll = false,
  isReviewingShowForm = false,
  submitForm,
  onPartialSubmit,
  professioni,
  setFieldTouched,
  setFieldValue,
  validateForm,
  values,
  setCustomerCache,
  serverSideErrors,
  isLoading,
  isUpperCase,
  setOnReviewErrors,
  isEnabled,
  preventivo,
  setIsIncompatible,
  setCustomerEnablers,
}) => {
  const val = React.useRef<TOccupationalDataValues>();
  const width = useWindowWidth();

  useEffect(() => {
    getAttivita();
    getProfessioni();
    getProvince();
    return () => {
      if (val.current) {
        setCustomerCache(val.current);
      }
    };
  }, []);

  useEffect(() => {
    val.current = values;
  }, [values]);

  const [provinciaDatoreLavoroOptions, localitaDatoreLavoroOptions] = useComuniProvincia(
    values.provinciaDatoreLavoro?.toString()
  );

  const [isPepDialogOpen, setIsPepDialogOpen] = useState(false);
  const [isDataUltimaOccupazioneDialogOpen, setIsDataUltimaOccupazioneDialogOpen] = useState(false);

  /**
   * Submit form at every change when in review
   * (because there is no submit button)
   */
  useSaveReviewing(
    isReviewing,
    values,
    onPartialSubmit || submitForm,
    setOnReviewErrors,
    validateForm,
    OCCUPATIONAL_DATA,
    1000
  );

  // logiche Attività
  useDidUpdateEffect(() => {
    resetField({ setFieldValue, setFieldTouched }, "professione");
    resetField({ setFieldValue, setFieldTouched }, "dataUltimaOccupazione");
    resetField({ setFieldValue, setFieldTouched }, "attivitaPrecedente");
    resetField({ setFieldValue, setFieldTouched }, "occupazionePrecedente");
    resetField({ setFieldValue, setFieldTouched }, "ragioneSocialeDatoreLavoro");
    resetField({ setFieldValue, setFieldTouched }, "indirizzoDatoreLavoro");
    resetField({ setFieldValue, setFieldTouched }, "telefonoDatoreLavoro");
    resetField({ setFieldValue, setFieldTouched }, "provinciaDatoreLavoro");
    resetField({ setFieldValue, setFieldTouched }, "localitaDatoreLavoro");
    resetField({ setFieldValue, setFieldTouched }, "capDatoreLavoro");
    resetField({ setFieldValue, setFieldTouched }, "reddito");
  }, [values.attivita]);

  // logiche Residente dal
  useDidUpdateEffect(() => {
    if (values.dataUltimaOccupazione && !checkDataUltimoImpiego(values.dataUltimaOccupazione?.toString())) {
      resetField({ setFieldValue, setFieldTouched }, "attivitaPrecedente");
      resetField({ setFieldValue, setFieldTouched }, "occupazionePrecedente");
    }
  }, [values.dataUltimaOccupazione]);

  // logiche Tipo Permesso Soggiorno
  useDidUpdateEffect(() => {
    if (values.dataUltimaOccupazione && !checkDataUltimoImpiego(values.dataUltimaOccupazione?.toString())) {
      resetField({ setFieldValue, setFieldTouched }, "fontiSecondari");
    }
  }, [values.redditiSecondari]);

  useDidUpdateEffect(() => {
    resetField({ setFieldValue, setFieldTouched }, "localitaDatoreLavoro");

    if (values.provinciaDatoreLavoro) {
      getComuni(values.provinciaDatoreLavoro?.toString());
    }

    if (values.provinciaDatoreLavoro === "*") {
      setCustomerEnablers({
        capDatoreLavoro: false,
      });
      setFieldValue("capDatoreLavoro", "00000");
    } else {
      setCustomerEnablers({
        capDatoreLavoro: true,
      });
    }
  }, [values.provinciaDatoreLavoro]);

  useGoogleTagManager("/dati-occupazionali", "Dati Occupazionali");
  useAdForm("occupazione");

  /**
   * When attività is not mapped in its loader options
   * reset attività and professione to empty value
   */
  useEffect(() => {
    if (attivita.length) {
      const isValid = attivita.some(a => a.value === values.attivita);
      if (!isValid) {
        resetField({ setFieldValue, setFieldTouched }, "attivita");
        resetField({ setFieldValue, setFieldTouched }, "professione");
      }
    }
  }, [attivita, values.attivita]);

  const getRegularFormItemClass = (fieldName: TFieldName) => {
    return classNames("col-xs-12", "col-sm-6", {
      "field-hidden": serverSideErrors
        ? isFormSectionHidden(isReviewing, isReviewingShowAll, fieldName, serverSideErrors) && !isReviewingShowForm
        : false,
    });
  };

  /** Check for incompatible customers with insurance:
   * - PENSIONATO-PENSIONE INVALIDITA'
   * - LAVORATORE AUTONOMO
   */

  useIncompatibilityCustomer(preventivo.data, setIsIncompatible, values.attivita, values.professione);

  useCapDatoreLavoro(isEnabled.capDatoreLavoro, values.capDatoreLavoro, values.provinciaDatoreLavoro, {
    setFieldValue,
    setFieldTouched,
  });

  return (
    <Form>
      <div className="row">
        <div className={getRegularFormItemClass("attivita")}>
          <Field name="attivita">
            {(field: FieldProps<TOccupationalDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "attivita" }}
                formikField={field}
                label="Attività"
                options={attivita}
                serverSideError={serverSideErrors ? serverSideErrors["attivita"] : ""}
                isResettable={true}
              />
            )}
          </Field>
        </div>
        <div className={getRegularFormItemClass("professione")}>
          <Field name="professione">
            {(field: FieldProps<TOccupationalDataValues>) => (
              <SelectField
                dataAttr={{ cypressSelector: "professione" }}
                formikField={field}
                label="Professione"
                options={values.attivita ? professioni[values.attivita] : []}
                serverSideError={serverSideErrors ? serverSideErrors["professione"] : ""}
                isResettable={true}
              />
            )}
          </Field>
        </div>

        {!has(values, "attivita") || values.attivita !== ATTIVITA_SENZA_REDDITO ? (
          <div className={getRegularFormItemClass("reddito")}>
            <Field name="reddito">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <NumericField
                  dataAttr={{ cypressSelector: "reddito" }}
                  isCurrency={true}
                  formikField={field}
                  label="Reddito mensile netto"
                  serverSideError={serverSideErrors ? serverSideErrors["reddito"] : ""}
                  helperText="Non inserire cifre decimali"
                />
              )}
            </Field>
          </div>
        ) : null}

        {values.attivita && !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(values.attivita?.toString()) ? (
          <div className={getRegularFormItemClass("dataUltimaOccupazione")}>
            <Field name="dataUltimaOccupazione">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <DateField
                  dataAttr={{ cypressSelector: "dataUltimaOccupazione" }}
                  formikField={field}
                  label="Data ultima assunzione/inizio attività (mm/aaaa)"
                  type="monthYear"
                  serverSideError={serverSideErrors ? serverSideErrors["dataUltimaOccupazione"] : ""}
                  tooltip={
                    <Tooltip icon={PEP_DIALOG_ICON} onTooltipClick={() => setIsDataUltimaOccupazioneDialogOpen(true)} />
                  }
                  helperText={
                    isMobile(width)
                      ? "Inserire la data di assunzione presso l'attuale datore di lavoro. Per i lavoratori autonomi, inserire la data di inizio dell'attività."
                      : ""
                  }
                />
              )}
            </Field>
          </div>
        ) : null}

        {values.dataUltimaOccupazione &&
        has(values, "dataUltimaOccupazione") &&
        checkDataUltimoImpiego(values.dataUltimaOccupazione.toString()) ? (
          <div className={getRegularFormItemClass("attivitaPrecedente")}>
            <Field name="attivitaPrecedente">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <SelectField
                  dataAttr={{ cypressSelector: "attivitaPrecedente" }}
                  formikField={field}
                  label="Attività Precedente"
                  options={attivita}
                  serverSideError={serverSideErrors ? serverSideErrors["attivitaPrecedente"] : ""}
                />
              )}
            </Field>
          </div>
        ) : null}
        {values.dataUltimaOccupazione &&
        has(values, "dataUltimaOccupazione") &&
        checkDataUltimoImpiego(values.dataUltimaOccupazione.toString()) ? (
          <div className={getRegularFormItemClass("occupazionePrecedente")}>
            <Field name="occupazionePrecedente">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <SelectField
                  dataAttr={{ cypressSelector: "occupazionePrecedente" }}
                  formikField={field}
                  label="Professione precedente"
                  serverSideError={serverSideErrors ? serverSideErrors["occupazionePrecedente"] : ""}
                  options={values.attivitaPrecedente ? professioni[values.attivitaPrecedente] : []}
                />
              )}
            </Field>
          </div>
        ) : null}
      </div>
      {values.attivita && !ARRAY_ATTIVITA_NON_IMPIEGATO.includes(values.attivita.toString()) ? (
        <div className="row">
          <div
            className={classNames("col-xs-12", {
              "field-hidden": serverSideErrors
                ? isFormSectionHidden(
                    isReviewing,
                    isReviewingShowAll,
                    [
                      "ragioneSocialeDatoreLavoro",
                      "indirizzoDatoreLavoro",
                      "telefonoDatoreLavoro",
                      "provinciaDatoreLavoro",
                      "localitaDatoreLavoro",
                    ],
                    serverSideErrors
                  )
                : false,
            })}
          >
            <p className="form__label">
              {isLavoratoreAutonomo(values.attivita.toString())
                ? "Dati dell'impresa/azienda"
                : "Datore di lavoro attuale"}
            </p>
          </div>

          <div className={getRegularFormItemClass("ragioneSocialeDatoreLavoro")}>
            <Field name="ragioneSocialeDatoreLavoro">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <TextField
                  dataAttr={{ cypressSelector: "ragioneSocialeDatoreLavoro" }}
                  formikField={field}
                  label="Ragione Sociale"
                  serverSideError={serverSideErrors ? serverSideErrors["ragioneSocialeDatoreLavoro"] : ""}
                  isUpperCase={isUpperCase["ragioneSocialeDatoreLavoro"]}
                />
              )}
            </Field>
          </div>

          <div className={getRegularFormItemClass("telefonoDatoreLavoro")}>
            <Field name="telefonoDatoreLavoro">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <TextField
                  dataAttr={{ cypressSelector: "telefonoDatoreLavoro" }}
                  formikField={field}
                  label="Telefono"
                  serverSideError={serverSideErrors ? serverSideErrors["telefonoDatoreLavoro"] : ""}
                  isUpperCase={isUpperCase["telefonoDatoreLavoro"]}
                  type="tel"
                />
              )}
            </Field>
          </div>

          {isEnabled["capDatoreLavoro"] && (
            <div className={getRegularFormItemClass("capDatoreLavoro")}>
              <Field name="capDatoreLavoro">
                {(field: FieldProps<TOccupationalDataValues>) => (
                  <TextField
                    dataAttr={{ cypressSelector: "capDatoreLavoro" }}
                    formikField={field}
                    label="CAP"
                    type="tel"
                    serverSideError={serverSideErrors ? serverSideErrors["capDatoreLavoro"] : ""}
                    isUpperCase={isUpperCase["capDatoreLavoro"]}
                  />
                )}
              </Field>
            </div>
          )}

          <div className={getRegularFormItemClass("provinciaDatoreLavoro")}>
            <Field name="provinciaDatoreLavoro">
              {(field: FieldProps<TOccupationalDataValues>) =>
                isEmpty(provinciaDatoreLavoroOptions) ? (
                  <TextField
                    dataAttr={{ cypressSelector: "provinciaDatoreLavoro" }}
                    formikField={field}
                    label="Provincia"
                    isUpperCase={isUpperCase["provinciaDatoreLavoro"]}
                  />
                ) : (
                  <SelectField
                    dataAttr={{ cypressSelector: "provinciaDatoreLavoro" }}
                    formikField={field}
                    label={"Provincia"}
                    options={provinciaDatoreLavoroOptions}
                    serverSideError={serverSideErrors ? serverSideErrors["provinciaDatoreLavoro"] : ""}
                  />
                )
              }
            </Field>
          </div>

          <div className={getRegularFormItemClass("localitaDatoreLavoro")}>
            <Field name="localitaDatoreLavoro">
              {(field: FieldProps<TOccupationalDataValues>) =>
                isEmpty(localitaDatoreLavoroOptions) ? (
                  <TextField
                    dataAttr={{ cypressSelector: "localitaDatoreLavoro" }}
                    formikField={field}
                    label="Comune"
                    serverSideError={serverSideErrors ? serverSideErrors["localitaDatoreLavoro"] : ""}
                    isUpperCase={isUpperCase["localitaDatoreLavoro"]}
                  />
                ) : (
                  <SelectField
                    dataAttr={{ cypressSelector: "localitaDatoreLavoro" }}
                    formikField={field}
                    label={"Comune"}
                    serverSideError={serverSideErrors ? serverSideErrors["localitaDatoreLavoro"] : ""}
                    options={localitaDatoreLavoroOptions}
                  />
                )
              }
            </Field>
          </div>

          <div className={getRegularFormItemClass("indirizzoDatoreLavoro")}>
            <Field name="indirizzoDatoreLavoro">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <TextField
                  dataAttr={{ cypressSelector: "indirizzoDatoreLavoro" }}
                  formikField={field}
                  label="Indirizzo"
                  serverSideError={serverSideErrors ? serverSideErrors["indirizzoDatoreLavoro"] : ""}
                  isUpperCase={isUpperCase["indirizzoDatoreLavoro"]}
                />
              )}
            </Field>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className={getRegularFormItemClass("redditiSecondari")}>
          <Field name="redditiSecondari">
            {(field: FieldProps<TOccupationalDataValues>) => (
              <BooleanRadio
                dataAttr={{ cypressSelector: "redditiSecondari" }}
                label="Altri redditi"
                formikField={field}
                trueLabel="Si"
                trueValue={REDDITI_SECONDARI_VALUES.true}
                falseLabel="No"
                falseValue={REDDITI_SECONDARI_VALUES.false}
                serverSideError={serverSideErrors ? serverSideErrors["redditiSecondari"] : ""}
              />
            )}
          </Field>
        </div>

        {values.redditiSecondari === REDDITI_SECONDARI_VALUES.true ? (
          <div className={getRegularFormItemClass("fontiSecondari")}>
            <Field name="fontiSecondari">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <TextField
                  dataAttr={{ cypressSelector: "fontiSecondari" }}
                  formikField={field}
                  label="Descrizione altri redditi"
                  serverSideError={serverSideErrors ? serverSideErrors["fontiSecondari"] : ""}
                  isUpperCase={isUpperCase["fontiSecondari"]}
                />
              )}
            </Field>
          </div>
        ) : null}

        <div className={getRegularFormItemClass("pep")}>
          <Field name="pep">
            {(field: FieldProps<TOccupationalDataValues>) => (
              <BooleanRadio
                dataAttr={{ cypressSelector: "pep" }}
                label="Persona politicamente esposta"
                tooltip={<Tooltip icon={PEP_DIALOG_ICON} onTooltipClick={() => setIsPepDialogOpen(true)} />}
                formikField={field}
                trueLabel="Si"
                trueValue={PEP_VALUES.true}
                falseLabel="No"
                falseValue={PEP_VALUES.false}
                serverSideError={serverSideErrors ? serverSideErrors["pep"] : ""}
              />
            )}
          </Field>
        </div>
      </div>

      {isPepDialogOpen ? (
        <InfoModal
          dismissLabel="CHIUDI"
          titleLabel={PEP_DIALOG_TITLE}
          textLabel={PEP_DIALOG_TEXT}
          isModalOpen={isPepDialogOpen}
          onDismiss={() => setIsPepDialogOpen(false)}
        />
      ) : null}

      {isDataUltimaOccupazioneDialogOpen ? (
        <InfoModal
          dismissLabel="CHIUDI"
          textLabel={ULTIMA_OCCUPAZIONE_DIALOG_TEXT}
          isModalOpen={isDataUltimaOccupazioneDialogOpen}
          onDismiss={() => setIsDataUltimaOccupazioneDialogOpen(false)}
        />
      ) : null}

      {isEnabled["altriRedditiNucleoFamigliare"] && (
        <div className="row">
          <div className={getRegularFormItemClass("altriRedditiNucleoFamigliare")}>
            <Field name="altriRedditiNucleoFamigliare">
              {(field: FieldProps<TOccupationalDataValues>) => (
                <NumericField
                  dataAttr={{ cypressSelector: "altriRedditiNucleoFamigliare" }}
                  formikField={field}
                  label="Altri redditi nucleo famigliare"
                  serverSideError={serverSideErrors ? serverSideErrors["altriRedditiNucleoFamigliare"] : ""}
                  isCurrency
                  helperText="Non inserire cifre decimali"
                />
              )}
            </Field>
          </div>
        </div>
      )}

      {!isReviewing ? (
        <React.Fragment>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                onClick={scrollToError(validateForm, isReviewing)}
                isLoading={isLoading}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </React.Fragment>
      ) : onPartialSubmit ? (
        <div>
          <div className="row center-sm">
            <div className="col-xs-12 col-sm-4">
              <Button
                /*disabled={!form.isValid}*/
                data-cy="next-step-button"
                fullWidth={true}
                size="big"
                theme="success"
                type="submit"
                isLoading={isLoading}
              >
                {LABEL_PROSEGUI}
              </Button>
            </div>
          </div>
          <div className="row center-sm">
            <div className="col-xs-12">
              <FormInfo />
            </div>
          </div>
        </div>
      ) : null}
    </Form>
  );
};

const FormWithFormik = withFormik<TProps, TOccupationalDataValues>({
  enableReinitialize: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit(values);
    sendGoogleTagManagerEvent("ButtonPP", "FunnelPP", "AvantiAUpload");
    sendAdFormEvent("avanti");
  },
  mapPropsToValues: (props: TProps) => props.initialValues,
  validateOnChange: true,
  validate: (values: TOccupationalDataValues, props: TProps) => {
    const { validationSchema, validationContext: context } = props;
    if (validationSchema) {
      return validationSchema
        .validate(values, { abortEarly: false, context })
        .then(() => ({}))
        .catch(yupToFormErrors);
    } else {
      warn("validationSchema passed to FormOccupationalData is not valid.");
      return {};
    }
  },
})(FormOccupationalData);

const mapStateToProps = (state: IStoreState) => ({
  attivita: getAttivitaOptions(state),
  professioni: getProfessioniOptions(state),
  isLoading: state.caricamento.isLoading,
  preventivo: state.caricamento.preventivo,
});

const mapDispatchToProps = {
  getAttivita,
  getProfessioni,
  setCustomerCache,
  setOnReviewErrors,
  setIsIncompatible,
  setCustomerEnablers,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWithFormik);
