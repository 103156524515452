// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AbstractPreventivoOutput,
    Assicurazione,
    PreventivoConsumoOutputAllOf,
    RataVariabile,
} from './';

/**
 * modello complessivo degli attributi di output
 * @export
 * @interface PreventivoConsumoOutput
 */
export interface PreventivoConsumoOutput {
    /**
     * codice del capo catena
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    capoCatena?: string;
    /**
     * @type {Date}
     * @memberof PreventivoConsumoOutput
     */
    dataCreazione?: Date;
    /**
     * importo totale dovuto dal cliente (finanziato + spese + assicurazioni)
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    importoDovuto?: number;
    /**
     * importo della rata
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    importoRata?: number;
    /**
     * costi di gestione della rata
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    incassoRata?: number;
    /**
     * nome del preventivo salvato
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    nomePreventivo?: string;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    numeroPreventivo?: number;
    /**
     * L\'elemento viene restituito solo se le rate sono variabili
     * @type {Array<RataVariabile>}
     * @memberof PreventivoConsumoOutput
     */
    rateVariabili?: Array<RataVariabile>;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    taeg?: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    tan?: number;
    /**
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    tipoProdotto?: PreventivoConsumoOutputTipoProdottoEnum;
    /**
     * codice del tipo di bene
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    tipoBene?: number;
    /**
     * codice della marca del veicolo
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    marca?: string;
    /**
     * codice del modello di veicolo
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    modello?: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    eurotax?: string;
    /**
     * url_secci
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    url_secci?: string;
    /**
     * Valore della maxirata
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    maxirata?: number;
    /**
     * @type {Array<Assicurazione>}
     * @memberof PreventivoConsumoOutput
     */
    assicurazioni?: Array<Assicurazione>;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    bollo?: number;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    interessi?: number;
    /**
     * finanziato lordo istanziato dalla banca
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    lordo?: number;
    /**
     * @type {Array<object>}
     * @memberof PreventivoConsumoOutput
     */
    noteLegali?: Array<object>;
    /**
     * @type {number}
     * @memberof PreventivoConsumoOutput
     */
    spese?: number;
    /**
     * descrizione del errore riscontrato in fase di calcolo
     * @type {string}
     * @memberof PreventivoConsumoOutput
     */
    errore?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PreventivoConsumoOutputTipoProdottoEnum {
    PA = 'PA',
    PP = 'PP',
    PF = 'PF'
}

