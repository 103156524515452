import * as React from "react";
import Container from "../container/container";
import "./hero.scss";

interface IOwnProps {
  description?: string | React.ReactNode;
  icon?: string | React.ReactNode;
  title?: string | React.ReactNode;
}

type Props = IOwnProps;

const Hero: React.FunctionComponent<Props> = ({ description, icon, title }) => {
  return (
    <div className="hero">
      <Container>
        {/* TODO: Improve styles of icon/text of the hero__icon element. */}
        {icon ? <div className="hero__icon">{icon}</div> : null}
        {title ? <div className="hero__title">{title}</div> : null}
        {description ? <div className="hero__description">{description}</div> : null}
      </Container>
    </div>
  );
};

export default Hero;
