// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ErrorOutputKOErroriErroriPianofinanziario,
} from './';

/**
 * @export
 * @interface ErrorOutputKOErroriErrori
 */
export interface ErrorOutputKOErroriErrori {
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    pianofinanziario?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    detrazioni?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    pagamento?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    servizi?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    CL?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    CO?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    G1?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    G2?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    G3?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    E1?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    E2?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    E3?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    TITOLAREEFFETTIVO1?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    TITOLAREEFFETTIVO2?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
    /**
     * @type {Array<ErrorOutputKOErroriErroriPianofinanziario>}
     * @memberof ErrorOutputKOErroriErrori
     */
    TITOLAREEFFETTIVO3?: Array<ErrorOutputKOErroriErroriPianofinanziario>;
}
