// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroTEL035ELETEL
 */
export interface WpNormInputParametroTEL035ELETEL {
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    CDXISO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    TPXTEL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    USOTEL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    PFXOPR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    PFXINT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    PFXNAZ?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroTEL035ELETEL
     */
    NR9TEL?: string;
}
