// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Cassandra,
} from './';

/**
 * @export
 * @interface CassandraResponse
 */
export interface CassandraResponse {
    /**
     * @type {Array<Cassandra>}
     * @memberof CassandraResponse
     */
    listaCassandra?: Array<Cassandra>;
}
