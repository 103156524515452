import produce, { Draft } from "immer";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import merge from "lodash.merge";
import set from "lodash.set";
import { object } from "yup";
import { customFormKeysDistribution } from "../../../custom/libraries/dataHandling/consts";
import { getCustomFormElements, getCustomKeys } from "../../../custom/libraries/dataHandling/dataHandling";
import { TCustomAllFormsKey, TCustomCustomer, TCustomFormName } from "../../../custom/libraries/dataHandling/types";
import {
  DOCUMENTARY_DATA,
  IFormIdleElements,
  IPraticaErrorsMapping,
  OCCUPATIONAL_DATA,
  PERSONAL_DATA,
  TAX_DEDUCTIONS,
  VEHICLE_DATA,
} from "../../../shared/constants/application";
import {
  ErrorOutputKOErroriErrori,
  PraticaConsumo,
  PraticaConsumoOkDataErrori,
  PraticaConsumoOkDataErroriServizi,
} from "../../../shared/generated/whiteLabel";
import { log } from "../../../shared/lib/log";
import { validateIncomingDate } from "../../../shared/lib/utility/date";
import { IFormRenderer } from "../../components/forms/types";
import {
  allKeys,
  documentaryDataKeys,
  formKeysDistribution,
  occupationalDataKeys,
  personalDataKeys,
  taxDeductionsKeys,
  undefinedDatasetElementNotAllowed,
  vehicleDataKeys,
} from "./consts";
import initialCustomer from "./initialCustomer";
import {
  IFormElementsSet,
  IQuery,
  IUnFlattenedCaricamentoValues,
  TAllFOrmsKey,
  TAllValues,
  TCustomer,
  TDataset,
  TDatasetCollection,
  TDocumentaryDataEnablers,
  TFieldAddress,
  TFieldName,
  TFormName,
  TForms,
  TGetFromCustomer,
  TMappingPaths,
  TOccupationalDataEnablers,
  TPersonalDataEnablers,
  TPostPraticaErrors,
  TServerSideErrors,
  TSomeFormComposedSchema,
  TSomeFormDataset,
  TSomeFormEnablers,
  TSomeFormIsInteger,
  TSomeFormIsUpperCase,
  TSomeFormServerSideErrors,
  TSomeFormValidationSchema,
  TSomeFormValues,
} from "./types";

export const getKeys = (
  formName: TFormName | TCustomFormName | "all" | TCustomAllFormsKey,
  notFoundCallback?: () => void
): Array<TFieldName> | undefined => {
  let keys: Array<TFieldName> | undefined;

  switch (formName) {
    case PERSONAL_DATA:
      keys = personalDataKeys;
      break;

    case DOCUMENTARY_DATA:
      keys = documentaryDataKeys;
      break;

    case VEHICLE_DATA:
      keys = vehicleDataKeys;
      break;

    case OCCUPATIONAL_DATA:
      keys = occupationalDataKeys;
      break;

    case TAX_DEDUCTIONS: {
      keys = taxDeductionsKeys;
      break;
    }

    case "all":
      keys = allKeys;
      break;

    default:
      keys = getCustomKeys(formName as TCustomFormName | TCustomAllFormsKey);
      if (!keys) {
        notFoundCallback && notFoundCallback();
      }
      break;
  }
  return keys;
};

/**
 * Get filtered customer data according to the params passed in. If customer is not valid, or
 * formName is not a valid name, or both formName and keys are not provided, it returns
 * the full TDataset pointed by the address.
 *
 * @param {TCustomer} customer Full TCustomer object
 * @param {TFieldAddress | IQuery} query Query value that indicate what should be retrieved
 * from the TCustomer object. It allows conditional queries too (IQuery object)
 * @param {TFormName | "all"} formName Name of specific form or all fields
 * @param {Array<Partial<TFieldName>> | TFieldName} keys  Read only if formName is undefined.
 * It's used for retrieving specific fields from the TCustomer object
 *
 * @return {TDataset | TSomeFormDataset} Customer dataset defined by one of its addresses
 */
export const getFromCustomer: TGetFromCustomer = (customer, query, formName, keys) => {
  if (customer) {
    let actualQuery: IQuery = { analyzedAddress: "", conditionalSubject: "", targetAddress: "" };
    switch (typeof query) {
      case "object":
        actualQuery = query;
        break;
      case "string":
        actualQuery.analyzedAddress = query;
        actualQuery.targetAddress = query;
        break;
      default:
        log("Invalid query input");
        return;
    }
    const undefinedNotAllowed =
      actualQuery.targetAddress && undefinedDatasetElementNotAllowed.includes(actualQuery.targetAddress);
    let actualKeys: Array<Partial<TFieldName>> | undefined;
    if (formName) {
      actualKeys = getKeys(formName);
    } else if (keys) {
      actualKeys = typeof keys === "string" ? [keys] : keys;
    }
    if (!actualKeys) {
      // It will only happen if getKeys returns undefined
      return;
    }
    return actualKeys.reduce((acc: TDataset | TSomeFormDataset, key: TFieldName) => {
      const target = customer[key][actualQuery.targetAddress];
      const analyzed = customer[key][actualQuery.analyzedAddress];
      if (undefinedNotAllowed && target === undefined) {
        return acc;
      }
      if (actualQuery.conditionalSubject && analyzed !== actualQuery.conditionalSubject) {
        return acc;
      }
      acc[key] = customer[key][actualQuery.targetAddress];
      return acc;
    }, {} as TDataset | TSomeFormDataset);
  }
  log('Couldn\'t read from "customer" because of invalid entry.');
  return getFromCustomer(initialCustomer, query, formName);
};

/**
 * Tries to convert a form from the flat version to the nested, to send it back to the server.
 * @return nestedForm {IUnFlattenedCaricamentoValues} the form in the nested version
 * @param values
 */
export const unFlattenFormStructure = (values: TAllValues): IUnFlattenedCaricamentoValues => {
  const outputMappingPaths = getFromCustomer(initialCustomer, "outputPath", "all");
  const nestedForm = {};
  Object.keys(outputMappingPaths).forEach(k => set(nestedForm, outputMappingPaths[k], values[k]));
  return nestedForm as IUnFlattenedCaricamentoValues;
};

/**
 * uses unFlattenFormStructure to convert the form from the flat to the nested version. Adds additional data to the output
 * @param values
 * @param preventivo_id
 */
export const flatToPraticaConsumo = (values: TAllValues, preventivo_id: string): PraticaConsumo => {
  const output: PraticaConsumo = merge(
    {
      cliente: {
        tipoanagrafica: "P",
      },
      pagamento: {
        tipo: "RI",
        intestatario: "CL",
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      preventivo_id,
    },

    unFlattenFormStructure(values)
  );

  /**
   * If the taxDeductions form is enabled, the user will have to check
   * vuoiBeneficiareDellaDetrazioneFiscale;
   */
  if (values.vuoiBeneficiareDellaDetrazioneFiscale) {
    return merge(output, {
      detrazioni: {
        destinatari: [
          {
            codicefiscale: values.codiceFiscale,
            adesione: values.vuoiBeneficiareDellaDetrazioneFiscale,
          },
        ],
      },
    });
  }

  // detrazioni object addresses will be created
  // regardless of the form's enabled state
  delete output.detrazioni;
  return output;
};

/**
 * Parse server payload into customer. The parsing transfers the values from
 * the payload into the customer's "value" field addresses
 *
 * @param payload
 * @param customer
 */
export const parseIntoCustomer = (
  payload: Partial<IUnFlattenedCaricamentoValues>,
  customer: TCustomer | TCustomCustomer | undefined,
  allFormsKey: TAllFOrmsKey
): TCustomer | undefined => {
  if (customer && payload) {
    const inputMappingPaths = getFromCustomer(customer, "inputPath", allFormsKey) as TMappingPaths;

    Object.keys(customer).forEach(k => {
      const currentValue = get(customer, k + ".value");
      let temp = get(payload, inputMappingPaths[k]);
      /**
       * Prefers undefined over empty string
       */
      if (currentValue === undefined && temp === "") {
        temp = undefined;
      } else if (temp !== "" && !temp) {
        temp = currentValue;
      }
      const incomingValue = temp;
      set(customer, k + ".value", incomingValue);
    });
  }
  log('Couldn\'t parse customer data fetching payload. "customer" or "payload" are not valid entries');
  return;
};

/**
 * Reset all `address` keys of `customer` to undefined
 *
 * @param {TCustomer} customer
 * @param {TFieldAddress} address
 * @param initial
 */
export const resetCustomer = (
  customer: TCustomer | TCustomCustomer | undefined,
  address: TFieldAddress,
  initial: TCustomer | TCustomCustomer
): TCustomer | TCustomCustomer | undefined => {
  if (customer) {
    return produce(customer, (draft: Draft<TCustomer | TCustomCustomer>) => {
      Object.keys(customer).forEach(field => {
        draft[field][address] = initial[field][address];
      });
    });
  }
};

/**
 * Update customer data with received values. It directs the updating
 * to the given address. Returns the same entry object if data is not valid
 *
 * @param customer
 * @param values
 * @param address
 */
export const updateCustomer = (
  customer: TCustomer | TCustomCustomer | undefined,
  dataset: Partial<TDataset> | undefined,
  address: TFieldAddress
): TCustomer | TCustomCustomer | undefined => {
  if (customer && dataset) {
    return produce(customer, (draft: Draft<TCustomer | TCustomCustomer>) => {
      Object.keys(dataset).forEach(key => {
        draft[key][address] = dataset[key];
      });
    });
  }
};

/**
 * Compare settled and cached values and return a flat object carrying
 * data that will feed the form. Cached values have the priority.
 *
 * @param customer
 * @param formName
 * @param customInitial
 */
export const composeFormValues = (
  customer: TCustomer | TCustomCustomer,
  formName: TFormName | TCustomFormName,
  customInitial?: TCustomCustomer
): TSomeFormValues | undefined => {
  const initial = customInitial ? customInitial : initialCustomer;
  if (customer) {
    const keys = getKeys(formName, () => console.error("Couldn't compose form values"));
    if (!keys) {
      return;
    }
    return keys.reduce((acc: TSomeFormValues, key: TFieldName) => {
      const isCacheDefault = customer[key].cache === initial[key].cache;
      const isValueDefault = customer[key].value === initial[key].value;
      acc[key] = initial[key].value;
      if (!isValueDefault) {
        acc[key] = customer[key].value;
      }
      if (!isCacheDefault) {
        acc[key] = customer[key].cache;
      }
      return acc;
    }, {} as TSomeFormValues);
  }
  log('Couldn\'t compose form values. "customer" is not a valid entry.');
  return composeFormValues(initial, formName);
};

/**
 * Check if date fields in customer have the required format.
 * If not, it returns an empty string.
 *
 * @param customer
 */
export const validateCustomerDateFields = (customer: TCustomer | TCustomCustomer | undefined): void => {
  if (customer) {
    Object.keys(customer).forEach(key => {
      if (customer[key].type === "date") {
        customer[key].value = validateIncomingDate(customer[key].value, customer[key].format);
      }
    });
  }
  log('Couldn\'t validate customer date fields. "customer" is not a valid entry');
  return;
};

/**
 * Set up the fields of the "text" type and that contain
 * isUpperCase equals true
 *
 * @param customer
 */
export const setUpperCaseFields = (
  customer: TCustomer | TCustomCustomer | undefined
): TCustomer | TCustomCustomer | undefined => {
  if (customer) {
    return produce(customer, (draft: Draft<TCustomer | TCustomCustomer>) => {
      Object.keys(draft).forEach(key => {
        if (draft[key].type === "text" && draft[key].isUpperCase) {
          if (typeof draft[key].value === "string") {
            const value = draft[key].value;
            const cache = draft[key].cache;
            draft[key].value = value.toUpperCase();
            draft[key].cache = cache.toUpperCase();
          } else {
            log("The customer value defined as upperCase is not string type.");
          }
        }
      });
    });
  }
  log("Couldn't set customer's upperCase fields. \"customer\" is not a valid entry");
  return;
};

export const composeIsUpperCaseDataset = (
  customer: TCustomer | TCustomCustomer,
  formName: TFormName | TCustomFormName
): TSomeFormIsUpperCase => {
  return getFromCustomer(
    customer,
    { analyzedAddress: "type", conditionalSubject: "text", targetAddress: "isUpperCase" },
    formName
  ) as TSomeFormIsUpperCase;
};

export const composeIsIntegerDataset = (
  customer: TCustomer | TCustomCustomer,
  formName: TFormName | TCustomFormName
): TSomeFormIsInteger => {
  return getFromCustomer(
    customer,
    { analyzedAddress: "type", conditionalSubject: "numeric", targetAddress: "isInteger" },
    formName
  ) as TSomeFormIsInteger;
};

/**
 * Set initial field enable status.
 *
 * @param customer
 */
export const initEnablers = (customer: TCustomer | undefined, isSpid = false): void => {
  if (customer) {
    customer.nome.isEnabled = !customer.nome.value;
    customer.cognome.isEnabled = !customer.cognome.value;
    customer.dataNascita.isEnabled = !customer.dataNascita.value;
    customer.luogoNascita.isEnabled = !customer.luogoNascita.value;
    customer.provinciaNascita.isEnabled = !customer.provinciaNascita.value;
    customer.sesso.isEnabled = !customer.sesso.value;
    if (customer.iban) {
      customer.iban.isEnabled = !customer.iban.value;
    }
    if (isSpid) {
      customer.nome.isEnabled = false;
      customer.cognome.isEnabled = false;
      customer.cittadinanza.isEnabled = false;
      customer.numCellulare.isEnabled = false;
      customer.email.isEnabled = false;
      customer.codiceFiscale.isEnabled = false;
      customer.tipoDocumento.isEnabled = false;
      customer.numeroDocumento.isEnabled = false;
      customer.dataRilascioDocumento.isEnabled = false;
      customer.dataScadenzaDocumento.isEnabled = false;
      customer.provinciaRilascioDocumento.isEnabled = false;
      customer.localitaRilascioDocumento.isEnabled = false;
    }
  }
  log('Couldn\'t set customer field enablers. "customer" is not a valid entry');
};

/**
 * Process the customer object and return an Yup's ObjectSchema to
 * be applied to Formik. If customer is not valid, return undefined.
 *
 * @param customer
 * @param formName
 */
export const composeValidationSchema = (
  customer: TCustomer | TCustomCustomer,
  formName: TFormName | TCustomFormName
): TSomeFormComposedSchema | Record<string, never> | undefined => {
  if (customer) {
    const rawSchema = getFromCustomer(customer, "validation", formName) as TSomeFormValidationSchema;
    return !isEmpty(rawSchema) ? object<TSomeFormValidationSchema>(rawSchema) : {};
  }
  log('Couldn\'t compose validation schema. "customer" is not a valid entry.');
  return;
};

/**
 * Parse savePratica failing's errors payload into a:
 * - serverSideErrors object
 * - specialErrorCode object
 *
 * @param payload savePratica failing's errors payload
 * @param mappingPaths customer inputPaths
 * @param userTypeKey
 * @param mappingPathPrefix
 */
export const parsePostPraticaErrors = (
  payload: PraticaConsumoOkDataErrori | ErrorOutputKOErroriErrori,
  mappingPaths: TMappingPaths,
  userTypeKey: string,
  mappingPathPrefix: string,
  descriptionsMapping?: IPraticaErrorsMapping
): TPostPraticaErrors => {
  const serverSideErrors: Partial<TServerSideErrors> = {};
  const unrecoverableErrors: PraticaConsumoOkDataErroriServizi[] = [];
  /**
   * Build up an array with the relevant server side errors ("CL" and "pagamento")
   */

  let errors = (payload[userTypeKey] as Array<PraticaConsumoOkDataErroriServizi>) || [];
  if (payload.pagamento) errors = errors?.concat(payload.pagamento);

  /**
   * Populate serverSideErrors
   */
  if (!isEmpty(errors)) {
    errors.forEach(error => {
      /**
       * Normalize inputPath
       */
      const inputPath = error.campo?.replace("anagrafica", mappingPathPrefix);
      /**
       * Assign error description to serverSideErrors
       */
      if (inputPath !== "") {
        const fieldName = Object.keys(mappingPaths).find(key => mappingPaths[key] === inputPath);

        if (fieldName) {
          serverSideErrors[fieldName] =
            descriptionsMapping && error.codice && descriptionsMapping[error.codice]
              ? descriptionsMapping[error.codice]
              : error.descrizione;
        } else {
          unrecoverableErrors.push(error);
        }
      } else {
        unrecoverableErrors.push(error);
      }
    });
  }
  return { serverSideErrors, unrecoverableErrors };
};

/**
 * Check if the passed TFieldName array contains at least one common field with
 * the dataset
 *
 * @param keys Customer field names
 * @param dataset Some customer dataset
 */
export const hasCommonFieldName = (keys: TFieldName[] | undefined, dataset: Partial<TDataset>): boolean => {
  if (!keys) {
    return false;
  }
  return keys.some((key: TFieldName) => Object.keys(dataset).indexOf(key) > -1);
};

/**
 * Valid only for regular forms
 * @param forms
 * @param errors
 */
export const getFormWithErrorsNames = (
  forms: Partial<TForms>,
  errors: TServerSideErrors
): Array<TFormName | TCustomFormName> => {
  const allKeysDistribution = { ...formKeysDistribution, ...customFormKeysDistribution };
  return Object.keys(allKeysDistribution).reduce(
    (acc: Array<TFormName | TCustomFormName>, formName: TFormName | TCustomFormName) => {
      const guard =
        hasCommonFieldName(allKeysDistribution[formName], errors) && forms.some(f => f?.formName === formName);
      if (guard) {
        acc.push(formName);
      }
      return acc;
    },
    [] as Array<TFormName>
  );
};

export const updateCustomerOnReviewErrors = (
  customer: TCustomer | TCustomCustomer | undefined,
  dataset: Partial<Record<TFieldName, string>> | undefined,
  caller: TFormName
): TCustomer | TCustomCustomer | undefined => {
  const keys = getKeys(caller, () =>
    console.error("Couldn't excecute updateCustomerOnReviewErrors because a formName wasn't provided")
  );
  if (!keys) {
    return;
  }
  if (dataset && customer) {
    return produce(customer, (draft: TCustomer | TCustomCustomer) => {
      keys.forEach(key => {
        draft[key]["hasOnReviewError"] = !!dataset[key];
      });
    });
  }
};

export const getOnReviewErrorFieldNames = (
  customer: TCustomer | TCustomCustomer,
  allFormsKey: TAllFOrmsKey
): Array<TFieldName> => {
  const dataset: TSomeFormDataset = getFromCustomer(customer, "hasOnReviewError", allFormsKey);
  return Object.keys(dataset).filter((key: TFieldName) => dataset[key] === true) as TFieldName[];
};

const composePersonalDataValidationContext = (customer: TCustomer): Record<string, unknown> => {
  if (customer) {
    return {
      isEnabled: getFromCustomer(customer, "isEnabled", PERSONAL_DATA) as Partial<TPersonalDataEnablers>,
    };
  } else {
    return {};
  }
};

const composeDocumentaryDataValidationContext = (customer: TCustomer): Record<string, unknown> => {
  if (customer) {
    return {
      value: getFromCustomer(customer, "value", undefined, "dataNascita") as Partial<TAllValues>,
      isEnabled: getFromCustomer(customer, "isEnabled", DOCUMENTARY_DATA) as Partial<TDocumentaryDataEnablers>,
    };
  } else {
    return {};
  }
};

const composeOccupationalDataValidationContext = (customer: TCustomer): Partial<TDatasetCollection> => {
  if (customer) {
    return {
      value: getFromCustomer(customer, "value", undefined, "dataNascita") as Partial<TAllValues>,
      isEnabled: getFromCustomer(customer, "isEnabled", OCCUPATIONAL_DATA) as Partial<TOccupationalDataEnablers>,
    };
  } else {
    return {};
  }
};

const composeTaxDeductionsValidationContext = (customer: TCustomer): Partial<TDatasetCollection> => {
  if (customer) {
    return {
      isEnabled: getFromCustomer(customer, "isEnabled", TAX_DEDUCTIONS) as Partial<TOccupationalDataEnablers>,
    };
  } else {
    return {};
  }
};

export const composeFormElementsSet = (
  customer: TCustomer | TCustomCustomer,
  formIdleElements: IFormIdleElements,
  renderer: IFormRenderer,
  customInitial?: TCustomCustomer
): IFormElementsSet => {
  let validationContext: Record<string, unknown> = {};
  let extra: Record<string, unknown> = {};
  switch (formIdleElements.formName) {
    case PERSONAL_DATA:
      validationContext = composePersonalDataValidationContext(customer as TCustomer);
      extra = {
        codiceFiscaleMappingPaths: getFromCustomer(customer as TCustomer, "inputPath", undefined, [
          "dataNascita",
          "provinciaNascita",
          "luogoNascita",
          "sesso",
        ]),
      };
      break;

    case DOCUMENTARY_DATA:
      validationContext = composeDocumentaryDataValidationContext(customer as TCustomer);
      break;

    case VEHICLE_DATA:
      break;

    case OCCUPATIONAL_DATA:
      validationContext = composeOccupationalDataValidationContext(customer as TCustomer);
      break;

    case TAX_DEDUCTIONS: {
      validationContext = composeTaxDeductionsValidationContext(customer as TCustomer);
      break;
    }

    default: {
      const { customValidationContext, customExtra } = getCustomFormElements(
        customer as TCustomCustomer,
        formIdleElements.formName as TCustomFormName
      );
      validationContext = customValidationContext;
      extra = customExtra;
    }
  }
  return {
    ...formIdleElements,
    initialValues: composeFormValues(customer, formIdleElements.formName, customInitial),
    composedSchema: composeValidationSchema(customer, formIdleElements.formName) || {},
    validationContext,
    isUpperCaseDataset: composeIsUpperCaseDataset(customer, formIdleElements.formName),
    isIntegerDataset: composeIsIntegerDataset(customer, formIdleElements.formName),
    isEnabledDataset: getFromCustomer(customer, "isEnabled", formIdleElements.formName) as TSomeFormEnablers,
    serverSideErrorsDataset: getFromCustomer(
      customer,
      "serverSideError",
      formIdleElements.formName
    ) as TSomeFormServerSideErrors,
    renderer,
    extra,
  };
};

export const getNextForm = (
  forms: Partial<TForms>,
  currentForm: IFormElementsSet,
  isSpid: boolean
): IFormElementsSet | undefined => {
  const currentIndex = forms.indexOf(currentForm);

  if (currentForm.formName === PERSONAL_DATA && isSpid) {
    const nextFormIndex = currentIndex + 2;
    return forms[nextFormIndex];
  }

  const nextFormIndex = currentIndex + 1;
  return forms[nextFormIndex];
};

export const getLastFormByValue = (forms: Partial<TForms>): IFormElementsSet | undefined => {
  const clone = [...forms];
  return clone.pop();
};
