import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { Dispatch } from "redux";
import { AbitazioniApi, AbitazioniOutput } from "../../../shared/generated/loader/abitazioni";
import { AgevolazioniOutput, DefaultApi as AgevolazioniApi } from "../../../shared/generated/loader/agevolazioni";
import { AttivitaApi, AttivitaOutput } from "../../../shared/generated/loader/attivita";
import { BeniApi, BeniOutput } from "../../../shared/generated/loader/beni";
import { CittadinanzaApi, CittadinanzaOutput } from "../../../shared/generated/loader/cittadinanza";
import { DocumentiApi, DocumentiOutput } from "../../../shared/generated/loader/documenti";
import { EntirilascioApi, EntirilascioOutput } from "../../../shared/generated/loader/entirilascio";
import { FormegiuridicheApi, FormegiuridicheOutput } from "../../../shared/generated/loader/formegiuridiche";
import { LoaderLocalitaRequest, LocalitaApi, LocalitaOutput } from "../../../shared/generated/loader/localita";
import { FinalitaPrestitoApi } from "../../../shared/generated/loader/localita-generico";
import { PagamentiOutput } from "../../../shared/generated/loader/pagamenti";
import {
  PermessidisoggiornoApi,
  PermessidisoggiornoOutput,
} from "../../../shared/generated/loader/permessidisoggiorno";
import { ProfessioniApi, ProfessioniOutput } from "../../../shared/generated/loader/professioni";
import { ProvincegenericoApi, ProvinceGenericoOutput } from "../../../shared/generated/loader/provincegenerico";
import { RedditoApi, RedditoOutput } from "../../../shared/generated/loader/reddito";
import { StaticiviliApi, StaticiviliOutput } from "../../../shared/generated/loader/staticivili";
import { TabelleApi } from "../../../shared/generated/loader/tabelle";
import { ListaZipCodeRequest, NormalizzatoreApi } from "../../../shared/generated/normalizzatore";
import { DefaultApi } from "../../../shared/generated/whiteLabel";
import { error } from "../../../shared/lib/log";
import IAction from "../../../shared/types/actions";
import { errorAction } from "../../lib/handlers";
import { loaderAbitazioniApiConfig } from "../../lib/loaderApiConfig/abitazioniApiConfig";
import { loaderAgevolazioniApiConfig } from "../../lib/loaderApiConfig/agevolazioniApiConfig";
import { loaderAttivitaApiConfig } from "../../lib/loaderApiConfig/attivitaApiConfig";
import { loaderBeniApiConfig } from "../../lib/loaderApiConfig/beniApiConfig";
import { loaderCittadinanzaApiConfig } from "../../lib/loaderApiConfig/cittadinanzaApiConfig";
import { loaderDocumentiApiConfig } from "../../lib/loaderApiConfig/documentiApiConfig";
import { loaderEntirilascioApiConfig } from "../../lib/loaderApiConfig/entirilascioApiConfig";
import { loaderFormegiuridicheApiConfig } from "../../lib/loaderApiConfig/formegiuridicheApiConfig";
import { loaderLocalitaApiConfig } from "../../lib/loaderApiConfig/localitaApiConfig";
import { loaderLocalitaGenericoApiConfig } from "../../lib/loaderApiConfig/localitaGenericoApiConfig";
import { loaderPermessidisoggiornoApiConfig } from "../../lib/loaderApiConfig/permessidisoggiornoApiConfig";
import { loaderProfessioniApiConfig } from "../../lib/loaderApiConfig/professioniApiConfig";
import { loaderProvincegenericoApiConfig } from "../../lib/loaderApiConfig/provincegenericoApiConfig";
import { loaderRedditoApiConfig } from "../../lib/loaderApiConfig/redditoApiConfig";
import { loaderStaticiviliApiConfig } from "../../lib/loaderApiConfig/staticiviliApiConfig";
import { loaderTabelleApiConfig } from "../../lib/loaderApiConfig/tabelleApiConfig";
import { normalizzatoreApiConfig } from "../../lib/normalizzatoreApiConfig";
import { whitelabelApiConfig } from "../../lib/whitelabelApiConfig";
import {
  GET_ABITAZIONI,
  GET_ABITAZIONI_FAILED,
  GET_ABITAZIONI_PENDING,
  GET_ATTIVITA,
  GET_ATTIVITA_FAILED,
  GET_ATTIVITA_PENDING,
  GET_BENI,
  GET_BENI_FAILED,
  GET_BENI_PENDING,
  GET_CAP_FAILED,
  GET_CAP_PENDING,
  GET_CITTADINANZA,
  GET_CITTADINANZA_FAILED,
  GET_CITTADINANZA_PENDING,
  GET_COMUNI,
  GET_COMUNI_FAILED,
  GET_COMUNI_PENDING,
  GET_DOCUMENTI,
  GET_DOCUMENTI_FAILED,
  GET_DOCUMENTI_PENDING,
  GET_ENTI_RILASCIO,
  GET_ENTI_RILASCIO_FAILED,
  GET_ENTI_RILASCIO_PENDING,
  GET_FORME_GIURIDICHE,
  GET_FORME_GIURIDICHE_FAILED,
  GET_FORME_GIURIDICHE_PENDING,
  GET_PAGAMENTI_PENDING,
  GET_PERMESSI_DI_SOGGIORNO,
  GET_PERMESSI_DI_SOGGIORNO_FAILED,
  GET_PERMESSI_DI_SOGGIORNO_PENDING,
  GET_PROFESSIONI,
  GET_PROFESSIONI_FAILED,
  GET_PROFESSIONI_PENDING,
  GET_PROVINCE,
  GET_PROVINCE_FAILED,
  GET_PROVINCE_PENDING,
  GET_REDDITO,
  GET_REDDITO_FAILED,
  GET_REDDITO_PENDING,
  GET_STATI_CIVILI,
  GET_STATI_CIVILI_FAILED,
  GET_STATI_CIVILI_PENDING,
  GET_TABELLE,
  GET_TABELLE_FAILED,
  GET_TABELLE_PENDING,
  GET_TAX_DEDUCTIONS,
  GET_TAX_DEDUCTIONS_FAILED,
  GET_TAX_DEDUCTIONS_PENDING,
} from "./consts";
import { IListaZipCode, IListaZipCodeOutputFIXED, IZipCodeOutputFIXED } from "./types";

export const abitazioniApi = new AbitazioniApi(loaderAbitazioniApiConfig());
export const attivitaApi = new AttivitaApi(loaderAttivitaApiConfig());
export const beniApi = new BeniApi(loaderBeniApiConfig());
export const cittadinanzaApi = new CittadinanzaApi(loaderCittadinanzaApiConfig());
export const localitaApi = new LocalitaApi(loaderLocalitaApiConfig());
export const finalitaPrestitoApi = new FinalitaPrestitoApi(loaderLocalitaGenericoApiConfig());
export const documentiApi = new DocumentiApi(loaderDocumentiApiConfig());
export const entiRilascioApi = new EntirilascioApi(loaderEntirilascioApiConfig());
export const formegiuridicheApi = new FormegiuridicheApi(loaderFormegiuridicheApiConfig());
export const pagamentiApi = new DefaultApi(whitelabelApiConfig());
export const permessidisoggiornoApi = new PermessidisoggiornoApi(loaderPermessidisoggiornoApiConfig());
export const professioniApi = new ProfessioniApi(loaderProfessioniApiConfig());
export const provincegenericoApi = new ProvincegenericoApi(loaderProvincegenericoApiConfig());
export const redditoApi = new RedditoApi(loaderRedditoApiConfig());
export const staticiviliApi = new StaticiviliApi(loaderStaticiviliApiConfig());
export const tabelleApi = new TabelleApi(loaderTabelleApiConfig());
export const agevolazioniApi = new AgevolazioniApi(loaderAgevolazioniApiConfig());

export const normalizzatoreApi = new NormalizzatoreApi(normalizzatoreApiConfig());

export const getAbitazioni = (): ((dispatch: Dispatch<IAction<AbitazioniOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_ABITAZIONI_PENDING,
  });
  abitazioniApi.loaderAbitazioni({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_ABITAZIONI,
      });
    },
    errorPayload => dispatch(errorAction(GET_ABITAZIONI_FAILED, errorPayload))
  );
};

export const getAttivita = (): ((dispatch: Dispatch<IAction<AttivitaOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_ATTIVITA_PENDING,
  });

  attivitaApi.loaderAttivita({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_ATTIVITA,
      });
    },
    errorPayload => dispatch(errorAction(GET_ATTIVITA_FAILED, errorPayload))
  );
};

export const getBeni = (): ((dispatch: Dispatch<IAction<BeniOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_BENI_PENDING,
  });

  beniApi.loaderBeni({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_BENI,
      });
    },
    errorPayload => dispatch(errorAction(GET_BENI_FAILED, errorPayload))
  );
};

// temporanea?
export interface ICapRequest {
  cap?: string;
}

export const getCap = (
  requestParameters: ICapRequest,
  residenceType: string
): ((dispatch: Dispatch<IAction<IListaZipCode>>) => void) => async dispatch => {
  if (!requestParameters.cap || requestParameters.cap === "00000") {
    return;
  }

  if (requestParameters.cap.length === 5) {
    dispatch({
      type: GET_CAP_PENDING,
    });

    const capRequest: ListaZipCodeRequest = {
      Input: {
        parametro: {
          LSTPAR: {
            NR9CND: "99", // Number of results returned by the service
            CDXISO: "ITA",
            FLXDSXDPT: "1", // "1" returns the "DSXOBJLPT" response as "VR", "0" as full name "VERONA"
            WPUSER: "wp",
            WPPASW: "wp1299",
          },
          ZIP_AREA_INP: {
            CDXZIP: requestParameters.cap, // zip code (CAP)
            MAXLIVLOC: "5", // escludi le località soppresse
            TPXEXT: "L", // "T" ritorna tutte le località, "L" ritorna un subset (corretto, come su Dealer)
          },
        },
      },
    };

    const getCapResponse = (response: IListaZipCodeOutputFIXED): IListaZipCode => {
      const emptyResults = { data: { cap: [] } };
      const results = get(response, "wpLstZipResult.ZIP_AREA_OUT.ZIP", []);

      if (results.length === 1 && isEmpty(results[0])) {
        return emptyResults;
      }
      return {
        data: {
          cap: results.map((item: IZipCodeOutputFIXED) => ({
            address: item.DSXOBJSTR,
            city: item.DSXOBJCNL,
            province: item.DSXOBJDPT,
            cap: item.CDXZIP,
          })),
        },
      };
    };

    normalizzatoreApi.listaZipCode(capRequest).subscribe(
      value => {
        dispatch({
          payload: getCapResponse(value as IListaZipCodeOutputFIXED),
          type: residenceType,
        });
      },
      errorPayload => dispatch(errorAction(GET_CAP_FAILED, errorPayload))
    );
  }
};

export const getCittadinanza = (): ((dispatch: Dispatch<IAction<CittadinanzaOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_CITTADINANZA_PENDING,
  });

  cittadinanzaApi.loaderCittadinanza({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_CITTADINANZA,
      });
    },
    errorPayload => dispatch(errorAction(GET_CITTADINANZA_FAILED, errorPayload))
  );
};

export const getComuni = (
  provincia: string,
  soppresse?: boolean
): ((dispatch: Dispatch<IAction<LocalitaOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_COMUNI_PENDING,
  });

  const requestParameters: LoaderLocalitaRequest = {
    Input: {
      provincia,
      soppresse: soppresse !== undefined ? soppresse : true,
    },
  };

  localitaApi.loaderLocalita(requestParameters).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_COMUNI,
      });
    },
    errorPayload => dispatch(errorAction(GET_COMUNI_FAILED, errorPayload))
  );
};

// TODO: Fix with RxJs's .toPromise()
// export const getInitialComuni = async (): Promise<LocalitaGenericoOutput> => {
//   return new Promise(resolve => {
//     finalitaPrestitoApi.loaderFinalitaPrestito({ Authorization: "" }).subscribe((data: LocalitaGenericoOutput) => {
//       resolve(data);
//     });
//   });
// };

export const getDocumenti = (): ((dispatch: Dispatch<IAction<DocumentiOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_DOCUMENTI_PENDING,
  });

  documentiApi.loaderDocumenti({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_DOCUMENTI,
      });
    },
    errorPayload => dispatch(errorAction(GET_DOCUMENTI_FAILED, errorPayload))
  );
};

export const getEntiRilascio = (): ((dispatch: Dispatch<IAction<EntirilascioOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_ENTI_RILASCIO_PENDING,
  });

  entiRilascioApi.loaderEntirilascio({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_ENTI_RILASCIO,
      });
    },
    errorPayload => dispatch(errorAction(GET_ENTI_RILASCIO_FAILED, errorPayload))
  );
};

export const getFormeGiuridiche = (): ((
  dispatch: Dispatch<IAction<FormegiuridicheOutput>>
) => void) => async dispatch => {
  dispatch({
    type: GET_FORME_GIURIDICHE_PENDING,
  });

  formegiuridicheApi.loaderFormegiuridiche({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_FORME_GIURIDICHE,
      });
    },
    errorPayload => dispatch(errorAction(GET_FORME_GIURIDICHE_FAILED, errorPayload))
  );
};

// FIXME: This ACTION is not called by anyone in the SPA. The method does **NOT** exist on DefaultApi
export const getPagamenti = (): ((dispatch: Dispatch<IAction<PagamentiOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_PAGAMENTI_PENDING,
  });

  error("This API does NOT exist");
  // pagamentiApi.pagamentiWLPost({}).subscribe(
  //   (value) => {
  //     dispatch({
  //       payload: value,
  //       type: GET_PAGAMENTI,
  //     });
  //   },
  //   errorPayload => dispatch(errorAction(GET_PAGAMENTI_FAILED, errorPayload))
  // );
};

export const getPermessiDiSoggiorno = (): ((
  dispatch: Dispatch<IAction<PermessidisoggiornoOutput>>
) => void) => async dispatch => {
  dispatch({
    type: GET_PERMESSI_DI_SOGGIORNO_PENDING,
  });

  permessidisoggiornoApi.loaderPermessidisoggiorno({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_PERMESSI_DI_SOGGIORNO,
      });
    },
    errorPayload => dispatch(errorAction(GET_PERMESSI_DI_SOGGIORNO_FAILED, errorPayload))
  );
};

export const getProfessioni = (): ((dispatch: Dispatch<IAction<ProfessioniOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_PROFESSIONI_PENDING,
  });

  professioniApi.loaderProfessioni({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_PROFESSIONI,
      });
    },
    errorPayload => dispatch(errorAction(GET_PROFESSIONI_FAILED, errorPayload))
  );
};

export const getProvince = (): ((dispatch: Dispatch<IAction<ProvinceGenericoOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_PROVINCE_PENDING,
  });

  provincegenericoApi.provincegenericoPost({ provinceGenericoInput: { fromcache: false } }).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_PROVINCE,
      });
    },
    errorPayload => dispatch(errorAction(GET_PROVINCE_FAILED, errorPayload))
  );
};

export const getReddito = (): ((dispatch: Dispatch<IAction<RedditoOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_REDDITO_PENDING,
  });

  redditoApi.loaderReddito({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_REDDITO,
      });
    },
    errorPayload => dispatch(errorAction(GET_REDDITO_FAILED, errorPayload))
  );
};

export const getStatiCivili = (): ((dispatch: Dispatch<IAction<StaticiviliOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_STATI_CIVILI_PENDING,
  });

  staticiviliApi.loaderStaticivili({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_STATI_CIVILI,
      });
    },
    errorPayload => dispatch(errorAction(GET_STATI_CIVILI_FAILED, errorPayload))
  );
};

export const getTabelle = (): ((dispatch: Dispatch<IAction<string>>) => void) => async dispatch => {
  dispatch({
    type: GET_TABELLE_PENDING,
  });

  tabelleApi.loaderTabelle({}).subscribe(
    value => {
      dispatch({
        payload: value,
        type: GET_TABELLE,
      });
    },
    errorPayload => dispatch(errorAction(GET_TABELLE_FAILED, errorPayload))
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const setComuniByCap = (values: any, addressType: string) => {
  return { type: addressType, payload: values };
};

export const getTaxDeductions = (): ((dispatch: Dispatch<IAction<AgevolazioniOutput>>) => void) => async dispatch => {
  dispatch({
    type: GET_TAX_DEDUCTIONS_PENDING,
  });

  agevolazioniApi.loaderAgevolazioni().subscribe(
    response => {
      dispatch({
        type: GET_TAX_DEDUCTIONS,
        payload: response,
      });
    },

    error => dispatch(errorAction(GET_TAX_DEDUCTIONS_FAILED, error))
  );
};
