import * as React from "react";

interface IImageProps {
  src?: string;
  title?: string;
}

export const Image: React.FunctionComponent<IImageProps> = ({ src, title }) => {
  return <img alt={title ? title : ""} src={src} />;
};
