import { getHOST } from "../lib/env_utils";

export const ENV_NAME = `${process.env.ENV_NAME}`;

export const HOST = getHOST(
  process.env.API_PROTOCOL,
  process.env.API_HOSTNAME,
  process.env.API_PORT,
  process.env.API_IGNORE_HOST
);

export const UPLOAD_PATH = `${process.env.UPLOAD_PATH}`;
export const PERSONAL_DATA_PATH = `${process.env.PERSONAL_DATA_PATH}`;

// export enum APP_NAME {
//   CARICAMENTO = 'caricamento',
//   UPLOAD = 'upload'
// };

// Google Tag Manager ID
export const GTM_ID = `${process.env.GTM_ID}`;

// ADFORM ID
export const ADFORM_ID = process.env.ADFORM_ID; //adForm account
export const ADFORM_FUNNEL_PREFIX = "Santander|PP_2020|funnel|";

export const API_PREFIX = process.env.API_PREFIX;

export const RECAPTCHA_KEY = "6LftybYUAAAAACw2w0zuE9YJkb23I3TuAv09XM9n";

export const FEATURE_FLAGS_KEY = "feature_flags";

// !!! Warning
// Keep this const in sync with its counterpart
// in src/shared/styles/_variables.scss
export const CSS_ROOT = "scb-whl-embed-root";

// Google Tag Manager ID
export const APP_ORIGIN_KEY = `${process.env.APP_ORIGIN_KEY}`;
