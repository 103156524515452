// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import {
    Eligibilita,
    Informativa,
    PreventivoAgevolazione,
} from '../models';

export interface PraticaInformativeGetRequest {
    intermediario: string;
    pratica: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

export interface PreventivoEligibilitaGetRequest {
    intermediario: string;
    numeroPreventivo: number;
    X_B3_TraceId?: string;
    codiceInternet?: string;
}

/**
 * no description
 */
export class P5Api extends BaseAPI {

    /**
     * Stampa informativa reativa alla pratica
     */
    praticaInformativeGet = (requestParameters: PraticaInformativeGetRequest): Observable<Informativa> => {
        throwIfRequired(requestParameters, 'intermediario', 'praticaInformativeGet');
        throwIfRequired(requestParameters, 'pratica', 'praticaInformativeGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.pratica && { 'pratica': requestParameters.pratica }),
        };

        return this.request<Informativa>({
            path: '/pratica/informative',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    preventivoAgevolazioni = (): Observable<PreventivoAgevolazione> => {
        return this.request<PreventivoAgevolazione>({
            path: '/preventivo/agevolazioni',
            method: 'GET',
        });
    };

    /**
     */
    preventivoEligibilitaGet = (requestParameters: PreventivoEligibilitaGetRequest): Observable<Eligibilita> => {
        throwIfRequired(requestParameters, 'intermediario', 'preventivoEligibilitaGet');
        throwIfRequired(requestParameters, 'numeroPreventivo', 'preventivoEligibilitaGet');

        const headers: HttpHeaders = {
            ...(requestParameters.X_B3_TraceId && { 'X-B3-TraceId': String(requestParameters.X_B3_TraceId) }),
        };

        const query: HttpQuery = {
            ...(requestParameters.codiceInternet && { 'codiceInternet': requestParameters.codiceInternet }),
            ...(requestParameters.intermediario && { 'intermediario': requestParameters.intermediario }),
            ...(requestParameters.numeroPreventivo && { 'numeroPreventivo': requestParameters.numeroPreventivo }),
        };

        return this.request<Eligibilita>({
            path: '/preventivo/eligibilita',
            method: 'GET',
            headers,
            query,
        });
    };

}
