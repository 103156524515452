// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * la risposta deve essere filtrata in base alla risposta fornita alla domanda da cui dipende (codiceRisposta == codiceDipendenza)
 * @export
 * @interface Risposta
 */
export interface Risposta {
    /**
     * @type {string}
     * @memberof Risposta
     */
    codiceRisposta?: string;
    /**
     * @type {string}
     * @memberof Risposta
     */
    descrizione?: string;
    /**
     * @type {boolean}
     * @memberof Risposta
     */
    selected?: boolean;
    /**
     * @type {string}
     * @memberof Risposta
     */
    codiceDipendenza?: string;
}
