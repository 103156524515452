// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * BP = Bollettino postale, RI = Conto Corrente Rid, CR = Carta Credito
 * @export
 * @enum {string}
 */
export enum ModalitaPagamento {
    BP = 'BP',
    RI = 'RI',
    CR = 'CR'
}

