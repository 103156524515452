// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TipoControparteEnum,
    TipoRiconoscimentoEnum,
} from './';

/**
 * 
 * @export
 * @interface SalvaRiconoscimentoInput
 */
export interface SalvaRiconoscimentoInput {
    /**
     * @type {number}
     * @memberof SalvaRiconoscimentoInput
     */
    numeroPreventivo?: number;
    /**
     * @type {TipoControparteEnum}
     * @memberof SalvaRiconoscimentoInput
     */
    tipoControparte?: TipoControparteEnum;
    /**
     * @type {TipoRiconoscimentoEnum}
     * @memberof SalvaRiconoscimentoInput
     */
    tipoRiconoscimento?: TipoRiconoscimentoEnum;
    /**
     * @type {number}
     * @memberof SalvaRiconoscimentoInput
     */
    pratica?: number;
}
