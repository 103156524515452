import classNames from "classnames";
import * as React from "react";
interface IOwnProps {
  className?: string;
  size?: number;
  type: string;
}
type Props = IOwnProps;

const Icon: React.FunctionComponent<Props> = ({ className, size, type }) => {
  const classes = classNames("icon", "material-icons", className ? { [className]: true } : null);
  return (
    <div className={classes} {...(size ? { style: { fontSize: size } } : {})}>
      {type}
    </div>
  );
};

export default Icon;
