// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ListaStradeInputParametroLSTPAR
 */
export interface ListaStradeInputParametroLSTPAR {
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    NR9CND?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXSYN?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    CDXISO?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    CDXISOSPC?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    CDPLNG?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXMIC?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXDSXCNY?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXDSXSTA?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXDSXREG?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXDSXDPT?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXDSXLAT?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    FLXDSXLPT?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    WPUSER?: string;
    /**
     * @type {string}
     * @memberof ListaStradeInputParametroLSTPAR
     */
    WPPASW?: string;
}
