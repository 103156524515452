import { LoaderServiziOutputDataServiziCO } from "../../generated/loaderServizi";
import { PreventivoOutputAssicurazioni } from "../../generated/whiteLabel";

export const getServiceGroup = (
  insuranceCode: string,
  servizi: LoaderServiziOutputDataServiziCO[]
): string | undefined => {
  return servizi && (servizi.find(servizio => servizio.codice === insuranceCode)?.gruppo as string | undefined);
};

/**Funzione che dato
 * @param insurances le assicurazioni in preventivo
 * @param allServices l'elenco di tutti i servizi con codici e gruppi
 * @param queryServiceGroup un gruppo da filtrare
 * mi restituisce le assicurazioni in preventivo facenti parte di quel gruppo
 */
export const getServiceCodes = (
  insurances: PreventivoOutputAssicurazioni[],
  allServices: LoaderServiziOutputDataServiziCO[],
  queryServiceGroup: string
): (string | undefined)[] => {
  const serviceCodesByGroup = allServices
    .filter(service => service.gruppo && service.gruppo.toString() === queryServiceGroup)
    .map(service => service.codice);
  return (
    insurances &&
    insurances.filter(insurance => serviceCodesByGroup?.includes(insurance.codice)).map(insurance => insurance.codice)
  );
};

export const randomSessionId = (): string => {
  const digits = "0123456789abcdef";
  let n = "";
  for (let i = 0; i < 50; i += 1) {
    const rand = Math.floor(Math.random() * 16);
    n += digits[rand];
  }
  return n;
};
