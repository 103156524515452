// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GetFunzionalitaOutputPreventivoAcconto,
    GetFunzionalitaOutputPreventivoAssicurazioni,
    TipoPianoFinanziarioEnum,
} from './';

/**
 * @export
 * @interface GetFunzionalitaOutputPreventivoListaPianiFinanziari
 */
export interface GetFunzionalitaOutputPreventivoListaPianiFinanziari {
    /**
     * @type {TipoPianoFinanziarioEnum}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    codice?: TipoPianoFinanziarioEnum;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    descrizione?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    provenienza?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    tipoFinanziamento?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    tabellaFinanziaria?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    codiceProdotto?: string;
    /**
     * @type {number}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    durata?: number;
    /**
     * @type {GetFunzionalitaOutputPreventivoAcconto}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    acconto?: GetFunzionalitaOutputPreventivoAcconto;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    chilometraggio?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    percentualeMaxirata?: string;
    /**
     * @type {string}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    percentualeCapitaleResiduo?: string;
    /**
     * @type {boolean}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    selected?: boolean;
    /**
     * @type {Array<GetFunzionalitaOutputPreventivoAssicurazioni>}
     * @memberof GetFunzionalitaOutputPreventivoListaPianiFinanziari
     */
    assicurazioni?: Array<GetFunzionalitaOutputPreventivoAssicurazioni>;
}
