/**
 * Export partner logos
 *
 * Exporting logos with a ts index allows us to use .jpg, .png and .svg logos without affecting shared code
 */

import illimityLogo from "./logo.svg";
import hyundaiLogo from "./logo-hyundai.png";
import intesysLogo from "./logo-intesys-BLUE.png";
import lynkcoLogo from "./logo-lynkco_light.png";
import koellikerLogo from "./logo-koelliker.svg";
import aiwaysLogo from "./logo-aiways.png";
import lenziLogo from "./logo-lenzi.png";

export { illimityLogo, hyundaiLogo, intesysLogo, lynkcoLogo, koellikerLogo, aiwaysLogo, lenziLogo };
