import * as React from "react";
import { useContext, useEffect } from "react";
import Container from "../../../shared/components/container/container";
import Hero from "../../../shared/components/hero/hero";
import Intro from "../../../shared/components/intro/intro";
import NestedSection from "../../../shared/components/nested_section/nested_section";
import { CustomizationContext } from "../../lib/customization/CustomizationContext";
import { customizationHookState } from "../../lib/customization/useCustomization";
import "./not_found.scss";

const NotFoundPage: React.FunctionComponent = () => {
  const { setState } = useContext(CustomizationContext);

  useEffect(() => {
    setState(customizationHookState.ERROR);
  }, []);

  return (
    <div className="not-found">
      <NestedSection
        nestAmount={64}
        headerContent={<Hero title="404" description="Pagina non trovata" />}
        bodyContent={
          <Container>
            <div className="not-found__description-wrapper">
              <Intro padded={true}>Non siamo riusciti a trovare la pagina a cui hai provato ad accedere.</Intro>
            </div>
          </Container>
        }
      />
    </div>
  );
};

export default NotFoundPage;
