// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface WpNormInputParametroPAR
 */
export interface WpNormInputParametroPAR {
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    RAGSOC?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    RGSRIC?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PARIVA?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    CODFIS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    LIVFIS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    DATNAS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    LOCNAS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    INDRES?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    LIVENOR?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    ESTLPO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PERSO1?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PERSO2?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PERSO3?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PERSO4?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PERSO5?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    PERSO6?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    FLXZLM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    FLXCOM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    FLXMEM?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    CDXLST?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    TIMSTP?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    INDCOO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COOATL?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COONAV?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COODEF?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COOBES?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COOATT?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COOIDQ?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    COOAPP?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    MCZO91?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    MCZO01?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    MCZAPP?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    MCZPUN?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    BESPRI?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    NUMAPP?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    CDXISO?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    WPUSER?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    WPPASW?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    CDPFAS?: string;
    /**
     * @type {string}
     * @memberof WpNormInputParametroPAR
     */
    FLXABB?: string;
}
