// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    SendMailInputRecipientDestination,
    SendMailInputRecipientFrom,
    SendMailInputRecipientMessage,
} from './';

/**
 * @export
 * @interface SendMailInputRecipient
 */
export interface SendMailInputRecipient {
    /**
     * @type {SendMailInputRecipientFrom}
     * @memberof SendMailInputRecipient
     */
    from?: SendMailInputRecipientFrom;
    /**
     * @type {string}
     * @memberof SendMailInputRecipient
     */
    reply_to?: string;
    /**
     * @type {string}
     * @memberof SendMailInputRecipient
     */
    subject?: string;
    /**
     * @type {SendMailInputRecipientDestination}
     * @memberof SendMailInputRecipient
     */
    destination?: SendMailInputRecipientDestination;
    /**
     * @type {SendMailInputRecipientMessage}
     * @memberof SendMailInputRecipient
     */
    message?: SendMailInputRecipientMessage;
}
