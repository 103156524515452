// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroTEL035ELETEL,
    WpNormInputParametroTEL035TELUNI,
} from './';

/**
 * @export
 * @interface WpNormInputParametroTEL035
 */
export interface WpNormInputParametroTEL035 {
    /**
     * @type {Array<WpNormInputParametroTEL035ELETEL>}
     * @memberof WpNormInputParametroTEL035
     */
    ELETEL?: Array<WpNormInputParametroTEL035ELETEL>;
    /**
     * @type {WpNormInputParametroTEL035TELUNI}
     * @memberof WpNormInputParametroTEL035
     */
    TELUNI?: WpNormInputParametroTEL035TELUNI;
}
