// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ListaStradeInputParametroLSTPAR,
    ListaZipCodeInputParametroZIPAREAINP,
} from './';

/**
 * @export
 * @interface ListaZipCodeInputParametro
 */
export interface ListaZipCodeInputParametro {
    /**
     * @type {ListaStradeInputParametroLSTPAR}
     * @memberof ListaZipCodeInputParametro
     */
    LSTPAR?: ListaStradeInputParametroLSTPAR;
    /**
     * @type {ListaZipCodeInputParametroZIPAREAINP}
     * @memberof ListaZipCodeInputParametro
     */
    ZIP_AREA_INP?: ListaZipCodeInputParametroZIPAREAINP;
}
