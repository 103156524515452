export const HERO_DESCRIPTION = "Riepilogo piano finanziario";
export const ACTIVITY_DESCRIPTION = [
  "Gentile Cliente,",
  "questo il riepilogo del tuo piano finanziario e relative assicurazioni idonee.",
  "Per poter procedere con la tua richiesta di finanziamento, ti chiediamo di rimuovere le assicurazioni non adeguate alla tua richiesta di finanziamento",
];
export const ATTENTION_LABEL = "Attenzione!";
export const INSURANCE_REMOVED_TITLE = "Assicurazioni rimosse";
export const INSURANCE_REMOVED_DESC = "Puoi visionare il piano finanziario aggiornato e proseguire con il processo.";
export const INSURANCE_LABEL = "POLIZZA ASSICURATIVA";
export const REMOVE_BUTTON_LABEL = "RIMUOVI ASSICURAZIONI";
export const GET_SECCI_LABEL = "DOWNLOAD SECCI";
export const SUBMIT_BUTTON_LABEL = "PROSEGUI";
export const REVOKE_BUTTON_LABEL = "RITIRA";
