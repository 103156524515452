import React from "react";
import { IFormRenderer } from "./types";
import FormPersonalData from "./formPersonalData/FormPersonalData";
import FormDocumentaryData from "./formDocumentaryData/FormDocumentaryData";
import FormOccupationalData from "./formOccupationalData/FormOccupationalData";
import {
  TPersonalDataValues,
  TPersonalDataEnablers,
  TPersonalDataComposedSchema,
  TPersonalDataServerSideErrors,
  TDocumentaryDataValues,
  TDocumentaryDataEnablers,
  TDocumentaryDataComposedSchema,
  TDocumentaryDataServerSideErrors,
  TOccupationalDataComposedSchema,
  TOccupationalDataValues,
  TOccupationalDataServerSideErrors,
  TOccupationalDataEnablers,
  TTaxDeductionsEnablers,
  TTaxDeductionsComposedSchema,
  TTaxDeductionsValues,
  TPersonalDataIsUpperCase,
  TPersonalDataIsInteger,
  TDocumentaryDataIsUpperCase,
  TDocumentaryDataIsInteger,
  TOccupationalDataIsUpperCase,
  TOccupationalDataIsInteger,
  TTaxDeductionsIsInteger,
  TTaxDeductionsServerSideErrors,
  TTaxDeductionsIsUpperCase,
} from "../../lib/dataHandling/types";

import FormTaxDeductions from "./formTaxDeductions/FormTaxDeductions";
import FormVehicleData from "./formVehicleData/FormVehicleData";

export const renderFormPersonalData: IFormRenderer = (
  formElementsSet,
  submitFormValues,
  isReviewing,
  isReviewingShowAll
) => {
  return (
    <div className="caricamento-step__form-column">
      <FormPersonalData
        initialValues={formElementsSet.initialValues as TPersonalDataValues}
        isEnabled={formElementsSet.isEnabledDataset as TPersonalDataEnablers}
        validationSchema={formElementsSet.composedSchema as TPersonalDataComposedSchema}
        serverSideErrors={formElementsSet.serverSideErrorsDataset as TPersonalDataServerSideErrors}
        isReviewing={isReviewing}
        isReviewingShowAll={isReviewingShowAll}
        isReviewingShowForm={formElementsSet.isReviewingShowForm}
        onSubmit={submitFormValues(formElementsSet)}
        codiceFiscaleMappingPaths={formElementsSet.extra?.codiceFiscaleMappingPaths}
        validationContext={formElementsSet.validationContext}
        isUpperCase={formElementsSet.isUpperCaseDataset as TPersonalDataIsUpperCase}
        isInteger={formElementsSet.isIntegerDataset as TPersonalDataIsInteger}
      />
    </div>
  );
};

export const renderFormDocumentaryData: IFormRenderer = (
  formElementsSet,
  submitFormValues,
  isReviewing,
  isReviewingShowAll
) => {
  return (
    <div className="caricamento-step__form-column">
      <FormDocumentaryData
        initialValues={formElementsSet.initialValues as TDocumentaryDataValues}
        isEnabled={formElementsSet.isEnabledDataset as TDocumentaryDataEnablers}
        validationSchema={formElementsSet.composedSchema as TDocumentaryDataComposedSchema}
        validationContext={formElementsSet.validationContext}
        serverSideErrors={formElementsSet.serverSideErrorsDataset as TDocumentaryDataServerSideErrors}
        isReviewing={isReviewing}
        isReviewingShowAll={isReviewingShowAll}
        isReviewingShowForm={formElementsSet.isReviewingShowForm}
        onSubmit={submitFormValues(formElementsSet)}
        isUpperCase={formElementsSet.isUpperCaseDataset as TDocumentaryDataIsUpperCase}
        isInteger={formElementsSet.isIntegerDataset as TDocumentaryDataIsInteger}
      />
    </div>
  );
};

export const renderFormOccupationalData: IFormRenderer = (
  formElementsSet,
  submitFormValues,
  isReviewing,
  isReviewingShowAll,
  sendToServer
) => {
  return (
    <div className="caricamento-step__form-column">
      <FormOccupationalData
        initialValues={formElementsSet.initialValues as TOccupationalDataValues}
        isEnabled={formElementsSet.isEnabledDataset as TOccupationalDataEnablers}
        validationSchema={formElementsSet.composedSchema as TOccupationalDataComposedSchema}
        validationContext={formElementsSet.validationContext}
        serverSideErrors={formElementsSet.serverSideErrorsDataset as TOccupationalDataServerSideErrors}
        isReviewing={isReviewing}
        isReviewingShowAll={isReviewingShowAll}
        isReviewingShowForm={formElementsSet.isReviewingShowForm}
        onPartialSubmit={sendToServer && submitFormValues(formElementsSet)} // TODO ?
        onSubmit={sendToServer || submitFormValues(formElementsSet)}
        isUpperCase={formElementsSet.isUpperCaseDataset as TOccupationalDataIsUpperCase}
        isInteger={formElementsSet.isIntegerDataset as TOccupationalDataIsInteger}
      />
    </div>
  );
};

export const renderFormVehicleData: IFormRenderer = (
  formElementsSet,
  submitFormValues,
  isReviewing,
  isReviewingShowAll,
  sendToServer
) => {
  return (
    <div className="caricamento-step__form-column">
      <FormVehicleData
        isReviewing={isReviewing}
        isReviewingShowAll={isReviewingShowAll}
        isReviewingShowForm={formElementsSet.isReviewingShowForm}
        onPartialSubmit={sendToServer && (() => null)}
        onSubmit={sendToServer || submitFormValues(formElementsSet)}
      />
    </div>
  );
};

export const renderFormTaxDeductions: IFormRenderer = (
  formElementsSet,
  submitFormValues,
  isReviewing,
  isReviewingShowAll,
  sendToServer
) => {
  return (
    <div className="caricamento-step__form-column">
      <FormTaxDeductions
        initialValues={formElementsSet.initialValues as TTaxDeductionsValues}
        isEnabled={formElementsSet.isEnabledDataset as TTaxDeductionsEnablers}
        validationSchema={formElementsSet.composedSchema as TTaxDeductionsComposedSchema}
        validationContext={formElementsSet.validationContext}
        serverSideErrors={formElementsSet.serverSideErrorsDataset as TTaxDeductionsServerSideErrors}
        isReviewing={isReviewing}
        isReviewingShowAll={isReviewingShowAll}
        isReviewingShowForm={formElementsSet.isReviewingShowForm}
        onPartialSubmit={sendToServer && submitFormValues(formElementsSet)}
        onSubmit={sendToServer || submitFormValues(formElementsSet)}
        isUpperCase={formElementsSet.isUpperCaseDataset as TTaxDeductionsIsUpperCase}
        isInteger={formElementsSet.isIntegerDataset as TTaxDeductionsIsInteger}
      />
    </div>
  );
};
