export const STATUS_GET_PREVENTIVO = "STATUS_GET_PREVENTIVO";
export const STATUS_GET_SERVIZI = "STATUS_GET_SERVIZI";
export const STATUS_GET_DOWNSELLING = "STATUS_GET_DOWNSELLING";
export const STATUS_POST_PREVENTIVO_RICALCOLO = "STATUS_POST_PREVENTIVO_RICALCOLO";
export const STATUS_GET_SECCI = "STATUS_GET_SECCI";
export const STATUS_POST_PIANO_FINANZIARIO = "STATUS_POST_PIANO_FINANZIARIO";
export const STATUS_TO_SUCCESS = "STATUS_TO_SUCCESS";
export const STATUS_WAITING = "STATUS_WAITING";
export const STATUS_ERROR = "STATUS_ERROR";
export const STATUS_GET_INFORMATIVA = "STATUS_GET_INFORMATIVA";
export const STATUS_GET_ELIGIBILITA_CHECK = "STATUS_GET_ELIGIBILITA_CHECK";
export const STATUS_GET_ELIGIBILITA = "STATUS_GET_ELIGIBILITA";
export const STATUS_GET_INFO_DOCS = "STATUS_GET_INFO_DOCS";
export const STATUS_GET_PREVENTIVO_DATI_CHECK = "STATUS_GET_PREVENTIVO_DATI_CHECK";
export const STATUS_GET_CUSTOMER = "STATUS_GET_CUSTOMER";
export const STATUS_RETURN_ONBOARDING = "STATUS_RETURN_ONBOARDING";

export const MODAL_CL_CHANGE = "modalClChange";
export const MODAL_PROVINCE_VARIATION = "modalProvinceVariation";
export const MODAL_GENERIC_VARIATION = "modalClRemove";

export const SWITCH_CL = "edit/SWITCH_CL";

export const INCOMPATIBILITY_MODAL_PROCEED = "edit/INCOMPATIBILITY_MODAL_PROCEED";
export const INCOMPATIBILITY_MODAL_DISMISS = "edit/INCOMPATIBILITY_MODAL_DISMISS";
export const INCOMPATIBILITY_MODAL_DISMISS_ALL = "edit/INCOMPATIBILITY_MODAL_DISMISS_ALL";

export const GET_PREVENTIVO_PENDING = "edit/GET_PREVENTIVO_PENDING";
export const GET_PREVENTIVO_SUCCEED = "edit/GET_PREVENTIVO_SUCCEED";
export const GET_PREVENTIVO_FAILED = "edit/GET_PREVENTIVO_FAILED";

/**For copy preventivo.assicurazioni on modificaPratica reducer */
export const GET_ACTIVE_INSURANCE = "edit/GET_ACTIVE_INSURANCE";
export const GET_ALL_SERVICES_CODE = "edit/GET_ALL_SERVICES_CODE";

export const GET_SERVIZI_PENDING = "edit/GET_SERVIZI_PENDING";
export const GET_SERVIZI_SUCCEED = "edit/GET_SERVIZI_SUCCEED";
export const GET_SERVIZI_FAILED = "edit/GET_SERVIZI_FAILED";

export const SET_IS_INCOMPATIBLE = "edit/SET_IS_INCOMPATIBLE";
export const SET_NUM_PRATICA = "edit/SET_NUM_PRATICA";
export const SET_CODICE_FISCALE = "edit/SET_CODICE_FISCALE";

export const GET_ELIGIBILITA_CHECK_PENDING = "edit/GET_ELIGIBILITA_CHECK_PENDING";
export const GET_ELIGIBILITA_CHECK_SUCCEED = "edit/GET_ELIGIBILITA_CHECK_SUCCEED";
export const GET_ELIGIBILITA_CHECK_FAILED = "edit/GET_ELIGIBILITA_CHECK_FAILED";

export const GET_DATI_CHECK_PENDING = "edit/GET_DATI_CHECK_PENDING";
export const GET_DATI_CHECK_SUCCEED = "edit/GET_DATI_CHECK_SUCCEED";
export const GET_DATI_CHECK_FAILED = "edit/GET_DATI_CHECK_FAILED";

export const GET_ELIGIBILITA_PENDING = "edit/GET_ELIGIBILITA_PENDING";
export const GET_ELIGIBILITA_SUCCEED = "edit/GET_ELIGIBILITA_SUCCEED";
export const GET_ELIGIBILITA_FAILED = "edit/GET_ELIGIBILITA_FAILED";

export const GET_CUSTOMER_PENDING = "edit/GET_CUSTOMER_PENDING";
export const GET_CUSTOMER_SUCCEED = "edit/GET_CUSTOMER_SUCCEED";
export const GET_CUSTOMER_FAILED = "edit/GET_CUSTOMER_FAILED";

/**getEligibilitaInfoDocs START */
export const GET_ELEGIBILITA_INFO_DOCS_PENDING = "edit/GET_ELEGIBILITA_INFO_DOCS_PENDING";
export const GET_ELEGIBILITA_INFO_DOCS_SUCCEED = "edit/GET_ELEGIBILITA_INFO_DOCS_SUCCEED";
export const GET_ELEGIBILITA_INFO_DOCS_FAILED = "edit/GET_ELEGIBILITA_INFO_DOCS_FAILED";

export const GET_PREVENTIVO_ELIGIBILITA_PENDING = "edit/GET_PREVENTIVO_ELIGIBILITA_PENDING";
export const GET_PREVENTIVO_ELIGIBILITA_SUCCEED = "edit/GET_PREVENTIVO_ELIGIBILITA_SUCCEED";
export const GET_PREVENTIVO_ELIGIBILITA_FAILED = "edit/GET_PREVENTIVO_ELIGIBILITA_FAILED";

export const POST_PREVENTIVO_ELIGIBILITA_PENDING = "edit/POST_PREVENTIVO_ELIGIBILITA_PENDING";
export const POST_PREVENTIVO_ELIGIBILITA_SUCCEED = "edit/POST_PREVENTIVO_ELIGIBILITA_SUCCEED";
export const POST_PREVENTIVO_ELIGIBILITA_FAILED = "edit/POST_PREVENTIVO_ELIGIBILITA_FAILED";

export const GET_PREVENTIVO_SECCI_PENDING = "edit/GET_PREVENTIVO_SECCI_PENDING";
export const GET_PREVENTIVO_SECCI_SUCCEED = "edit/GET_PREVENTIVO_SECCI_SUCCEED";
export const GET_PREVENTIVO_SECCI_FAILED = "edit/GET_PREVENTIVO_SECCI_FAILED";
/**getEligibilitaInfoDocs STOP */

export const GET_QUESTIONNAIRES_DOWNSELLING_PENDING = "edit/GET_QUESTIONNAIRES_DOWNSELLING_PENDING";
export const GET_QUESTIONNAIRES_DOWNSELLING_SUCCEED = "edit/GET_QUESTIONNAIRES_DOWNSELLING_SUCCEED";
export const GET_QUESTIONNAIRES_DOWNSELLING_FAILED = "edit/GET_QUESTIONNAIRES_DOWNSELLING_FAILED";

export const POST_PREVENTIVO_RICALCOLO_PENDING = "edit/POST_PREVENTIVO_RICALCOLO_PENDING";
export const POST_PREVENTIVO_RICALCOLO_SUCCEED = "edit/POST_PREVENTIVO_RICALCOLO_SUCCEED";
export const POST_PREVENTIVO_RICALCOLO_FAILED = "edit/POST_PREVENTIVO_RICALCOLO_FAILED";

export const GET_PRATICA_RITIRO_PENDING = "edit/GET_PRATICA_RITIRO_PENDING";
export const GET_PRATICA_RITIRO_SUCCEED = "edit/GET_PRATICA_RITIRO_SUCCEED";
export const GET_PRATICA_RITIRO_FAILED = "edit/GET_PRATICA_RITIRO_FAILED";

export const POST_PIANO_FINANZIARIO_PENDING = "edit/POST_PIANO_FINANZIARIO_PENDING";
export const POST_PIANO_FINANZIARIO_SUCCEED = "edit/POST_PIANO_FINANZIARIO_SUCCEED";
export const POST_PIANO_FINANZIARIO_FAILED = "edit/POST_PIANO_FINANZIARIO_FAILED";

export const RETURN_TO_PERSONAL_DATA_PENDING = "edit/RETURN_TO_PERSONAL_DATA_PENDING";
export const RETURN_TO_PERSONAL_DATA_SUCCEED = "edit/RETURN_TO_PERSONAL_DATA_SUCCEED";
export const RETURN_TO_PERSONAL_DATA_FAILED = "edit/RETURN_TO_PERSONAL_DATA_FAILED";

export const PRATICA_RICHIESTA_MODIFICA_FAILED = "edit/PRATICA_RICHIESTA_MODIFICA_FAILED";

export const UPLOAD_CONTINUE = "edit/UPLOAD_CONTINUE";
