import get from "lodash.get";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectOptions = Record<string, any>;

export interface ILabelValue {
  label: string | number;
  value: string | number;
}

export const arrayToSelectOptions = (list: Array<SelectOptions>, labelKey: string, valueKey: string): SelectOptions[] =>
  list.map(listItem => {
    const label = get(listItem, labelKey);
    const value = get(listItem, valueKey);

    if (!label || !value) {
      console.error(
        `Value "${valueKey}" or Label "${labelKey}" keys are invalid or not present in the current item ${listItem}`
      );
    }

    return {
      label,
      value,
    };
  });
