// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AbstractPreventivoInput,
    AbstractPreventivoOutput,
    PreventivoAllOf,
    Promozione,
    Provenienza,
    RataVariabile,
} from './';

/**
 * Modello generico per un preventivo sia CO che LE, utile ad esempio per le API di lista.<br/> Nel caso in cui il campo promozione fosse valorizzato ci indica che il preventivo è una promozione
 * @export
 * @interface Preventivo
 */
export interface Preventivo {
    /**
     * codice del bene
     * @type {string}
     * @memberof Preventivo
     */
    codiceBene: string;
    /**
     * codice del prodotto
     * @type {string}
     * @memberof Preventivo
     */
    codiceProdotto: string;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    commissioni: number;
    /**
     * @type {string}
     * @memberof Preventivo
     */
    descrizioneFinanziamento: string;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    durata: number;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    finanziato: number;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    prezzoVendita: number;
    /**
     * @type {string}
     * @memberof Preventivo
     */
    tabellaFinanziaria: string;
    /**
     * codice del tipo di bene
     * @type {number}
     * @memberof Preventivo
     */
    tipoBene?: number;
    /**
     * codice della marca del veicolo
     * @type {string}
     * @memberof Preventivo
     */
    marca?: string;
    /**
     * @type {string}
     * @memberof Preventivo
     */
    modello: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof Preventivo
     */
    eurotax?: string;
    /**
     * codice del capo catena
     * @type {string}
     * @memberof Preventivo
     */
    capoCatena?: string;
    /**
     * @type {Date}
     * @memberof Preventivo
     */
    dataCreazione?: Date;
    /**
     * importo totale dovuto dal cliente (finanziato + spese + assicurazioni)
     * @type {number}
     * @memberof Preventivo
     */
    importoDovuto?: number;
    /**
     * importo della rata
     * @type {number}
     * @memberof Preventivo
     */
    importoRata?: number;
    /**
     * costi di gestione della rata
     * @type {number}
     * @memberof Preventivo
     */
    incassoRata?: number;
    /**
     * nome del preventivo salvato
     * @type {string}
     * @memberof Preventivo
     */
    nomePreventivo?: string;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    numeroPreventivo?: number;
    /**
     * L\'elemento viene restituito solo se le rate sono variabili
     * @type {Array<RataVariabile>}
     * @memberof Preventivo
     */
    rateVariabili?: Array<RataVariabile>;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    taeg?: number;
    /**
     * @type {number}
     * @memberof Preventivo
     */
    tan?: number;
    /**
     * @type {string}
     * @memberof Preventivo
     */
    tipoProdotto?: PreventivoTipoProdottoEnum;
    /**
     * url_secci
     * @type {string}
     * @memberof Preventivo
     */
    url_secci?: string;
    /**
     * Valore della maxirata per le casistiche CO, valore dell\'importo di riscatto per le casistiche LE
     * @type {number}
     * @memberof Preventivo
     */
    importoRataFinale?: number;
    /**
     * importo che può o deve pagare il cliente per poter tenere il bene a fine finanziamento
     * @type {number}
     * @memberof Preventivo
     */
    importoRiscatto?: number;
    /**
     * Preventivo: un preventivo è scaduto se sono passati più di tre giorni dalla data di creazione;<br/> Promozione: una promozione è scaduta se la data corrente è maggiore alla data di fine validità della stessa
     * @type {boolean}
     * @memberof Preventivo
     */
    scaduto?: boolean;
    /**
     * concatenzione nomi assicurazioni presenti nel preventivo
     * @type {string}
     * @memberof Preventivo
     */
    nomiServizi: string;
    /**
     * @type {Promozione}
     * @memberof Preventivo
     */
    promozione?: Promozione;
    /**
     * @type {Provenienza}
     * @memberof Preventivo
     */
    provenienza: Provenienza;
}

/**
 * @export
 * @enum {string}
 */
export enum PreventivoTipoProdottoEnum {
    PA = 'PA',
    PP = 'PP',
    PF = 'PF'
}

