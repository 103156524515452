/* ---------------------------------
.infoModal
--------------------------------- */

import Dialog, { DialogButton, DialogContent, DialogFooter, DialogProps, DialogTitle } from "@material/react-dialog";
import classNames from "classnames";
import React from "react";
import "./info_modal.scss";

interface IOwnProps extends Partial<DialogProps<HTMLElement>> {
  dismissLabel?: string;
  titleLabel?: string;
  textLabel?: string;
  isModalOpen?: boolean;
  renderContent?: React.ReactNode;
  onDismiss?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

type Props = IOwnProps;

const InfoModal: React.FunctionComponent<Props> = ({
  dismissLabel,
  titleLabel,
  textLabel,
  isModalOpen,
  className,
  renderContent,
  onDismiss,
}) => {
  const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onDismiss) {
      onDismiss(e);
    }
  };

  const classes = classNames("suggestions-modal", "info-modal", {
    ...(className ? { [className]: true } : {}),
  });

  return (
    <Dialog open={isModalOpen} escapeKeyAction={""} scrimClickAction={""} className={classes}>
      <DialogTitle className="suggestions-modal__dialog-title">
        <div className="suggestions-modal__title-text">{titleLabel}</div>
      </DialogTitle>
      <DialogContent>
        {renderContent ? (
          renderContent
        ) : (
          <div className="suggestions-modal__dialog-content">
            <a href="#"></a> {/* added to prevent autofocus */}
            <div className="suggestions-modal__dialog-text">
              <p dangerouslySetInnerHTML={{ __html: textLabel?.toString() || "" }}></p>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogFooter>
        <DialogButton
          className="dialog__action-close button button--secondary"
          action="dismiss"
          onClick={handleDismiss}
        >
          {dismissLabel}
        </DialogButton>
      </DialogFooter>
    </Dialog>
  );
};

export default InfoModal;
