import * as React from "react";
import "./spinner.scss";

interface IOwnProps {
  color?: string;
  centered?: boolean;
}

type Props = IOwnProps;

const Spinner: React.FunctionComponent<Props> = props => {
  const { color, centered } = props;

  return (
    <svg
      className="spinner"
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      style={
        centered
          ? {
              position: "fixed",
              left: "calc(50% - 30px)",
              top: "calc(50% - 30px)",
              width: 60,
              height: 60,
            }
          : {}
      }
    >
      <circle
        className="path"
        style={color ? { stroke: color } : undefined}
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        cx="16"
        cy="16"
        r="10"
      />
    </svg>
  );
};

export default Spinner;
