// tslint:disable
/**
 * tabelle
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TabelleOutputData,
} from './';

/**
 * 
 * @export
 * @interface TabelleOutput
 */
export interface TabelleOutput {
    /**
     * @type {boolean}
     * @memberof TabelleOutput
     */
    success?: boolean;
    /**
     * @type {TabelleOutputData}
     * @memberof TabelleOutput
     */
    data?: TabelleOutputData;
}
