// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * identifica la tipologia di importo alla firma <br/> ACCOUNTO: importo alla firma con solo acconto <br/> ACCONTO_COMMISSIONI_BOLLO_SERVIZI: importo alla firma che comprende: acconto + commissioni + bollo + importi servizi
 * @export
 * @enum {string}
 */
export enum TipologiaImportoFirma {
    ACCONTO = 'ACCONTO',
    ACCONTOCOMMISSIONIBOLLOSERVIZI = 'ACCONTO_COMMISSIONI_BOLLO_SERVIZI'
}

