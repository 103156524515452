// tslint:disable
/**
 * beni
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders } from '../runtime';
import {
    BeniOutput,
} from '../models';

export interface LoaderBeniRequest {
    fromcache?: boolean;
}

/**
 * no description
 */
export class BeniApi extends BaseAPI {

    /**
     */
    loaderBeni = (requestParameters: LoaderBeniRequest): Observable<BeniOutput> => {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'X-IBM-Client-Id': this.configuration.apiKey('X-IBM-Client-Id') }), // clientIdHeader authentication
            ...(this.configuration.apiKey && { 'X-IBM-Client-Secret': this.configuration.apiKey('X-IBM-Client-Secret') }), // clientSecretHeader authentication
        };

        return this.request<BeniOutput>({
            path: '/beni',
            method: 'POST',
            headers,
            body: requestParameters.fromcache as any,
        });
    };

}
