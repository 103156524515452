import get from "lodash.get";
import { AjaxError } from "rxjs/ajax";
import IAction, { IError } from "../../shared/types/actions";

export const errorAction = (type: string, errorData: AjaxError): IAction<IError> => {
  const errorPayload = {
    error: true,
    payload: {
      message: "Response null",
      status: 500,
    },
    type,
  };

  if (errorData.response === null) {
    return errorPayload;
  }

  // handle multiple values for description/code
  const description = get(errorData, "response.description") || get(errorData, "response.message");
  const code = get(errorData, "response.code") || get(errorData, "response.status");

  // This could do something with errorData before dispatching it to Redux.
  // E.g. Call to Analytics (?)

  return {
    error: true,
    payload: {
      message: description,
      status: code,
    },
    type,
  };
};
