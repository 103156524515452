// tslint:disable
/**
 * E2E
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * definizione di un allestimento
 * @export
 * @interface Eurotax
 */
export interface Eurotax {
    /**
     * Tipologia di alimentazione del veicolo
     * @type {string}
     * @memberof Eurotax
     */
    alimentazione?: string;
    /**
     * @type {number}
     * @memberof Eurotax
     */
    cilindrata?: number;
    /**
     * descrizione specifica dell\'allestimento, ad esempio da usare nella lista
     * @type {string}
     * @memberof Eurotax
     */
    descrizione?: string;
    /**
     * Codice Eurotax
     * @type {string}
     * @memberof Eurotax
     */
    eurotax?: string;
    /**
     * Descrizione generica dell\'allestimento
     * @type {string}
     * @memberof Eurotax
     */
    gamma?: string;
    /**
     * Prezzo di listino
     * @type {number}
     * @memberof Eurotax
     */
    listino?: number;
    /**
     * A,C,I,N,O,R,V,F,M,T
     * @type {string}
     * @memberof Eurotax
     */
    tipologia?: string;
    /**
     * Modello
     * @type {string}
     * @memberof Eurotax
     */
    modello?: string;
}
