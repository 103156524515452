import { useEffect } from "react";
import { resetField } from "../../../../shared/lib/form/form";
import { TFieldValue } from "../../../lib/dataHandling/types";
import { IFormikSetters } from "../types";

export const useCapDatoreLavoro = (
  isEnabled: boolean | undefined,
  capDatoreLavoro: TFieldValue,
  provinciaDatoreLavoro: TFieldValue,
  formikSetters: IFormikSetters
): void => {
  const { setFieldValue, setFieldTouched } = formikSetters;
  useEffect(() => {
    if (isEnabled && capDatoreLavoro === "00000" && provinciaDatoreLavoro !== "*") {
      resetField({ setFieldValue, setFieldTouched }, "capDatoreLavoro");
    }
  }, [isEnabled, capDatoreLavoro, provinciaDatoreLavoro, setFieldValue, setFieldTouched]);
};
