// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    NuovaControparteControparti,
    NuovaControparteData,
    NuovaControparteDetrazioni,
    NuovaContropartePagamento,
} from './';

/**
 * 
 * @export
 * @interface NuovaControparte
 */
export interface NuovaControparte {
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    codicefinco?: string;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    provenienza?: string;
    /**
     * @type {number}
     * @memberof NuovaControparte
     */
    pratica?: number;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    tipoconvenzionato?: string;
    /**
     * @type {number}
     * @memberof NuovaControparte
     */
    numeropreventivo?: number;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    datapreventivo?: string;
    /**
     * @type {NuovaControparteData}
     * @memberof NuovaControparte
     */
    data?: NuovaControparteData;
    /**
     * @type {NuovaControparteControparti}
     * @memberof NuovaControparte
     */
    controparti?: NuovaControparteControparti;
    /**
     * @type {NuovaContropartePagamento}
     * @memberof NuovaControparte
     */
    pagamento?: NuovaContropartePagamento;
    /**
     * @type {boolean}
     * @memberof NuovaControparte
     */
    invioscb?: boolean;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    capitale_residuo?: string;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    scadenza_rata?: string;
    /**
     * @type {NuovaControparteDetrazioni}
     * @memberof NuovaControparte
     */
    detrazioni?: NuovaControparteDetrazioni;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    preventivo_id?: string;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    salvataggiospot?: string;
    /**
     * @type {string}
     * @memberof NuovaControparte
     */
    scadenzarata?: string;
}
