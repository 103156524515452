import classNames from "classnames";
import get from "lodash.get";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { IAddress, INormalizzatoreResult } from "../normalizzatore.types";
import "./normalizzatoreHelperText.scss";
import NormalizzatoreModal from "./normalizzatoreModal";
import { CSS_ROOT } from "../../../../shared/config/constants";

interface IOwnProps {
  className?: string;
  helpMeIcon?: string | React.ReactNode;
  helpMeLabel?: string;
  ignoraLabel?: string;
  modalTitleLabel?: string;
  normalizzatoreOutput: INormalizzatoreResult | null;
  onSuggestionSelect: (address: IAddress) => void;
}

type Props = IOwnProps;

const NormalizzatoreHelperText: React.FunctionComponent<Props> = ({
  className,
  helpMeIcon,
  helpMeLabel,
  ignoraLabel,
  modalTitleLabel,
  normalizzatoreOutput,
  onSuggestionSelect,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const helperTextClickHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    // if modal is closed by clicking on "Ignore", also remove helper text
    setShowModal(false);
  };

  const suggestionSelectHandler = (address: IAddress) => {
    // if modal is closed by selecting a value, also remove helper text
    setShowModal(false);

    if (onSuggestionSelect) {
      onSuggestionSelect(address);
    }
  };

  const suggestions = get(normalizzatoreOutput, "output.suggestions", []);
  const showSuggestionsLabel = suggestions.length > 0;

  const classes = classNames("normalizzatore-helper-text", {
    "normalizzatore-helper-text__has-suggestions": suggestions.length,
    ...(className ? { [className]: true } : {}),
  });

  let error;
  if (normalizzatoreOutput) {
    error = suggestions.length <= 0 ? normalizzatoreOutput.errorToShow : undefined;
  }
  // const error = suggestions.length <= 0 ? normalizzatoreOutput?.errorToShow : undefined;

  return (
    <React.Fragment>
      {error && <span className="normalizzatore-helper-text__error">{error}</span>}

      {showSuggestionsLabel && (
        <span className={classes} onClick={helperTextClickHandler}>
          {helpMeIcon ? (
            typeof helpMeIcon === "string" ? (
              <span className="material-icons icon--warning">{helpMeIcon}</span>
            ) : (
              helpMeIcon
            )
          ) : (
            <span className="material-icons icon--warning">warning</span>
          )}
          <span className="">{helpMeLabel ? helpMeLabel : "Aiutami"}</span>
        </span>
      )}

      {/* Append the modal to the <body> element */}
      {showModal && normalizzatoreOutput && suggestions
        ? createPortal(
            <NormalizzatoreModal
              suggestions={suggestions}
              ignoraLabel={ignoraLabel}
              titleLabel={modalTitleLabel}
              onCloseModal={closeModalHandler}
              onSuggestionSelect={suggestionSelectHandler}
            />,
            // we need to render inside the CSS_ROOT,
            // otherwise the scoped styles will break
            document.getElementById(CSS_ROOT) as HTMLDivElement
          )
        : null}
    </React.Fragment>
  );
};

export default React.memo(NormalizzatoreHelperText);
