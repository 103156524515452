// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroKIIELECLI,
    WpNormInputParametroKIOCLIDOP,
} from './';

/**
 * @export
 * @interface WpNormInputParametroKIO
 */
export interface WpNormInputParametroKIO {
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIO
     */
    FLXDOP?: string;
    /**
     * @type {WpNormInputParametroKIIELECLI}
     * @memberof WpNormInputParametroKIO
     */
    ELECLI?: WpNormInputParametroKIIELECLI;
    /**
     * @type {string}
     * @memberof WpNormInputParametroKIO
     */
    NUMDOP?: string;
    /**
     * @type {Array<WpNormInputParametroKIOCLIDOP>}
     * @memberof WpNormInputParametroKIO
     */
    CLIDOP?: Array<WpNormInputParametroKIOCLIDOP>;
}
