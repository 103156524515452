import produce from "immer";
import get from "lodash.get";
import { PP_ACTIVITY_FILTER } from "../../../shared/constants/application";
import { getFeatureFlag } from "../../../shared/lib/featureFlagsFunctions";
import { hideOptions } from "../../components/forms/form.utilities";
import {
  GET_ABITAZIONI,
  GET_ABITAZIONI_FAILED,
  GET_ABITAZIONI_PENDING,
  GET_ATTIVITA,
  GET_ATTIVITA_FAILED,
  GET_ATTIVITA_PENDING,
  GET_CAP,
  GET_CAP_FAILED,
  GET_CAP_PENDING,
  GET_CAP_PREVIOUS_ADDRESS,
  GET_CITTADINANZA,
  GET_CITTADINANZA_FAILED,
  GET_CITTADINANZA_PENDING,
  GET_COMUNI,
  GET_COMUNI_FAILED,
  GET_COMUNI_PENDING,
  GET_DOCUMENTI,
  GET_DOCUMENTI_FAILED,
  GET_DOCUMENTI_PENDING,
  GET_ENTI_RILASCIO,
  GET_ENTI_RILASCIO_FAILED,
  GET_ENTI_RILASCIO_PENDING,
  GET_PAGAMENTI,
  GET_PAGAMENTI_FAILED,
  GET_PAGAMENTI_PENDING,
  GET_PERMESSI_DI_SOGGIORNO,
  GET_PERMESSI_DI_SOGGIORNO_FAILED,
  GET_PERMESSI_DI_SOGGIORNO_PENDING,
  GET_PROFESSIONI,
  GET_PROFESSIONI_FAILED,
  GET_PROFESSIONI_PENDING,
  GET_PROVINCE,
  GET_PROVINCE_FAILED,
  GET_PROVINCE_PENDING,
  GET_REDDITO,
  GET_REDDITO_FAILED,
  GET_REDDITO_PENDING,
  GET_STATI_CIVILI,
  GET_STATI_CIVILI_FAILED,
  GET_STATI_CIVILI_PENDING,
  GET_TAX_DEDUCTIONS,
  GET_TAX_DEDUCTIONS_FAILED,
  GET_TAX_DEDUCTIONS_PENDING,
  RESET_ABITAZIONI,
  RESET_ATTIVITA,
  RESET_CAP,
  RESET_CITTADINANZA,
  RESET_COMUNI,
  RESET_COMUNI_RESIDENZA_BY_CAP,
  RESET_DOCUMENTI,
  RESET_ENTI_RILASCIO,
  RESET_PAGAMENTI,
  RESET_PERMESSI_DI_SOGGIORNO,
  RESET_PROFESSIONI,
  RESET_PROVINCE,
  RESET_REDDITO,
  RESET_STATI_CIVILI,
  SET_COMUNI_PREVIOUS_ADDRESS_BY_CAP,
  SET_COMUNI_RESIDENZA_BY_CAP,
} from "./consts";
import { IState } from "./types";

const initialState: IState = {
  abitazioni: { loading: false },
  attivita: { loading: false },
  beni: { loading: false },
  cap: { loading: false },
  capPreviousAddress: { loading: false },
  cittadinanza: { loading: false },
  comuni: { loading: false },
  comuniByCap: {
    residenza: { loading: false },
  },
  comuniPreviousAddressByCap: {
    residenza: { loading: false },
  },
  documenti: { loading: false },
  entiRilascio: { loading: false },
  formeGiuridiche: { loading: false },
  pagamenti: { loading: false },
  permessiDiSoggiorno: { loading: false },
  professioni: { loading: false },
  province: { loading: false },
  reddito: { loading: false },
  statiCivili: { loading: false },
  tabelle: { loading: false },
  taxDeductions: { loading: false },
};

export default produce((draft: IState, action) => {
  switch (action.type) {
    case GET_ABITAZIONI: {
      draft.abitazioni.data = get(action, "payload.data");
      draft.abitazioni.loading = false;
      return;
    }
    case GET_ABITAZIONI_PENDING: {
      draft.abitazioni.loading = true;
      return;
    }
    case GET_ABITAZIONI_FAILED: {
      draft.abitazioni.loading = false;
      return;
    }
    case RESET_ABITAZIONI: {
      draft.abitazioni.data = undefined;
      draft.abitazioni.loading = false;
      return;
    }

    case GET_ATTIVITA: {
      /**
       * DIG-418 - hide options ["senza reddito","altro"] from picklist
       **/
      const options = get(action, "payload.data");

      const filteredActivity = {
        attivita: hideOptions(options.attivita, PP_ACTIVITY_FILTER, getFeatureFlag("hideSomeActivityOptions")),
      };

      draft.attivita.data = filteredActivity;
      draft.attivita.loading = false;
      return;
    }
    case GET_ATTIVITA_PENDING: {
      draft.attivita.loading = true;
      return;
    }
    case GET_ATTIVITA_FAILED: {
      draft.attivita.loading = false;
      return;
    }
    case RESET_ATTIVITA: {
      draft.attivita.data = undefined;
      draft.attivita.loading = false;
      return;
    }

    // case GET_BENI: {
    //   draft.beni.data = action.payload.data;
    //   draft.beni.loading = false;
    //   return;
    // }
    // case GET_BENI_PENDING: {
    //   draft.beni.loading = true;
    //   return;
    // }
    // case GET_BENI_FAILED: {
    //   draft.beni.loading = false;
    //   return;
    // }
    // case RESET_BENI: {
    //   draft.beni.data = undefined;
    //   draft.beni.loading = false;
    //   return;
    // }

    case GET_CITTADINANZA: {
      draft.cittadinanza.data = get(action, "payload.data");
      draft.cittadinanza.loading = false;
      return;
    }
    case GET_CITTADINANZA_PENDING: {
      draft.cittadinanza.loading = true;
      return;
    }
    case GET_CITTADINANZA_FAILED: {
      draft.cittadinanza.loading = false;
      return;
    }
    case RESET_CITTADINANZA: {
      draft.cittadinanza.data = undefined;
      draft.cittadinanza.loading = false;
      return;
    }

    case GET_CAP: {
      draft.cap.data = get(action, "payload.data");
      draft.cap.loading = false;
      return;
    }
    case GET_CAP_PREVIOUS_ADDRESS: {
      draft.capPreviousAddress.data = get(action, "payload.data");
      draft.cap.loading = false;
      return;
    }
    case GET_CAP_PENDING: {
      draft.cap.loading = true;
      return;
    }
    case GET_CAP_FAILED: {
      draft.cap.loading = false;
      return;
    }
    case RESET_CAP: {
      draft.cap.data = undefined;
      draft.cap.loading = false;
      return;
    }

    case GET_COMUNI: {
      draft.comuni.data = get(action, "payload.data");
      draft.comuni.loading = false;
      return;
    }
    case GET_COMUNI_PENDING: {
      draft.comuni.loading = true;
      return;
    }
    case GET_COMUNI_FAILED: {
      draft.comuni.loading = false;
      return;
    }
    case RESET_COMUNI: {
      draft.comuni.data = undefined;
      draft.comuni.loading = false;
      return;
    }

    case SET_COMUNI_RESIDENZA_BY_CAP: {
      draft.comuniByCap.residenza.data = get(action, "payload");
      draft.comuniByCap.residenza.loading = false;
      return;
    }
    case RESET_COMUNI_RESIDENZA_BY_CAP: {
      draft.comuniByCap.residenza.data = undefined;
      draft.comuniByCap.residenza.loading = false;
      return;
    }

    case SET_COMUNI_PREVIOUS_ADDRESS_BY_CAP: {
      draft.comuniPreviousAddressByCap.residenza.data = get(action, "payload");
      draft.comuniPreviousAddressByCap.residenza.loading = false;
      return;
    }

    case GET_DOCUMENTI: {
      draft.documenti.data = get(action, "payload.data");
      draft.documenti.loading = false;
      return;
    }
    case GET_DOCUMENTI_PENDING: {
      draft.documenti.loading = true;
      return;
    }
    case GET_DOCUMENTI_FAILED: {
      draft.documenti.loading = false;
      return;
    }
    case RESET_DOCUMENTI: {
      draft.documenti.data = undefined;
      draft.documenti.loading = false;
      return;
    }

    case GET_ENTI_RILASCIO: {
      draft.entiRilascio.data = get(action, "payload.data");
      draft.entiRilascio.loading = false;
      return;
    }
    case GET_ENTI_RILASCIO_PENDING: {
      draft.entiRilascio.loading = true;
      return;
    }
    case GET_ENTI_RILASCIO_FAILED: {
      draft.entiRilascio.loading = false;
      return;
    }
    case RESET_ENTI_RILASCIO: {
      draft.entiRilascio.data = undefined;
      draft.entiRilascio.loading = false;
      return;
    }

    // case GET_FORME_GIURIDICHE: {
    //   draft.formeGiuridiche.data = action.payload.data;
    //   draft.formeGiuridiche.loading = false;
    //   return;
    // }
    // case GET_FORME_GIURIDICHE_PENDING: {
    //   draft.formeGiuridiche.loading = true;
    //   return;
    // }
    // case GET_FORME_GIURIDICHE_FAILED: {
    //   draft.formeGiuridiche.loading = false;
    //   return;
    // }
    // case RESET_FORME_GIURIDICHE: {
    //   draft.formeGiuridiche.data = undefined;
    //   draft.formeGiuridiche.loading = false;
    //   return;
    // }

    case GET_PAGAMENTI: {
      draft.pagamenti.data = get(action, "payload.data");
      draft.pagamenti.loading = false;
      return;
    }
    case GET_PAGAMENTI_PENDING: {
      draft.pagamenti.loading = true;
      return;
    }
    case GET_PAGAMENTI_FAILED: {
      draft.pagamenti.loading = false;
      return;
    }
    case RESET_PAGAMENTI: {
      draft.pagamenti.data = undefined;
      draft.pagamenti.loading = false;
      return;
    }

    case GET_PERMESSI_DI_SOGGIORNO: {
      draft.permessiDiSoggiorno.data = get(action, "payload.data");
      draft.permessiDiSoggiorno.loading = false;
      return;
    }

    case GET_PERMESSI_DI_SOGGIORNO_PENDING: {
      draft.permessiDiSoggiorno.loading = true;
      return;
    }

    case GET_PERMESSI_DI_SOGGIORNO_FAILED: {
      draft.permessiDiSoggiorno.loading = false;
      return;
    }

    case RESET_PERMESSI_DI_SOGGIORNO: {
      draft.permessiDiSoggiorno.data = undefined;
      draft.permessiDiSoggiorno.loading = false;
      return;
    }

    case GET_PROFESSIONI: {
      draft.professioni.data = get(action, "payload.data");
      draft.professioni.loading = false;
      return;
    }

    case GET_PROFESSIONI_PENDING: {
      draft.professioni.loading = true;
      return;
    }

    case GET_PROFESSIONI_FAILED: {
      draft.professioni.loading = false;
      return;
    }

    case RESET_PROFESSIONI: {
      draft.professioni.data = undefined;
      draft.professioni.loading = false;
      return;
    }

    case GET_PROVINCE: {
      draft.province.data = get(action, "payload.data");
      draft.province.loading = false;
      return;
    }

    case GET_PROVINCE_PENDING: {
      draft.province.loading = true;
      return;
    }

    case GET_PROVINCE_FAILED: {
      draft.province.loading = false;
      return;
    }

    case RESET_PROVINCE: {
      draft.province.data = undefined;
      draft.province.loading = false;
      return;
    }

    case GET_REDDITO: {
      draft.reddito.data = get(action, "payload.data");
      draft.reddito.loading = false;
      return;
    }

    case GET_REDDITO_PENDING: {
      draft.reddito.loading = true;
      return;
    }

    case GET_REDDITO_FAILED: {
      draft.reddito.loading = false;
      return;
    }

    case RESET_REDDITO: {
      draft.reddito.data = undefined;
      draft.reddito.loading = false;
      return;
    }

    case GET_STATI_CIVILI: {
      draft.statiCivili.data = get(action, "payload.data");
      draft.statiCivili.loading = false;
      return;
    }

    case GET_STATI_CIVILI_PENDING: {
      draft.statiCivili.loading = true;
      return;
    }

    case GET_STATI_CIVILI_FAILED: {
      draft.statiCivili.loading = false;
      return;
    }

    case RESET_STATI_CIVILI: {
      draft.statiCivili.data = undefined;
      draft.statiCivili.loading = false;
      return;
    }

    // case GET_TABELLE: {
    //   draft.tabelle.data = action.payload.data;
    //   draft.tabelle.loading = false;
    //   return;
    // }
    //
    // case GET_TABELLE_PENDING: {
    //   draft.tabelle.loading = true;
    //   return;
    // }
    //
    // case GET_TABELLE_FAILED: {
    //   draft.tabelle.loading = false;
    //   return;
    // }
    //
    // case RESET_TABELLE: {
    //   draft.tabelle.data = undefined;
    //   draft.tabelle.loading = false;
    //   return;
    // }

    case GET_TAX_DEDUCTIONS_PENDING: {
      draft.taxDeductions.loading = true;
      return;
    }

    case GET_TAX_DEDUCTIONS: {
      draft.taxDeductions.data = get(action, "payload.data");
      draft.taxDeductions.loading = false;
      return;
    }

    case GET_TAX_DEDUCTIONS_FAILED: {
      draft.taxDeductions.loading = false;
      return;
    }
  }
}, initialState);
