// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PraticaConsumoOkDataControparti
 */
export interface PraticaConsumoOkDataControparti {
    /**
     * @type {number}
     * @memberof PraticaConsumoOkDataControparti
     */
    esponente1?: number;
    /**
     * @type {number}
     * @memberof PraticaConsumoOkDataControparti
     */
    esponente2?: number;
    /**
     * @type {number}
     * @memberof PraticaConsumoOkDataControparti
     */
    garante1?: number;
    /**
     * @type {number}
     * @memberof PraticaConsumoOkDataControparti
     */
    garante2?: number;
    /**
     * @type {number}
     * @memberof PraticaConsumoOkDataControparti
     */
    garante3?: number;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkDataControparti
     */
    titolareeffettivo1?: string;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkDataControparti
     */
    titolareeffettivo2?: string;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkDataControparti
     */
    titolareeffettivo3?: string;
    /**
     * @type {string}
     * @memberof PraticaConsumoOkDataControparti
     */
    coobbligato?: string;
    /**
     * @type {number}
     * @memberof PraticaConsumoOkDataControparti
     */
    esponente3?: number;
}
