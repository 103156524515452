import Numbro from "numbro";
import merge from "lodash.merge";

Numbro.registerLanguage({
  languageTag: "it-IT",
  delimiters: {
    thousands: ".",
    decimal: ",",
    thousandsSize: 0,
  },
  abbreviations: {
    thousand: "mila",
    million: "mil",
    billion: "b",
    trillion: "t",
  },
  ordinal: function () {
    return "º";
  },
  currency: {
    symbol: "€",
    position: "postfix",
    code: "EUR",
  },
  currencyFormat: {
    thousandSeparated: true,
    totalLength: 4,
    spaceSeparated: true,
    average: true,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: true,
      average: true,
    },
    fullWithTwoDecimals: {
      output: "currency",
      mantissa: 2,
      spaceSeparated: true,
      thousandSeparated: true,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: "currency",
      spaceSeparated: true,
      thousandSeparated: true,
      mantissa: 0,
    },
  },
});

Numbro.setLanguage("it-IT");

export interface INumbroFormattingOptions {
  thousandSeparated?: boolean;
  mantissa?: number;
  forceSign?: boolean;
  optionalMantissa?: boolean;
  trimMantissa?: boolean;
  negative?: "sign" | "parenthesis";
  average?: boolean;
  spaceSeparated?: boolean;
  output?: "currency" | "percent" | "byte" | "time" | "ordinal" | "number";
  totalLength?: number;
  currencyPosition?: "prefix" | "infix" | "postfix";
}

const defaultFormattingOptions = {
  thousandSeparated: true,
  mantissa: 2,
};

const defaultCurrencyFormattingOptions = {
  thousandSeparated: true,
  mantissa: 2,
  currencyPosition: "postfix",
  spaceSeparated: false,
};

/**
 * Format number values.
 * @param {string | number} number to convert. If it's a string, it converts it with parseFloat
 * @param {INumbroFormattingOptions} formattingOptions
 */
export const formatNumber = (number: number | string, formattingOptions?: INumbroFormattingOptions): string => {
  const convertedNumber = typeof number === "string" ? parseFloat(number) : number;
  const mergedFormattingOptions = merge({}, defaultFormattingOptions, formattingOptions);
  return Numbro(convertedNumber).format(mergedFormattingOptions);
};

/**
 * Format Currencies values.
 * @param {string | number} number to convert. If it's a string, it converts it with parseFloat
 * @param {INumbroFormattingOptions} formattingOptions
 */
export const formatCurrency = (number: number | string, formattingOptions?: INumbroFormattingOptions): string => {
  const convertedNumber = typeof number === "string" ? parseFloat(number) : number;
  const mergedFormattingOptions = merge({}, defaultCurrencyFormattingOptions, formattingOptions);
  return Numbro(convertedNumber).formatCurrency(mergedFormattingOptions).replace(/,00/, "");
};

/**
 * Converts a string from services to a number (float)
 * @param {string} number. The number
 */
export const convertStringToNumber = (number: string): number => {
  return parseFloat(number);
};
