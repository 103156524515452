// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    QuestionarioPreference,
} from './';

/**
 * @export
 * @interface PostQuestionarioAdeguatezza
 */
export interface PostQuestionarioAdeguatezza {
    /**
     * @type {number}
     * @memberof PostQuestionarioAdeguatezza
     */
    numeroPratica?: number;
    /**
     * @type {string}
     * @memberof PostQuestionarioAdeguatezza
     */
    provenienza?: PostQuestionarioAdeguatezzaProvenienzaEnum;
    /**
     * @type {Array<QuestionarioPreference>}
     * @memberof PostQuestionarioAdeguatezza
     */
    questionari?: Array<QuestionarioPreference>;
}

/**
 * @export
 * @enum {string}
 */
export enum PostQuestionarioAdeguatezzaProvenienzaEnum {
    CO = 'CO',
    LE = 'LE',
    CA = 'CA'
}

