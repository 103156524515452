import get from "lodash.get";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import uniq from "lodash.uniq";
import uniqWith from "lodash.uniqwith";
import { useContext, useEffect, useRef, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { normalizzatoreContext } from "./index";
import { filterAppellatives } from "./listaStrade.utils";
import {
  CapObservableGenerator,
  ComuniObservableGenerator,
  IAddress,
  INormalizzatoreLoading,
  INormalizzatoreResult,
  IProvincia,
  ListaStrade,
  ListaStradeObservableGenerator,
  NormalizzatoreObservableGenerator,
} from "./normalizzatore.types";

export interface IUseNormalizzatoreGetters {
  cap$: CapObservableGenerator;
  comuni$: ComuniObservableGenerator;
  listaStrade$: ListaStradeObservableGenerator;
  normalizzatore$: NormalizzatoreObservableGenerator;
}

export interface IUseNormalizzatoreSetters {
  cap: (cap?: string) => void;
  comune: (comune?: string) => void;
  indirizzo: (indirizzo?: string) => void;
  provincia: (provincia?: string) => void;
}

export interface IUseNormalizzatoreOptions {
  debounce?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isChanged = (prev: any, curr: any) => isEqual(prev, curr);

interface IUseNormalizzatore {
  (
    address: Partial<IAddress>,
    getters: IUseNormalizzatoreGetters,
    setters: IUseNormalizzatoreSetters,
    options?: IUseNormalizzatoreOptions
  ): {
    listaProvince: IProvincia[] | null;
    listaComuni: Record<string, string[]> | null;
    listaStrade: ListaStrade;
    normalizzatore: INormalizzatoreResult | null;
    loading: INormalizzatoreLoading;
    errors: {
      comuni: boolean;
    };
  };
}

export const useNormalizzatore: IUseNormalizzatore = (address, getters, setters, options) => {
  const ctx = useContext(normalizzatoreContext);
  const [capLoading, setCapLoading] = useState<boolean>(false);
  const [listaStradeLoading, setListaStradeLoading] = useState<boolean>(false);
  const [normalizzatoreLoading, setNormalizzatoreLoading] = useState<boolean>(false);

  const [listaComuni, setListaComuni] = useState<Record<string, string[]> | null>(null); // eg: { TN: ["Arco"] }
  const [comuniError, setComuniError] = useState<boolean>(false);

  const [listaProvince, setListaProvince] = useState<IProvincia[] | null>(null);
  const [listaStrade, setListaStrade] = useState<ListaStrade>([]);
  const [normalizzatore, setNormalizzatore] = useState<INormalizzatoreResult | null>(null);

  const getNormalizzatore$ = useRef(
    new Subject().pipe(
      debounceTime(options && options.debounce ? options.debounce : 1000),
      switchMap((address: Partial<IAddress>) => {
        return getters.normalizzatore$(address);
      })
    ) as Subject<Partial<IAddress> | INormalizzatoreResult | null>
  );

  const getListaStrade$ = useRef(
    new Subject().pipe(
      debounceTime(options && options.debounce ? options.debounce : 1000),
      distinctUntilChanged(isChanged),
      switchMap(address => {
        return getters.listaStrade$(address);
      })
    ) as Subject<Partial<IAddress> | ListaStrade>
  );

  if (!getters) {
    throw new Error("You did not pass getters");
  }

  if (!setters) {
    throw new Error("You did not pass setters");
  }

  useEffect(() => {
    setNormalizzatoreLoading(true);
    const normalizzatoreSubscription = getNormalizzatore$.current.subscribe(
      (res: INormalizzatoreResult) => {
        setNormalizzatoreLoading(false);
        // pass outside normalizzatore output
        setNormalizzatore(res);
      },
      () => {
        setNormalizzatoreLoading(false);
      }
    );
    return () => normalizzatoreSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    // also, if an address is set,
    if (normalizzatore && normalizzatore.output && normalizzatore.output.address) {
      const { cap, comune, indirizzo } = normalizzatore.output.address;

      // always overwrite indirizzo
      if (setters.indirizzo) {
        setters.indirizzo(indirizzo);
      }

      // and overwrite the cap and comune ONLY if it's different from the one selected
      if (cap !== address.cap && setters.cap) {
        setters.cap(cap);
      }

      if (comune !== address.comune && setters.comune) {
        setters.comune(comune);
      }
    }
  }, [normalizzatore]);

  useEffect(() => {
    const listaStradeSubscription = getListaStrade$.current.subscribe(
      (res: ListaStrade) => {
        setListaStradeLoading(false);

        if (res && res.length) {
          setListaStrade(res);
        } else {
          setListaStrade([]);
        }
      },
      () => {
        setListaStradeLoading(false);
      }
    );
    return () => listaStradeSubscription.unsubscribe();
  }, []);

  // Call to listaZipCode service
  useEffect(() => {
    if (address.cap && address.cap.length === 5) {
      // call zipCode hook
      setCapLoading(true);
      setComuniError(false);

      getters.cap$(address.cap).subscribe(
        res => {
          setCapLoading(false);

          if (res && !isEmpty(res)) {
            const provinceListFromResult = uniq(res.map(r => r.provincia));
            const comuniListFromResult = uniqWith(res, isEqual);

            // Filter province from Normalizzatore context
            const filteredProvince = ctx.province.filter(provincia =>
              provinceListFromResult.includes(provincia.codice)
            );

            // Group comuni by provincia --> E.g { "TN": [ ... ] }
            const groupedComuni: Record<string, string[]> = comuniListFromResult.reduce((acc, next) => {
              if (!get(acc, next.provincia)) {
                acc[next.provincia] = [next.comune];
              } else {
                acc[next.provincia].push(next.comune);
              }
              return acc;
            }, {});

            setListaProvince(filteredProvince);
            setListaComuni(groupedComuni);

            // Set form values
            if (filteredProvince.length > 0) {
              // if the actual provincia is in the list
              const activeProvinciaIsInResults =
                address.provincia &&
                filteredProvince.find(provincia => provincia.codice === address.provincia) !== undefined;

              if (activeProvinciaIsInResults) {
                // Do not set the provincia, as it's already selected,
                // but set the first available comune (if is not set),
                // or if not in the groupedComuni object

                const comuneIsNotSet = !address.comune;
                const comuneIsInCurrentProvinciaList =
                  address.comune &&
                  address.provincia &&
                  groupedComuni[address.provincia] &&
                  (groupedComuni[address.provincia] || []).includes(address.comune);

                if (comuneIsNotSet || !comuneIsInCurrentProvinciaList) {
                  const [comune] = get(groupedComuni, address.provincia || "");

                  if (setters.comune) {
                    setters.comune(comune);
                  }
                }
              } else {
                // set the first available provincia
                const [provincia] = filteredProvince;

                if (setters.provincia) {
                  setters.provincia(provincia.codice);
                }

                const comuniForProvincia = get(groupedComuni, provincia.codice, []);

                if (comuniForProvincia.length >= 1) {
                  // if there is only a comune for the selected provincia,
                  // then set it
                  const [comune] = comuniForProvincia;

                  if (setters.comune) {
                    setters.comune(comune);
                  }
                }
              }
            }
          } else {
            // if there are no results, set listaProvince and listaComuni to [] (not null)
            setListaProvince([]);
            setListaComuni({});

            // and reset its values

            if (setters.provincia) {
              setters.provincia("");
            }

            if (setters.comune) {
              setters.comune("");
            }
          }
        },
        () => {
          setCapLoading(false);
          setComuniError(true);
        }
      );
    }

    // address.provincia is added to the deps just to make it set the first comune
    // option when provincia changes (for multiple province's CAP -- e.g 18025)
  }, [address.cap, address.provincia, ctx.province]);

  // Call to listaStrade service
  useEffect(() => {
    if (!!address.cap && !!address.indirizzo) {
      const filteredAppellatives = filterAppellatives(address.indirizzo);

      if (filteredAppellatives.length > 2) {
        setListaStradeLoading(true);
        getListaStrade$.current.next({ ...address, indirizzo: filteredAppellatives });
      }
    }
  }, [address.cap, address.comune, address.indirizzo, ctx.province]);

  // Call to Normalizzatore service
  useEffect(() => {
    const haveValues = !!address.cap && !!address.indirizzo;

    if (haveValues) {
      getNormalizzatore$.current.next(address);
    }
  }, [address.cap, address.provincia, address.comune, address.indirizzo, ctx.province]);

  return {
    listaComuni,
    listaProvince,
    listaStrade,
    normalizzatore,
    loading: {
      cap: capLoading,
      listaStrade: listaStradeLoading,
      normalizzatore: normalizzatoreLoading,
    },
    errors: {
      comuni: comuniError,
    },
  };
};
