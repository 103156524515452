import { createContext } from "react";
import { initialCustomization } from "../../config/customization";
import { customizationHookState } from "./useCustomization";
import { ICustomizationState } from "./customization.types";

export const CustomizationContext = createContext<ICustomizationState>({
  data: initialCustomization,
  state: customizationHookState.IDLE,
  setState: () => {
    return;
  },
});
