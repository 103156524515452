import MaterialTextField, { HelperText, Input } from "@material/react-text-field";
import classNames from "classnames";
import { FieldProps } from "formik";
import get from "lodash.get";
import * as React from "react";
import Autosuggest, { ChangeEvent, InputProps, SuggestionsFetchRequestedParams } from "react-autosuggest";
import { IDataAttributes } from "../../../caricamento/types/form";
import { LOADING_LABEL } from "../../constants/labels";
import "./autocomplete_field.scss";

interface IOwnProps {
  // Shrink the field to a smallest variant. Defaults to true
  dense?: boolean;
  // Disable the field. Defaults to false
  disabled?: boolean;
  // Formik render prop
  formikField: FieldProps;
  // The label for the field
  label?: string;
  // Defaults to true
  fullWidth?: boolean;
  helperText?: string | React.ReactNode;
  isLoading?: boolean;
  textarea?: boolean;
  maxLength?: number | undefined;
  rows?: number | undefined;
  onChange?: (e: React.FormEvent) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  serverSideError?: string;
  // autocomplete options
  getDataSource?: (value: string) => void;
  dataSource: Array<unknown>;
  // Data attribute for consistent DOM targeting
  dataAttr?: IDataAttributes;
}

type Props = IOwnProps;

const AutocompleteField: React.FunctionComponent<Props> = ({
  dense = false,
  disabled = false,
  formikField,
  fullWidth = true,
  label = "",
  helperText,
  isLoading,
  onChange,
  textarea = false,
  maxLength,
  rows = 1,
  getDataSource,
  dataSource,
  onBlur,
  serverSideError,
  dataAttr,
}) => {
  const isPristine = !get(formikField.form.touched, formikField.field.name);
  const isValid = !((!isPristine && get(formikField.form.errors, formikField.field.name)) || serverSideError);

  const classes = classNames({
    "text-field--fullwidth": fullWidth,
  });

  // Spread operator is used to remove some values from the field variable, even if that variables are not used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange: formikOnChange, onBlur: formikOnBlur, ...field } = formikField.field;

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    // formikOnChange(e);
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    formikOnBlur(e);
  };

  const onSuggestionSelected = (event: React.FormEvent /*, data: SuggestionSelectedEventData<any>*/): void => {
    event.preventDefault();
  };

  const onSuggestionsFetchRequested = (params: SuggestionsFetchRequestedParams) => {
    if (getDataSource && get(params, "value") && get(params, "reason") && params.reason == "input-changed") {
      getDataSource(params.value);
    }
  };

  const onSuggestionsClearRequested = () => {
    dataSource = [];
  };

  // TODO: da generalizzare meglio
  const getSuggestionValue = (suggestion: string) => {
    return suggestion;
  };

  const renderSuggestion = (suggestion: string) => {
    return <span className="autocomplete-field__suggestion-item">{suggestion}</span>;
  };

  const onChangeAutocomplete = (event: React.FormEvent, params: ChangeEvent): void => {
    if (params.method !== "escape") {
      formikField.form.setFieldValue(formikField.field.name, params.newValue);
      changeHandler(event);
    }
  };
  const onBlurAutocomplete = (event: React.FocusEvent<HTMLInputElement> /*, params: BlurEvent<any>*/): void => {
    blurHandler(event);
  };

  const inputProps = {
    onBlur: onBlurAutocomplete,
    value: formikField.field.value,
    onChange: onChangeAutocomplete,
  };

  const renderInputComponent = (inputProps: InputProps<unknown>) => {
    return (
      <MaterialTextField
        outlined={false}
        className={classes}
        dense={dense}
        label={label}
        textarea={textarea}
        helperText={
          isLoading ? (
            <HelperText persistent={true} isValidationMessage={false}>
              {LOADING_LABEL}
            </HelperText>
          ) : serverSideError ? (
            <HelperText isValidationMessage={true} persistent={true} validation={true}>
              {serverSideError}
            </HelperText>
          ) : isPristine || isValid ? (
            helperText ? (
              <HelperText persistent={true} isValidationMessage={false}>
                {helperText}
              </HelperText>
            ) : (
              <HelperText>{/* Empty Component */}</HelperText>
            )
          ) : (
            <HelperText isValidationMessage={true} persistent={true} validation={true}>
              {get(formikField.form.errors, formikField.field.name)}
            </HelperText>
          )
        }
      >
        <Input
          data-cy={dataAttr && dataAttr.cypressSelector}
          maxLength={maxLength}
          rows={rows}
          isValid={isValid}
          disabled={disabled}
          type="text"
          {...field}
          {...inputProps}
          ref={(input: unknown) => {
            if (input && (input as { inputElement: HTMLInputElement }).inputElement) {
              inputProps.ref((input as { inputElement: HTMLInputElement }).inputElement);
            }
          }}
        />
      </MaterialTextField>
    );
  };

  return (
    <div className="autocomplete-field">
      <Autosuggest
        suggestions={dataSource}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
      />
    </div>
  );
};

export default React.memo(AutocompleteField);
