// tslint:disable
/**
 * tabelle
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    TabelleOutputDataTabelleMarcamod,
} from './';

/**
 * @export
 * @interface TabelleOutputDataLE
 */
export interface TabelleOutputDataLE {
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    codice?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    descrizione?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    durata_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    durata_max?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    durata_step?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    anticipo_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    anticipo_max?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    anticipo_step?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    riscatto_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    riscatto_max?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    riscatto_step?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    fin_min?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    fin_max?: string;
    /**
     * @type {number}
     * @memberof TabelleOutputDataLE
     */
    spese_min?: number;
    /**
     * @type {number}
     * @memberof TabelleOutputDataLE
     */
    spese_max?: number;
    /**
     * @type {number}
     * @memberof TabelleOutputDataLE
     */
    spese_fisse?: number;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    forza_spese?: string;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    contributo?: string;
    /**
     * @type {number}
     * @memberof TabelleOutputDataLE
     */
    viscontributo?: number;
    /**
     * @type {string}
     * @memberof TabelleOutputDataLE
     */
    tasso?: string;
    /**
     * @type {boolean}
     * @memberof TabelleOutputDataLE
     */
    comparazione?: boolean;
    /**
     * @type {Array<TabelleOutputDataTabelleMarcamod>}
     * @memberof TabelleOutputDataLE
     */
    marcamod?: Array<TabelleOutputDataTabelleMarcamod>;
}
