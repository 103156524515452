// tslint:disable
/**
 * caricamento
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    PagamentoCartaOKPayload,
} from './';

/**
 * 
 * @export
 * @interface PagamentoCartaOK
 */
export interface PagamentoCartaOK {
    /**
     * @type {boolean}
     * @memberof PagamentoCartaOK
     */
    success?: boolean;
    /**
     * @type {object}
     * @memberof PagamentoCartaOK
     */
    error?: object;
    /**
     * @type {number}
     * @memberof PagamentoCartaOK
     */
    remainingTry?: number;
    /**
     * @type {PagamentoCartaOKPayload}
     * @memberof PagamentoCartaOK
     */
    payload?: PagamentoCartaOKPayload;
}
