export const scrollTo = (y = 0, duration = 0): PromiseLike<void> => {
  const initialY = document.documentElement.scrollTop || document.body.scrollTop;
  const baseY = (initialY + y) * 0.5;
  const difference = initialY - baseY;
  const startTime = performance.now();

  const step = () => {
    let normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;

    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));

    if (normalizedTime < 1) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);

  return new Promise(resolve => {
    window.setTimeout(() => resolve(), duration);
  });
};

export const scrollToTop = (duration = 0): PromiseLike<void> => {
  // returns the promise returned from the scrollTo function, to make it "thenable"
  return scrollTo(0, duration);
};

const PRIMITIVES = ["number", "string", "boolean"];

/**
 * Check recursively if the object or children are equal to exitCondition.
 * Values are checked to be === to exitCondition only if string, number or boolean
 * If the type cannot be parsed (e.g is not Array, Object, number, string or boolean,
 * a warning is logged and the check will return false
 * @param objectToCheck - The object being checked
 * @param exitCondition - The value you expect on the object
 * @param allowEmpty - Value to be returned if an array is empty or an object has no keys
 *                     (e.g new Date()). Defaults to false.
 */
export const checkValuesRecursive = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectToCheck: number | string | Array<any> | Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  exitCondition: any,
  allowEmpty = false
): boolean => {
  if (Array.isArray(objectToCheck)) {
    if (objectToCheck.length === 0) {
      return allowEmpty;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return objectToCheck.every((item: any) => checkValuesRecursive(item, exitCondition, allowEmpty));
  }
  if (typeof objectToCheck === "object") {
    const keys = Object.keys(objectToCheck);
    return keys.length > 0
      ? keys.every((itemKey: string) => checkValuesRecursive(objectToCheck[itemKey], exitCondition, allowEmpty))
      : allowEmpty;
  }
  if (PRIMITIVES.includes(typeof objectToCheck)) {
    return objectToCheck === exitCondition;
  }
  console.warn("Type of objectToCheck (%o) cannot be parsed. objectToCheck: %o", typeof objectToCheck, objectToCheck);
  return false;
};
