import React from "react";
import "./tooltip.scss";

interface IOwnProps {
  icon: string;
  onTooltipClick?: () => void;
}

type Props = IOwnProps;

const Tooltip: React.FunctionComponent<Props> = ({ icon, onTooltipClick }) => {
  const handleClick = () => {
    if (onTooltipClick) {
      onTooltipClick();
    }
  };

  return (
    <a className="tooltip" onClick={handleClick}>
      <span className={"tooltip-icon icon material-icons"}>{icon}</span>
    </a>
  );
};

export default React.memo(Tooltip);
