import classNames from "classnames";
import isEmpty from "lodash.isempty";
import * as React from "react";
import { LoaderServiziOutputDataServiziCO } from "../../generated/loaderServizi";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { getFeatureFlag } from "../../lib/featureFlagsFunctions";
import { isMobile } from "../../lib/utility/responsive";
import CollapseText from "../collapseText/CollapseText";
import Container from "../container/container";
import "./summary.scss";
import { SummaryItem } from "./SummaryItem";

interface IOwnProps {
  beneRichiesto?: string;
  tan?: string;
  taeg?: string;
  taegMassimo?: number;
  rataMensile?: string;
  durata?: string;
  importoTotale?: string;
  assicurazioni?: LoaderServiziOutputDataServiziCO[] | undefined;
  theme?: "gradient" | "flat";
}

type Props = IOwnProps;

const Summary: React.FunctionComponent<Props> = ({
  beneRichiesto,
  durata,
  rataMensile,
  taeg,
  taegMassimo,
  tan,
  importoTotale,
  assicurazioni,
  theme = "flat",
}) => {
  const classes = classNames({
    summary: true,
    "summary--flat": theme === "flat",
    "summary--gradient": theme === "gradient",
  });

  const getFormattedValue = (value: string | number): string => {
    const convertToString = value.toString();
    const fullStopFormat = parseFloat(convertToString.replace(",", "."));
    const roundedValue = Math.ceil(fullStopFormat * 100) / 100;
    return roundedValue.toFixed(2).replace(".", ",");
  };

  const width = useWindowWidth();

  const descAssicurazioni = assicurazioni?.map(item => item.descrizione).toString();

  return (
    <div className={classes} style={{ animation: "fadeIn 1s" }}>
      <Container className={theme === "gradient" ? "summary__translucid-section" : ""}>
        {isMobile(width, 520) ? (
          <CollapseText
            collapseText={
              <>
                <ul className={classNames("summary__section-list")}>
                  {beneRichiesto && <SummaryItem label="Importo richiesto" value={beneRichiesto} />}
                  {tan && <SummaryItem label="TAN Fisso" value={`${getFormattedValue(tan || "")}%`} />}
                  {taeg && <SummaryItem label="TAEG Fisso" value={`${getFormattedValue(taeg || "")}%`} />}
                  {taegMassimo && getFeatureFlag("taegMassimoOnSummary") && (
                    <SummaryItem
                      label="TAEG Massimo"
                      value={`${getFormattedValue(taegMassimo || "")}%`}
                      info="Opzione Salto Rata e Cambio Rata"
                    />
                  )}
                  {rataMensile && <SummaryItem label="Rata Mensile" value={rataMensile} info="Escluse spese incasso" />}
                </ul>
              </>
            }
            expandText={
              <>
                <ul className={classNames("summary__section-list")}>
                  {beneRichiesto && <SummaryItem label="Importo richiesto" value={beneRichiesto} />}
                  {tan && <SummaryItem label="TAN Fisso" value={`${getFormattedValue(tan || "")}%`} />}
                  {taeg && <SummaryItem label="TAEG Fisso" value={`${getFormattedValue(taeg || "")}%`} />}
                  {taegMassimo && getFeatureFlag("taegMassimoOnSummary") && (
                    <SummaryItem
                      label="TAEG Massimo"
                      value={`${getFormattedValue(taegMassimo || "")}%`}
                      info="Opzione Salto Rata e Cambio Rata"
                    />
                  )}
                  {rataMensile && <SummaryItem label="Rata Mensile" value={rataMensile} info="Escluse spese incasso" />}
                  {durata && <SummaryItem label="Mesi" value={durata} />}
                  {importoTotale && <SummaryItem label="Importo tot. dovuto" value={importoTotale} />}
                </ul>
                {!isEmpty(assicurazioni) && (
                  <p className="summary__insurance">
                    <span className="summary__insurance-title">Assicurazioni attive:</span> {descAssicurazioni}
                  </p>
                )}
              </>
            }
          />
        ) : (
          <>
            <ul className="summary__section-list">
              {beneRichiesto && <SummaryItem label="Importo richiesto" value={beneRichiesto} />}
              {tan && <SummaryItem label="TAN Fisso" value={`${getFormattedValue(tan || "")}%`} />}
              {taeg && <SummaryItem label="TAEG Fisso" value={`${getFormattedValue(taeg || "")}%`} />}
              {taegMassimo && getFeatureFlag("taegMassimoOnSummary") && (
                <SummaryItem
                  label="TAEG Massimo"
                  value={`${getFormattedValue(taegMassimo || "")}%`}
                  info="Opzione Salto Rata e Cambio Rata"
                />
              )}
              {rataMensile && <SummaryItem label="Rata Mensile" value={rataMensile} info="Escluse spese incasso" />}
              {durata && <SummaryItem label="Mesi" value={durata} />}
              {importoTotale && <SummaryItem label="Importo tot. dovuto" value={importoTotale} />}
            </ul>
            {!isEmpty(assicurazioni) && (
              <p className="summary__insurance">
                <span className="summary__insurance-title">Assicurazioni attive:</span> {descAssicurazioni}
              </p>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default React.memo(Summary);
