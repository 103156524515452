// tslint:disable
/**
 * normalizzatore
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WpNormInputParametroINR620DSXSTRUFFDSXST1,
} from './';

/**
 * @export
 * @interface WpNormInputParametroINR620DSXSTRUFF
 */
export interface WpNormInputParametroINR620DSXSTRUFF {
    /**
     * @type {string}
     * @memberof WpNormInputParametroINR620DSXSTRUFF
     */
    DSXADD?: string;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFFDSXST1}
     * @memberof WpNormInputParametroINR620DSXSTRUFF
     */
    DSXST1?: WpNormInputParametroINR620DSXSTRUFFDSXST1;
    /**
     * @type {WpNormInputParametroINR620DSXSTRUFFDSXST1}
     * @memberof WpNormInputParametroINR620DSXSTRUFF
     */
    DSXST2?: WpNormInputParametroINR620DSXSTRUFFDSXST1;
}
