/* obj members can be snake-case to match the API spec */
import produce from "immer";
import get from "lodash.get";
import has from "lodash.has";
import isEmpty from "lodash.isempty";
import { customFormsIdleElements } from "../../../custom/libraries/redux/consts";
import {
  CARICAMENTO_STEPS,
  DOCUMENTARY_DATA,
  TARGATURA_INCAGLI_COVID_ERROR,
} from "../../../shared/constants/application";
import { LoaderServiziOutputData } from "../../../shared/generated/loaderServizi";
import {
  PraticaConsumoOk,
  PraticaConsumoOkDataErroriServizi,
  PreventivoOutput,
} from "../../../shared/generated/whiteLabel";
import { convertStringToNumber, formatNumber } from "../../../shared/lib/utility/numbers";
import { isCreditLifeInsurance } from "../../components/caricamento/caricamento.utils";
import {
  initEnablers,
  parseIntoCustomer,
  resetCustomer,
  setUpperCaseFields,
  updateCustomer,
  updateCustomerOnReviewErrors,
  validateCustomerDateFields,
} from "../../lib/dataHandling/dataHandling";
import initialCustomer from "../../lib/dataHandling/initialCustomer";
import { TCustomer } from "../../lib/dataHandling/types";
import {
  CLEAR_CARICAMENTO_STATE,
  GET_CUSTOMER,
  GET_CUSTOMER_FAILED,
  GET_CUSTOMER_PENDING,
  GET_ERRORI_FAILED,
  GET_ERRORI_PENDING,
  GET_ERRORI_SUCCEED,
  GET_PREVENTIVO,
  GET_PREVENTIVO_FAILED,
  GET_PREVENTIVO_PENDING,
  GET_SERVIZI,
  GET_SERVIZI_FAILED,
  GET_SERVIZI_PENDING,
  GET_TAX_DEDUCTIONS_FORM_ACTIVATION,
  GET_TAX_DEDUCTIONS_FORM_ACTIVATION_FAILED,
  GET_TAX_DEDUCTIONS_FORM_ACTIVATION_PENDING,
  HIDE_NOTIFICATION,
  MERGE_INTO_CUSTOMER,
  RESET_CUSTOMER,
  SAVE_PRATICA_FAILED,
  SAVE_PRATICA_FAILED_WITH_NO_ERRORS,
  SAVE_PRATICA_PENDING,
  SAVE_PRATICA_SUCCEED,
  SET_ACTIVE_STEP,
  SET_COMPLETE_STEP,
  SET_CUSTOMER_CACHE,
  SET_CUSTOMER_ENABLERS,
  SET_CUSTOMER_VALUES,
  SET_ERROR_STEP,
  SET_FORM_ENABLER,
  SET_IS_INCOMPATIBLE,
  SET_IS_REVIEWING,
  SET_IS_REVIEWING_SHOW_ALL,
  SET_IS_REVIEWING_SHOW_FORM,
  SET_ON_REVIEW_ERRORS,
  SET_UPPER_CASE_FIELDS,
  STOP_SAVE_PRATICA,
  UPDATE_CUSTOMER,
} from "./consts";
import { IState } from "./types";

export const initialState: IState = {
  isSpid: false,
  preventivo: {
    data: undefined,
    loading: false,
    updatedAt: "",
  },
  servizi: {
    data: undefined,
    loading: false,
  },
  formsIdleElements: [...CARICAMENTO_STEPS, ...customFormsIdleElements],
  apiBoundFormSections: {
    taxDeductions: {
      loading: false,
      error: null,
    },
  },
  customer: {
    data: initialCustomer,
    loading: false,
    updatedAt: "",
  },
  isReviewing: false,
  isReviewingShowAll: false,
  isPraticaSuccess: false,
  exitToken: "",
  isLoading: false,
  incompatible: {
    isIncompatible: false,
    message: "",
  },
  notifications: {
    message: "",
    timeout: 4000,
    visible: false,
  },
  hasUnrecoverableError: false,
  scrollToServerSideError: false,
};

export default produce((draft: IState, action) => {
  switch (action.type) {
    case CLEAR_CARICAMENTO_STATE: {
      return initialState;
    }
    case GET_CUSTOMER: {
      if (has(action.payload, "data") && !isEmpty(action.payload.data)) {
        parseIntoCustomer(action.payload, draft.customer.data, action.meta.allFormsKey);
        validateCustomerDateFields(draft.customer.data);
        initEnablers(draft.customer.data as TCustomer, action.payload.spid);
      }
      draft.isSpid = !!action.payload.spid;
      if (action.payload.spid) {
        const index = draft.formsIdleElements.findIndex(step => step.formName === DOCUMENTARY_DATA);
        draft.formsIdleElements[index].isComplete = true;
      }
      draft.customer.loading = false;
      return;
    }

    case SET_ON_REVIEW_ERRORS: {
      draft.customer.data = updateCustomerOnReviewErrors(draft.customer.data, action.payload, action.caller);
      return;
    }

    case GET_CUSTOMER_PENDING: {
      draft.customer.loading = true;
      return;
    }

    case GET_CUSTOMER_FAILED: {
      draft.customer.loading = false;
      return;
    }

    case GET_PREVENTIVO: {
      const result: PreventivoOutput = { ...action.payload };

      result.tan = result.tan && formatNumber(convertStringToNumber(result.tan), { mantissa: 3 });
      result.taeg = result.taeg && formatNumber(convertStringToNumber(result.taeg));

      draft.preventivo.data = result;

      if (get(action.payload, "assicurazioni") && draft.customer.data) {
        draft.customer.data.privacy6.isEnabled = isCreditLifeInsurance(action.payload.assicurazioni);
      }

      draft.preventivo.loading = false;
      return;
    }

    case GET_PREVENTIVO_PENDING: {
      draft.preventivo.loading = true;
      return;
    }

    case GET_PREVENTIVO_FAILED: {
      draft.preventivo.loading = false;
      return;
    }

    case GET_SERVIZI_PENDING: {
      draft.servizi.loading = true;
      return;
    }

    case GET_SERVIZI: {
      draft.servizi.data = action.payload.data as LoaderServiziOutputData;

      draft.servizi.loading = false;
      return;
    }

    case GET_SERVIZI_FAILED: {
      draft.servizi.loading = false;
      return;
    }

    case SET_CUSTOMER_VALUES: {
      draft.customer.data = updateCustomer(draft.customer.data, action.payload, "value");
      return;
    }

    case SET_UPPER_CASE_FIELDS: {
      draft.customer.data = setUpperCaseFields(draft.customer.data);
      return;
    }

    case SET_CUSTOMER_CACHE: {
      draft.customer.data = updateCustomer(draft.customer.data, action.payload, "cache");
      return;
    }

    case RESET_CUSTOMER: {
      draft.customer.data = resetCustomer(draft.customer.data, action.payload, action.meta.initial);
      return;
    }

    case SET_CUSTOMER_ENABLERS: {
      draft.customer.data = updateCustomer(draft.customer.data, action.payload, "isEnabled");
      return;
    }

    case UPDATE_CUSTOMER: {
      draft.customer.data = updateCustomer(draft.customer.data, action.payload, action.meta.address);
      return;
    }

    case MERGE_INTO_CUSTOMER: {
      const data = { ...draft.customer.data, ...action.payload };
      Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
      draft.customer.data = data;
      return;
    }

    case SET_ACTIVE_STEP: {
      const activeStepSlug = action.payload.stepSlug;
      draft.formsIdleElements.forEach(step => {
        step.isActive = step.formName === activeStepSlug;
      });
      return;
    }

    case SET_COMPLETE_STEP: {
      draft.formsIdleElements.forEach(step => {
        if (step.formName === action.payload.stepSlug) {
          step.isComplete = action.payload.isComplete;
        }
      });
      return;
    }

    case SET_ERROR_STEP: {
      draft.formsIdleElements.forEach(step => {
        if (step.formName === action.payload.stepSlug) {
          step.hasError = action.payload.hasError;
        }
      });
      return;
    }

    case SET_IS_REVIEWING: {
      draft.isReviewing = action.payload.isReviewing;
      return;
    }

    case SET_IS_REVIEWING_SHOW_ALL: {
      draft.isReviewingShowAll = action.payload.isReviewingShowAll;
      return;
    }

    case SET_IS_REVIEWING_SHOW_FORM: {
      draft.formsIdleElements.forEach(step => {
        if (step.formName === action.payload) {
          step.isReviewingShowForm = true;
        }
      });
      return;
    }

    case GET_ERRORI_PENDING: {
      draft.isLoading = true;
      return;
    }

    case GET_ERRORI_SUCCEED: {
      draft.isLoading = false;
      return;
    }

    case SAVE_PRATICA_PENDING: {
      draft.isLoading = true;
      draft.scrollToServerSideError = false;
      draft.formsIdleElements.forEach(step => {
        step.hasError = false;
      });
      return;
    }

    case SAVE_PRATICA_SUCCEED: {
      const response: PraticaConsumoOk = action.payload;

      draft.isPraticaSuccess = true;
      draft.exitToken = response.token2 || "";
      // draft.isLoading = false;
      return;
    }

    case GET_ERRORI_FAILED:
    case SAVE_PRATICA_FAILED: {
      const { serverSideErrors, enablers, unrecoverableErrors } = action.payload;

      draft.isReviewing = true;
      draft.isLoading = false;
      draft.customer.data = updateCustomer(draft.customer.data, serverSideErrors, "serverSideError");
      draft.customer.data = updateCustomer(draft.customer.data, enablers, "isEnabled");

      draft.scrollToServerSideError = true;
      if (!isEmpty(unrecoverableErrors)) {
        unrecoverableErrors.forEach((error: PraticaConsumoOkDataErroriServizi) => {
          if (error.codice && TARGATURA_INCAGLI_COVID_ERROR.includes(error.codice)) {
            draft.hasUnrecoverableError = true;
          }
        });
      }
      return;
    }

    case SAVE_PRATICA_FAILED_WITH_NO_ERRORS: {
      const { payload } = action;
      draft.isLoading = false;
      draft.notifications = { ...payload };
      return;
    }

    case STOP_SAVE_PRATICA: {
      draft.isPraticaSuccess = false;
      draft.isLoading = false;
      draft.formsIdleElements[0].isReviewingShowForm = true;
      draft.formsIdleElements[0].isActive = true;
      return;
    }

    case HIDE_NOTIFICATION: {
      draft.notifications.visible = false;
      return;
    }

    case SET_IS_INCOMPATIBLE: {
      const { payload } = action;
      draft.incompatible = payload;
      return;
    }

    case SET_FORM_ENABLER: {
      const formIdleElement = draft.formsIdleElements.find(
        formIdleElements => formIdleElements.formName === action.payload.formName
      );
      if (formIdleElement) {
        formIdleElement.isEnabled = action.payload.isEnabled;
      }
      return;
    }

    case GET_TAX_DEDUCTIONS_FORM_ACTIVATION_PENDING: {
      if (draft.apiBoundFormSections.taxDeductions) {
        draft.apiBoundFormSections.taxDeductions.loading = true;
      }
      return;
    }

    case GET_TAX_DEDUCTIONS_FORM_ACTIVATION: {
      if (draft.apiBoundFormSections.taxDeductions) {
        draft.apiBoundFormSections.taxDeductions.loading = false;
      }
      return;
    }

    case GET_TAX_DEDUCTIONS_FORM_ACTIVATION_FAILED: {
      const { error } = action;
      if (draft.apiBoundFormSections.taxDeductions) {
        draft.apiBoundFormSections.taxDeductions.loading = false;
        draft.apiBoundFormSections.taxDeductions.error = error;
        return;
      }
    }
  }
}, initialState);
