// tslint:disable
/**
 * whiteLabel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DomandaDependency,
    RispostaDescriptor,
} from './';

/**
 * @export
 * @interface DomandaDescriptor
 */
export interface DomandaDescriptor {
    /**
     * @type {string}
     * @memberof DomandaDescriptor
     */
    codiceDomanda?: string;
    /**
     * @type {number}
     * @memberof DomandaDescriptor
     */
    ordineDomanda?: number;
    /**
     * @type {string}
     * @memberof DomandaDescriptor
     */
    titoloDomanda?: string;
    /**
     * @type {string}
     * @memberof DomandaDescriptor
     */
    descrizioneDomanda?: string;
    /**
     * @type {DomandaDependency}
     * @memberof DomandaDescriptor
     */
    dipendenza?: DomandaDependency;
    /**
     * @type {Array<RispostaDescriptor>}
     * @memberof DomandaDescriptor
     */
    risposte?: Array<RispostaDescriptor>;
}
